<template>
  <div class="formitem">
      <div class="title">{{field.text}}</div>
      <van-uploader v-model="imgvalue" multiple :max-size="20 * 1024 * 1024" @oversize="onOversize" :afterRead="afterRead" :before-delete="beforeDelete"/>
  </div>
</template>

<script setup>
import { ref,defineProps, onMounted,toRefs } from "vue";
import API from "@/axios";
import {Toast} from "vant";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  defaultData: {
    type: Object,
    required: false
  },
  setData: {
    type: Function,
    required: false
  },
  name: {
    type: String,
    required: false
  }
});

const imgvalue = ref([]);
const result = ref([]);
let sort = 0;

onMounted(()=>{
  for(let i in defaultData.value){
    let imgData = defaultData.value[i];
    imgvalue.value.push({
      url: imgData.img,
      thumb_img: imgData.thumb_img
    });
    result.value.push(imgData);
    sort = imgData.sort;
  }
});
const onOversize = () => {
    Toast.fail('文件大小不能超过 20MB');
};
function afterRead(file){
  file.status = 'uploading';
  file.message = '上传中';
  let tsort = ++sort;
  let form = new FormData();
  form.append('file', file.file);
  API({
    url: '/web/uploadImg',
    method: "post",
    data:form,
    timeout: 60000,
  }).then((res) => {
    file.url = res.data.url;
    file.thumb_img = res.data.thumb_img;
    file.status = 'done';
    result.value.push({
      img: file.url,
      img_url: file.url,
      thumb_img: file.thumb_img,
      sort: tsort
    });
    props.setData(name.value, result.value);
  });
}
function beforeDelete(file,item){
  delete result.value[item.index];
  props.setData(name.value, result.value);
  return true;
}
const { field, name, defaultData } = toRefs(props);
</script>

<style lang="less" scoped>
.formitem {
  margin-top: 10px;
  margin-bottom: 30px;
}
.title{
  padding: 0px 20px 30px;
  font-size: @font-size;
  line-height: @font-size;
  color: @font-secondary-color;
}
</style>
