<template>
  <div class="table">
    <div class="title">
      <div class="itemcont">
        <div class="item" v-for="(item, index) in title" :class="data_url == item?'on' : ''" :key="index" @click="changeTab(item)">
          {{ index }}<span></span>
        </div>
      </div>
      <button type="button" v-if="search&&Object.keys(search).length>0" @click="showSearch">搜索</button>
    </div>
    <div class="cont">
      <!-- <div class="thead">
        <div class="item" v-for="(item, index) in fields" :key="index" 
        :style="'width: '+ (item.width?item.width:'80') +'px;'"  v-html="item.text"></div>
      </div> -->
      <div class="tbody" :style="hasPage?'max-height:480px;':''">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="dataLimit"
        >
          <div class="tr" v-for="(item, index) in tableData" :key="index">
            <!-- <div class="item" v-for="(tfield, k) in fields" :text="tfield.text" :key="k" :data-id="item.id?item.id:0"
             :style="'width: '+ (tfield.width?tfield.width:'80') +'px;'" v-html="item[k]"></div> -->

             <div class="item" v-for="(tfield, k) in fields" :text="tfield.text" :key="k" :data-id="item.id?item.id:0">
              <div class="text">{{fields[k].text}}</div>
              <div class="val">{{item[k]}}</div>
             </div>
          
          </div>
        </van-list>
      </div>
    </div>
    <searchForm :searchfun="searchFun" :search="search" :searchData="searchData" v-if="searchShow" />
  </div>
</template>

<script setup>
import { onMounted, toRefs, defineProps, ref } from "vue";
import searchForm from "@/components/selectbox";
import API from "@/axios";

const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: false
  },
  dataLimit:{
    type:Number,
    required:false,
    default:10
  }
});
const { form,pageUrl,dataLimit } = toRefs(props);
const loading = ref(true);
const finished = ref(true);
const fields = ref({});
const search = ref({});
const tableData = ref([]);
const dataUrl = ref('');
let apiUrl = '';
let currentPage = 1;
const hasPage = ref(false);
const searchShow = ref(false);
let searchData = ref({});


function onLoad() {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  if(hasPage.value){
    getData();
  }else{
     finished.value = true;
     loading.value = false;
  }
}

onMounted(() => {
  getFields();
});

function getApiUrl(){
  if(dataUrl.value.indexOf('?') === 0){
    if(apiUrl.indexOf('?') > -1){
      return apiUrl.substr(0, apiUrl.indexOf('?')) + dataUrl.value;
    }else{
      return apiUrl + dataUrl.value;
    }
  }
  return dataUrl.value;
}


function getFields() {
  apiUrl = pageUrl.value;
  hasPage.value = form.value.page ? true : false;
  dataUrl.value = form.value.data_url;
  let tfields = form.value.field;
  for(let i in tfields){
    if(!tfields[i].hide && !tfields[i].wap_hide && i != 'checkbox'){
      fields.value[i] = tfields[i];
    }
  }
  let tsearch = form.value.search?form.value.search:{};
  for(let i in tsearch){
    if(tsearch[i].default){
      searchData.value[i] = tsearch[i].default;
    }else{
      searchData.value[i] = '';
    }
    search.value[i] = tsearch[i];
  }
  getData();
}

function getData() {
  let url = getApiUrl();
  let limit = dataLimit.value;
  let data = {};
  data.page = currentPage;
  data.limit = limit;
  data.search = searchData.value;
  loading.value = true;
  API({
    url: url,
    method: "post",
    data:data,
  }).then((res) => {
    if(currentPage == 1){
        tableData.value = res.data.data;
    } else {
        tableData.value = tableData.value.concat(res.data.data);
    }
    if(res.data.data.length < limit){
        finished.value = true;
    } else {
        finished.value = false;
    }
    loading.value = false;
    currentPage++;
  });
}

function showSearch() {
  searchShow.value = true;
}
function searchFun(data, is_ok) {
  searchShow.value = false;
  if(is_ok){
    currentPage = 1;
    searchData.value = data;
    getData();
  }
}
</script>

<style lang="less" scoped>
.table {
  height: auto;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  .cont {
    overflow: hidden;
  }
  .thead {
    background: #e5ebff;
    height: 70px;
    width: auto;
    display: inline-flex;
    .item {
      color: @font-color;
      font-size: @font-size;
      line-height: 70px;
      font-weight: bolder;
      font-family: "Regular";
      text-align: center;
      white-space: nowrap;
      padding-left: 20px;
      display: block;
    }
    .item:last-of-type {
      padding-right: 20px;
    }
  }
  .tbody {
    max-height: 800px;
    overflow-y: auto;
    margin-bottom: 30px;
    width: 100%;
    display: inline-block;
    .tr {
      border-radius: 10px;
      box-shadow: 0 0 20px #e1e1e1;
      margin: 10px;
      padding: 15px;
      margin-bottom: 26px;
      .item {
        color: @font-secondary-color;
        font-size: @fontmini-size;
        line-height: 36px;
        overflow: hidden;
        text-align: center;
        word-break: break-all;
        display: flex;
        text-overflow: ellipsis;
        white-space: nowrap;
        justify-content: space-between;
        padding: 5px 0;
        font-size: 28px;
      }
    }
  }
}

.title {
  padding: 0 20px;
  display: flex;
  padding-top: 20px;
  margin-bottom: 20px;
  .itemcont{
    flex:1;
    min-width:0;
    display:flex;
  }
  .item {
    position: relative;
    padding-bottom: 20px;
    font-size: @subtitle-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-color;
    line-height: @subtitle-size;
    margin-right: 30px;
    span {
      position: absolute;
      left: 5%;
      right: 5%;
      bottom: 0;
      height: 4px;
      border-radius: 2px;
      background: @theme-color;
      display: none;
    }
  }
  .on {
    span {
      display: block;
    }
  }
}
.title{
  button {
    background: none;
    font-size: @font-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-color;
    line-height: @font-size;
    float: right;
  }
}
</style>
