<template>
  <div class="countbox">
    <div class="topimg"><img src="@/assets/img/toppic1.png" alt="" /></div>
    <div class="contant">
      <div class="remarks" v-if="form.remarks" v-html="form.remarks"></div>
      <div class="subcontant">
        <template v-for="(item, index) in fields" :key="index">
          <div class="listchart" v-if="item.type == 'map'" :class="item.tsort == 1 ? 'first' : ''">
            <div class="title">
              <span>{{ item.text }}</span>
            </div>
            <echartBox :field="item" :setId="index" :pageUrl="pageUrl" />
          </div>
          <div class="listchart" style="max-height: 510px" v-if="item.type == 'table'" :class="item.tsort == 1 ? 'first' : ''">
            <div class="title">
              <span>{{ item.text }}</span>
            </div>
            <template v-if="item.event == 'list'">
              <listBox :pageUrl="pageUrl" :form="item" />
            </template>
            <template v-else>
              <tableBox :pageUrl="pageUrl" :form="item" />
            </template>
          </div>
          <div class="listchart" v-if="item.type == 'tab'">
            <tabBox :data="item.value" />
          </div>
          <div class="listchart" v-if="item.type == 'overview'">
            <viewTabBox :form="item" :pageUrl="pageUrl" />
          </div>
          <infoBox v-if="item.type == 'info' || item.value == 'web.count.info'" :form="item" :title="item.text"
            :defaultData="defaultData[index]" />
        </template>

      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, toRefs } from "vue";
import tableBox from "@/resources/counts/TableBox";
import listBox from "@/resources/ListBox";
import echartBox from "@/resources/counts/EchartBox";
import tabBox from "@/resources/counts/TabBox";
import infoBox from "@/resources/counts/InfoBox";
import viewTabBox from "@/resources/counts/ViewTabBox";
const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: false
  },
  defaultData: {
    type: Object,
    required: false
  },
});
const { form, pageUrl, defaultData } = toRefs(props);
const fields = ref({});

onMounted(() => {
  getFields();
});
function getFields() {
  let index = 1;
  let tfields = form.value.field;
  // console.log(form.value)
  if (tfields) {
    for (let i in tfields) {
      tfields[i]['tsort'] = index;
      ++index;
    }
  }

  fields.value = tfields;
}
</script>

<style lang="less" scoped>
.countbox {
  position: relative;
  height: 100%;
  width: 100%;

  .topimg {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }
}

.contant {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
}

.subcontant {
  //padding-top: 45px;
  min-height: 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.listchart {
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  height: auto;
  margin-bottom: 50px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-size: @title-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-color;
      line-height: @title-size;
    }

    .more {
      background: @theme-color;
      font-size: @fontmini-size;
      font-family: "Bold";
      color: @font-white-color;
      line-height: @fontmini-size;
      width: 120px;
      height: 50px;
      border-radius: 25px;
    }
  }
}

.first {
  .title {
    span {
      color: @font-white-color;
    }
  }
}

.titletab {
  display: flex;
  padding: 24px 24px 0 24px;
  margin-bottom: 60px;

  .item {
    margin-right: 50px;
    font-size: @font-size;
    color: @font-white-color;
    position: relative;
    padding-bottom: 14px;

    span {
      position: absolute;
      background: @font-white-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }

  .on {
    font-weight: bolder;

    span {
      display: block;
    }
  }
}

.tabs {
  height: auto;
}

.remarks {
  padding: 10px 10px;
  font-size: @font-size;
  line-height: @title-size;
  color: @font-white-color;
  border: 1px solid @font-white-color;
  border-style: dashed;
  border-radius: 5px;
  margin: 20px 30px;
}</style>
