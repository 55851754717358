<template>
  <div class="formitem">
    <van-field
        v-model="industryText"
        is-link
        readonly
        :name="name"
        :label="field.text?field.text:'所属行业'"
        :placeholder="field.place?field.place:'请选择行业'"
        @click="industryShow = true"
        class="areabox"
        v-if="fieldShow"
    />
    <van-popup v-model:show="industryShow" round position="bottom" @click-overlay="onClickOverlay">
      <van-tree-select
          v-model:main-active-index="activeIndex"
          v-model:active-id="activeId"
          :items="items"
          @click-nav="clickOne"
          @click-item="saveIndustry"
      />
    </van-popup>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, toRefs, watch} from "vue";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  defaultData: {
    required: false
  },
  setData: {
    type: Function,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  returnType:{
    type:String,
    required:false,
    default:'text',//id、text、both
  },
  fieldShow:{
    type:Boolean,
    required: false,
    default:true
  },
  boxShow:{
    type:Boolean,
    required: false,
    default:false
  },
});

const { field, name, returnType,defaultData,boxShow } = toRefs(props);
const industryShow = ref(false);
const industryText = ref("");//比如KTv
const activeIndex = ref(0);//左侧下标
const activeId = ref(0);//右侧id,比如101
const result = ref('');
let items = [];//实际用到的配置

//默认配置
const defaultItems = [
  {text: '娱乐', id: 1, children: [
      {text: 'KTV',id: 101},
      {text: '游戏厅',id: 102},
      {text: '台球室',id: 103},
      {text: '电影院',id: 104},
      {text: '夜总会',id: 105},
      {text: '女仆馆',id: 106},
      {text: '轰趴馆',id: 107},
      {text: '网吧网咖',id: 108},
      {text: '卡丁车馆',id: 109},
      {text: '儿童娱乐',id: 110},
      {text: '私人影院',id: 111},
      {text: '剧本杀/密室逃脱',id: 112},
    ]},
  {text: '休闲', id: 2, children: [
      {text: '咖啡厅',id: 201},
      {text: '饮品店',id: 202},
      {text: '健身馆',id: 203},
      {text: '自助茶室',id: 204},
      {text: '洗浴汗蒸',id: 205},
      {text: '足浴按摩',id: 206},
      {text: '体育场馆',id: 207},
      {text: '茶楼/棋牌室',id: 208},
      {text: '酒吧(静吧）',id: 209},
      {text: '酒吧(嗨吧）',id: 210},
      {text: '酒吧(live house)',id: 211},
      {text: '户外露营/农家乐',id: 212},
      {text: '主机房',id: 213},
    ]},
  {text: '住宿', id: 3, children: [
      {text: '民宿',id: 301},
      {text: '酒店',id: 302},
      {text: '公寓',id: 303},
      {text: '电竞酒店',id: 304},
    ]},
  {text: '餐饮', id: 4, children: [
      {text: '餐厅',id: 401},
      {text: '西餐厅',id: 402},
      {text: '烧烤店',id: 403},
      {text: '火锅店',id: 404},
      {text: '中大型中餐馆',id: 405},
    ]},
  {text: '大场景', id: 5, children: [
      {text: '购物中心',id: 151},
      {text: '旅游景点',id: 502},
      {text: '活动演出',id: 503},
      {text: '综合娱乐馆',id: 504},
    ]},
  {text: '零售场景', id: 6, children: [
      {text: '便利店',id: 601},
      {text: '大型超市',id: 602},
    ]},
  {text: '丽人', id: 7, children: [
      {text: '美容美发',id: 701},
    ]},
];

onMounted(()=>{
  //配置
  if((field.value.value == undefined) || (field.value.value == '') || (field.value.value == [])){
    items = defaultItems;
  }else{
    items = field.value.value;
  }

  if((defaultData.value == '')
      ||( typeof(defaultData.value) == 'undefined')
      ){
    result.value ='';
  }else{
    industryText.value =defaultData.value;
    //默认的字段值
    let pregOk = false;
    let childIndustry,pregText;
    for(let i in items)
    {
      if(pregOk == true){break;}//匹配成功则跳出循环

      if(items[i].children == undefined || items[i].children == []){//无子级
        if(items[i].text == industryText.value){//成功匹配左侧文字
          activeIndex.value = i;//左侧下标
          pregOk = true;
        }
      }else{//有子级
        childIndustry = items[i].children;
        for(let b in childIndustry){
          pregText = childIndustry[b].text;//市名
          if(pregText == industryText.value){//成功匹配右侧文字
            activeIndex.value = i;//左侧下标
            activeId.value = childIndustry[b].id;//右侧id
            pregOk = true;
            break;
          }
        }
      }
    }

    if(returnType.value == 'id'){
      result.value = defaultData.value;
    }else if(returnType.value == 'both'){
      industryText.value =defaultData.value;
      result.value = {'text':defaultData.value,'value':activeId.value};
    }else {
      result.value = industryText.value = defaultData.value
    }

  }
});
//点击左侧菜单监听
const saveIndustry = (item) =>{
  if(returnType.value == 'id'){
    result.value = item.id;
  }else if(returnType.value == 'both'){
    result.value = {'text':item.text,'value':item.id};
  }else {
    result.value = item.text;
  }
  industryShow.value = false
  industryText.value = item.text;
  props.setData(name.value, result.value);
}
//点击右侧菜单监听
const clickOne = (index) => {
  if((typeof(items[index].children) == "undefined") || (items[index].children.length == 0)){
    if(returnType.value == 'id'){
      result.value = items[index].id;
    }else if(returnType.value == 'both'){
      result.value = {'text':items[index].text,'value':items[index].id};
    }else {
      result.value = items[index].text;
    }
    industryShow.value = false;
    industryText.value = items[index].text;
    props.setData(name.value, result.value);
  }
}

const onClickOverlay = () => {
  industryShow.value = false;
  props.setData(name.value, 'overlay');
};

///监听控制点击时展示地区弹窗
watch(boxShow, (newData) => {
  if (newData) {
    industryShow.value = true;
  } else {
    industryShow.value = false;
  }
});

</script>

<style lang="less" scoped>
.formitem {
  margin-bottom: 30px;
}
</style>
