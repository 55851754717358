<template>
  <div class="infobox">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="remarks" v-if="form.remarks" v-html="form.remarks"></div>
    <van-form>
      <template v-for="(item, index) in fields" :key="index">
        <div class="formitem" v-if="item.type == 'span'">
          <span class="title">{{item.text}}</span>
          <span class="title" v-html="subData[index]"></span>
        </div>
        <div class="formitem" v-if="item.type == 'hr'">
          <div class="hr" />
        </div>
      </template>

      <div style="margin: 16px">
        <button class="backBtn" @click="back">
          返回
        </button>
      </div>
    </van-form>
  </div>
</template>

<script setup>
import { ref,defineProps, onMounted,toRefs } from "vue";
import { useRouter } from "vue-router";
const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  defaultData: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false
  }
});

const router = useRouter();
const fields = ref({});
const subData = ref({});

const { form, defaultData, title } = toRefs(props);

onMounted(() => {
  getFields();
});

function getFields() {
  let tfields = form.value.field;
  for(let i in tfields){
    subData.value[i] = typeof(tfields[i]['default']) != 'undefined'?tfields[i]['default']:'';
  }
  fields.value = tfields;
  for(let i in defaultData.value){
    if(typeof(subData.value[i])!='undefined'){
      subData.value[i] = defaultData.value[i];
    }
  }
}

function back(){
  router.back();
}

</script>

<style lang="less" scoped>
.infobox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  overflow-y: auto;
  z-index: 50;
}
.hr{
  border-top: 1px solid @font-third-color;
  border-style: dashed;
  border-bottom: none;
}

.formitem {
  margin-bottom: 30px;
}
.title{
  padding: 0px 20px 30px;
  font-size: @font-size;
  line-height: @font-size;
  color: @font-secondary-color;
}
.backBtn{
  padding: 10px 40px;
  background: @theme-secondary-color;
  color: #fff;
  font-size: @font-size;
  margin-right: 50px;
}
.remarks{
  padding: 10px 10px;
  font-size: @font-size;
  line-height: @title-size;
  color: @font-secondary-color;
  border: 1px solid @font-third-color;
  border-style: dashed;
  border-radius: 5px;
  margin-bottom: 30px;
}
</style>
