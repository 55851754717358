<template>
  <div class="BDstoreDetail">
    <van-nav-bar
        :title="title"
        left-text="返回"
        left-arrow
        @click-left="back"
    />
    <div class="contant">
      <template v-for="(item,index) in fields" :key="index">
        <!--信息排版-->
        <template v-if="item.type == 'info'">
          <div class="title">{{item.title??''}}</div>
          <div class="setinfo">
            <template  v-for="(item2,index2) in item.field" :key="index2">
              <!--普通文字-->
              <div class="item" v-if="item2.type == 'span'">
                <div class="tit">{{item2.text}}</div>
                <div class="text" v-html="infoData[index][index2]"></div>
              </div>
              <!--多行文本域-->
              <div class="item" v-else-if="item2.type == 'textarea'">
                <div class="tit">{{item2.text}}</div>
                <div class="texts">{{infoData[index][index2]}}</div>
              </div>
              <!--标题组合-->
              <div class="top" v-else-if="item2.type == 'title_group'">
                <div class="left">
                  <div class="title">{{infoData[index][item2.title_field]}}</div>
                  <div class="addrtext">
                    {{infoData[index][item2.subtitle_field]}}
                  </div>
                </div>
                <div class="guide" v-show="item2.guide">
                  <div class="icon">
                    <img src="@/assets/img/pos.png" alt="" />
                  </div>
                </div>
              </div>
              <!--多图-->
              <div class="item" v-else-if="item2.type == 'images'">
                <div class="tit">{{item2.text}}</div>
                  <div class="pic" v-for="(img,ii) in infoData[index][index2]"  :key="ii">
                    <img :src="img.img_url" alt="" />
                  </div>
              </div>
              <!--单图-->
              <div class="item" v-else-if="item2.type == 'image'">
                <div class="tit">{{item2.text}}</div>
                <div class="pic"><img :src="infoData[index][index2]" alt="" /></div>
              </div>
            </template>
          </div>
        </template>
        <!--表格排版-->
        <template  v-else-if="item.type == 'table'">
          <div class="title">{{item.title??item.remarks}}</div>
          <tableBox :pageUrl="pageUrl" :form="item" :dataLimit="20" />
        </template>
      </template>
    </div>
    <div class="btns" v-show="showButton">
      <button type="button" v-for="(item, index) in buttons" :key="index" @click="footerBtn(item)">{{item.title}}</button>
    </div>
  </div>
</template>

<script setup>

import {ref, onMounted, defineProps, toRefs} from "vue";
import tableBox from "@/resources/counts/TableBox";
import {Dialog, Toast} from 'vant';
import { useRouter } from "vue-router";
import VIEW from "@/axios/view";
const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: false
  },
  infoData: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false
  },
  viewType: {
    type: String,
    required: false
  }
});
const { form, pageUrl, infoData ,title,viewType} = toRefs(props);
const router = useRouter();
const fields = ref({});
const buttons = ref([]);
const showButton = ref(false);
onMounted(() => {
  //字段处理
  if(typeof(form.value.h5_field) != 'undefined'){
    fields.value  = form.value.h5_field;
  }else{
    fields.value  = form.value.field;
  }
  //是否有底部按钮
  hasButton();

  //数据处理
  if(viewType.value == 'web.common.count'){
    getCountFields();
  }else if(viewType.value == 'web.common.info'){
    infoData.value.info = infoData.value;
    getInfoFields();
  }
});

//统计页面配置
function getCountFields(){}

//详情页面字段
function getInfoFields() {
  var tmp = JSON.parse(JSON.stringify(fields.value));
  fields.value.info = {
    type: 'info',
    field: tmp
  };
}

//是否有操作按钮
function hasButton() {
  for (let i in fields.value) {
    if (fields.value[i]['type'] == 'info') {
      Object.values(fields.value[i]['field']).forEach((item) => {
        if (item.type == 'button' && item.field) {
          buttons.value = item.field;
        }
      });
    }else if(fields.value[i]['type'] == 'button'){
      buttons.value = fields.value[i]['field'];
    }
  }

  if (buttons.value.length > 0) {
    showButton.value = true;
  }
}
//底部按钮
function footerBtn(item) {
  if (item.type == 'event_confirm') {
    Dialog.confirm({
      title: item.title,
      message: item.text ?? '您确定要'+item.title+'吗？',
      width: "80%"
    })
      .then(() => {
        //确认则请求接口
        VIEW({
          url: item.url,
          method: "post",
        }).then((res) => {
          if (res.data.status == 200) {
            // console.log(res)
            router.push({
              name: 'event',
              query: {url:encodeURIComponent(res.data.data.url)}
            });
          }
          Toast.fail(res.data.msg);
        });
      })
      .catch(() => {
        // on cancel
      });
  } else {
    router.push({
      name: 'event',
      query: {url:encodeURIComponent(item.url)}
    });
  }
}

function back(){
  router.back();
}
</script>

<style lang="less" scoped>
.BDstoreDetail {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 113px;
  right: 0;
  z-index: 999;
  background: #fff;
  width: 100vw;
  overflow: hidden;
}
.contant{
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
}
.title {
  margin-bottom: 20px;
  font-size: @subtitle-size;
  color: @font-color;
  font-weight: 600;
}
.setinfo {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;
    .left {
      flex: 1;
      min-width: 0;
      margin-right: 10px;
      .title {
        color: @font-color;
        font-size: @subtitle-size;
        line-height: @subtitle-size;
        margin-bottom: 20px;
        font-weight: bolder;
        font-family: "Regular";
      }
      .addrtext {
        color: @font-color;
        font-size: @subtitle-size;
        line-height: @subtitle-size;
        height: @subtitle-size;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
      }
    }
    .guide {
      width: 80px;
      height: 80px;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      overflow: hidden;
      .icon {
        width: 100%;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .tit {
      margin-right: 20px;
    }
    .text {
      color: #999;
      min-width: 0;
      flex: 1;
      text-align: right;
      overflow: hidden;
    }
    .texts {
      color: #999;
      flex: 1;
      min-width: 0;
    }
    .pic {
      width: 180px;
      img {
        width: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }
}

.btns {
  display: flex;
  padding: 20px 0 0 0;
  margin-bottom: 20px;
  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }
  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
    flex: 2;
  }
}
</style>
