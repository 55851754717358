<template>
  <div class="Radiobox">
    <div class="title" v-if="title">{{title}}</div>
    <div
      class="item"
      v-for="(item, index) in RadioArray"
      :class="index == radioindex ? 'on' : ''"
      :key="index"
      @click="checkClick(item, index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs, onMounted } from "vue";

const props = defineProps({
  RadioArray: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  select: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  setData: {
    type: Function,
    default: Function,
    required: true,
  },
});

const checktext = ref("");
const radioindex = ref("0");

function checkClick(item, index) {
  checktext.value = item;
  radioindex.value = index;
  props.setData(name.value, radioindex.value);
}
onMounted(() => {
  radioindex.value = select.value;
});

const { RadioArray,name, select } = toRefs(props);
</script>

<style lang="less" scoped>
.Radiobox {
  display: flex;
  flex-flow: wrap;
  .item {
    font-size: @font-size;
    line-height: @font-size;
    color: @font-secondary-color;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    padding: 15px;
    background: #efefef;
  }
  .on {
    color: @font-white-color;
    background: #6d8bf7;
    border: 1px solid #6d8bf7;
  }
}
.title{
  font-size: @font-size;
  line-height: @font-size;
  color: @font-secondary-color;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #00000000;
  padding: 15px;
}
</style>
