<template>
  <div class="filterBox">

    <div class="select">
      <template v-for="(item, index) in searchField" :key="index" >
        <template  v-if="item.type=='select_input'">
          <!-- 下拉搜索组合 -->
          <div class="search" >
            <div class="searchbox">
              <div class="selec"  @click="showStateArr[item.select.column] = true">
                {{item.place}}<img src="@/assets/img/arrow_down.png" alt="" />
              </div>
              <!-- 搜索左边下拉 -->
              <selectBox :field="item.select" :name="item.select.column" :defaultData="searchData[item.select.column]" returnType="both" :setData="setData" :boxShow="showStateArr[item.select.column]" :fieldShow="false"/>
              <!-- 搜索右边输入 -->
              <inputSearch :field="item.input" :name="item.input.column" :defaultData="searchData[item.input.column]" :setData="setData"/>
            </div>
            <button v-if="showFieldBtn == true" type="button" class="showtextitenbtn" @click="showTextItem">展示字段</button>
          </div>
        </template>

        <template v-else>
          <div class="item" :class="select_class"  @click="showStateArr[index] = true">
            <span>{{item.place}}</span>
            <img src="@/assets/img/arrow_down.png" alt="" />
          </div>

          <!-- 下拉选择器 -->
          <selectBox  v-if="item.type=='select'" :returnType="item.return_type ? item.return_type : 'both'"
                      :field="item" :name="index" :defaultData="searchData[index]" :setData="setData" :boxShow="showStateArr[index]" :fieldShow="false"/>
          <!-- 行业 -->
          <industryBox v-else-if="item.type=='industry'" returnType="both"
                       :field="item" :name="index" :defaultData="searchData[index]" :setData="setData" :boxShow="showStateArr[index]" :fieldShow="false"/>
          <!-- 地区 -->
          <areaBox  v-else-if="item.type=='area'"
                    :field="item" :name="index" :defaultData="searchData[index]" :setData="setData" :boxShow="showStateArr[index]" :fieldShow="false"/>
          <!-- 日期 -->
          <dateBox v-else-if="item.type=='date'" rangeType="range"
                   :field="item" :name="index" :defaultData="searchData[index]" :setData="setData" :boxShow="showStateArr[index]" :fieldShow="false"/>
          <!--范围输入框组合 -->
          <inputRange v-else-if="item.type=='input_range'"
                      :field="item" :name="index" @setMultipleData="setMultipleData" :boxShow="showStateArr[index]" />
          <!-- 下拉和日期组合 -->
          <daySelectPup v-else-if="item.type=='select_date'"
                        :field="item" :name="index" @setMultipleData="setMultipleData" :boxShow="showStateArr[index]" />
        </template>

      </template>
    </div>

  </div>
</template>

<script setup>
import {ref, defineEmits, defineProps, toRefs, onMounted} from "vue";
import areaBox from "@/resources/forms/AreaBox";
import dateBox from "@/resources/forms/DateBox";
import industryBox from "@/resources/forms/IndustryBox";
import selectBox from "@/resources/forms/SelectBox";
import inputSearch from "@/resources/forms/InputSearch";
import daySelectPup from "@/components/daySelectPup";
import inputRange from "@/components/inputRange";
import mitts from "@/bus";

// 调用父组件方法
const props = defineProps({
  searchField: {
    type: Object,
    required: true
  },
  searchData: {
    type: Object,
    required: true
  },
  showFieldBtn:{
    type: Boolean,
    required:false,
    default: false
  }
});
const { searchField,searchData } = toRefs(props);
const select_class = ref('');
const showStateArr = ref([]);//搜索字段对应显示控制

onMounted(() => {
  //下拉条件数量
  let selectCount= 0;
  for (let key in searchField.value) {
    if(searchField.value[key].type == 'select_input'){
      let select_key = searchField.value[key].select;
      showStateArr[select_key.column] = false;

      let input_key = searchField.value[key].input;
      showStateArr[input_key.column] = false;
    }else{
      // if(searchField.value[key].type == 'select' && (searchField.value[key].default != '' || (searchField.value[key].default != 0))){
        // searchField.value[key].place  = searchField.value[key].value[searchField.value[key].default];
      // }
      selectCount++;
    }
    showStateArr[key] = false;
  }
  //根据下拉条件数量设置宽度
  switch (selectCount){
    case 3:
      select_class.value = 'itemthree';
      break;
    case 2:
      select_class.value = 'itemtwo';
      break;
    case 1:
      select_class.value = 'itemone';
      break;
    default :
      select_class.value = '';
      break;
  }
});

//单独赋值搜索条件
function setData(name, res){
  showStateArr.value[name] = false;
  let valTmp,textTmp;
  let sendSearch =false;
  if(typeof(res) == "object"){
    valTmp = res.value;
    textTmp = res.text;
  }else{
    valTmp = textTmp  = res ;
  }

  //如果是未选择直接关闭弹窗则只需要改变显示状态
  if(valTmp == 'overlay'){
    return false;
  }

  //如果值有变化则发送数据请求
  if(searchData.value[name] != valTmp || name == 'title'){
    searchData.value[name] = valTmp;
    sendSearch = true;
  }
  if(name == 'title_type'){
    searchField.value['title_like'].place = textTmp;
  }else if(name == 'title'){
    searchField.value['title_like'].input.place = textTmp;
  }else if(textTmp != ''){
    //如果文字不为空则覆盖显示内容
    searchField.value[name].place = textTmp;
  }

  if(sendSearch == true){
    setFilter(searchData.value);
  }
}

//批量赋值搜索条件
function setMultipleData(val){
  let sendSearch = false;
  for (let key in val) {
    let tmpVal = val[key];
    //对应组件弹窗是否显示
    showStateArr.value[key] = false;
    //取消操作则跳出
    if(tmpVal == 'overlay'){
      sendSearch = false;
      break;
    }
    //搜索条件赋值
    searchData.value[key] = tmpVal;
    //显示文案赋值
    if(searchField.value[key]){
      searchField.value[key].place = tmpVal;
    }
    // 是否搜索
    sendSearch = true;
  }

  if(sendSearch == true){
    setFilter(searchData.value);
  }
}

// 调用父组件方法 传值给父级
const emit = defineEmits(["setFilter"]);
function setFilter(val) {
  emit("setFilter", val);
}

// 展示字段
function showTextItem(){
  mitts.emit('showTextItem');
}
</script>

<style lang="less" scoped>
.filterBox {
  border-radius: 15px;
  padding: 20px 10px 0 10px;
  background: #fff;
  margin: 0 24px;
  box-shadow: 0 0 10px #d7d7d7;
  margin-bottom: 30px;
}

.select {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  .item {
    width: calc(25% - 20px);
    height: 60px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    padding: 0 5px;
    border-radius: 10px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      flex: 1;
      min-width: 0;
      font-size: @font-size;
      color: @font-secondary-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    img {
      width: 20px;
      display: block;
      margin-left: 10px;
    }
  }
  .itemone{
    width: calc(100% - 20px);
  }
  .itemtwo{
    width: calc(50% - 20px);
  }
  .itemthree{
    width: calc(33.3% - 20px);
  }
}

.search {
  display: flex;
  margin: 0 10px;
  border-radius: 10px;
  height: 70px;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
  .searchbox{
    display: flex;
    flex: 1;
    min-width: 0;
    background: #f3f3f3;
    height: 100%;
    padding: 0 20px;
    align-items: center;
  }
  .selec {
    margin-right: 10px;
    font-size: @font-size;
    color: @font-secondary-color;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      display: block;
      margin-left: 10px;
    }
  }

  .showtextitenbtn{
    padding: 10px;
    font-size: 26px;
    color: #fff;
    background: @theme-secondary-color;
    border-radius: 10px;
    margin-left: 15px;
  }
}

</style>
