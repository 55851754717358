<template>
  <div class="textItemBox">
    <div class="cont">
      <van-checkbox v-model="item.checked" v-for="(item, index) in textItemList" :key="index" :name="item.val"
        @click="checkChange(index)">{{ item.title }}</van-checkbox>
    </div>
    <div class="btns">
      <button type="button" @click="backClick">返回</button>
      <button type="button" @click="submitClick">提交</button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs} from "vue";
import mitts from "@/bus";

const emit = defineEmits(["setTextItem"]);
const props = defineProps({
  textItemList: {
    type: Object,
    required: true,
  },
});
const { textItemList } = toRefs(props);

function checkChange(index) {
  textItemList.value[index].checked !== textItemList.value[index].checked
}

function submitClick() {
  // 向父组件传值
  emit("setTextItem", textItemList.value);

  mitts.emit('hideTextItem');
}

function backClick() {
  mitts.emit('hideTextItem');
}
</script>

<style lang="less" scoped>
.textItemBox {
  height: 60vh;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
}

.cont {
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
}

.btns {
  display: flex;

  button {
    background: #fff;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }

  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
  }
}
</style>