<template>
  <div class="formbox">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="remarks" v-if="form.remarks" v-html="form.remarks"></div>
    <van-form>
      <template v-for="(item, index) in fields" :key="index">
        <!-- 文本框 -->
        <van-cell-group inset v-if="item.type == 'input'">
          <van-field v-model="subData[index]" :name="index" v-show="showField[index]" :label="item.text" :placeholder="item.place?item.place:''" />
          <!-- 通过 pattern 进行正则校验 -->
<!--           <van-field
            v-model="value1"
            name="pattern"
            placeholder="正则校验"
          /> -->
        </van-cell-group>
        <template v-if="item.type == 'input_mobile'">
          <van-cell-group inset>
            <van-field v-model="subData[index]" type="tel" maxlength="11" :name="index" v-show="showField[index]" :label="item.text" :placeholder="item.place?item.place:''" >
            <!-- 通过 pattern 进行正则校验 -->
  <!--           <van-field
              v-model="value1"
              name="pattern"
              placeholder="正则校验"
            /> -->
            <template #button>
              <button type="button" class="sendbtn" v-if="isSendcode" @click="sendCodeClick">发送验证码</button>
              <button type="button" class="waitbtn" v-else>{{ timer }}秒后可重发</button>
            </template>
            </van-field>
            <span class="title" v-if="item.help">{{item.help}}</span>
          </van-cell-group>
          <van-cell-group inset v-show="showVeryCode">
            <van-field v-model="subData['mobile_code']" type="number" maxlength="4" :name="mobile_code" label="验证码：" placeholder="请输入验证码" />
          </van-cell-group>
        </template>

        <van-cell-group inset v-if="item.type == 'map'">
          <van-field v-model="subData[index]" :name="index" :label="item.text" :placeholder="item.place?item.place:''" @click="showMapClick(index)" />
          <!-- 通过 pattern 进行正则校验 -->
<!--           <van-field
            v-model="value1"
            name="pattern"
            placeholder="正则校验"
          /> -->
        </van-cell-group>

        <!-- 密码 -->
        <van-cell-group inset v-else-if="item.type == 'password'">
          <van-field
              v-show="showField[index]"
            v-model="subData[index]"
            type="password"
            :name="index"
            :label="item.text"
            :placeholder="item.place?item.place:''"
          />
        </van-cell-group>

        <!-- 文本域 -->
        <van-cell-group inset v-else-if="item.type == 'textarea'">
          <van-field
              v-show="showField[index]"
            v-model="subData[index]"
            ref="textarea"
            type="textarea"
            :name="index"
            :label="item.text"
            :rows="item.rows?item.rows:5"
            maxlength="50"
            :placeholder="item.place?item.place:''"
          />
        </van-cell-group>


        <!-- 行内元素 -->
        <div class="formitem" v-else-if="item.type == 'span'">
          <span class="title" v-show="showField[index]">{{item.text}}：</span>
          <span class="sub_title" v-show="showField[index]" v-html="item.value"></span>
        </div>

        <!-- 复选框 -->
        <div class="formitem" v-else-if="item.type == 'checkbox'">
          <checkBox v-show="showField[index]" :checkArray="item.value" :select="subData[index]" :name="index" :setData="setData" :title="item.text" />
        </div>

        <!-- 单选框 -->
        <div class="formitem" v-else-if="item.type == 'radio'">
          <radioBox  v-show="showField[index]" :RadioArray="item.value" :select="subData[index]" :name="index" :setData="setData" :title="item.text"/>
        </div>
        <!-- 步进器 -->
        <div class="formitem" v-else-if="item.type == 'number'">
          <van-stepper  v-show="showField[index]" v-model="subData[index]" />
        </div>
        <!-- 评分 -->
        <div class="formitem" v-else-if="item.type == 'star'">
          <van-rate  v-show="showField[index]" v-model="subData[index]" color="#486ffb" />
        </div>
        <!-- 滑块 -->
        <div class="formitem" v-else-if="item.type == 'rate'">
          <van-slider  v-show="showField[index]" v-model="subData[index]" button-size="20px" bar-height="2px" />
        </div>
        <!-- 多文件上传 -->
        <template v-else-if="item.type == 'images' || item.value == 'web.common.uploadImgs'">
          <imagesBox  v-show="showField[index]" :field="item" :name="index" :defaultData="subData[index]" :setData="setData" />
        </template>
        <!-- 单文件上传 -->
        <template v-else-if="item.type == 'image' || item.value == 'web.common.uploadImg'">
          <imageBox  v-show="showField[index]" :field="item" :name="index" :defaultData="subData[index]" :setData="setData" />
        </template>
        <!-- 下拉选择器 -->
        <template v-else-if="item.type == 'select'">
          <selectBox  v-show="showField[index]" :field="item" :name="index" :defaultData="subData[index]" :setData="setData" :returnType="item.return_type ? item.return_type : 'id'"/>
        </template>
        <!-- 地区选择器 -->
        <template v-else-if="item.type == 'area_select' || item.value == 'web.common.area'">
          <areaBox  v-show="showField[index]" :field="item" :name="index" :defaultData="subData[index]" :setData="setData" />
        </template>

        <!-- 行业 -->
        <template v-else-if="item.type == 'industry_select'">
          <industryBox  v-show="showField[index]" :field="item" :name="index" :defaultData="subData[index]" :setData="setData" />
        </template>

        <!-- 日期选择器 -->
        <template v-else-if="item.type == 'date'">
          <dateBox  v-show="showField[index]" :field="item" :name="index" :defaultData="subData[index]" :setData="setData" />
        </template>

        <template v-else-if="item.type == 'custom' && item.value == 'web.tool.userKeyInfo'">
          <van-cell-group inset>
            <van-field v-model="subData[index]" :name="index" v-show="showField[index]" :label="item.text" :placeholder="item.place?item.place:''" center>
              <template #button>
                <van-button size="large" round type="primary" class="ukey_button" @click="verifyUkey(index)">校验</van-button>
              </template>
            </van-field>
          </van-cell-group>

        </template>
      </template>

      <div style="margin: 16px">
        <van-button round type="primary" native-type="submit" class="subBtn" @click="submitClick">
          提交
        </van-button>
        <van-button round type="primary" native-type="submit" class="backBtn" @click="back">
          返回
        </van-button>
      </div>
    </van-form>

    <Map v-if="showMap" @closePup="closePup" @sendData="sendData" @getwxLocalposition="getwxLocalposition" :wxposition="wxposition" ref="BackMapData" />

    <!-- 旧手机验证码弹窗 -->
    <van-popup v-model:show="telVerifyCodeshow" position="center" :style="{ width: '80%' }" round>
      <div class="verifycodebox">
        <div class="title">修改验证</div>
        <div class="tip">您修改的信息需进行手机号码安全验证</div>
        <div class="tele">原手机号:{{subData.old_mobile}}</div>
        <div class="code">
          <div class="tit">验证码：</div>
          <div class="inp">
            <input type="text" v-model="subData.old_mobile_code" :disabled="disableSwitch" placeholder="请输入验证码" />
          </div>

          <button type="button" class="sendbtn" v-if="isTcSendcode" @click="sendTcCodeClick">发送验证码</button>
          <button type="button" class="waitbtn" v-else>{{ tcTimer }}秒后可重发</button>
        </div>

        <div class="btn">
          <button type="button" @click="tcCancer">取消</button>
          <button type="button" @click="tcSubmit">提交</button>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script setup>
import { ref,defineProps, onMounted,toRefs,watch,onUnmounted} from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import checkBox from "@/components/Checkbox";
import radioBox from "@/components/Radiobox";
import dateBox from "@/resources/forms/DateBox";
import imageBox from "@/resources/forms/ImageBox";
import imagesBox from "@/resources/forms/ImagesBox";
import selectBox from "@/resources/forms/SelectBox";
import areaBox from "@/resources/forms/AreaBox";
import industryBox from "@/resources/forms/IndustryBox";
import API from "@/axios";
import mitts from "@/bus";
import Map from "@/components/MapContainer";

const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: false
  },
  defaultData: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false
  }
});

const router = useRouter();
const fields = ref({});
const subUrl = ref('');
const subData = ref({});
const showField = ref({});
const showMap = ref(false);
const BackMapData = ref(null)
const mapIndex = ref("")
const wxposition = ref()
let apiUrl = '';

const { form, pageUrl, defaultData, title } = toRefs(props);
let haveFun = false;
let jqFunArr = [];
onMounted(() => {
  getFields();

  getwxconfig()
  
  if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
  }

  /*
  //获取当前界面dom操作
  import { getCurrentInstance} from "vue";
  const pageInstance = getCurrentInstance();
  const tagDomObj = pageInstance.refs;
  if(typeof(tagDomObj.divDom) !='undefined'){
    console.log(tagDomObj)
  }
   */
});
// //监听控制父级页面点击时展示地区弹窗
watch(subData.value, (newVal, oldVal) => {
  if(oldVal){console.log('旧数据')}
  if(haveFun == true){
    // console.log(newVal)
    // console.log(oldVal)
    for(let i in jqFunArr){
      if(jqFunArr[i] == 'divide_text'){
        // divide_text();
      }else if(jqFunArr[i] == 'add_membership_auth'){
        if(subData.value.add_membership_auth == 1){
          showField.value.user_key = true;
          showField.value.effective_date = true;
        }else{
          showField.value.user_key = false;
          showField.value.effective_date = false;
        }
      }else if(jqFunArr[i] == 'have_mb_radio'){
        if(subData.value.have_mb == 1){
          showField.value.mb_name = false;
          showField.value.mb_id = true;
        }else{
          showField.value.mb_name = true;
          showField.value.mb_id = false;
        }
      }
    }
  }
});

function getApiUrl(){
  // console.log('getApiUrl', subUrl.value, apiUrl);
  if(subUrl.value.indexOf('?') === 0){
    if(apiUrl.indexOf('?') > -1){
      return apiUrl + '&' + subUrl.value.substr(subUrl.value.indexOf('?') + 1);
    }else{
      return apiUrl + subUrl.value;
    }
  }
  return subUrl.value;
}


function getFields() {
  apiUrl = pageUrl.value;
  subUrl.value = form.value.sub_url;

  let tfields = form.value.field;
  for(let i in tfields){
    showField.value[i] = true;
    subData.value[i] = (typeof(tfields[i]['default']) != 'undefined'?tfields[i]['default']:'');
    if(tfields[i]['required']){
      tfields[i]['text'] = '*' + tfields[i]['text'];
    }
  }
  fields.value = tfields;
  for(let i in defaultData.value){
    if(typeof(subData.value[i])!='undefined'){
      subData.value[i] = defaultData.value[i];
    }
  }

  // 需要额外验证短信验证码使用
  if (typeof defaultData.value.old_mobile != 'undefined') {
    subData.value['old_mobile'] = defaultData.value.old_mobile
  }
  if (typeof defaultData.value.send_type != 'undefined') {
    subData.value['send_type'] = defaultData.value.send_type
  }

  jqFunArr = form.value.jqFun;
  if(typeof(jqFunArr) != 'undefined' && jqFunArr != '' && typeof(jqFunArr) == 'object'){
    haveFun = true;
  }
  // console.log('subData.value', subData.value);
}

function setData(name, value){
  //如果是未选择直接关闭弹窗则只需要改变显示状态
  if(value == 'overlay'){
    return false;
  }
  subData.value[name] = value;
}

function subPost() {
  let url = getApiUrl();
  let tfields = form.value.field;
  for(let i in tfields){
    if(tfields[i]['required']){
      if(!subData.value[i]){
        Toast.fail({
          message: '请设置：' + tfields[i]['text'].substr(1)
        });
        return false;
      }
    }
  }

  API({
    url: url,
    method: "post",
    data:subData.value,
  }).then((res) => {
    Toast.success({
      message: res.data.msg,
    });
    if(res.data.status == 200){
      if(res.data.data.url){
        mitts.emit("showLoading");
        router.push({
          name:'event',
          query:{url:encodeURIComponent(res.data.data.url)}
        })
      } else if(res.data.data.is_reload) {
        location.reload()
      }
    }
  });
}
function back(){
  // router.back();
  router.go(-2)
}

function showMapClick(keyName){
  mapIndex.value = keyName
  showMap.value = true;

  if(subData.value[keyName] !== ""){
    let data={
      lnglat: [Number(subData.value.lng),Number(subData.value.lat),],
      address: subData.value[keyName],
    }
    setTimeout(()=>{
      BackMapData.value.getMap(data);
    },500)
  }
}

function closePup(val) {
  showMap.value = val;
}

function sendData(val) {
  subData.value[mapIndex.value] = val.address
  subData.value.lng = val.lnglat[0]
  subData.value.lat = val.lnglat[1]
  showMap.value = false
}

// function sendMapData(val) {
  
// }

function getwxconfig(){
  API({
    url: "/web/wxJs",
    method: "post",
    data:{
      'url':encodeURIComponent(window.location.href.split("#")[0])
    }
  }).then((res) => {
    if(res.data.status == 200){
      // console.log(res.data)
      wxposition.value = res.data.data
     
    }
  });
}

/**
 * 根据用户标识查询as用户信息
 * @param index
 */
function verifyUkey(index) {
  let userKey = subData.value[index];
  API({
    url: '/web/ukeyInfo',
    method: "post",
    data:{user_key:userKey},
  }).then((res) => {
    if(res.data.status == 200){
      Toast({
        message: res.data.data.nickname,
        icon: res.data.data.headimg,
      });
    }else{
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function submitClick(){
  if (subData.value.old_mobile) {
    // 绑定手机时，存在需要短信验证的手机号时，二次弹窗获取短信验证码
    if (!subData.value.mobile) {
      Toast.fail('请填写手机号');
      return;
    }
    if (!subData.value.mobile_code) {
      Toast.fail('请填写手机验证码');
      return;
    }

    telVerifyCodeshow.value = true
    return;
  }

  subPost()
}

// 短信验证码相关
function tcCancer(){
  telVerifyCodeshow.value = false
}
//短信弹窗提交
function tcSubmit(){
  if(!subData.value.old_mobile_code){
    Toast.fail('请先填写验证码');
    return;
  }

  subPost(true);
}

// 验证码倒计时
const isSendcode = ref(true)
const timer = ref(60)

const travTimer = ref(null)
const showVeryCode = ref(false)

function sendCodeClick() {
  if(!subData.value.mobile){
    Toast.fail('请先填写手机号');
    return;
  }
  API({
    url: "/web/sendSms",
    method: "post",
    data: {
      phone:subData.value.mobile,
      type: subData.value.send_type ? subData.value.send_type : 'bank_bind',
      source: 'mobile_verify'
    }
  }).then((res) => {
    if (res.data.status == 200) {
      isSendcode.value = false
      showVeryCode.value = true
      timer.value = 60
      travTimer.value = setInterval(() => {
        if (timer.value == 0) {
          isSendcode.value = true;
          clearInterval(travTimer.value)
        } else {
          timer.value = timer.value - 1
        }
      }, 1000)
    }else{
      Toast.fail(res.data.msg);
    }
  });
}

// 弹窗验证码倒计时
const telVerifyCodeshow = ref(false)
const isTcSendcode = ref(true)
const tcTimer = ref(60)
const TCtravTimer = ref(null)

const disableSwitch = ref(true)
function sendTcCodeClick() {
  if(!subData.value.old_mobile){
    Toast.fail('暂无手机号');
    return;
  }
  API({
    url: "/web/sendSms",
    method: "post",
    data: {
      phone:subData.value.old_mobile,
      type: subData.value.send_type ? subData.value.send_type : 'bank_bind',
      source: 'mobile_verify'
    }
  }).then((res) => {
    if (res.data.status == 200) {
      isTcSendcode.value = false
      disableSwitch.value = false
      tcTimer.value = 60
      TCtravTimer.value = setInterval(() => {
        if (tcTimer.value == 0) {
          disableSwitch.value = true
          isTcSendcode.value = true;
          clearInterval(TCtravTimer.value)
        } else {
          tcTimer.value = tcTimer.value - 1
        }
      }, 1000)
    }else{
      Toast.fail(res.data.msg);
    }
  });
}

onMounted(()=>{
  mitts.emit("toptitle", false);
})

onUnmounted(()=>{
  mitts.emit("toptitle", true);
})
</script>

<style lang="less" scoped>
.ukey_button {
  width: 100px;
  font-size: 25px;
}
.formbox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  overflow-y: auto;
  z-index: 50;
}

.formitem {
  margin-bottom: 30px;
  display: flex;
}
.title{
  max-width: 300px;
  padding: 0px 20px 30px;
  font-size: @font-size;
  line-height: @font-size;
  color: @font-secondary-color;
}
.sub_title{
  flex: 1;
  min-width: 0;
  font-size: @font-size;
  line-height: @font-size;
}
.subBtn{
  padding: 10px 40px;
  background: @theme-secondary-color;
  color: #fff;
  font-size: @font-size;
  margin-right: 50px;
}
.backBtn{
  padding: 10px 40px;
  background: @theme-third-color;
  color: #fff;
  font-size: @font-size;
  margin-right: 50px;
}
.remarks{
  padding: 10px 10px;
  font-size: @font-size;
  line-height: @title-size;
  color: @font-secondary-color;
  border: 1px solid @font-third-color;
  border-style: dashed;
  border-radius: 5px;
  margin-bottom: 30px;
}

// 短信验证码相关
.sendbtn {
      color: #fff;
      background: @theme-secondary-color;
    }

    .waitbtn {
      color: #999;
      background: #f6f6f6;
    }
.verifycodebox {
  .title {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }

  .tip {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .tele {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    .tit {
      margin-right: 20px;
    }

    .inp {
      min-width: 0;
      flex: 1;
      display: flex;
      justify-content: flex-end;

      input {
        width: 100%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    button {
      font-size: 24px;
      padding: 10px;
      border-radius: 10px;
      margin-left: 10px;
    }

    .sendbtn {
      color: #fff;
      background: @theme-secondary-color;
    }

    .waitbtn {
      color: #999;
      background: #f6f6f6;
    }
  }

  .btn {
    display: flex;
    button {
      background: #fff;
      font-size: 28px;
      height: 66px;
      line-height: 66px;
      border-radius: 10px;
      margin-right: 20px;
      flex: 1;
      border: 3px solid @theme-secondary-color;
      color: @theme-secondary-color;
    }

    button:last-of-type {
      border: 3px solid @theme-secondary-color;
      background: @theme-secondary-color;
      color: @font-white-color;
      margin-right: 0;
    }
  }
}
</style>
