<template>
  <div class="listpage">
    <!-- <div class="topimg"><img src="@/assets/img/toppic1.png" alt="" /></div> -->
    <div class="contant">
      <FilterBox
        @setFilter="setFilter"
        :searchField="searchField"
        :searchData="searchData"
        :showFieldBtn = "showFieldBtn"
        v-if="searchShow"
      />

      <div class="btns" v-if="showTopButton">
        <topButtonBox :buttons="topButtons" />
        <!--
        <template v-for="(item, index) in topButtons">
          <button class="addbtn" type="button" :key="index"
              v-if=" (item.type == 1 || item.type == 'open') && auths.indexOf(item.url) > -1 "
              @click="topBtn(item)"
          >
            {{ item.title }}
          </button>
        </template>
         -->
      </div>

      <div class="listcont">
        <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadList"
            offset="10"
        >
          <!-- <datashow :field="APIurl" /> -->
          <template v-if="showCountField">
            <countDataBox
                :field="countField"
                :data="countData2"
                :searchfun="searchfun"
                showPosition="top"
            />
          </template>
          <ListBox
              :tableData="tableData"
              :tableField="tableField"
              :pageUrl="pageUrl"
              :detailUrl = "detailUrl"
              :authUpdateAt = "authUpdateAt"
              @setFilter="setFilter"
          />
        </van-list>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterBox from "@/components/FilterBox";
import countDataBox from "@/resources/lists/CountDataBox";
import ListBox from "@/resources/lists/ListTmp";
import topButtonBox from "@/components/FloatingBubble.vue";
import { toRefs, defineProps, onMounted, ref } from "vue";
import API from "@/axios";
import mitts from "@/bus";
import {Toast} from "vant";
// import { useRouter } from "vue-router/dist/vue-router";
// const router = useRouter();
const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: false,
  },
  countData: {
    type: Object,
    required: false,
  },
  auths: {
    type: Array,
    required: false,
  },
});
// 上拉加载
const loading = ref(true);
const finished = ref(false);
const searchField = ref({});
const tableData = ref({});
const tableField = ref({});
const dataUrl = ref("");
const searchShow = ref(false);
let apiUrl = "";
let currentPage = 1;
let hasPage = false;
const searchData = ref({});
const topButtons = ref([]);
const countField = ref({});
const countData2 = ref({});
const showCountField = ref(false);
const showTopButton = ref(false);
const detailUrl = ref('');
const authUpdateAt = ref('');
const showFieldBtn = ref(false);//是否显示展示字段按钮

const { form, pageUrl, countData } = toRefs(props);

//列表顶部操作按钮
// function topBtn(item) {
//   mitts.emit("showLoading");
//   router.push({
//     name: "event",
//     query: { url: encodeURIComponent(item.url) },
//   });
// }

onMounted(() => {
  mitts.emit("showLoading");
  getFields();
});

function getFields() {
  apiUrl = pageUrl.value;
  hasPage = form.value.page ? true : false;
  dataUrl.value = form.value.data_url;
  detailUrl.value = (form.value.h5_detail_url??'');
  authUpdateAt.value = (form.value.auth_updated_at??'');
  showFieldBtn.value = (form.value.h5_show_field_btn??false);

  //初始化顶部按钮，比如增删查改
  let h5Button = (form.value.h5_button ?? form.value.button);
  if (h5Button) {
    //过滤导出、隐藏字段等按钮
    h5Button.forEach((item)=>{
      if(item.type==1){
        topButtons.value.push(item);
      }
    })
    if(topButtons.value.length > 0){
      showTopButton.value = true;
    }
  }

  //初始化统计字段
  if (form.value.count && form.value.count.field) {
    countField.value = form.value.count.field;
    countData2.value = countData.value;
    showCountField.value = true;
  }
  //初始化列表字段
  tableField.value =(form.value.h5_field ?? form.value.field);

  //初始化搜索条件
  let tsearch = (form.value.h5_search ?? form.value.search);
  if (Object.keys(tsearch).length > 0) {
    searchShow.value = true;
    for (let i in tsearch) {
      if (tsearch[i].default || tsearch[i].default == 0) {
        searchData.value[i] = tsearch[i].default;
      } else {
        searchData.value[i] = "";
      }
      if (tsearch[i].type == "select_input") {
        searchData.value[tsearch[i].select.column] = tsearch[i].select.default_val;
        searchData.value[tsearch[i].input.column] = tsearch[i].input.default;
      }else if(tsearch[i].type == "select_date"){
        searchData.value[tsearch[i].select_column] = tsearch[i].select_default;
        searchData.value[tsearch[i].date_column] = tsearch[i].date_default;
      }
      searchField.value[i] = tsearch[i];
    }
  }

  getData();
}

function getData() {
  let url = getApiUrl();
  let limit = 15;
  let data = {};
  data.page = currentPage;
  data.limit = limit;
  data.search = searchData.value;
  loading.value = true;
  API({
    url: url,
    method: "post",
    data: data,
  }).then((res) => {
    if(res.data.status == 200){
      if (currentPage == 1) {
        tableData.value = res.data.data;
      } else {
        tableData.value = tableData.value.concat(res.data.data);
      }

      //如果接口未返回count_data则不刷新统计组件
      if(typeof(res.data.count_data) != 'undefined'){
        countData2.value = res.data.count_data;
      }

      if (res.data.data.length < limit) {
        finished.value = true;
      } else {
        finished.value = false;
      }
      loading.value = false;
      currentPage++;
    }else{
      Toast.fail(res.data.msg);
    }
  });
}

function getApiUrl() {
  if(dataUrl.value == undefined){
    return '';
  }
  if (dataUrl.value.indexOf("?") === 0) {
    if (apiUrl.indexOf("?") > -1) {
      return apiUrl.substr(0, apiUrl.indexOf("?")) + dataUrl.value;
    } else {
      return apiUrl + dataUrl.value;
    }
  }
  return dataUrl.value;
}

function setFilter(val) {
  // 获取筛选组件传来的值
  if (val) {
    searchData.value = val;
  }
  currentPage = 1;
  getData();
}

function loadList() {
  // console.log("加载");
  // 获取列表数据
  // 获取的值传值给   listArray.value

  // 加载完成 设置成下面的 可查看 shoplist.vue这个组件
  // 官方示例  https://vant-contrib.gitee.io/vant/v3/#/zh-CN/list
  // finished.value = true;
  // loading.value = false;
  if (hasPage) {
    getData();
  } else {
    finished.value = true;
    loading.value = false;
  }
}
function searchfun(data, is_ok) {
  // if (data) {
  //   console.log(data);
  // }
  // if (is_ok) {
  //   console.log(is_ok);
  // }
  if(is_ok == 1 && data){
    currentPage = 1;
    searchData.value = data;
    getData();
  }
}

</script>

<style lang="less" scoped>
.listpage {
  position: relative;
  height: 100vh;
  width: 100vw;
  .topimg {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
}
.contant {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  height: 100%;
}

.titletab {
  display: flex;
  padding: 24px 24px 0 24px;
  margin-bottom: 60px;
  .item {
    margin-right: 20px;
    font-size: @font-size;
    color: @font-white-color;
    position: relative;
    padding-bottom: 14px;
    span {
      position: absolute;
      background: @font-white-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }
  .on {
    font-weight: bolder;
    span {
      display: block;
    }
  }
}

.listcont {
  height: 10px;
  flex-grow: 1;
  overflow-y: auto;
}
</style>
