<template>
    <div class="inputRange">
        <van-popup v-model:show="selectPupShow" position="bottom"  @click-overlay="onClickOverlay" round>
            <div class="titlebox">
                <button type="button" @click="cancerClick">取消</button>
                <div class="title">{{field.text}}</div>
                <button type="button" @click="OkClick">确认</button>
            </div>
            <div class="input">
              <input type="text" :name="field.min_column.name" v-model="minmath" :placeholder="field.min_column.place">
              <span>-</span>
              <input type="text" :name="field.max_column.name" v-model="maxmath" :placeholder="field.max_column.place">
            </div>
        </van-popup>
    </div>
</template>

<script setup>
import {ref, defineProps, toRefs, defineEmits, watch} from "vue"

const emit = defineEmits(["setMultipleData"]);

const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  name: {
    type: String,
    required: false
  },
  boxShow: {
        type: Boolean,
        required: false,
        default: false
  },
});

const { field,name,boxShow} = toRefs(props);

const selectPupShow = ref(false)
const minmath = ref("")
const maxmath = ref("")
const returnData = ref({});


function OkClick() {
  selectPupShow.value = false;
  returnData.value[name.value] = minmath.value+'至'+maxmath.value;
  returnData.value[field.value.min_column.name] = minmath.value;
  returnData.value[field.value.max_column.name] = maxmath.value;
  emit("setMultipleData", returnData.value);
}

function cancerClick() {
  selectPupShow.value = false;
  returnData.value[name.value] ='overlay';
  emit("setMultipleData",returnData.value);
}

const onClickOverlay = () => {
  selectPupShow.value = false;
  returnData.value[name.value] ='overlay';
  emit("setMultipleData", returnData.value);
};

watch(boxShow, (newData) => {
    if (newData) {
        selectPupShow.value = true;
    } else {
        selectPupShow.value = false;
    }
});
</script>

<style lang="less" scoped>
.titlebox {
    display: flex;
    padding: 20px;

    .title {
        flex: 1;
        text-align: center;
    }

    button {
        background: none;
    }
}

.input {
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;

    input {
        flex: 1;
        min-width: 0;
        border: 2px solid #dfdfdf;
        line-height: 46px;
        font-size: 26px;
        border-radius: 10px;
        text-align: right;
        padding: 0 10px;
        box-sizing: border-box;
    }

    span {
        margin: 0 20px;
    }
}</style>