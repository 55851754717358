<template>
  <div class="container">
    <div class="topimg"><img src="@/assets/img/toppic1.png" alt="" /></div>
    <div class="contant">
      <listBox
        v-if="showComponent && componentName == 'listBox'"
        :pageUrl="pageUrl"
        :form="form"
        :title="title"
        :countData="countData"
        :auths="auths"
      />
      <formBox
        v-else-if="showComponent && componentName == 'formBox'"
        :pageUrl="pageUrl"
        :form="form"
        :title="title"
        :defaultData="defaultData"
      />
      <countBox
        v-else-if="showComponent && componentName == 'countBox'"
        :pageUrl="pageUrl"
        :form="form"
        :defaultData="defaultData"
      />
      <infoBox
        v-else-if="showComponent && componentName == 'infoBox'"
        :form="form"
        :title="title"
        :defaultData="defaultData"
      />
      <newListBox
        v-else-if="showComponent && componentName == 'newListBox'"
        :pageUrl="pageUrl"
        :form="form"
        :title="title"
        :countData="countData"
        :auths="auths"
      />
      <newInfoBox
          v-else-if="showComponent && componentName == 'newInfoBox'"
          :pageUrl="pageUrl"
          :form="form"
          :title="title"
          :infoData="defaultData"
          :viewType="resData[0]"
      />
      <!-- <div class="component">
        <component :is="componentName"
         v-if="showComponent" 
         :pageUrl="pageUrl" 
         :form="form" 
         :title="title" 
         :defaultData="defaultData" 
         :countData="countData"></component>
      </div> -->
    </div>

    
  </div>
</template>

<script setup>
import { onMounted, ref, onUpdated } from "vue";
import { useRoute, useRouter } from "vue-router";
import VIEW from "@/axios/view";
import listBox from "@/resources/ListBox";
import formBox from "@/resources/FormBox";
import countBox from "@/resources/CountBox";
import infoBox from "@/resources/InfoBox";
import newListBox from "@/resources/NewListBox";
import newInfoBox from "@/resources/NewInfoBox";
import mitts from "@/bus";
import {Toast} from "vant";

const componentName = ref("");
const router = useRouter();
const route = useRoute();
const secondMenus = ref([]);
const pageUrl = ref("");
const form = ref({});
const defaultData = ref({});
const countData = ref({});
const showComponent = ref(false);
let currentUrl = "";
const title = ref("");
const resData = ref({});
const auths = ref([]);

onMounted(() => {
  window.toEvent = function (url) {
    mitts.emit("showLoading");
    router.push({
      name: "event",
      query: { url: encodeURIComponent(url) },
    });
  };

  getForm();
  setTimeout(()=>{
    mitts.emit("toptitle", true);
  },300)
});
function getForm() {
  currentUrl = decodeURIComponent(route.query.url);
  if (!currentUrl) {
    currentUrl = decodeURIComponent(route.params.url);
  }
  // console.log("currentUrl", currentUrl, route.params);
  pageUrl.value = currentUrl;
  VIEW({
    url: pageUrl.value,
    method: "post",
  }).then((res) => {
    if(res.data.status != 200){
      Toast.fail({
        message: res.data.msg,
        onClose:()=>{
          router.push({
            path: "/enter/h5Home",
          });
        },
      });
    }
    let data = res.data.data;
    resData.value = data;
    countData.value = (data[1]["count_data"]??[]);
    title.value = data[1]["title"];
    form.value = data[1]["form"];
    auths.value = data[1]["auths"];
    
    secondMenus.value =
      data[1]["second_menu"] && data[1]["second_menu"]["childs"]
        ? data[1]["second_menu"]["childs"]
          : [];

    if(data[1].second_menu !== undefined ){
      if(data[1].second_menu.h5_main_nav_Selected){
        mitts.emit("navindex",data[1].second_menu.h5_main_nav_Selected)
      }
    }
    
    mitts.emit("auths", auths.value )
    mitts.emit("secondMenus", secondMenus.value )

    if(form.value.h5_tpl){
      if (form.value.h5_tpl == "newList") {
        componentName.value = "newListBox";
      } else if(form.value.h5_tpl == "newInfo"){
        defaultData.value = data[1]["data"];
        componentName.value = "newInfoBox";
      } else if (form.value.h5_tpl == 'formBox') {
        defaultData.value = data[1]["data"];
        componentName.value = "formBox";
      } else {
        mitts.emit("routTo", form.value.h5_tpl);
      }
    } else if (data[0] == "web.common.list") {
      componentName.value = "listBox";
    } else if (data[0] == "web.common.add") {
      componentName.value = "formBox";
    } else if (data[0] == "web.common.edit") {
      defaultData.value = data[1]["data"];
      componentName.value = "formBox";
    } else if (data[0] == "web.common.count") {
      defaultData.value = data[1]["data"];
      componentName.value = "countBox";
    } else if (data[0] == "web.common.info") {
      defaultData.value = data[1]["data"];
      componentName.value = "infoBox";
    } 
    showComponent.value = true;
    mitts.emit("navs", data[1]["navs"]);

    if(pageUrl.value == "/web/businessList"){
      mitts.emit("navindex","business")
    }else if(pageUrl.value == "/web/userBillList"){
      mitts.emit("navindex","bills")
    }else if(pageUrl.value == "/web/storeReport"){
      mitts.emit("navindex","statistics")
    }
  });
  setTimeout(() => {
    mitts.emit("closeLoading");
  }, 500);
}


onUpdated(() => {
  if (currentUrl != decodeURIComponent(route.query.url)) {
    componentName.value = "";//重置防止获取当前组件类型又发起一次post请求
    showComponent.value = false;
    getForm();
  }
});
</script>

<style lang="less" scoped>
.container {
  position: relative;
  height: 100%;
  width: 100%;
  .topimg {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
}
.contant {
  position: absolute;
  left: 0;
  top: 100px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
}

.topZero{
  top: 0;
}
.titletab {
  display: flex;
  padding: 24px 24px 0 24px;
  margin-bottom: 60px;
  justify-content: space-between;
  .tabNr {
    display: flex;
  }
  .item {
    margin-right: 20px;
    font-size: @font-size;
    color: @font-white-color;
    position: relative;
    padding-bottom: 14px;
    span {
      position: absolute;
      background: @font-white-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }
  .on {
    font-weight: bolder;
    span {
      display: block;
    }
  }
  .addbtn {
    margin-left: 10px;
    font-size: 32px;
    color: @font-white-color;
    background: none;
    font-weight: 600;
  }
}
.component {
  height: 10px;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}
</style>
