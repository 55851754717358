<template>
    <div class="daySelectPup">
        <van-popup v-model:show="selectPupShow" position="bottom"  @click-overlay="onPopupCancel" round>
            <van-picker :title="field.select_text" :columns="selectColumns"  :default-index="selectDefaultIndex"
                        @confirm="onSelectClick" @cancel="onSelectCancel" />
        </van-popup>

        <van-calendar v-model:show="dayPupShow" type="range"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :show-confirm="false"
                      :allow-same-day="true"
                      color="#486ffb"
                      @confirm="onDateConfirm"
                      @close="onDateClose"/>

    </div>
</template>

<script setup>

import {ref, defineProps, toRefs, defineEmits, watch, onMounted} from 'vue';
const emit = defineEmits(["setMultipleData"]);

const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  name: {
    type: String,
    required: false
  },
  boxShow: {
    type: Boolean,
    required: false,
    default: false
  },
});

const {field,name,boxShow } = toRefs(props);
const returnData = ref({});
const selectPupShow = ref(false)
const dayPupShow = ref(false)

const rangeType = ref("")
const dayrange = ref("")

const selectColumns = [];
const selectDefaultIndex = ref(0);
let selectItems = {};

const minDate = ref(new Date(2020, 0, 1));
const maxDate = ref(new Date());


onMounted(()=>{
  //配置
  selectItems = field.value.select_val;
  //转成下拉组件需要的的数据格式
  let index = 0;
  for(let i in selectItems){
    selectColumns.push({text:selectItems[i], value:i});
    if(field.value.select_default == i){
      selectDefaultIndex.value = index;
    }
    ++index;
  }
});

//下拉选中
function onSelectClick(item) {
  selectPupShow.value = false
  rangeType.value = item.value;
  if (rangeType.value == "diy") {
    returnData.value[field.value.select_column] = rangeType.value;
    dayPupShow.value = true;
  } else {
    returnData.value[name.value] = item.text;
    returnData.value[field.value.select_column] = rangeType.value;
    returnData.value[field.value.date_column] = '';
    emit("setMultipleData", returnData.value);
  }
}

const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
//日期选择
const onDateConfirm = (values) => {
  const [start, end] = values;
  dayPupShow.value = false;
  dayrange.value = `${formatDate(start)} ~ ${formatDate(end)}`;
  returnData.value[name.value] = dayrange.value;
  returnData.value[field.value.date_column] = dayrange.value;
  emit("setMultipleData", returnData.value);
};

//下拉取消
function onSelectCancel() {
  selectPupShow.value = false;
  rangeType.value = ""
  dayrange.value = ""
  returnData.value[name.value] ='overlay';
  emit("setMultipleData",returnData.value);
}

//弹窗取消
const onPopupCancel = () => {
  selectPupShow.value = false;
  rangeType.value = ""
  dayrange.value = ""
  returnData.value[name.value] ='overlay';
  emit("setMultipleData",returnData.value);
};

//日期弹窗关闭
const onDateClose = () => {
  selectPupShow.value = false;
  rangeType.value = ""
  dayrange.value = ""
  returnData.value[name.value] ='overlay';
  emit("setMultipleData",returnData.value);
};

watch(boxShow, (newData) => {
  if (newData) {
    selectPupShow.value = true;
  } else {
    selectPupShow.value = false;
  }
});

</script>

<style lang="less" scoped>
.dayrange {
    display: flex;
    align-items: center;

    .icon {
        width: 36px;
        margin-right: 10px;

        img {
            width: 100%;
            display: block;
        }
    }

    .text {
        font-size: @font-size;
        line-height: @font-size;
        color: @font-secondary-color;
    }
}
</style>