<template>
  <div class="listbox">
    <div class="remarks" v-if="form.remarks" v-html="form.remarks"></div>
    <template v-if="showCountField">
      <countDataBox
       :field="countField"
       :data="countData2"
       :searchfun="searchfun"
      />
    </template>
    <div class="subtitle" v-if="title || Object.keys(search).length>0" :style="titleColor?'color:'+titleColor:''">
      {{ title?title:'' }}
      <template v-for="(item, index) in topButtons">
      <button class="left-btn"
       type="button"
       :key="index"
       v-if="(item.type == 1 || item.type=='open') && auths.indexOf(item.url) > -1"
       @click="topBtn(item)">{{item.title}}</button>
      </template>
      <button class="right-btn" type="button" v-if="Object.keys(search).length>0" @click="showSearch">搜索</button>
    </div>
    <div class="lists">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        offset="10"
      >
        <div class="item" v-for="(item, index) in tableData" :key="index" :data-id="item.id?item.id:0">
          <div class="listtext" v-for="(tfield, k) in fields"  :key="k">
            <template v-if="k=='operate'">
              <div class="itemname">操作</div>
              <div class="itemval">
                <template v-for="(opt, i) in tfield">
                  <button class="opt-btn"
                   type="button"
                   v-if="item[k]&&item[k][i]==0?false:true"
                   :key="i"
                   @click="optBtn(opt, item)">{{opt.title}}</button>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="itemname" v-html="tfield.text"></div>
              <div class="itemval" v-html="item[k]"></div>
            </template>
          </div>
        </div>
      </van-list>
    </div>
    <searchForm :searchfun="searchfun" :search="search" :searchData="searchData" v-if="searchShow" />
  </div>
</template>

<script setup>
import { toRefs, defineProps, onMounted, ref } from "vue";
import countDataBox from "@/resources/lists/CountDataBox";
import searchForm from "@/components/selectbox";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import API from "@/axios";
import mitts from "@/bus";
const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: false
  },
  titleColor: {
    type: String,
    required: false
  },
  countData: {
    type: Object,
    required: false
  },
  auths: {
    type: Array,
    required: false
  }
});

const router = useRouter();
const loading = ref(true);
const finished = ref(true);
const fields = ref({});
const search = ref({});
const tableData = ref([]);
const dataUrl = ref('');
const searchShow = ref(false);
let apiUrl = '';
let currentPage = 1;
let hasPage = false;
const searchData = ref({});
const topButtons = ref([]);
const countField = ref({});
const countData2 = ref({});
const showCountField = ref(false);

const { form, pageUrl, titleColor, countData } = toRefs(props);

onMounted(() => {
  getFields();
});

function onLoad() {
  if(hasPage){
    getData();
  }else{
     finished.value = true;
     loading.value = false;
  }
}

function topBtn(item) {
  mitts.emit("showLoading");
  router.push({
    name: 'event',
    query: {url:encodeURIComponent(item.url)}
  });
}

function getOptUrl(optUrl){
  if(optUrl.indexOf('?') === 0){
    if(pageUrl.value.indexOf('?') > -1){
      return pageUrl.value + '&' + optUrl.substr(optUrl.indexOf('?') + 1);
    }else{
      return pageUrl.value + optUrl;
    }
  }
  return optUrl;
}

function optBtn(opt, item){
  if(opt.confirm){
    if(confirm(opt.confirm)){
      let url = getOptUrl(opt.url);
      API({
        url: url,
        method: "post",
        data:{ids:item.id,id:item.id},
      }).then((res) => {
        Toast.success({
          message: res.data.msg,
        });
        if(res.data.status == 200){
          searchfun(searchData.value, 1);
        }
      });
    }else{
      return false;
    }
  }else{
    let url = opt.url;
    if(url.indexOf('?') > -1){
      url = url + '&id=' + item.id;
    }else{
      url = url + '?id=' + item.id;
    }
    mitts.emit("showLoading");
    router.push({
      name: 'event',
      query: {url:encodeURIComponent(url)}
    });
  }
}

function getApiUrl(){
  if(dataUrl.value.indexOf('?') === 0){
    if(apiUrl.indexOf('?') > -1){
      return apiUrl.substr(0, apiUrl.indexOf('?')) + dataUrl.value;
    }else{
      return apiUrl + dataUrl.value;
    }
  }
  return dataUrl.value;
}


function getFields() {
  apiUrl = pageUrl.value;
  hasPage = form.value.page ? true : false;
  dataUrl.value = form.value.data_url;
  if(form.value.count && form.value.count.field){
    countField.value = form.value.count.field;
    countData2.value = countData.value;
    showCountField.value = true;
  }
  let tfields = form.value.field;
  for(let i in tfields){
    if(!tfields[i].hide && !tfields[i].wap_hide && i != 'checkbox'){
      fields.value[i] = tfields[i];
    }
  }
  let tsearch = form.value.search?form.value.search:{};
  for(let i in tsearch){
    if(tsearch[i].default){
      searchData.value[i] = tsearch[i].default;
    }else{
      searchData.value[i] = '';
    }
    search.value[i] = tsearch[i];
  }
  topButtons.value = form.value.button?form.value.button:[];
  getData();
}

function getData() {
  let url = getApiUrl();
  let limit = 15;
  let data = {};
  data.page = currentPage;
  data.limit = limit;
  data.search = searchData.value;
  loading.value = true;
  API({
    url: url,
    method: "post",
    data:data,
  }).then((res) => {
    if(currentPage == 1){
        tableData.value = res.data.data;
    } else {
        tableData.value = tableData.value.concat(res.data.data);
    }
    if(res.data.data.length < limit){
        finished.value = true;
    } else {
        finished.value = false;
    }
    loading.value = false;
    currentPage++;
  });
}

function showSearch() {
  searchShow.value = true;
}
function searchfun(data, is_ok) {
  searchShow.value = false;
  if(is_ok){
    currentPage = 1;
    searchData.value = data;
    getData();
  }
}
</script>

<style lang="less" scoped>
.listbox {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
.subtitle {
  font-size: @subtitle-size;
  font-weight: bolder;
  font-family: "Regular";
  color: @font-color;
  line-height: @subtitle-size;
  padding: 20px 30px 0 30px;
  margin-bottom: 20px;
}
.lists {
  padding: 10px 30px 0 30px;
  flex-grow: 1;
  min-height: 10px;
  overflow-y: auto;
  .item {
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: @font-white-color;
    box-shadow: 0 0 10px #d7d7d7;
    border-radius: 10px;
    margin-bottom: 40px;
    .listtext {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      .itemname {
        font-size: @font-size;
        font-weight: bolder;
        font-family: "Regular";
        color: @font-secondary-color;
        line-height: @font-size;
      }
      .itemval {
        font-size: @font-size;
        font-family: "Regular";
        color: @font-third-color;
        line-height: @font-size;
        text-align: right;
        max-width: 80%;
        line-height: @title-size;
      }
    }
  }
}
.subtitle{
  .left-btn {
    background: none;
    font-size: @font-size;
    font-weight: normal;
    font-family: "Regular";
    color: @font-white-color;
    line-height: @font-size;
  }
  .left-btn{
    padding: 10px 20px;
    margin-right: 10px;
    background: @font-hot-color;
    font-size: @fontmini-size;
  }
  .right-btn {
    font-weight: normal;
    font-family: "Regular";
    color: @font-white-color;
    line-height: @font-size;
    padding: 10px 20px;
    background: @font-hot-color;
    font-size: @fontmini-size;
    float: right;
  }
}
.opt-btn{
  padding: 5px 10px;
  margin-left: 10px;
  background: @theme-secondary-color;
  font-size: @fontmini-size;
  color: @font-white-color;
  border-radius: 5px;
}
.remarks{
  padding: 10px 10px;
  font-size: @font-size;
  line-height: @title-size;
  color: @font-white-color;
  border: 1px solid @font-white-color;
  border-style: dashed;
  border-radius: 5px;
  margin: 20px 30px;
}
</style>
