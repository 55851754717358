<template>
  <div class="searchbox">
    <van-search
        :name="name"
        class="searchinput"
        v-model="inputData"
        :placeholder="placeholderText"
        :clearable="false"
        @search="searchClick"
        @cancel="onCancel"
    />
    <div @click="onClickButton">搜索</div>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, toRefs} from "vue";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  defaultData: {
    required: false
  },
  name: {
    type: String,
    required: false
  },
  setData: {
    type: Function,
    required: false
  },
});

const { field, name, defaultData } = toRefs(props);
const inputData = ref('')
const result = ref('');
const placeholderText = ref('请输入')

onMounted(()=>{
  if(defaultData.value != '' && defaultData.value != undefined){
    inputData.value = defaultData.value;
  }
  if(field.value.place != '' && field.value.place != undefined){
    placeholderText.value = field.value.place
  }
});

function searchClick(val){
  result.value = val;
  props.setData(name.value, result.value);
}
const onClickButton = () => {
  props.setData(name.value, inputData.value);
};
const onCancel = () => {
  props.setData(name.value, 'overlay');
};
</script>

<style lang="less" scoped>
.searchbox {
  flex: 1;
  min-width: 0;
  display: flex;
  .searchinput {
    background: none;
    padding: 0;
    flex: 1;
    min-width: 0;
  }
}

</style>