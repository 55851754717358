<template>
  <div class="echartbox">
    <div class="title">
      <div class="itemcont">
        <div class="item" v-for="(item, index) in title" :class="apiUrl == item?'on' : ''" :key="index" @click="changeTab(item)">
          {{ index }}<span></span>
        </div>
      </div>
      <button type="button" v-if="search&&Object.keys(search).length>0" @click="showSearch">搜索</button>
    </div>
    <div :id="setId" class="echart"></div>
    <searchForm :searchfun="searchFun" :search="search" :searchData="searchData" v-if="searchShow" />
  </div>
</template>

<script setup>
import { onMounted, toRefs, defineProps, ref } from "vue";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, PieChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import searchForm from "@/components/selectbox";
import API from "@/axios";

const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  setId: {
    type: String,
    required: true,
  },
  pageUrl: {
    type: String,
    required: true,
  },
});
const { field, setId, pageUrl } = toRefs(props);
const title = ref([]);
const dataUrl = ref('');
let apiUrl = '';
const search = ref({});
const searchShow = ref(false);
let searchData = ref({});

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  ToolboxComponent,
]);

function getData() {
  let url = getApiUrl();
  let myChart = echarts.init(document.getElementById(setId.value));
  API({
    url: url,
    method: "post",
    data: searchData.value,
  }).then((res) => {
    myChart.setOption(res.data.map);
    // data.value = res.data.data;
  });
}

function changeTab(item){
  apiUrl.value = item;
  getData();
}

function getApiUrl(){
  if(dataUrl.value.indexOf('?') === 0){
    if(apiUrl.indexOf('?') > -1){
      return apiUrl.substr(0, apiUrl.indexOf('?')) + dataUrl.value;
    }else{
      return apiUrl + dataUrl.value;
    }
  }
  return dataUrl.value;
}

function showSearch() {
  searchShow.value = true;
}
function searchFun(data, is_ok) {
  searchShow.value = false;
  if(is_ok){
    searchData.value = data;
    getData();
  }
}

onMounted(() => {
  apiUrl = pageUrl.value;
  title.value = field.value.tab_title;
  dataUrl.value = field.value.data_url;
  let tsearch = field.value.search?field.value.search:{};
  for(let i in tsearch){
    if(tsearch[i].default){
      searchData.value[i] = tsearch[i].default;
    }else{
      searchData.value[i] = '';
    }
    search.value[i] = tsearch[i];
  }
  getData();
});
// 接下来的使用就跟之前一样，初始化图表，设置配置项
</script>
<style lang="less" scoped>
.echartbox {
  width: 100%;
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  margin-bottom: 30px;
}
.echart {
  height: 500px;
}

.title {
  padding: 0 30px;
  display: flex;
  padding-top: 20px;
  padding-bottom: 10px;
  .itemcont{
    flex:1;
    min-width:0;
    display:flex;
  }
  .item {
    position: relative;
    padding-bottom: 20px;
    font-size: @subtitle-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-color;
    line-height: @subtitle-size;
    margin-right: 30px;
    span {
      position: absolute;
      left: 5%;
      right: 5%;
      bottom: 0;
      height: 4px;
      border-radius: 2px;
      background: @theme-color;
      display: none;
    }
  }
  .on {
    span {
      display: block;
    }
  }
}
.title{
  button {
    background: none;
    font-size: @font-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-color;
    line-height: @font-size;
    float: right;
  }
}
</style>
