<template>
  <div>
    <div class="mtitle" v-if="form.text" v-html="form.text"></div>
    <div class="remarks" v-if="form.remarks" v-html="form.remarks"></div>
    <div class="infobox">
      <van-form>
        <template v-for="(item, index) in fields" :key="index">
          <div class="formitem" v-if="item.type == 'span'">
            <span class="title">{{item.text}}</span>
            <span class="title" v-html="subData[index]"></span>
          </div>

          <div class="formitem" v-if="item.type == 'image'">
            <span class="title">{{item.text}}</span>
            <div class="imagesbox">
              <div class="imgs" >
                <van-image
                    width="100%"
                    height="100%"
                    fit="cover"
                    :src="subData[index]"
                />
              </div>
            </div>
          </div>

          <div class="formitem" v-if="item.type == 'images'">
            <span class="title">{{item.text}}</span>
            <div class="imagesbox">
              <div class="imgs" v-for="(imgs, kk) in subData[index]" :key="kk">
                <van-image
                         width="100%"
                         height="100%"
                         fit="cover"
                         :src="imgs.img"
                />
              </div>
            </div>
          </div>
        </template>
      </van-form>
    </div>
  </div>
</template>

<script setup>
import { ref,defineProps, onMounted,toRefs } from "vue";
const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  defaultData: {
    type: Object,
    required: false
  }
});

const fields = ref({});
const subData = ref({});

const { form, defaultData } = toRefs(props);

onMounted(() => {
  getFields();
});

function getFields() {
  let tfields = form.value.field;
  for(let i in tfields){
    subData.value[i] = typeof(tfields[i]['default']) != 'undefined'?tfields[i]['default']:'';
    if(!tfields[i].hide && !tfields[i].wap_hide && i != 'checkbox'){
      fields.value[i] = tfields[i];
    }
  }
  for(let i in defaultData.value){
    if(typeof(subData.value[i])!='undefined'){
      subData.value[i] = defaultData.value[i];
    }
  }
}


</script>

<style lang="less" scoped>
.mtitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: @title-size;
  font-weight: bolder;
  font-family: "Regular";
  color: @font-color;
  line-height: @title-size;
  padding: 20px 30px 0px;
}
.remarks{
  padding: 10px 10px;
  font-size: @font-size;
  line-height: @title-size;
  color: @font-secondary-color;
  border: 1px solid @font-third-color;
  border-style: dashed;
  border-radius: 5px;
  margin-bottom: 30px;
}
.infobox {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 30px 20px 20px;
  background: #fff;
  overflow-y: auto;
  border-radius: 10px;
  margin: 30px;
  .formitem {
    margin-bottom: 30px;
  }
  .title{
    padding: 0px 20px 30px;
    font-size: @font-size;
    line-height: @font-size;
    color: @font-secondary-color;
  }
  .imagesbox{
    display: flex;
    padding: 0 20px;
  }
  .imgs {
    width: 118px;
    height: 118px;
    margin-right: 30px;
  }
}

</style>
