<template>
  <div class="showdata" :class="bgClass">
    <div class="datashow" :class="columnClass" v-if="num>0" >
      <div class="item"
           v-for="(item, index) in field"
           :key="index"
           :class="countKey == index?'on':''   "
           :style="appendHiddenStyle(item.more_hide,sethide,index)"
           @click="countSearch(item)">
        <div class="text">{{ item.text }}</div>
        <div class="math">
          <div class="val" v-html="data[index]?data[index]:item.default"></div>
          <div>{{item.unit?item.unit:''}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {toRefs, defineProps, onMounted, ref,watch} from "vue";
// import mitts from "@/bus";
// import {toRefs, defineProps, onMounted, ref} from "vue";

const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  data: {
    type: Object,
    required: false,
  },
  showPosition:{
    type:String,
    required:false,
    default:'top',//top、list
  },
 searchfun: {
    type: Function,
    default: Function,
    required: false,
 },
 sethide: {
    type: Boolean,
    required: false,
  },
  showFields: {
    type: Object,
    required: false,
  },
});
const { field,data,showPosition,sethide,showFields } = toRefs(props);
const countKey = ref("")
const num = ref(4);
const bgClass = ref('');
const columnClass = ref('');

onMounted(()=>{
  if(showPosition.value == 'list'){
    bgClass.value = 'listshow';
  }else{
    columnClass.value = 'itemthree'
  }
  num.value = Object.keys(field.value).length;
});

//是否需要追加隐藏样式
function appendHiddenStyle(moreHide,sethide,key){
  let thsStyle = 'width:' + (99/num.value)+'%;';
  //如果字段设置了默认折叠隐藏 && 该行折叠
  if(moreHide && sethide){
    thsStyle +='display:none';
  }else if(showPosition.value=='list' && typeof(showFields.value[key]) !== 'undefined' && showFields.value[key].checked === false){
  //   自定义不展示字段
    thsStyle +='display:none';
  }
  return thsStyle;
}

//点击统计数据触发筛选功能
const countSearch = (item) => {
  if(item.search_key == undefined || item.search_key == ''){
    countKey.value = '';
    props.searchfun({},0);
  }else{
    countKey.value = item.search_key;
    props.searchfun({'count_search':countKey},1);
  }
};

watch(sethide,
    (newData, oldData) => {
      if (newData !== oldData) {
        sethide.value = newData;
      }
    },
    { deep: true }
);

</script>

<style lang="less" scoped>
.showdata {
  padding: 0 24px;
}

.datashow {
  background: @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-width: 25%;
    margin-bottom: 20px;
    .text {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .math {
      color: @font-third-color;
      font-size: @font-size;
      line-height: @font-size;
      font-family: "Regular";
      display: flex;
      justify-content: center;
    }
    .closeitem{display: none}
  }
  .on{
    .math {
      color: @font-hot-color;
      font-size: @font-size;
      line-height: @font-size;
      font-family: "Bold";
    }
  }

}

.itemthree {
  .item{
    min-width: 33.33%;
  }
}

.listshow {
  padding: 0;
  .datashow {
    box-shadow:none;
    background: #f3f3f3;
  }
}

</style>
