<template>
  <div class="ListBox">
    <div
      class="item"
      v-for="(item, index) in tableData"
      :key="index"
      :data-id="item.id ? item.id : 0"
    >
      <template v-for="(tfield, k) in tableField" :key="k"  >
      <div class="itemCont" @click="toDetail(detailUrl,item.id)" >

        <!--加粗首行-->
        <div class="title" v-if="tfield.type == 'h1'" :class="appendHiddenStyle(tfield,index,k)">
          {{ item[k] }}
        </div>

        <!--头像昵称副标题组合-->
        <div class="avatar" v-else-if="tfield.type == 'nick_img'" :class="appendHiddenStyle(tfield,index,k)">
          <div class="pic">
            <img
              :src="
                item[tfield.img] !== ''
                  ? item[tfield.img]
                  : require('@/assets/img/avatar.png')
              "
              alt=""
            />
          </div>
          <div class="name">
            <div class="nikename" v-text="item[tfield.title]"></div>
            <div class="tag" v-html="item[tfield.desc]"></div>
          </div>
        </div>

        <!--标签组-->
        <div class="text" v-else-if="tfield.type == 'tag'" :class="appendHiddenStyle(tfield,index,k)">
          <!--          <div class="txt">{{tfield.text}}</div>-->
          <div class="kinds">
            <template v-for="(tag, kk) in item[k]" :key="kk">
              <span v-if="tfield.is_call == true" class="kinditem" @click.prevent="callPhone(tag.phone)">{{ tag.text }}<van-icon name="phone-o" /></span>
              <span v-else class="kinditem" >{{ tag }}</span>
            </template>
          </div>
        </div>

        <!--操作按钮-->
        <div v-else-if="k == 'operate'" class="text"  :class="appendHiddenStyle(tfield,index,k)">
          <div class="itemname">操作</div>
          <div class="itemval">
            <template v-for="(opt, i) in tfield.val">
              <button
                class="opt-btn"
                type="button"
                v-if="((item[k] && item[k][i] == 0) || (typeof item[i] == 'string' && item[i] == '')) ? false : true"
                :key="i"
                @click="optBtn(opt, item)"
              >
                {{ opt.title }}
              </button>
            </template>
          </div>
        </div>

        <!--状态水印图标-->
        <div v-else-if="tfield.type =='watermark'" class="markpic" :class="appendHiddenStyle(tfield,index,k)">
          <div class="mark" >
            <img :src="item[k]" alt="" />
          </div>
        </div>

        <!--统计字段-->
<!--        ref="DataBox"-->
        <countDataBox
            v-else-if="tfield.type == 'count'"
            :field="tfield.fields"
            :data="item"
            showPosition="list"
            :searchfun="jumpPage"
            :showFields="showFields"
            :sethide="showindex.indexOf(index) == -1 ? true : false"
        />

        <!--查看更多-->
        <div class="bottombtn" v-else-if="tfield.type =='more'">
          <span v-if="showindex.indexOf(index) == -1" @click="showMoreClick(index)">更多<van-icon name="arrow-down" /></span>
          <span v-else @click="closeMoreClick(index)">收起<van-icon name="arrow-up" /></span>
        </div>

        <!--普通展示-->
        <div class="text"  v-else :class="appendHiddenStyle(tfield,index,k)">
          <div class="txt" v-if="tfield.text !== ''" >{{ tfield.text }}</div>
          <span class="righttxt" v-html="item[k]"></span>
        </div>

      </div>

      </template>
    </div>

    <!-- 显示字段 -->
    <van-popup v-model:show="textItemShow" position="bottom" round>
      <textItemBox @setTextItem="setTextItem" :textItemList = "showFields"/>
    </van-popup>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, toRefs, ref, onMounted} from "vue";
import countDataBox from "@/resources/lists/CountDataBox";
import textItemBox from "@/components/textItemBox";
import { useRouter, useRoute } from "vue-router";
import API from "@/axios";
import { Toast } from "vant";
import mitts from "@/bus";
const route = useRoute();
const router = useRouter();

const props = defineProps({
  tableData: {
    type: Object,
    required: false,
  },
  tableField: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: true,
  },
  detailUrl:{
    type:String,
    required:false,
    default:''
  },
  authUpdateAt:{
    type: String,
    required:false,
    default: ''
  }
});

const { tableData, tableField, pageUrl,detailUrl, authUpdateAt } = toRefs(props);

//控制列表字段的显隐
const showindex = ref([]);//某行折叠还是展开
const moreHide =ref(true)//更多按钮显隐
const textItemShow = ref(false)//是否显示展示字段弹窗
const showFields = ref({});//展示字段列表
const storageName = (pageUrl.value ?? route.query.url)+'_showFields';//缓存配置
const authUpdateAtStorageName = (pageUrl.value ?? route.query.url)+'_authUpdateAt';//缓存权限更新时间

onMounted(()=>{
  setTimeout(() => {
    // localStorage.removeItem(storageName)
    let storageFields = localStorage.getItem(storageName)
    // 数据权限更新时间不同时，删除字段显示缓存，重新加载
    let storageAuthUpdateAt = localStorage.getItem(authUpdateAtStorageName)
    if (authUpdateAt.value && storageAuthUpdateAt != authUpdateAt.value) {
      localStorage.setItem(authUpdateAtStorageName, authUpdateAt.value)
      localStorage.removeItem(storageName);
      storageFields = '';
    }
    if (storageFields) {
      showFields.value = JSON.parse(storageFields);
    } else {
      for (let key in tableField.value) {
        if (key == 'more') {
          continue;
        } else if (tableField.value[key].type == 'nick_img') {
          continue;
        } else if (tableField.value[key].type == 'count') {
          let count_fields = tableField.value[key].fields;
          for (let key2 in count_fields) {
            showFields.value[key2] = {
              title: count_fields[key2].text,
              val: key2,
              checked: true
            };
          }
        } else {
          showFields.value[key] = {
            title: tableField.value[key].text,
            val: key,
            checked: true
          };
        }
      }
      localStorage.setItem(storageName, JSON.stringify(showFields.value))

    }
  }, 300)
  mitts.on("showTextItem", () => {
    setTimeout(()=>{
      textItemShow.value = true;
    },300)
  });
  mitts.on("hideTextItem", () => {
    setTimeout(()=>{
      textItemShow.value = false;
    },300)
  });
})

//是否需要追加隐藏样式
function appendHiddenStyle(tfield,index,key){
  // console.log(typeof(showFields.value))
  //如果字段设置了默认折叠隐藏 && 该行折叠
  if(tfield.more_hide && showindex.value.indexOf(index) == -1){
    return 'closeitem';
  }else if(typeof(showFields.value[key]) !== 'undefined' && showFields.value[key].checked === false){
    //自定义不展示字段
    return 'closeitem';
  }else{
    return '';
  }
}
//展示字段组件传参
function setTextItem(val){
  // val是组件传过来的值
  // console.log('ttt',val)
  showFields.value = val
  localStorage.setItem(storageName,JSON.stringify(showFields.value))
}

//更多展开
function showMoreClick(index){
  showindex.value.push(index)
  moreHide.value = false
}
//更多折叠
function closeMoreClick (index){
  showindex.value = showindex.value.filter(item => item !== index);
  moreHide.value = true
}
//统计字段跳转
function jumpPage(data, is_ok) {
  if (data) {
    console.log(data);
  }
  if (is_ok) {
    console.log(is_ok);
  }
}

//列表操作按钮
function optBtn(opt, item) {
  if (opt.confirm) {
    if (confirm(opt.confirm)) {
      let url = getOptUrl(opt.url);
      API({
        url: url,
        method: "post",
        data: { ids: item.id, id: item.id },
      }).then((res) => {
        Toast.success({
          message: res.data.msg,
        });
        if (res.data.status == 200) {
          setFilter("");
        }
      });
    } else {
      return false;
    }
  } else {
    let url = opt.url;
    if (url.indexOf("?") > -1) {
      url = url + "&id=" + item.id;
    } else {
      url = url + "?id=" + item.id;
    }
    mitts.emit("showLoading");
    router.push({
      name: "event",
      query: { url: encodeURIComponent(url) },
    });
  }
}

//获取按钮请求地址
function getOptUrl(optUrl) {
  if (optUrl.indexOf("?") === 0) {
    if (pageUrl.value.indexOf("?") > -1) {
      return pageUrl.value + "&" + optUrl.substr(optUrl.indexOf("?") + 1);
    } else {
      return pageUrl.value + optUrl;
    }
  }
  return optUrl;
}
//打电话
function callPhone(phoneNumber) {
  if(phoneNumber){
    window.location.href = 'tel://' + phoneNumber
  }else{
    Toast.fail("该角色暂无填写手机号");
  }
  
}
//跳转到详情页
function toDetail(url,dataId){
  if(url){
    if (url.indexOf("?") > -1) {
      url = url + "&id=" + dataId;
    } else {
      url = url + "?id=" + dataId;
    }
    mitts.emit("showLoading");
    router.push({
      name: "event",
      query: { url: encodeURIComponent(url) },
    });
  }
}

// 调用父组件方法 传值给父级
const emit = defineEmits(["setFilter"]);
function setFilter(val) {
  emit("setFilter", val);
}


</script>

<style lang="less" scoped>
.ListBox {
  padding: 0 24px;
  margin-top: 30px;
  position: relative;
}

.item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #d7d7d7;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
  
  .text {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    font-size: @font-size;
    line-height: @font-size*1.2;
    color: @font-color;
    .txt {
      margin-right: 20px;
      width: @font-size*6;
    }
    .righttxt{
      text-align: left;
      flex: 1;
    min-width: 0;
    overflow: hidden;
    box-sizing: border-box;
    padding-right: 100px;
    }
    .kinds {
      margin-right: 20px;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      min-width: 0;
      justify-content: flex-start;
      .kinditem {
        padding: 5px;
        border-radius: 6px;
        border: 1px solid #2a57fa;
        font-size: @font-size;
        line-height: @font-size;
        color: @theme-color;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .closeitem{display: none}
  .title {
    font-size: @subtitle-size;
    color: @font-color;
    line-height: @subtitle-size;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .avatar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    .pic {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 20px;
      overflow: hidden;
      box-shadow: 0 0 5px #999;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
    .name {
      flex: 1;
      min-width: 0;
      .nikename {
        font-size: @subtitle-size;
        color: @font-color;
        line-height: @subtitle-size;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .tag {
        font-size: @font-size;
        line-height: @font-size;
        color: @font-color;
      }
    }
  }

  .itemname {
    font-size: @font-size;
    font-weight: bolder;
    font-family: "Regular";
    color: @font-secondary-color;
    line-height: @font-size;
  }
  .itemval {
    font-size: @font-size;
    font-family: "Regular";
    color: @font-third-color;
    line-height: @font-size;
    text-align: right;
    max-width: 80%;
    line-height: @title-size;
  }

  .opt-btn {
    padding: 5px 10px;
    margin-left: 10px;
    background: @theme-secondary-color;
    font-size: @fontmini-size;
    color: @font-white-color;
    border-radius: 5px;
  }
}
.markpic {
  position: absolute;
  right: 20px;
  bottom: 20px;
  opacity: 0.6;
  -webkit-opacity: 0.6;
  width: 150px;
  .mark {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
}

.bottombtn{
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  top:10px;
  color: @theme-secondary-color;
}
</style>
