<template>
  <div class="tabbox">
    <div class="datashow" v-if="num>0">
      <div class="item" v-for="(item, index) in data" :key="index" :style="'width:' + (99/num)+'%;'">
        <div class="text">{{ index }}</div>
        <div class="math">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, defineProps, onMounted, ref } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: false,
  }
});
const num = ref(4);
onMounted(()=>{
  num.value = Object.keys(data.value).length;
});
const { data } = toRefs(props);
</script>

<style lang="less" scoped>
.tabbox {
  margin-top: 10px;
}
.datashow {
  background: @font-white-color;
  min-height: 150px;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-width: 20%;
    margin-bottom: 10px;
    .text {
      color: @font-color;
      font-size: @font-size;
      line-height: @font-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .math {
      color: @font-third-color;
      font-size: @font-size;
      line-height: @font-size;
      font-family: "Regular";
    }
  }
}
</style>
