<template>
  <div class="formitem mini">
    <van-field
      v-model="result"
      is-link
      readonly
      :name="name"
      :label="field.text"
      :placeholder="field.place?field.place:''"
      @click="showCalendar = true"
    />
    <van-calendar v-model:show="showCalendar" type="range" @confirm="MultipleData" :allow-same-day="true" :min-date="minDate" />
  </div>
</template>

<script setup>
import { ref,defineProps, onMounted,toRefs } from "vue";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  setData: {
    type: Function,
    required: false
  },
  name: {
    type: String,
    required: false
  }
});

const columns = [];
const result = ref('');
const minDate = ref(new Date(2010, 0, 1));
const showCalendar = ref(false);
let selectItems = {};

onMounted(()=>{
  selectItems = field.value.value;
  for(let i in selectItems){
    columns.push({text:selectItems[i], value:i});
  }
  if(field.value.default){
    result.value = Calendardata2(new Date(field.value.default)) + '至';
  }
});

const Calendardata = (date) => {
  let day = date.getDate();
  if(day<10){
    day = '0'+day;
  }
  let month = date.getMonth() + 1;
  if(month<10){
    month = '0'+month;
  }
  return `${date.getFullYear()}-${month}-${day}`;
};

const Calendardata2 = (date) => {
  let day = date.getDate();
  if(day<10){
    day = '0'+day;
  }
  let month = date.getMonth() + 1;
  if(month<10){
    month = '0'+month;
  }
  return `${month}-${day}`;
};
const MultipleData = (values) => {
  const [start, end] = values;
  showCalendar.value = false;
  result.value = `${Calendardata2(start)}至${Calendardata2(end)}`;
  props.setData(name.value,`${Calendardata(start)}`, `${Calendardata(end)}`);
};
const { field, name } = toRefs(props);
</script>

<style lang="less" scoped>
.formitem {
  //margin-bottom: 30px;
  display: inline-block;
  line-height: 30px;
  width: 300px;
}
</style>
