<template>
  <div class="tabbox">
    <div class="tabs">
      <div class="tab_item" v-for="(item, index) in search" :key="index">
        <template v-if="item.type=='date-radio'">
          <div class="date_item" v-for="(item2, index2) in item.value" :class="index2 == searchData[index]?'on':''" :key="index2" @click="dateRadioClick(index, index2)">
            {{item2}}
          </div>
        </template>
        <template v-if="item.type=='dateDays'">
          <datesBox :field="item" :showmutiple="true" :name="index" :setData="setDatesData" />
        </template>
      </div>
    </div>
    <div class="datashow" v-if="num>0">
      <div class="item" v-for="(item, index) in data" :key="index" :style="'width:' + (99/num)+'%;'">
        <template v-if="item.text">
          <div class="text">{{ item.text }}</div>
          <div class="math">{{ item.val }}</div>
        </template>
        <template v-else>
          <div class="text">{{ index }}</div>
          <div class="math">{{ item }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, defineProps, onMounted, ref } from "vue";
import datesBox from "@/resources/counts/DatesBox";
import API from "@/axios";

const props = defineProps({
  form: {
    type: Object,
    required: false,
  },
  pageUrl: {
    type: String,
    required: false,
  }
});
const search = ref({});
const searchData = ref({});
const num = ref(4);
const data = ref({});
let dataUrl = '';
onMounted(()=>{
  dataUrl = form.value.data_url;
  let tsearch = form.value.search;
  for(let i in tsearch){
    search.value[i] = tsearch[i];
    if(tsearch[i]['default']){
      searchData.value[i] = tsearch[i]['default'];
    }
  }
  getData();
});

function getDataUrl(){
  if(dataUrl.indexOf('?') === 0){
    if(pageUrl.value.indexOf('?') > -1){
      return pageUrl.value + '&' + dataUrl.substr(dataUrl.indexOf('?') + 1);
    }else{
      return pageUrl.value + dataUrl;
    }
  }
  return dataUrl;
}
function getData() {
  let url = getDataUrl();
  API({
    url: url,
    method: "post",
    data: {search: searchData.value},
  }).then((res) => {
    data.value = res.data.data;
    num.value = Object.keys(data.value).length;
  });
}
function dateRadioClick(name, value){
  search.value[name]['default'] = value;
  searchData.value = {};
  searchData.value[name] = value;
  getData();
}
function setDatesData(name, start, end){
  searchData.value = {};
  searchData.value['start_'+ name] = start;
  searchData.value['end_'+ name] = end;
  getData();
}
const { form,pageUrl } = toRefs(props);
</script>

<style lang="less" scoped>
.tabbox {
  margin: 10px 0px;
  background:  @font-white-color;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  padding: 20px;
}
.tabs{
  margin-left: 10px;
}
.tab_item{
  margin: 10px 5px 40px;
  color: @font-color;
  font-size: @font-size;
  line-height: @font-size;
  background: @font-white-color;
  display: inline-block;
  .date_item{
    display:inline-block;
    padding: 10px 5px;
    margin-right: 10px;
  }

}

.on{
  border-bottom: 1px solid #ccc;
}
.datashow {
  background: @font-white-color;
  min-height: 150px;
  //box-shadow: 0 0 10px #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-width: 25%;
    margin-bottom: 20px;
    .text {
      color: @font-color;
      font-size: @font-size;
      line-height: @font-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .math {
      color: @font-third-color;
      font-size: @font-size;
      line-height: @font-size;
      font-family: "Regular";
    }
  }
}
</style>
