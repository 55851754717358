<template>
  <div class="formitem">
    <van-field
        v-model="areaText"
        is-link
        readonly
        :name="name"
        :label="field.text?field.text:'所属区域'"
        :placeholder="field.place?field.place:'请选择省市区'"
        @click="areaShow = true"
        class="areabox"
        v-if="fieldShow"
    />
    <van-popup v-model:show="areaShow" round position="bottom"  @click-overlay="onClickOverlay">
      <van-cascader
          v-model="areaValue"
          title="请选择所在地区"
          :options="areaoptions"
          :field-names="fileNames"
          active-color="#2a57fa"
          @close="areaShow = false"
          @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, toRefs, watch} from "vue";
const props = defineProps({
  field: {
    type: Object,
    required: false,
  },
  defaultData: {
    required: false
  },
  setData: {
    type: Function,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  fieldShow:{
    type:Boolean,
    required: false,
    default:true
  },
  boxShow:{
    type:Boolean,
    required: false,
    default:false
  },
});

const { field, name, defaultData,boxShow } = toRefs(props);

// 地区选择
const areaShow = ref(false);
const areaText = ref("");
const areaValue = ref("");
const fileNames = {
  text:'name',
  value:'region_code',
  children:'child',
}
// 选项列表，children 代表子选项，支持多级嵌套
const areaoptions =[
  {
    "id": 1,
    "name": "北京市",
    "region_code": "110000",
    "child": [
      {
        "id": 2,
        "name": "北京市",
        "region_code": "110100",
        "child": [
          {
            "id": 3,
            "name": "东城区",
            "region_code": "110101",

          },
          {
            "id": 4,
            "name": "西城区",
            "region_code": "110102",

          },
          {
            "id": 5,
            "name": "朝阳区",
            "region_code": "110105",

          },
          {
            "id": 6,
            "name": "丰台区",
            "region_code": "110106",

          },
          {
            "id": 7,
            "name": "石景山区",
            "region_code": "110107",

          },
          {
            "id": 8,
            "name": "海淀区",
            "region_code": "110108",

          },
          {
            "id": 9,
            "name": "门头沟区",
            "region_code": "110109",

          },
          {
            "id": 10,
            "name": "房山区",
            "region_code": "110111",

          },
          {
            "id": 11,
            "name": "通州区",
            "region_code": "110112",

          },
          {
            "id": 12,
            "name": "顺义区",
            "region_code": "110113",

          },
          {
            "id": 13,
            "name": "昌平区",
            "region_code": "110114",

          },
          {
            "id": 14,
            "name": "大兴区",
            "region_code": "110115",

          },
          {
            "id": 15,
            "name": "怀柔区",
            "region_code": "110116",

          },
          {
            "id": 16,
            "name": "平谷区",
            "region_code": "110117",

          },
          {
            "id": 17,
            "name": "密云区",
            "region_code": "110118",

          },
          {
            "id": 18,
            "name": "延庆区",
            "region_code": "110119",

          }
        ]
      }
    ]
  },
  {
    "id": 19,
    "name": "天津市",
    "region_code": "120000",
    "child": [
      {
        "id": 20,
        "name": "天津市",
        "region_code": "120100",
        "child": [
          {
            "id": 21,
            "name": "和平区",
            "region_code": "120101",

          },
          {
            "id": 22,
            "name": "河东区",
            "region_code": "120102",

          },
          {
            "id": 23,
            "name": "河西区",
            "region_code": "120103",

          },
          {
            "id": 24,
            "name": "南开区",
            "region_code": "120104",

          },
          {
            "id": 25,
            "name": "河北区",
            "region_code": "120105",

          },
          {
            "id": 26,
            "name": "红桥区",
            "region_code": "120106",

          },
          {
            "id": 27,
            "name": "东丽区",
            "region_code": "120110",

          },
          {
            "id": 28,
            "name": "西青区",
            "region_code": "120111",

          },
          {
            "id": 29,
            "name": "津南区",
            "region_code": "120112",

          },
          {
            "id": 30,
            "name": "北辰区",
            "region_code": "120113",

          },
          {
            "id": 31,
            "name": "武清区",
            "region_code": "120114",

          },
          {
            "id": 32,
            "name": "宝坻区",
            "region_code": "120115",

          },
          {
            "id": 33,
            "name": "滨海新区",
            "region_code": "120116",

          },
          {
            "id": 34,
            "name": "宁河区",
            "region_code": "120117",

          },
          {
            "id": 35,
            "name": "静海区",
            "region_code": "120118",

          },
          {
            "id": 36,
            "name": "蓟州区",
            "region_code": "120119",

          }
        ]
      }
    ]
  },
  {
    "id": 37,
    "name": "河北省",
    "region_code": "130000",
    "child": [
      {
        "id": 38,
        "name": "石家庄市",
        "region_code": "130100",
        "child": [
          {
            "id": 39,
            "name": "长安区",
            "region_code": "130102",

          },
          {
            "id": 40,
            "name": "桥西区",
            "region_code": "130104",

          },
          {
            "id": 41,
            "name": "新华区",
            "region_code": "130105",

          },
          {
            "id": 42,
            "name": "井陉矿区",
            "region_code": "130107",

          },
          {
            "id": 43,
            "name": "裕华区",
            "region_code": "130108",

          },
          {
            "id": 44,
            "name": "藁城区",
            "region_code": "130109",

          },
          {
            "id": 45,
            "name": "鹿泉区",
            "region_code": "130110",

          },
          {
            "id": 46,
            "name": "栾城区",
            "region_code": "130111",

          },
          {
            "id": 47,
            "name": "井陉县",
            "region_code": "130121",

          },
          {
            "id": 48,
            "name": "正定县",
            "region_code": "130123",

          },
          {
            "id": 49,
            "name": "行唐县",
            "region_code": "130125",

          },
          {
            "id": 50,
            "name": "灵寿县",
            "region_code": "130126",

          },
          {
            "id": 51,
            "name": "高邑县",
            "region_code": "130127",

          },
          {
            "id": 52,
            "name": "深泽县",
            "region_code": "130128",

          },
          {
            "id": 53,
            "name": "赞皇县",
            "region_code": "130129",

          },
          {
            "id": 54,
            "name": "无极县",
            "region_code": "130130",

          },
          {
            "id": 55,
            "name": "平山县",
            "region_code": "130131",

          },
          {
            "id": 56,
            "name": "元氏县",
            "region_code": "130132",

          },
          {
            "id": 57,
            "name": "赵县",
            "region_code": "130133",

          },
          {
            "id": 58,
            "name": "辛集市",
            "region_code": "130181",

          },
          {
            "id": 59,
            "name": "晋州市",
            "region_code": "130183",

          },
          {
            "id": 60,
            "name": "新乐市",
            "region_code": "130184",

          }
        ]
      },
      {
        "id": 61,
        "name": "唐山市",
        "region_code": "130200",
        "child": [
          {
            "id": 62,
            "name": "路南区",
            "region_code": "130202",

          },
          {
            "id": 63,
            "name": "路北区",
            "region_code": "130203",

          },
          {
            "id": 64,
            "name": "古冶区",
            "region_code": "130204",

          },
          {
            "id": 65,
            "name": "开平区",
            "region_code": "130205",

          },
          {
            "id": 66,
            "name": "丰南区",
            "region_code": "130207",

          },
          {
            "id": 67,
            "name": "丰润区",
            "region_code": "130208",

          },
          {
            "id": 68,
            "name": "曹妃甸区",
            "region_code": "130209",

          },
          {
            "id": 69,
            "name": "滦南县",
            "region_code": "130224",

          },
          {
            "id": 70,
            "name": "乐亭县",
            "region_code": "130225",

          },
          {
            "id": 71,
            "name": "迁西县",
            "region_code": "130227",

          },
          {
            "id": 72,
            "name": "玉田县",
            "region_code": "130229",

          },
          {
            "id": 73,
            "name": "遵化市",
            "region_code": "130281",

          },
          {
            "id": 74,
            "name": "迁安市",
            "region_code": "130283",

          },
          {
            "id": 75,
            "name": "滦州市",
            "region_code": "130284",

          }
        ]
      },
      {
        "id": 76,
        "name": "秦皇岛市",
        "region_code": "130300",
        "child": [
          {
            "id": 77,
            "name": "海港区",
            "region_code": "130302",

          },
          {
            "id": 78,
            "name": "山海关区",
            "region_code": "130303",

          },
          {
            "id": 79,
            "name": "北戴河区",
            "region_code": "130304",

          },
          {
            "id": 80,
            "name": "抚宁区",
            "region_code": "130306",

          },
          {
            "id": 81,
            "name": "青龙满族自治县",
            "region_code": "130321",

          },
          {
            "id": 82,
            "name": "昌黎县",
            "region_code": "130322",

          },
          {
            "id": 83,
            "name": "卢龙县",
            "region_code": "130324",

          }
        ]
      },
      {
        "id": 84,
        "name": "邯郸市",
        "region_code": "130400",
        "child": [
          {
            "id": 85,
            "name": "邯山区",
            "region_code": "130402",

          },
          {
            "id": 86,
            "name": "丛台区",
            "region_code": "130403",

          },
          {
            "id": 87,
            "name": "复兴区",
            "region_code": "130404",

          },
          {
            "id": 88,
            "name": "峰峰矿区",
            "region_code": "130406",

          },
          {
            "id": 89,
            "name": "肥乡区",
            "region_code": "130407",

          },
          {
            "id": 90,
            "name": "永年区",
            "region_code": "130408",

          },
          {
            "id": 91,
            "name": "临漳县",
            "region_code": "130423",

          },
          {
            "id": 92,
            "name": "成安县",
            "region_code": "130424",

          },
          {
            "id": 93,
            "name": "大名县",
            "region_code": "130425",

          },
          {
            "id": 94,
            "name": "涉县",
            "region_code": "130426",

          },
          {
            "id": 95,
            "name": "磁县",
            "region_code": "130427",

          },
          {
            "id": 96,
            "name": "邱县",
            "region_code": "130430",

          },
          {
            "id": 97,
            "name": "鸡泽县",
            "region_code": "130431",

          },
          {
            "id": 98,
            "name": "广平县",
            "region_code": "130432",

          },
          {
            "id": 99,
            "name": "馆陶县",
            "region_code": "130433",

          },
          {
            "id": 100,
            "name": "魏县",
            "region_code": "130434",

          },
          {
            "id": 101,
            "name": "曲周县",
            "region_code": "130435",

          },
          {
            "id": 102,
            "name": "武安市",
            "region_code": "130481",

          }
        ]
      },
      {
        "id": 103,
        "name": "邢台市",
        "region_code": "130500",
        "child": [
          {
            "id": 104,
            "name": "襄都区",
            "region_code": "130502",

          },
          {
            "id": 105,
            "name": "信都区",
            "region_code": "130503",

          },
          {
            "id": 106,
            "name": "任泽区",
            "region_code": "130505",

          },
          {
            "id": 107,
            "name": "南和区",
            "region_code": "130506",

          },
          {
            "id": 108,
            "name": "临城县",
            "region_code": "130522",

          },
          {
            "id": 109,
            "name": "内丘县",
            "region_code": "130523",

          },
          {
            "id": 110,
            "name": "柏乡县",
            "region_code": "130524",

          },
          {
            "id": 111,
            "name": "隆尧县",
            "region_code": "130525",

          },
          {
            "id": 112,
            "name": "宁晋县",
            "region_code": "130528",

          },
          {
            "id": 113,
            "name": "巨鹿县",
            "region_code": "130529",

          },
          {
            "id": 114,
            "name": "新河县",
            "region_code": "130530",

          },
          {
            "id": 115,
            "name": "广宗县",
            "region_code": "130531",

          },
          {
            "id": 116,
            "name": "平乡县",
            "region_code": "130532",

          },
          {
            "id": 117,
            "name": "威县",
            "region_code": "130533",

          },
          {
            "id": 118,
            "name": "清河县",
            "region_code": "130534",

          },
          {
            "id": 119,
            "name": "临西县",
            "region_code": "130535",

          },
          {
            "id": 120,
            "name": "南宫市",
            "region_code": "130581",

          },
          {
            "id": 121,
            "name": "沙河市",
            "region_code": "130582",

          }
        ]
      },
      {
        "id": 122,
        "name": "保定市",
        "region_code": "130600",
        "child": [
          {
            "id": 123,
            "name": "竞秀区",
            "region_code": "130602",

          },
          {
            "id": 124,
            "name": "莲池区",
            "region_code": "130606",

          },
          {
            "id": 125,
            "name": "满城区",
            "region_code": "130607",

          },
          {
            "id": 126,
            "name": "清苑区",
            "region_code": "130608",

          },
          {
            "id": 127,
            "name": "徐水区",
            "region_code": "130609",

          },
          {
            "id": 128,
            "name": "涞水县",
            "region_code": "130623",

          },
          {
            "id": 129,
            "name": "阜平县",
            "region_code": "130624",

          },
          {
            "id": 130,
            "name": "定兴县",
            "region_code": "130626",

          },
          {
            "id": 131,
            "name": "唐县",
            "region_code": "130627",

          },
          {
            "id": 132,
            "name": "高阳县",
            "region_code": "130628",

          },
          {
            "id": 133,
            "name": "容城县",
            "region_code": "130629",

          },
          {
            "id": 134,
            "name": "涞源县",
            "region_code": "130630",

          },
          {
            "id": 135,
            "name": "望都县",
            "region_code": "130631",

          },
          {
            "id": 136,
            "name": "安新县",
            "region_code": "130632",

          },
          {
            "id": 137,
            "name": "易县",
            "region_code": "130633",

          },
          {
            "id": 138,
            "name": "曲阳县",
            "region_code": "130634",

          },
          {
            "id": 139,
            "name": "蠡县",
            "region_code": "130635",

          },
          {
            "id": 140,
            "name": "顺平县",
            "region_code": "130636",

          },
          {
            "id": 141,
            "name": "博野县",
            "region_code": "130637",

          },
          {
            "id": 142,
            "name": "雄县",
            "region_code": "130638",

          },
          {
            "id": 143,
            "name": "涿州市",
            "region_code": "130681",

          },
          {
            "id": 144,
            "name": "定州市",
            "region_code": "130682",

          },
          {
            "id": 145,
            "name": "安国市",
            "region_code": "130683",

          },
          {
            "id": 146,
            "name": "高碑店市",
            "region_code": "130684",

          }
        ]
      },
      {
        "id": 147,
        "name": "张家口市",
        "region_code": "130700",
        "child": [
          {
            "id": 148,
            "name": "桥东区",
            "region_code": "130702",

          },
          {
            "id": 149,
            "name": "桥西区",
            "region_code": "130703",

          },
          {
            "id": 150,
            "name": "宣化区",
            "region_code": "130705",

          },
          {
            "id": 151,
            "name": "下花园区",
            "region_code": "130706",

          },
          {
            "id": 152,
            "name": "万全区",
            "region_code": "130708",

          },
          {
            "id": 153,
            "name": "崇礼区",
            "region_code": "130709",

          },
          {
            "id": 154,
            "name": "张北县",
            "region_code": "130722",

          },
          {
            "id": 155,
            "name": "康保县",
            "region_code": "130723",

          },
          {
            "id": 156,
            "name": "沽源县",
            "region_code": "130724",

          },
          {
            "id": 157,
            "name": "尚义县",
            "region_code": "130725",

          },
          {
            "id": 158,
            "name": "蔚县",
            "region_code": "130726",

          },
          {
            "id": 159,
            "name": "阳原县",
            "region_code": "130727",

          },
          {
            "id": 160,
            "name": "怀安县",
            "region_code": "130728",

          },
          {
            "id": 161,
            "name": "怀来县",
            "region_code": "130730",

          },
          {
            "id": 162,
            "name": "涿鹿县",
            "region_code": "130731",

          },
          {
            "id": 163,
            "name": "赤城县",
            "region_code": "130732",

          }
        ]
      },
      {
        "id": 164,
        "name": "承德市",
        "region_code": "130800",
        "child": [
          {
            "id": 165,
            "name": "双桥区",
            "region_code": "130802",

          },
          {
            "id": 166,
            "name": "双滦区",
            "region_code": "130803",

          },
          {
            "id": 167,
            "name": "鹰手营子矿区",
            "region_code": "130804",

          },
          {
            "id": 168,
            "name": "承德县",
            "region_code": "130821",

          },
          {
            "id": 169,
            "name": "兴隆县",
            "region_code": "130822",

          },
          {
            "id": 170,
            "name": "滦平县",
            "region_code": "130824",

          },
          {
            "id": 171,
            "name": "隆化县",
            "region_code": "130825",

          },
          {
            "id": 172,
            "name": "丰宁满族自治县",
            "region_code": "130826",

          },
          {
            "id": 173,
            "name": "宽城满族自治县",
            "region_code": "130827",

          },
          {
            "id": 174,
            "name": "围场满族蒙古族自治县",
            "region_code": "130828",

          },
          {
            "id": 175,
            "name": "平泉市",
            "region_code": "130881",

          }
        ]
      },
      {
        "id": 176,
        "name": "沧州市",
        "region_code": "130900",
        "child": [
          {
            "id": 177,
            "name": "新华区",
            "region_code": "130902",

          },
          {
            "id": 178,
            "name": "运河区",
            "region_code": "130903",

          },
          {
            "id": 179,
            "name": "沧县",
            "region_code": "130921",

          },
          {
            "id": 180,
            "name": "青县",
            "region_code": "130922",

          },
          {
            "id": 181,
            "name": "东光县",
            "region_code": "130923",

          },
          {
            "id": 182,
            "name": "海兴县",
            "region_code": "130924",

          },
          {
            "id": 183,
            "name": "盐山县",
            "region_code": "130925",

          },
          {
            "id": 184,
            "name": "肃宁县",
            "region_code": "130926",

          },
          {
            "id": 185,
            "name": "南皮县",
            "region_code": "130927",

          },
          {
            "id": 186,
            "name": "吴桥县",
            "region_code": "130928",

          },
          {
            "id": 187,
            "name": "献县",
            "region_code": "130929",

          },
          {
            "id": 188,
            "name": "孟村回族自治县",
            "region_code": "130930",

          },
          {
            "id": 189,
            "name": "泊头市",
            "region_code": "130981",

          },
          {
            "id": 190,
            "name": "任丘市",
            "region_code": "130982",

          },
          {
            "id": 191,
            "name": "黄骅市",
            "region_code": "130983",

          },
          {
            "id": 192,
            "name": "河间市",
            "region_code": "130984",

          }
        ]
      },
      {
        "id": 193,
        "name": "廊坊市",
        "region_code": "131000",
        "child": [
          {
            "id": 194,
            "name": "安次区",
            "region_code": "131002",

          },
          {
            "id": 195,
            "name": "广阳区",
            "region_code": "131003",

          },
          {
            "id": 196,
            "name": "固安县",
            "region_code": "131022",

          },
          {
            "id": 197,
            "name": "永清县",
            "region_code": "131023",

          },
          {
            "id": 198,
            "name": "香河县",
            "region_code": "131024",

          },
          {
            "id": 199,
            "name": "大城县",
            "region_code": "131025",

          },
          {
            "id": 200,
            "name": "文安县",
            "region_code": "131026",

          },
          {
            "id": 201,
            "name": "大厂回族自治县",
            "region_code": "131028",

          },
          {
            "id": 202,
            "name": "霸州市",
            "region_code": "131081",

          },
          {
            "id": 203,
            "name": "三河市",
            "region_code": "131082",

          }
        ]
      },
      {
        "id": 204,
        "name": "衡水市",
        "region_code": "131100",
        "child": [
          {
            "id": 205,
            "name": "桃城区",
            "region_code": "131102",

          },
          {
            "id": 206,
            "name": "冀州区",
            "region_code": "131103",

          },
          {
            "id": 207,
            "name": "枣强县",
            "region_code": "131121",

          },
          {
            "id": 208,
            "name": "武邑县",
            "region_code": "131122",

          },
          {
            "id": 209,
            "name": "武强县",
            "region_code": "131123",

          },
          {
            "id": 210,
            "name": "饶阳县",
            "region_code": "131124",

          },
          {
            "id": 211,
            "name": "安平县",
            "region_code": "131125",

          },
          {
            "id": 212,
            "name": "故城县",
            "region_code": "131126",

          },
          {
            "id": 213,
            "name": "景县",
            "region_code": "131127",

          },
          {
            "id": 214,
            "name": "阜城县",
            "region_code": "131128",

          },
          {
            "id": 215,
            "name": "深州市",
            "region_code": "131182",

          }
        ]
      }
    ]
  },
  {
    "id": 216,
    "name": "山西省",
    "region_code": "140000",
    "child": [
      {
        "id": 217,
        "name": "太原市",
        "region_code": "140100",
        "child": [
          {
            "id": 218,
            "name": "小店区",
            "region_code": "140105",

          },
          {
            "id": 219,
            "name": "迎泽区",
            "region_code": "140106",

          },
          {
            "id": 220,
            "name": "杏花岭区",
            "region_code": "140107",

          },
          {
            "id": 221,
            "name": "尖草坪区",
            "region_code": "140108",

          },
          {
            "id": 222,
            "name": "万柏林区",
            "region_code": "140109",

          },
          {
            "id": 223,
            "name": "晋源区",
            "region_code": "140110",

          },
          {
            "id": 224,
            "name": "清徐县",
            "region_code": "140121",

          },
          {
            "id": 225,
            "name": "阳曲县",
            "region_code": "140122",

          },
          {
            "id": 226,
            "name": "娄烦县",
            "region_code": "140123",

          },
          {
            "id": 227,
            "name": "古交市",
            "region_code": "140181",

          }
        ]
      },
      {
        "id": 228,
        "name": "大同市",
        "region_code": "140200",
        "child": [
          {
            "id": 229,
            "name": "新荣区",
            "region_code": "140212",

          },
          {
            "id": 230,
            "name": "平城区",
            "region_code": "140213",

          },
          {
            "id": 231,
            "name": "云冈区",
            "region_code": "140214",

          },
          {
            "id": 232,
            "name": "云州区",
            "region_code": "140215",

          },
          {
            "id": 233,
            "name": "阳高县",
            "region_code": "140221",

          },
          {
            "id": 234,
            "name": "天镇县",
            "region_code": "140222",

          },
          {
            "id": 235,
            "name": "广灵县",
            "region_code": "140223",

          },
          {
            "id": 236,
            "name": "灵丘县",
            "region_code": "140224",

          },
          {
            "id": 237,
            "name": "浑源县",
            "region_code": "140225",

          },
          {
            "id": 238,
            "name": "左云县",
            "region_code": "140226",

          }
        ]
      },
      {
        "id": 239,
        "name": "阳泉市",
        "region_code": "140300",
        "child": [
          {
            "id": 240,
            "name": "城区",
            "region_code": "140302",

          },
          {
            "id": 241,
            "name": "矿区",
            "region_code": "140303",

          },
          {
            "id": 242,
            "name": "郊区",
            "region_code": "140311",

          },
          {
            "id": 243,
            "name": "平定县",
            "region_code": "140321",

          },
          {
            "id": 244,
            "name": "盂县",
            "region_code": "140322",

          }
        ]
      },
      {
        "id": 245,
        "name": "长治市",
        "region_code": "140400",
        "child": [
          {
            "id": 246,
            "name": "潞州区",
            "region_code": "140403",

          },
          {
            "id": 247,
            "name": "上党区",
            "region_code": "140404",

          },
          {
            "id": 248,
            "name": "屯留区",
            "region_code": "140405",

          },
          {
            "id": 249,
            "name": "潞城区",
            "region_code": "140406",

          },
          {
            "id": 250,
            "name": "襄垣县",
            "region_code": "140423",

          },
          {
            "id": 251,
            "name": "平顺县",
            "region_code": "140425",

          },
          {
            "id": 252,
            "name": "黎城县",
            "region_code": "140426",

          },
          {
            "id": 253,
            "name": "壶关县",
            "region_code": "140427",

          },
          {
            "id": 254,
            "name": "长子县",
            "region_code": "140428",

          },
          {
            "id": 255,
            "name": "武乡县",
            "region_code": "140429",

          },
          {
            "id": 256,
            "name": "沁县",
            "region_code": "140430",

          },
          {
            "id": 257,
            "name": "沁源县",
            "region_code": "140431",

          }
        ]
      },
      {
        "id": 258,
        "name": "晋城市",
        "region_code": "140500",
        "child": [
          {
            "id": 259,
            "name": "城区",
            "region_code": "140502",

          },
          {
            "id": 260,
            "name": "沁水县",
            "region_code": "140521",

          },
          {
            "id": 261,
            "name": "阳城县",
            "region_code": "140522",

          },
          {
            "id": 262,
            "name": "陵川县",
            "region_code": "140524",

          },
          {
            "id": 263,
            "name": "泽州县",
            "region_code": "140525",

          },
          {
            "id": 264,
            "name": "高平市",
            "region_code": "140581",

          }
        ]
      },
      {
        "id": 265,
        "name": "朔州市",
        "region_code": "140600",
        "child": [
          {
            "id": 266,
            "name": "朔城区",
            "region_code": "140602",

          },
          {
            "id": 267,
            "name": "平鲁区",
            "region_code": "140603",

          },
          {
            "id": 268,
            "name": "山阴县",
            "region_code": "140621",

          },
          {
            "id": 269,
            "name": "应县",
            "region_code": "140622",

          },
          {
            "id": 270,
            "name": "右玉县",
            "region_code": "140623",

          },
          {
            "id": 271,
            "name": "怀仁市",
            "region_code": "140681",

          }
        ]
      },
      {
        "id": 272,
        "name": "晋中市",
        "region_code": "140700",
        "child": [
          {
            "id": 273,
            "name": "榆次区",
            "region_code": "140702",

          },
          {
            "id": 274,
            "name": "太谷区",
            "region_code": "140703",

          },
          {
            "id": 275,
            "name": "榆社县",
            "region_code": "140721",

          },
          {
            "id": 276,
            "name": "左权县",
            "region_code": "140722",

          },
          {
            "id": 277,
            "name": "和顺县",
            "region_code": "140723",

          },
          {
            "id": 278,
            "name": "昔阳县",
            "region_code": "140724",

          },
          {
            "id": 279,
            "name": "寿阳县",
            "region_code": "140725",

          },
          {
            "id": 280,
            "name": "祁县",
            "region_code": "140727",

          },
          {
            "id": 281,
            "name": "平遥县",
            "region_code": "140728",

          },
          {
            "id": 282,
            "name": "灵石县",
            "region_code": "140729",

          },
          {
            "id": 283,
            "name": "介休市",
            "region_code": "140781",

          }
        ]
      },
      {
        "id": 284,
        "name": "运城市",
        "region_code": "140800",
        "child": [
          {
            "id": 285,
            "name": "盐湖区",
            "region_code": "140802",

          },
          {
            "id": 286,
            "name": "临猗县",
            "region_code": "140821",

          },
          {
            "id": 287,
            "name": "万荣县",
            "region_code": "140822",

          },
          {
            "id": 288,
            "name": "闻喜县",
            "region_code": "140823",

          },
          {
            "id": 289,
            "name": "稷山县",
            "region_code": "140824",

          },
          {
            "id": 290,
            "name": "新绛县",
            "region_code": "140825",

          },
          {
            "id": 291,
            "name": "绛县",
            "region_code": "140826",

          },
          {
            "id": 292,
            "name": "垣曲县",
            "region_code": "140827",

          },
          {
            "id": 293,
            "name": "夏县",
            "region_code": "140828",

          },
          {
            "id": 294,
            "name": "平陆县",
            "region_code": "140829",

          },
          {
            "id": 295,
            "name": "芮城县",
            "region_code": "140830",

          },
          {
            "id": 296,
            "name": "永济市",
            "region_code": "140881",

          },
          {
            "id": 297,
            "name": "河津市",
            "region_code": "140882",

          }
        ]
      },
      {
        "id": 298,
        "name": "忻州市",
        "region_code": "140900",
        "child": [
          {
            "id": 299,
            "name": "忻府区",
            "region_code": "140902",

          },
          {
            "id": 300,
            "name": "定襄县",
            "region_code": "140921",

          },
          {
            "id": 301,
            "name": "五台县",
            "region_code": "140922",

          },
          {
            "id": 302,
            "name": "代县",
            "region_code": "140923",

          },
          {
            "id": 303,
            "name": "繁峙县",
            "region_code": "140924",

          },
          {
            "id": 304,
            "name": "宁武县",
            "region_code": "140925",

          },
          {
            "id": 305,
            "name": "静乐县",
            "region_code": "140926",

          },
          {
            "id": 306,
            "name": "神池县",
            "region_code": "140927",

          },
          {
            "id": 307,
            "name": "五寨县",
            "region_code": "140928",

          },
          {
            "id": 308,
            "name": "岢岚县",
            "region_code": "140929",

          },
          {
            "id": 309,
            "name": "河曲县",
            "region_code": "140930",

          },
          {
            "id": 310,
            "name": "保德县",
            "region_code": "140931",

          },
          {
            "id": 311,
            "name": "偏关县",
            "region_code": "140932",

          },
          {
            "id": 312,
            "name": "原平市",
            "region_code": "140981",

          }
        ]
      },
      {
        "id": 313,
        "name": "临汾市",
        "region_code": "141000",
        "child": [
          {
            "id": 314,
            "name": "尧都区",
            "region_code": "141002",

          },
          {
            "id": 315,
            "name": "曲沃县",
            "region_code": "141021",

          },
          {
            "id": 316,
            "name": "翼城县",
            "region_code": "141022",

          },
          {
            "id": 317,
            "name": "襄汾县",
            "region_code": "141023",

          },
          {
            "id": 318,
            "name": "洪洞县",
            "region_code": "141024",

          },
          {
            "id": 319,
            "name": "古县",
            "region_code": "141025",

          },
          {
            "id": 320,
            "name": "安泽县",
            "region_code": "141026",

          },
          {
            "id": 321,
            "name": "浮山县",
            "region_code": "141027",

          },
          {
            "id": 322,
            "name": "吉县",
            "region_code": "141028",

          },
          {
            "id": 323,
            "name": "乡宁县",
            "region_code": "141029",

          },
          {
            "id": 324,
            "name": "大宁县",
            "region_code": "141030",

          },
          {
            "id": 325,
            "name": "隰县",
            "region_code": "141031",

          },
          {
            "id": 326,
            "name": "永和县",
            "region_code": "141032",

          },
          {
            "id": 327,
            "name": "蒲县",
            "region_code": "141033",

          },
          {
            "id": 328,
            "name": "汾西县",
            "region_code": "141034",

          },
          {
            "id": 329,
            "name": "侯马市",
            "region_code": "141081",

          },
          {
            "id": 330,
            "name": "霍州市",
            "region_code": "141082",

          }
        ]
      },
      {
        "id": 331,
        "name": "吕梁市",
        "region_code": "141100",
        "child": [
          {
            "id": 332,
            "name": "离石区",
            "region_code": "141102",

          },
          {
            "id": 333,
            "name": "文水县",
            "region_code": "141121",

          },
          {
            "id": 334,
            "name": "交城县",
            "region_code": "141122",

          },
          {
            "id": 335,
            "name": "兴县",
            "region_code": "141123",

          },
          {
            "id": 336,
            "name": "临县",
            "region_code": "141124",

          },
          {
            "id": 337,
            "name": "柳林县",
            "region_code": "141125",

          },
          {
            "id": 338,
            "name": "石楼县",
            "region_code": "141126",

          },
          {
            "id": 339,
            "name": "岚县",
            "region_code": "141127",

          },
          {
            "id": 340,
            "name": "方山县",
            "region_code": "141128",

          },
          {
            "id": 341,
            "name": "中阳县",
            "region_code": "141129",

          },
          {
            "id": 342,
            "name": "交口县",
            "region_code": "141130",

          },
          {
            "id": 343,
            "name": "孝义市",
            "region_code": "141181",

          },
          {
            "id": 344,
            "name": "汾阳市",
            "region_code": "141182",

          }
        ]
      }
    ]
  },
  {
    "id": 345,
    "name": "内蒙古自治区",
    "region_code": "150000",
    "child": [
      {
        "id": 346,
        "name": "呼和浩特市",
        "region_code": "150100",
        "child": [
          {
            "id": 347,
            "name": "新城区",
            "region_code": "150102",

          },
          {
            "id": 348,
            "name": "回民区",
            "region_code": "150103",

          },
          {
            "id": 349,
            "name": "玉泉区",
            "region_code": "150104",

          },
          {
            "id": 350,
            "name": "赛罕区",
            "region_code": "150105",

          },
          {
            "id": 351,
            "name": "土默特左旗",
            "region_code": "150121",

          },
          {
            "id": 352,
            "name": "托克托县",
            "region_code": "150122",

          },
          {
            "id": 353,
            "name": "和林格尔县",
            "region_code": "150123",

          },
          {
            "id": 354,
            "name": "清水河县",
            "region_code": "150124",

          },
          {
            "id": 355,
            "name": "武川县",
            "region_code": "150125",

          }
        ]
      },
      {
        "id": 356,
        "name": "包头市",
        "region_code": "150200",
        "child": [
          {
            "id": 357,
            "name": "东河区",
            "region_code": "150202",

          },
          {
            "id": 358,
            "name": "昆都仑区",
            "region_code": "150203",

          },
          {
            "id": 359,
            "name": "青山区",
            "region_code": "150204",

          },
          {
            "id": 360,
            "name": "石拐区",
            "region_code": "150205",

          },
          {
            "id": 361,
            "name": "白云鄂博矿区",
            "region_code": "150206",

          },
          {
            "id": 362,
            "name": "九原区",
            "region_code": "150207",

          },
          {
            "id": 363,
            "name": "土默特右旗",
            "region_code": "150221",

          },
          {
            "id": 364,
            "name": "固阳县",
            "region_code": "150222",

          },
          {
            "id": 365,
            "name": "达尔罕茂明安联合旗",
            "region_code": "150223",

          }
        ]
      },
      {
        "id": 366,
        "name": "乌海市",
        "region_code": "150300",
        "child": [
          {
            "id": 367,
            "name": "海勃湾区",
            "region_code": "150302",

          },
          {
            "id": 368,
            "name": "海南区",
            "region_code": "150303",

          },
          {
            "id": 369,
            "name": "乌达区",
            "region_code": "150304",

          }
        ]
      },
      {
        "id": 370,
        "name": "赤峰市",
        "region_code": "150400",
        "child": [
          {
            "id": 371,
            "name": "红山区",
            "region_code": "150402",

          },
          {
            "id": 372,
            "name": "元宝山区",
            "region_code": "150403",

          },
          {
            "id": 373,
            "name": "松山区",
            "region_code": "150404",

          },
          {
            "id": 374,
            "name": "阿鲁科尔沁旗",
            "region_code": "150421",

          },
          {
            "id": 375,
            "name": "巴林左旗",
            "region_code": "150422",

          },
          {
            "id": 376,
            "name": "巴林右旗",
            "region_code": "150423",

          },
          {
            "id": 377,
            "name": "林西县",
            "region_code": "150424",

          },
          {
            "id": 378,
            "name": "克什克腾旗",
            "region_code": "150425",

          },
          {
            "id": 379,
            "name": "翁牛特旗",
            "region_code": "150426",

          },
          {
            "id": 380,
            "name": "喀喇沁旗",
            "region_code": "150428",

          },
          {
            "id": 381,
            "name": "宁城县",
            "region_code": "150429",

          },
          {
            "id": 382,
            "name": "敖汉旗",
            "region_code": "150430",

          }
        ]
      },
      {
        "id": 383,
        "name": "通辽市",
        "region_code": "150500",
        "child": [
          {
            "id": 384,
            "name": "科尔沁区",
            "region_code": "150502",

          },
          {
            "id": 385,
            "name": "科尔沁左翼中旗",
            "region_code": "150521",

          },
          {
            "id": 386,
            "name": "科尔沁左翼后旗",
            "region_code": "150522",

          },
          {
            "id": 387,
            "name": "开鲁县",
            "region_code": "150523",

          },
          {
            "id": 388,
            "name": "库伦旗",
            "region_code": "150524",

          },
          {
            "id": 389,
            "name": "奈曼旗",
            "region_code": "150525",

          },
          {
            "id": 390,
            "name": "扎鲁特旗",
            "region_code": "150526",

          },
          {
            "id": 391,
            "name": "霍林郭勒市",
            "region_code": "150581",

          }
        ]
      },
      {
        "id": 392,
        "name": "鄂尔多斯市",
        "region_code": "150600",
        "child": [
          {
            "id": 393,
            "name": "东胜区",
            "region_code": "150602",

          },
          {
            "id": 394,
            "name": "康巴什区",
            "region_code": "150603",

          },
          {
            "id": 395,
            "name": "达拉特旗",
            "region_code": "150621",

          },
          {
            "id": 396,
            "name": "准格尔旗",
            "region_code": "150622",

          },
          {
            "id": 397,
            "name": "鄂托克前旗",
            "region_code": "150623",

          },
          {
            "id": 398,
            "name": "鄂托克旗",
            "region_code": "150624",

          },
          {
            "id": 399,
            "name": "杭锦旗",
            "region_code": "150625",

          },
          {
            "id": 400,
            "name": "乌审旗",
            "region_code": "150626",

          },
          {
            "id": 401,
            "name": "伊金霍洛旗",
            "region_code": "150627",

          }
        ]
      },
      {
        "id": 402,
        "name": "呼伦贝尔市",
        "region_code": "150700",
        "child": [
          {
            "id": 403,
            "name": "海拉尔区",
            "region_code": "150702",

          },
          {
            "id": 404,
            "name": "扎赉诺尔区",
            "region_code": "150703",

          },
          {
            "id": 405,
            "name": "阿荣旗",
            "region_code": "150721",

          },
          {
            "id": 406,
            "name": "莫力达瓦达斡尔族自治旗",
            "region_code": "150722",

          },
          {
            "id": 407,
            "name": "鄂伦春自治旗",
            "region_code": "150723",

          },
          {
            "id": 408,
            "name": "鄂温克族自治旗",
            "region_code": "150724",

          },
          {
            "id": 409,
            "name": "陈巴尔虎旗",
            "region_code": "150725",

          },
          {
            "id": 410,
            "name": "新巴尔虎左旗",
            "region_code": "150726",

          },
          {
            "id": 411,
            "name": "新巴尔虎右旗",
            "region_code": "150727",

          },
          {
            "id": 412,
            "name": "满洲里市",
            "region_code": "150781",

          },
          {
            "id": 413,
            "name": "牙克石市",
            "region_code": "150782",

          },
          {
            "id": 414,
            "name": "扎兰屯市",
            "region_code": "150783",

          },
          {
            "id": 415,
            "name": "额尔古纳市",
            "region_code": "150784",

          },
          {
            "id": 416,
            "name": "根河市",
            "region_code": "150785",

          }
        ]
      },
      {
        "id": 417,
        "name": "巴彦淖尔市",
        "region_code": "150800",
        "child": [
          {
            "id": 418,
            "name": "临河区",
            "region_code": "150802",

          },
          {
            "id": 419,
            "name": "五原县",
            "region_code": "150821",

          },
          {
            "id": 420,
            "name": "磴口县",
            "region_code": "150822",

          },
          {
            "id": 421,
            "name": "乌拉特前旗",
            "region_code": "150823",

          },
          {
            "id": 422,
            "name": "乌拉特中旗",
            "region_code": "150824",

          },
          {
            "id": 423,
            "name": "乌拉特后旗",
            "region_code": "150825",

          },
          {
            "id": 424,
            "name": "杭锦后旗",
            "region_code": "150826",

          }
        ]
      },
      {
        "id": 425,
        "name": "乌兰察布市",
        "region_code": "150900",
        "child": [
          {
            "id": 426,
            "name": "集宁区",
            "region_code": "150902",

          },
          {
            "id": 427,
            "name": "卓资县",
            "region_code": "150921",

          },
          {
            "id": 428,
            "name": "化德县",
            "region_code": "150922",

          },
          {
            "id": 429,
            "name": "商都县",
            "region_code": "150923",

          },
          {
            "id": 430,
            "name": "兴和县",
            "region_code": "150924",

          },
          {
            "id": 431,
            "name": "凉城县",
            "region_code": "150925",

          },
          {
            "id": 432,
            "name": "察哈尔右翼前旗",
            "region_code": "150926",

          },
          {
            "id": 433,
            "name": "察哈尔右翼中旗",
            "region_code": "150927",

          },
          {
            "id": 434,
            "name": "察哈尔右翼后旗",
            "region_code": "150928",

          },
          {
            "id": 435,
            "name": "四子王旗",
            "region_code": "150929",

          },
          {
            "id": 436,
            "name": "丰镇市",
            "region_code": "150981",

          }
        ]
      },
      {
        "id": 437,
        "name": "兴安盟",
        "region_code": "152200",
        "child": [
          {
            "id": 438,
            "name": "乌兰浩特市",
            "region_code": "152201",

          },
          {
            "id": 439,
            "name": "阿尔山市",
            "region_code": "152202",

          },
          {
            "id": 440,
            "name": "科尔沁右翼前旗",
            "region_code": "152221",

          },
          {
            "id": 441,
            "name": "科尔沁右翼中旗",
            "region_code": "152222",

          },
          {
            "id": 442,
            "name": "扎赉特旗",
            "region_code": "152223",

          },
          {
            "id": 443,
            "name": "突泉县",
            "region_code": "152224",

          }
        ]
      },
      {
        "id": 444,
        "name": "锡林郭勒盟",
        "region_code": "152500",
        "child": [
          {
            "id": 445,
            "name": "二连浩特市",
            "region_code": "152501",

          },
          {
            "id": 446,
            "name": "锡林浩特市",
            "region_code": "152502",

          },
          {
            "id": 447,
            "name": "阿巴嘎旗",
            "region_code": "152522",

          },
          {
            "id": 448,
            "name": "苏尼特左旗",
            "region_code": "152523",

          },
          {
            "id": 449,
            "name": "苏尼特右旗",
            "region_code": "152524",

          },
          {
            "id": 450,
            "name": "东乌珠穆沁旗",
            "region_code": "152525",

          },
          {
            "id": 451,
            "name": "西乌珠穆沁旗",
            "region_code": "152526",

          },
          {
            "id": 452,
            "name": "太仆寺旗",
            "region_code": "152527",

          },
          {
            "id": 453,
            "name": "镶黄旗",
            "region_code": "152528",

          },
          {
            "id": 454,
            "name": "正镶白旗",
            "region_code": "152529",

          },
          {
            "id": 455,
            "name": "正蓝旗",
            "region_code": "152530",

          },
          {
            "id": 456,
            "name": "多伦县",
            "region_code": "152531",

          }
        ]
      },
      {
        "id": 457,
        "name": "阿拉善盟",
        "region_code": "152900",
        "child": [
          {
            "id": 458,
            "name": "阿拉善左旗",
            "region_code": "152921",

          },
          {
            "id": 459,
            "name": "阿拉善右旗",
            "region_code": "152922",

          },
          {
            "id": 460,
            "name": "额济纳旗",
            "region_code": "152923",

          }
        ]
      }
    ]
  },
  {
    "id": 461,
    "name": "辽宁省",
    "region_code": "210000",
    "child": [
      {
        "id": 462,
        "name": "沈阳市",
        "region_code": "210100",
        "child": [
          {
            "id": 463,
            "name": "和平区",
            "region_code": "210102",

          },
          {
            "id": 464,
            "name": "沈河区",
            "region_code": "210103",

          },
          {
            "id": 465,
            "name": "大东区",
            "region_code": "210104",

          },
          {
            "id": 466,
            "name": "皇姑区",
            "region_code": "210105",

          },
          {
            "id": 467,
            "name": "铁西区",
            "region_code": "210106",

          },
          {
            "id": 468,
            "name": "苏家屯区",
            "region_code": "210111",

          },
          {
            "id": 469,
            "name": "浑南区",
            "region_code": "210112",

          },
          {
            "id": 470,
            "name": "沈北新区",
            "region_code": "210113",

          },
          {
            "id": 471,
            "name": "于洪区",
            "region_code": "210114",

          },
          {
            "id": 472,
            "name": "辽中区",
            "region_code": "210115",

          },
          {
            "id": 473,
            "name": "康平县",
            "region_code": "210123",

          },
          {
            "id": 474,
            "name": "法库县",
            "region_code": "210124",

          },
          {
            "id": 475,
            "name": "新民市",
            "region_code": "210181",

          }
        ]
      },
      {
        "id": 476,
        "name": "大连市",
        "region_code": "210200",
        "child": [
          {
            "id": 477,
            "name": "中山区",
            "region_code": "210202",

          },
          {
            "id": 478,
            "name": "西岗区",
            "region_code": "210203",

          },
          {
            "id": 479,
            "name": "沙河口区",
            "region_code": "210204",

          },
          {
            "id": 480,
            "name": "甘井子区",
            "region_code": "210211",

          },
          {
            "id": 481,
            "name": "旅顺口区",
            "region_code": "210212",

          },
          {
            "id": 482,
            "name": "金州区",
            "region_code": "210213",

          },
          {
            "id": 483,
            "name": "普兰店区",
            "region_code": "210214",

          },
          {
            "id": 484,
            "name": "长海县",
            "region_code": "210224",

          },
          {
            "id": 485,
            "name": "瓦房店市",
            "region_code": "210281",

          },
          {
            "id": 486,
            "name": "庄河市",
            "region_code": "210283",

          }
        ]
      },
      {
        "id": 487,
        "name": "鞍山市",
        "region_code": "210300",
        "child": [
          {
            "id": 488,
            "name": "铁东区",
            "region_code": "210302",

          },
          {
            "id": 489,
            "name": "铁西区",
            "region_code": "210303",

          },
          {
            "id": 490,
            "name": "立山区",
            "region_code": "210304",

          },
          {
            "id": 491,
            "name": "千山区",
            "region_code": "210311",

          },
          {
            "id": 492,
            "name": "台安县",
            "region_code": "210321",

          },
          {
            "id": 493,
            "name": "岫岩满族自治县",
            "region_code": "210323",

          },
          {
            "id": 494,
            "name": "海城市",
            "region_code": "210381",

          }
        ]
      },
      {
        "id": 495,
        "name": "抚顺市",
        "region_code": "210400",
        "child": [
          {
            "id": 496,
            "name": "新抚区",
            "region_code": "210402",

          },
          {
            "id": 497,
            "name": "东洲区",
            "region_code": "210403",

          },
          {
            "id": 498,
            "name": "望花区",
            "region_code": "210404",

          },
          {
            "id": 499,
            "name": "顺城区",
            "region_code": "210411",

          },
          {
            "id": 500,
            "name": "抚顺县",
            "region_code": "210421",

          },
          {
            "id": 501,
            "name": "新宾满族自治县",
            "region_code": "210422",

          },
          {
            "id": 502,
            "name": "清原满族自治县",
            "region_code": "210423",

          }
        ]
      },
      {
        "id": 503,
        "name": "本溪市",
        "region_code": "210500",
        "child": [
          {
            "id": 504,
            "name": "平山区",
            "region_code": "210502",

          },
          {
            "id": 505,
            "name": "溪湖区",
            "region_code": "210503",

          },
          {
            "id": 506,
            "name": "明山区",
            "region_code": "210504",

          },
          {
            "id": 507,
            "name": "南芬区",
            "region_code": "210505",

          },
          {
            "id": 508,
            "name": "本溪满族自治县",
            "region_code": "210521",

          },
          {
            "id": 509,
            "name": "桓仁满族自治县",
            "region_code": "210522",

          }
        ]
      },
      {
        "id": 510,
        "name": "丹东市",
        "region_code": "210600",
        "child": [
          {
            "id": 511,
            "name": "元宝区",
            "region_code": "210602",

          },
          {
            "id": 512,
            "name": "振兴区",
            "region_code": "210603",

          },
          {
            "id": 513,
            "name": "振安区",
            "region_code": "210604",

          },
          {
            "id": 514,
            "name": "宽甸满族自治县",
            "region_code": "210624",

          },
          {
            "id": 515,
            "name": "东港市",
            "region_code": "210681",

          },
          {
            "id": 516,
            "name": "凤城市",
            "region_code": "210682",

          }
        ]
      },
      {
        "id": 517,
        "name": "锦州市",
        "region_code": "210700",
        "child": [
          {
            "id": 518,
            "name": "古塔区",
            "region_code": "210702",

          },
          {
            "id": 519,
            "name": "凌河区",
            "region_code": "210703",

          },
          {
            "id": 520,
            "name": "太和区",
            "region_code": "210711",

          },
          {
            "id": 521,
            "name": "黑山县",
            "region_code": "210726",

          },
          {
            "id": 522,
            "name": "义县",
            "region_code": "210727",

          },
          {
            "id": 523,
            "name": "凌海市",
            "region_code": "210781",

          },
          {
            "id": 524,
            "name": "北镇市",
            "region_code": "210782",

          }
        ]
      },
      {
        "id": 525,
        "name": "营口市",
        "region_code": "210800",
        "child": [
          {
            "id": 526,
            "name": "站前区",
            "region_code": "210802",

          },
          {
            "id": 527,
            "name": "西市区",
            "region_code": "210803",

          },
          {
            "id": 528,
            "name": "鲅鱼圈区",
            "region_code": "210804",

          },
          {
            "id": 529,
            "name": "老边区",
            "region_code": "210811",

          },
          {
            "id": 530,
            "name": "盖州市",
            "region_code": "210881",

          },
          {
            "id": 531,
            "name": "大石桥市",
            "region_code": "210882",

          }
        ]
      },
      {
        "id": 532,
        "name": "阜新市",
        "region_code": "210900",
        "child": [
          {
            "id": 533,
            "name": "海州区",
            "region_code": "210902",

          },
          {
            "id": 534,
            "name": "新邱区",
            "region_code": "210903",

          },
          {
            "id": 535,
            "name": "太平区",
            "region_code": "210904",

          },
          {
            "id": 536,
            "name": "清河门区",
            "region_code": "210905",

          },
          {
            "id": 537,
            "name": "细河区",
            "region_code": "210911",

          },
          {
            "id": 538,
            "name": "阜新蒙古族自治县",
            "region_code": "210921",

          },
          {
            "id": 539,
            "name": "彰武县",
            "region_code": "210922",

          }
        ]
      },
      {
        "id": 540,
        "name": "辽阳市",
        "region_code": "211000",
        "child": [
          {
            "id": 541,
            "name": "白塔区",
            "region_code": "211002",

          },
          {
            "id": 542,
            "name": "文圣区",
            "region_code": "211003",

          },
          {
            "id": 543,
            "name": "宏伟区",
            "region_code": "211004",

          },
          {
            "id": 544,
            "name": "弓长岭区",
            "region_code": "211005",

          },
          {
            "id": 545,
            "name": "太子河区",
            "region_code": "211011",

          },
          {
            "id": 546,
            "name": "辽阳县",
            "region_code": "211021",

          },
          {
            "id": 547,
            "name": "灯塔市",
            "region_code": "211081",

          }
        ]
      },
      {
        "id": 548,
        "name": "盘锦市",
        "region_code": "211100",
        "child": [
          {
            "id": 549,
            "name": "双台子区",
            "region_code": "211102",

          },
          {
            "id": 550,
            "name": "兴隆台区",
            "region_code": "211103",

          },
          {
            "id": 551,
            "name": "大洼区",
            "region_code": "211104",

          },
          {
            "id": 552,
            "name": "盘山县",
            "region_code": "211122",

          }
        ]
      },
      {
        "id": 553,
        "name": "铁岭市",
        "region_code": "211200",
        "child": [
          {
            "id": 554,
            "name": "银州区",
            "region_code": "211202",

          },
          {
            "id": 555,
            "name": "清河区",
            "region_code": "211204",

          },
          {
            "id": 556,
            "name": "铁岭县",
            "region_code": "211221",

          },
          {
            "id": 557,
            "name": "西丰县",
            "region_code": "211223",

          },
          {
            "id": 558,
            "name": "昌图县",
            "region_code": "211224",

          },
          {
            "id": 559,
            "name": "调兵山市",
            "region_code": "211281",

          },
          {
            "id": 560,
            "name": "开原市",
            "region_code": "211282",

          }
        ]
      },
      {
        "id": 561,
        "name": "朝阳市",
        "region_code": "211300",
        "child": [
          {
            "id": 562,
            "name": "双塔区",
            "region_code": "211302",

          },
          {
            "id": 563,
            "name": "龙城区",
            "region_code": "211303",

          },
          {
            "id": 564,
            "name": "朝阳县",
            "region_code": "211321",

          },
          {
            "id": 565,
            "name": "建平县",
            "region_code": "211322",

          },
          {
            "id": 566,
            "name": "喀喇沁左翼蒙古族自治县",
            "region_code": "211324",

          },
          {
            "id": 567,
            "name": "北票市",
            "region_code": "211381",

          },
          {
            "id": 568,
            "name": "凌源市",
            "region_code": "211382",

          }
        ]
      },
      {
        "id": 569,
        "name": "葫芦岛市",
        "region_code": "211400",
        "child": [
          {
            "id": 570,
            "name": "连山区",
            "region_code": "211402",

          },
          {
            "id": 571,
            "name": "龙港区",
            "region_code": "211403",

          },
          {
            "id": 572,
            "name": "南票区",
            "region_code": "211404",

          },
          {
            "id": 573,
            "name": "绥中县",
            "region_code": "211421",

          },
          {
            "id": 574,
            "name": "建昌县",
            "region_code": "211422",

          },
          {
            "id": 575,
            "name": "兴城市",
            "region_code": "211481",

          }
        ]
      }
    ]
  },
  {
    "id": 576,
    "name": "吉林省",
    "region_code": "220000",
    "child": [
      {
        "id": 577,
        "name": "长春市",
        "region_code": "220100",
        "child": [
          {
            "id": 578,
            "name": "南关区",
            "region_code": "220102",

          },
          {
            "id": 579,
            "name": "宽城区",
            "region_code": "220103",

          },
          {
            "id": 580,
            "name": "朝阳区",
            "region_code": "220104",

          },
          {
            "id": 581,
            "name": "二道区",
            "region_code": "220105",

          },
          {
            "id": 582,
            "name": "绿园区",
            "region_code": "220106",

          },
          {
            "id": 583,
            "name": "双阳区",
            "region_code": "220112",

          },
          {
            "id": 584,
            "name": "九台区",
            "region_code": "220113",

          },
          {
            "id": 585,
            "name": "农安县",
            "region_code": "220122",

          },
          {
            "id": 586,
            "name": "榆树市",
            "region_code": "220182",

          },
          {
            "id": 587,
            "name": "德惠市",
            "region_code": "220183",

          },
          {
            "id": 588,
            "name": "公主岭市",
            "region_code": "220184",

          }
        ]
      },
      {
        "id": 589,
        "name": "吉林市",
        "region_code": "220200",
        "child": [
          {
            "id": 590,
            "name": "昌邑区",
            "region_code": "220202",

          },
          {
            "id": 591,
            "name": "龙潭区",
            "region_code": "220203",

          },
          {
            "id": 592,
            "name": "船营区",
            "region_code": "220204",

          },
          {
            "id": 593,
            "name": "丰满区",
            "region_code": "220211",

          },
          {
            "id": 594,
            "name": "永吉县",
            "region_code": "220221",

          },
          {
            "id": 595,
            "name": "蛟河市",
            "region_code": "220281",

          },
          {
            "id": 596,
            "name": "桦甸市",
            "region_code": "220282",

          },
          {
            "id": 597,
            "name": "舒兰市",
            "region_code": "220283",

          },
          {
            "id": 598,
            "name": "磐石市",
            "region_code": "220284",

          }
        ]
      },
      {
        "id": 599,
        "name": "四平市",
        "region_code": "220300",
        "child": [
          {
            "id": 600,
            "name": "铁西区",
            "region_code": "220302",

          },
          {
            "id": 601,
            "name": "铁东区",
            "region_code": "220303",

          },
          {
            "id": 602,
            "name": "梨树县",
            "region_code": "220322",

          },
          {
            "id": 603,
            "name": "伊通满族自治县",
            "region_code": "220323",

          },
          {
            "id": 604,
            "name": "双辽市",
            "region_code": "220382",

          }
        ]
      },
      {
        "id": 605,
        "name": "辽源市",
        "region_code": "220400",
        "child": [
          {
            "id": 606,
            "name": "龙山区",
            "region_code": "220402",

          },
          {
            "id": 607,
            "name": "西安区",
            "region_code": "220403",

          },
          {
            "id": 608,
            "name": "东丰县",
            "region_code": "220421",

          },
          {
            "id": 609,
            "name": "东辽县",
            "region_code": "220422",

          }
        ]
      },
      {
        "id": 610,
        "name": "通化市",
        "region_code": "220500",
        "child": [
          {
            "id": 611,
            "name": "东昌区",
            "region_code": "220502",

          },
          {
            "id": 612,
            "name": "二道江区",
            "region_code": "220503",

          },
          {
            "id": 613,
            "name": "通化县",
            "region_code": "220521",

          },
          {
            "id": 614,
            "name": "辉南县",
            "region_code": "220523",

          },
          {
            "id": 615,
            "name": "柳河县",
            "region_code": "220524",

          },
          {
            "id": 616,
            "name": "梅河口市",
            "region_code": "220581",

          },
          {
            "id": 617,
            "name": "集安市",
            "region_code": "220582",

          }
        ]
      },
      {
        "id": 618,
        "name": "白山市",
        "region_code": "220600",
        "child": [
          {
            "id": 619,
            "name": "浑江区",
            "region_code": "220602",

          },
          {
            "id": 620,
            "name": "江源区",
            "region_code": "220605",

          },
          {
            "id": 621,
            "name": "抚松县",
            "region_code": "220621",

          },
          {
            "id": 622,
            "name": "靖宇县",
            "region_code": "220622",

          },
          {
            "id": 623,
            "name": "长白朝鲜族自治县",
            "region_code": "220623",

          },
          {
            "id": 624,
            "name": "临江市",
            "region_code": "220681",

          }
        ]
      },
      {
        "id": 625,
        "name": "松原市",
        "region_code": "220700",
        "child": [
          {
            "id": 626,
            "name": "宁江区",
            "region_code": "220702",

          },
          {
            "id": 627,
            "name": "前郭尔罗斯蒙古族自治县",
            "region_code": "220721",

          },
          {
            "id": 628,
            "name": "长岭县",
            "region_code": "220722",

          },
          {
            "id": 629,
            "name": "乾安县",
            "region_code": "220723",

          },
          {
            "id": 630,
            "name": "扶余市",
            "region_code": "220781",

          }
        ]
      },
      {
        "id": 631,
        "name": "白城市",
        "region_code": "220800",
        "child": [
          {
            "id": 632,
            "name": "洮北区",
            "region_code": "220802",

          },
          {
            "id": 633,
            "name": "镇赉县",
            "region_code": "220821",

          },
          {
            "id": 634,
            "name": "通榆县",
            "region_code": "220822",

          },
          {
            "id": 635,
            "name": "洮南市",
            "region_code": "220881",

          },
          {
            "id": 636,
            "name": "大安市",
            "region_code": "220882",

          }
        ]
      },
      {
        "id": 637,
        "name": "延边朝鲜族自治州",
        "region_code": "222400",
        "child": [
          {
            "id": 638,
            "name": "延吉市",
            "region_code": "222401",

          },
          {
            "id": 639,
            "name": "图们市",
            "region_code": "222402",

          },
          {
            "id": 640,
            "name": "敦化市",
            "region_code": "222403",

          },
          {
            "id": 641,
            "name": "珲春市",
            "region_code": "222404",

          },
          {
            "id": 642,
            "name": "龙井市",
            "region_code": "222405",

          },
          {
            "id": 643,
            "name": "和龙市",
            "region_code": "222406",

          },
          {
            "id": 644,
            "name": "汪清县",
            "region_code": "222424",

          },
          {
            "id": 645,
            "name": "安图县",
            "region_code": "222426",

          }
        ]
      }
    ]
  },
  {
    "id": 646,
    "name": "黑龙江省",
    "region_code": "230000",
    "child": [
      {
        "id": 647,
        "name": "哈尔滨市",
        "region_code": "230100",
        "child": [
          {
            "id": 648,
            "name": "道里区",
            "region_code": "230102",

          },
          {
            "id": 649,
            "name": "南岗区",
            "region_code": "230103",

          },
          {
            "id": 650,
            "name": "道外区",
            "region_code": "230104",

          },
          {
            "id": 651,
            "name": "平房区",
            "region_code": "230108",

          },
          {
            "id": 652,
            "name": "松北区",
            "region_code": "230109",

          },
          {
            "id": 653,
            "name": "香坊区",
            "region_code": "230110",

          },
          {
            "id": 654,
            "name": "呼兰区",
            "region_code": "230111",

          },
          {
            "id": 655,
            "name": "阿城区",
            "region_code": "230112",

          },
          {
            "id": 656,
            "name": "双城区",
            "region_code": "230113",

          },
          {
            "id": 657,
            "name": "依兰县",
            "region_code": "230123",

          },
          {
            "id": 658,
            "name": "方正县",
            "region_code": "230124",

          },
          {
            "id": 659,
            "name": "宾县",
            "region_code": "230125",

          },
          {
            "id": 660,
            "name": "巴彦县",
            "region_code": "230126",

          },
          {
            "id": 661,
            "name": "木兰县",
            "region_code": "230127",

          },
          {
            "id": 662,
            "name": "通河县",
            "region_code": "230128",

          },
          {
            "id": 663,
            "name": "延寿县",
            "region_code": "230129",

          },
          {
            "id": 664,
            "name": "尚志市",
            "region_code": "230183",

          },
          {
            "id": 665,
            "name": "五常市",
            "region_code": "230184",

          }
        ]
      },
      {
        "id": 666,
        "name": "齐齐哈尔市",
        "region_code": "230200",
        "child": [
          {
            "id": 667,
            "name": "龙沙区",
            "region_code": "230202",

          },
          {
            "id": 668,
            "name": "建华区",
            "region_code": "230203",

          },
          {
            "id": 669,
            "name": "铁锋区",
            "region_code": "230204",

          },
          {
            "id": 670,
            "name": "昂昂溪区",
            "region_code": "230205",

          },
          {
            "id": 671,
            "name": "富拉尔基区",
            "region_code": "230206",

          },
          {
            "id": 672,
            "name": "碾子山区",
            "region_code": "230207",

          },
          {
            "id": 673,
            "name": "梅里斯达斡尔族区",
            "region_code": "230208",

          },
          {
            "id": 674,
            "name": "龙江县",
            "region_code": "230221",

          },
          {
            "id": 675,
            "name": "依安县",
            "region_code": "230223",

          },
          {
            "id": 676,
            "name": "泰来县",
            "region_code": "230224",

          },
          {
            "id": 677,
            "name": "甘南县",
            "region_code": "230225",

          },
          {
            "id": 678,
            "name": "富裕县",
            "region_code": "230227",

          },
          {
            "id": 679,
            "name": "克山县",
            "region_code": "230229",

          },
          {
            "id": 680,
            "name": "克东县",
            "region_code": "230230",

          },
          {
            "id": 681,
            "name": "拜泉县",
            "region_code": "230231",

          },
          {
            "id": 682,
            "name": "讷河市",
            "region_code": "230281",

          }
        ]
      },
      {
        "id": 683,
        "name": "鸡西市",
        "region_code": "230300",
        "child": [
          {
            "id": 684,
            "name": "鸡冠区",
            "region_code": "230302",

          },
          {
            "id": 685,
            "name": "恒山区",
            "region_code": "230303",

          },
          {
            "id": 686,
            "name": "滴道区",
            "region_code": "230304",

          },
          {
            "id": 687,
            "name": "梨树区",
            "region_code": "230305",

          },
          {
            "id": 688,
            "name": "城子河区",
            "region_code": "230306",

          },
          {
            "id": 689,
            "name": "麻山区",
            "region_code": "230307",

          },
          {
            "id": 690,
            "name": "鸡东县",
            "region_code": "230321",

          },
          {
            "id": 691,
            "name": "虎林市",
            "region_code": "230381",

          },
          {
            "id": 692,
            "name": "密山市",
            "region_code": "230382",

          }
        ]
      },
      {
        "id": 693,
        "name": "鹤岗市",
        "region_code": "230400",
        "child": [
          {
            "id": 694,
            "name": "向阳区",
            "region_code": "230402",

          },
          {
            "id": 695,
            "name": "工农区",
            "region_code": "230403",

          },
          {
            "id": 696,
            "name": "南山区",
            "region_code": "230404",

          },
          {
            "id": 697,
            "name": "兴安区",
            "region_code": "230405",

          },
          {
            "id": 698,
            "name": "东山区",
            "region_code": "230406",

          },
          {
            "id": 699,
            "name": "兴山区",
            "region_code": "230407",

          },
          {
            "id": 700,
            "name": "萝北县",
            "region_code": "230421",

          },
          {
            "id": 701,
            "name": "绥滨县",
            "region_code": "230422",

          }
        ]
      },
      {
        "id": 702,
        "name": "双鸭山市",
        "region_code": "230500",
        "child": [
          {
            "id": 703,
            "name": "尖山区",
            "region_code": "230502",

          },
          {
            "id": 704,
            "name": "岭东区",
            "region_code": "230503",

          },
          {
            "id": 705,
            "name": "四方台区",
            "region_code": "230505",

          },
          {
            "id": 706,
            "name": "宝山区",
            "region_code": "230506",

          },
          {
            "id": 707,
            "name": "集贤县",
            "region_code": "230521",

          },
          {
            "id": 708,
            "name": "友谊县",
            "region_code": "230522",

          },
          {
            "id": 709,
            "name": "宝清县",
            "region_code": "230523",

          },
          {
            "id": 710,
            "name": "饶河县",
            "region_code": "230524",

          }
        ]
      },
      {
        "id": 711,
        "name": "大庆市",
        "region_code": "230600",
        "child": [
          {
            "id": 712,
            "name": "萨尔图区",
            "region_code": "230602",

          },
          {
            "id": 713,
            "name": "龙凤区",
            "region_code": "230603",

          },
          {
            "id": 714,
            "name": "让胡路区",
            "region_code": "230604",

          },
          {
            "id": 715,
            "name": "红岗区",
            "region_code": "230605",

          },
          {
            "id": 716,
            "name": "大同区",
            "region_code": "230606",

          },
          {
            "id": 717,
            "name": "肇州县",
            "region_code": "230621",

          },
          {
            "id": 718,
            "name": "肇源县",
            "region_code": "230622",

          },
          {
            "id": 719,
            "name": "林甸县",
            "region_code": "230623",

          },
          {
            "id": 720,
            "name": "杜尔伯特蒙古族自治县",
            "region_code": "230624",

          }
        ]
      },
      {
        "id": 721,
        "name": "伊春市",
        "region_code": "230700",
        "child": [
          {
            "id": 722,
            "name": "伊美区",
            "region_code": "230717",

          },
          {
            "id": 723,
            "name": "乌翠区",
            "region_code": "230718",

          },
          {
            "id": 724,
            "name": "友好区",
            "region_code": "230719",

          },
          {
            "id": 725,
            "name": "嘉荫县",
            "region_code": "230722",

          },
          {
            "id": 726,
            "name": "汤旺县",
            "region_code": "230723",

          },
          {
            "id": 727,
            "name": "丰林县",
            "region_code": "230724",

          },
          {
            "id": 728,
            "name": "大箐山县",
            "region_code": "230725",

          },
          {
            "id": 729,
            "name": "南岔县",
            "region_code": "230726",

          },
          {
            "id": 730,
            "name": "金林区",
            "region_code": "230751",

          },
          {
            "id": 731,
            "name": "铁力市",
            "region_code": "230781",

          }
        ]
      },
      {
        "id": 732,
        "name": "佳木斯市",
        "region_code": "230800",
        "child": [
          {
            "id": 733,
            "name": "向阳区",
            "region_code": "230803",

          },
          {
            "id": 734,
            "name": "前进区",
            "region_code": "230804",

          },
          {
            "id": 735,
            "name": "东风区",
            "region_code": "230805",

          },
          {
            "id": 736,
            "name": "郊区",
            "region_code": "230811",

          },
          {
            "id": 737,
            "name": "桦南县",
            "region_code": "230822",

          },
          {
            "id": 738,
            "name": "桦川县",
            "region_code": "230826",

          },
          {
            "id": 739,
            "name": "汤原县",
            "region_code": "230828",

          },
          {
            "id": 740,
            "name": "同江市",
            "region_code": "230881",

          },
          {
            "id": 741,
            "name": "富锦市",
            "region_code": "230882",

          },
          {
            "id": 742,
            "name": "抚远市",
            "region_code": "230883",

          }
        ]
      },
      {
        "id": 743,
        "name": "七台河市",
        "region_code": "230900",
        "child": [
          {
            "id": 744,
            "name": "新兴区",
            "region_code": "230902",

          },
          {
            "id": 745,
            "name": "桃山区",
            "region_code": "230903",

          },
          {
            "id": 746,
            "name": "茄子河区",
            "region_code": "230904",

          },
          {
            "id": 747,
            "name": "勃利县",
            "region_code": "230921",

          }
        ]
      },
      {
        "id": 748,
        "name": "牡丹江市",
        "region_code": "231000",
        "child": [
          {
            "id": 749,
            "name": "东安区",
            "region_code": "231002",

          },
          {
            "id": 750,
            "name": "阳明区",
            "region_code": "231003",

          },
          {
            "id": 751,
            "name": "爱民区",
            "region_code": "231004",

          },
          {
            "id": 752,
            "name": "西安区",
            "region_code": "231005",

          },
          {
            "id": 753,
            "name": "林口县",
            "region_code": "231025",

          },
          {
            "id": 754,
            "name": "绥芬河市",
            "region_code": "231081",

          },
          {
            "id": 755,
            "name": "海林市",
            "region_code": "231083",

          },
          {
            "id": 756,
            "name": "宁安市",
            "region_code": "231084",

          },
          {
            "id": 757,
            "name": "穆棱市",
            "region_code": "231085",

          },
          {
            "id": 758,
            "name": "东宁市",
            "region_code": "231086",

          }
        ]
      },
      {
        "id": 759,
        "name": "黑河市",
        "region_code": "231100",
        "child": [
          {
            "id": 760,
            "name": "爱辉区",
            "region_code": "231102",

          },
          {
            "id": 761,
            "name": "逊克县",
            "region_code": "231123",

          },
          {
            "id": 762,
            "name": "孙吴县",
            "region_code": "231124",

          },
          {
            "id": 763,
            "name": "北安市",
            "region_code": "231181",

          },
          {
            "id": 764,
            "name": "五大连池市",
            "region_code": "231182",

          },
          {
            "id": 765,
            "name": "嫩江市",
            "region_code": "231183",

          }
        ]
      },
      {
        "id": 766,
        "name": "绥化市",
        "region_code": "231200",
        "child": [
          {
            "id": 767,
            "name": "北林区",
            "region_code": "231202",

          },
          {
            "id": 768,
            "name": "望奎县",
            "region_code": "231221",

          },
          {
            "id": 769,
            "name": "兰西县",
            "region_code": "231222",

          },
          {
            "id": 770,
            "name": "青冈县",
            "region_code": "231223",

          },
          {
            "id": 771,
            "name": "庆安县",
            "region_code": "231224",

          },
          {
            "id": 772,
            "name": "明水县",
            "region_code": "231225",

          },
          {
            "id": 773,
            "name": "绥棱县",
            "region_code": "231226",

          },
          {
            "id": 774,
            "name": "安达市",
            "region_code": "231281",

          },
          {
            "id": 775,
            "name": "肇东市",
            "region_code": "231282",

          },
          {
            "id": 776,
            "name": "海伦市",
            "region_code": "231283",

          }
        ]
      },
      {
        "id": 777,
        "name": "大兴安岭地区",
        "region_code": "232700",
        "child": [
          {
            "id": 778,
            "name": "漠河市",
            "region_code": "232701",

          },
          {
            "id": 779,
            "name": "呼玛县",
            "region_code": "232721",

          },
          {
            "id": 780,
            "name": "塔河县",
            "region_code": "232722",

          }
        ]
      }
    ]
  },
  {
    "id": 781,
    "name": "上海市",
    "region_code": "310000",
    "child": [
      {
        "id": 782,
        "name": "上海市",
        "region_code": "310100",
        "child": [
          {
            "id": 783,
            "name": "黄浦区",
            "region_code": "310101",

          },
          {
            "id": 784,
            "name": "徐汇区",
            "region_code": "310104",

          },
          {
            "id": 785,
            "name": "长宁区",
            "region_code": "310105",

          },
          {
            "id": 786,
            "name": "静安区",
            "region_code": "310106",

          },
          {
            "id": 787,
            "name": "普陀区",
            "region_code": "310107",

          },
          {
            "id": 788,
            "name": "虹口区",
            "region_code": "310109",

          },
          {
            "id": 789,
            "name": "杨浦区",
            "region_code": "310110",

          },
          {
            "id": 790,
            "name": "闵行区",
            "region_code": "310112",

          },
          {
            "id": 791,
            "name": "宝山区",
            "region_code": "310113",

          },
          {
            "id": 792,
            "name": "嘉定区",
            "region_code": "310114",

          },
          {
            "id": 793,
            "name": "浦东新区",
            "region_code": "310115",

          },
          {
            "id": 794,
            "name": "金山区",
            "region_code": "310116",

          },
          {
            "id": 795,
            "name": "松江区",
            "region_code": "310117",

          },
          {
            "id": 796,
            "name": "青浦区",
            "region_code": "310118",

          },
          {
            "id": 797,
            "name": "奉贤区",
            "region_code": "310120",

          },
          {
            "id": 798,
            "name": "崇明区",
            "region_code": "310151",

          }
        ]
      }
    ]
  },
  {
    "id": 799,
    "name": "江苏省",
    "region_code": "320000",
    "child": [
      {
        "id": 800,
        "name": "南京市",
        "region_code": "320100",
        "child": [
          {
            "id": 801,
            "name": "玄武区",
            "region_code": "320102",

          },
          {
            "id": 802,
            "name": "秦淮区",
            "region_code": "320104",

          },
          {
            "id": 803,
            "name": "建邺区",
            "region_code": "320105",

          },
          {
            "id": 804,
            "name": "鼓楼区",
            "region_code": "320106",

          },
          {
            "id": 805,
            "name": "浦口区",
            "region_code": "320111",

          },
          {
            "id": 806,
            "name": "栖霞区",
            "region_code": "320113",

          },
          {
            "id": 807,
            "name": "雨花台区",
            "region_code": "320114",

          },
          {
            "id": 808,
            "name": "江宁区",
            "region_code": "320115",

          },
          {
            "id": 809,
            "name": "六合区",
            "region_code": "320116",

          },
          {
            "id": 810,
            "name": "溧水区",
            "region_code": "320117",

          },
          {
            "id": 811,
            "name": "高淳区",
            "region_code": "320118",

          }
        ]
      },
      {
        "id": 812,
        "name": "无锡市",
        "region_code": "320200",
        "child": [
          {
            "id": 813,
            "name": "锡山区",
            "region_code": "320205",

          },
          {
            "id": 814,
            "name": "惠山区",
            "region_code": "320206",

          },
          {
            "id": 815,
            "name": "滨湖区",
            "region_code": "320211",

          },
          {
            "id": 816,
            "name": "梁溪区",
            "region_code": "320213",

          },
          {
            "id": 817,
            "name": "新吴区",
            "region_code": "320214",

          },
          {
            "id": 818,
            "name": "江阴市",
            "region_code": "320281",

          },
          {
            "id": 819,
            "name": "宜兴市",
            "region_code": "320282",

          }
        ]
      },
      {
        "id": 820,
        "name": "徐州市",
        "region_code": "320300",
        "child": [
          {
            "id": 821,
            "name": "鼓楼区",
            "region_code": "320302",

          },
          {
            "id": 822,
            "name": "云龙区",
            "region_code": "320303",

          },
          {
            "id": 823,
            "name": "贾汪区",
            "region_code": "320305",

          },
          {
            "id": 824,
            "name": "泉山区",
            "region_code": "320311",

          },
          {
            "id": 825,
            "name": "铜山区",
            "region_code": "320312",

          },
          {
            "id": 826,
            "name": "丰县",
            "region_code": "320321",

          },
          {
            "id": 827,
            "name": "沛县",
            "region_code": "320322",

          },
          {
            "id": 828,
            "name": "睢宁县",
            "region_code": "320324",

          },
          {
            "id": 829,
            "name": "新沂市",
            "region_code": "320381",

          },
          {
            "id": 830,
            "name": "邳州市",
            "region_code": "320382",

          }
        ]
      },
      {
        "id": 831,
        "name": "常州市",
        "region_code": "320400",
        "child": [
          {
            "id": 832,
            "name": "天宁区",
            "region_code": "320402",

          },
          {
            "id": 833,
            "name": "钟楼区",
            "region_code": "320404",

          },
          {
            "id": 834,
            "name": "新北区",
            "region_code": "320411",

          },
          {
            "id": 835,
            "name": "武进区",
            "region_code": "320412",

          },
          {
            "id": 836,
            "name": "金坛区",
            "region_code": "320413",

          },
          {
            "id": 837,
            "name": "溧阳市",
            "region_code": "320481",

          }
        ]
      },
      {
        "id": 838,
        "name": "苏州市",
        "region_code": "320500",
        "child": [
          {
            "id": 839,
            "name": "虎丘区",
            "region_code": "320505",

          },
          {
            "id": 840,
            "name": "吴中区",
            "region_code": "320506",

          },
          {
            "id": 841,
            "name": "相城区",
            "region_code": "320507",

          },
          {
            "id": 842,
            "name": "姑苏区",
            "region_code": "320508",

          },
          {
            "id": 843,
            "name": "吴江区",
            "region_code": "320509",

          },
          {
            "id": 844,
            "name": "常熟市",
            "region_code": "320581",

          },
          {
            "id": 845,
            "name": "张家港市",
            "region_code": "320582",

          },
          {
            "id": 846,
            "name": "昆山市",
            "region_code": "320583",

          },
          {
            "id": 847,
            "name": "太仓市",
            "region_code": "320585",

          }
        ]
      },
      {
        "id": 848,
        "name": "南通市",
        "region_code": "320600",
        "child": [
          {
            "id": 849,
            "name": "崇川区",
            "region_code": "320602",

          },
          {
            "id": 850,
            "name": "港闸区",
            "region_code": "320611",

          },
          {
            "id": 851,
            "name": "通州区",
            "region_code": "320612",

          },
          {
            "id": 852,
            "name": "如东县",
            "region_code": "320623",

          },
          {
            "id": 853,
            "name": "启东市",
            "region_code": "320681",

          },
          {
            "id": 854,
            "name": "如皋市",
            "region_code": "320682",

          },
          {
            "id": 855,
            "name": "海门市",
            "region_code": "320684",

          },
          {
            "id": 856,
            "name": "海安市",
            "region_code": "320685",

          }
        ]
      },
      {
        "id": 857,
        "name": "连云港市",
        "region_code": "320700",
        "child": [
          {
            "id": 858,
            "name": "连云区",
            "region_code": "320703",

          },
          {
            "id": 859,
            "name": "海州区",
            "region_code": "320706",

          },
          {
            "id": 860,
            "name": "赣榆区",
            "region_code": "320707",

          },
          {
            "id": 861,
            "name": "东海县",
            "region_code": "320722",

          },
          {
            "id": 862,
            "name": "灌云县",
            "region_code": "320723",

          },
          {
            "id": 863,
            "name": "灌南县",
            "region_code": "320724",

          }
        ]
      },
      {
        "id": 864,
        "name": "淮安市",
        "region_code": "320800",
        "child": [
          {
            "id": 865,
            "name": "淮安区",
            "region_code": "320803",

          },
          {
            "id": 866,
            "name": "淮阴区",
            "region_code": "320804",

          },
          {
            "id": 867,
            "name": "清江浦区",
            "region_code": "320812",

          },
          {
            "id": 868,
            "name": "洪泽区",
            "region_code": "320813",

          },
          {
            "id": 869,
            "name": "涟水县",
            "region_code": "320826",

          },
          {
            "id": 870,
            "name": "盱眙县",
            "region_code": "320830",

          },
          {
            "id": 871,
            "name": "金湖县",
            "region_code": "320831",

          }
        ]
      },
      {
        "id": 872,
        "name": "盐城市",
        "region_code": "320900",
        "child": [
          {
            "id": 873,
            "name": "亭湖区",
            "region_code": "320902",

          },
          {
            "id": 874,
            "name": "盐都区",
            "region_code": "320903",

          },
          {
            "id": 875,
            "name": "大丰区",
            "region_code": "320904",

          },
          {
            "id": 876,
            "name": "响水县",
            "region_code": "320921",

          },
          {
            "id": 877,
            "name": "滨海县",
            "region_code": "320922",

          },
          {
            "id": 878,
            "name": "阜宁县",
            "region_code": "320923",

          },
          {
            "id": 879,
            "name": "射阳县",
            "region_code": "320924",

          },
          {
            "id": 880,
            "name": "建湖县",
            "region_code": "320925",

          },
          {
            "id": 881,
            "name": "东台市",
            "region_code": "320981",

          }
        ]
      },
      {
        "id": 882,
        "name": "扬州市",
        "region_code": "321000",
        "child": [
          {
            "id": 883,
            "name": "广陵区",
            "region_code": "321002",

          },
          {
            "id": 884,
            "name": "邗江区",
            "region_code": "321003",

          },
          {
            "id": 885,
            "name": "江都区",
            "region_code": "321012",

          },
          {
            "id": 886,
            "name": "宝应县",
            "region_code": "321023",

          },
          {
            "id": 887,
            "name": "仪征市",
            "region_code": "321081",

          },
          {
            "id": 888,
            "name": "高邮市",
            "region_code": "321084",

          }
        ]
      },
      {
        "id": 889,
        "name": "镇江市",
        "region_code": "321100",
        "child": [
          {
            "id": 890,
            "name": "京口区",
            "region_code": "321102",

          },
          {
            "id": 891,
            "name": "润州区",
            "region_code": "321111",

          },
          {
            "id": 892,
            "name": "丹徒区",
            "region_code": "321112",

          },
          {
            "id": 893,
            "name": "丹阳市",
            "region_code": "321181",

          },
          {
            "id": 894,
            "name": "扬中市",
            "region_code": "321182",

          },
          {
            "id": 895,
            "name": "句容市",
            "region_code": "321183",

          }
        ]
      },
      {
        "id": 896,
        "name": "泰州市",
        "region_code": "321200",
        "child": [
          {
            "id": 897,
            "name": "海陵区",
            "region_code": "321202",

          },
          {
            "id": 898,
            "name": "高港区",
            "region_code": "321203",

          },
          {
            "id": 899,
            "name": "姜堰区",
            "region_code": "321204",

          },
          {
            "id": 900,
            "name": "兴化市",
            "region_code": "321281",

          },
          {
            "id": 901,
            "name": "靖江市",
            "region_code": "321282",

          },
          {
            "id": 902,
            "name": "泰兴市",
            "region_code": "321283",

          }
        ]
      },
      {
        "id": 903,
        "name": "宿迁市",
        "region_code": "321300",
        "child": [
          {
            "id": 904,
            "name": "宿城区",
            "region_code": "321302",

          },
          {
            "id": 905,
            "name": "宿豫区",
            "region_code": "321311",

          },
          {
            "id": 906,
            "name": "沭阳县",
            "region_code": "321322",

          },
          {
            "id": 907,
            "name": "泗阳县",
            "region_code": "321323",

          },
          {
            "id": 908,
            "name": "泗洪县",
            "region_code": "321324",

          }
        ]
      }
    ]
  },
  {
    "id": 909,
    "name": "浙江省",
    "region_code": "330000",
    "child": [
      {
        "id": 910,
        "name": "杭州市",
        "region_code": "330100",
        "child": [
          {
            "id": 911,
            "name": "上城区",
            "region_code": "330102",

          },
          {
            "id": 912,
            "name": "下城区",
            "region_code": "330103",

          },
          {
            "id": 913,
            "name": "江干区",
            "region_code": "330104",

          },
          {
            "id": 914,
            "name": "拱墅区",
            "region_code": "330105",

          },
          {
            "id": 915,
            "name": "西湖区",
            "region_code": "330106",

          },
          {
            "id": 916,
            "name": "滨江区",
            "region_code": "330108",

          },
          {
            "id": 917,
            "name": "萧山区",
            "region_code": "330109",

          },
          {
            "id": 918,
            "name": "余杭区",
            "region_code": "330110",

          },
          {
            "id": 919,
            "name": "富阳区",
            "region_code": "330111",

          },
          {
            "id": 920,
            "name": "临安区",
            "region_code": "330112",

          },
          {
            "id": 921,
            "name": "桐庐县",
            "region_code": "330122",

          },
          {
            "id": 922,
            "name": "淳安县",
            "region_code": "330127",

          },
          {
            "id": 923,
            "name": "建德市",
            "region_code": "330182",

          }
        ]
      },
      {
        "id": 924,
        "name": "宁波市",
        "region_code": "330200",
        "child": [
          {
            "id": 925,
            "name": "海曙区",
            "region_code": "330203",

          },
          {
            "id": 926,
            "name": "江北区",
            "region_code": "330205",

          },
          {
            "id": 927,
            "name": "北仑区",
            "region_code": "330206",

          },
          {
            "id": 928,
            "name": "镇海区",
            "region_code": "330211",

          },
          {
            "id": 929,
            "name": "鄞州区",
            "region_code": "330212",

          },
          {
            "id": 930,
            "name": "奉化区",
            "region_code": "330213",

          },
          {
            "id": 931,
            "name": "象山县",
            "region_code": "330225",

          },
          {
            "id": 932,
            "name": "宁海县",
            "region_code": "330226",

          },
          {
            "id": 933,
            "name": "余姚市",
            "region_code": "330281",

          },
          {
            "id": 934,
            "name": "慈溪市",
            "region_code": "330282",

          }
        ]
      },
      {
        "id": 935,
        "name": "温州市",
        "region_code": "330300",
        "child": [
          {
            "id": 936,
            "name": "鹿城区",
            "region_code": "330302",

          },
          {
            "id": 937,
            "name": "龙湾区",
            "region_code": "330303",

          },
          {
            "id": 938,
            "name": "瓯海区",
            "region_code": "330304",

          },
          {
            "id": 939,
            "name": "洞头区",
            "region_code": "330305",

          },
          {
            "id": 940,
            "name": "永嘉县",
            "region_code": "330324",

          },
          {
            "id": 941,
            "name": "平阳县",
            "region_code": "330326",

          },
          {
            "id": 942,
            "name": "苍南县",
            "region_code": "330327",

          },
          {
            "id": 943,
            "name": "文成县",
            "region_code": "330328",

          },
          {
            "id": 944,
            "name": "泰顺县",
            "region_code": "330329",

          },
          {
            "id": 945,
            "name": "瑞安市",
            "region_code": "330381",

          },
          {
            "id": 946,
            "name": "乐清市",
            "region_code": "330382",

          },
          {
            "id": 947,
            "name": "龙港市",
            "region_code": "330383",

          }
        ]
      },
      {
        "id": 948,
        "name": "嘉兴市",
        "region_code": "330400",
        "child": [
          {
            "id": 949,
            "name": "南湖区",
            "region_code": "330402",

          },
          {
            "id": 950,
            "name": "秀洲区",
            "region_code": "330411",

          },
          {
            "id": 951,
            "name": "嘉善县",
            "region_code": "330421",

          },
          {
            "id": 952,
            "name": "海盐县",
            "region_code": "330424",

          },
          {
            "id": 953,
            "name": "海宁市",
            "region_code": "330481",

          },
          {
            "id": 954,
            "name": "平湖市",
            "region_code": "330482",

          },
          {
            "id": 955,
            "name": "桐乡市",
            "region_code": "330483",

          }
        ]
      },
      {
        "id": 956,
        "name": "湖州市",
        "region_code": "330500",
        "child": [
          {
            "id": 957,
            "name": "吴兴区",
            "region_code": "330502",

          },
          {
            "id": 958,
            "name": "南浔区",
            "region_code": "330503",

          },
          {
            "id": 959,
            "name": "德清县",
            "region_code": "330521",

          },
          {
            "id": 960,
            "name": "长兴县",
            "region_code": "330522",

          },
          {
            "id": 961,
            "name": "安吉县",
            "region_code": "330523",

          }
        ]
      },
      {
        "id": 962,
        "name": "绍兴市",
        "region_code": "330600",
        "child": [
          {
            "id": 963,
            "name": "越城区",
            "region_code": "330602",

          },
          {
            "id": 964,
            "name": "柯桥区",
            "region_code": "330603",

          },
          {
            "id": 965,
            "name": "上虞区",
            "region_code": "330604",

          },
          {
            "id": 966,
            "name": "新昌县",
            "region_code": "330624",

          },
          {
            "id": 967,
            "name": "诸暨市",
            "region_code": "330681",

          },
          {
            "id": 968,
            "name": "嵊州市",
            "region_code": "330683",

          }
        ]
      },
      {
        "id": 969,
        "name": "金华市",
        "region_code": "330700",
        "child": [
          {
            "id": 970,
            "name": "婺城区",
            "region_code": "330702",

          },
          {
            "id": 971,
            "name": "金东区",
            "region_code": "330703",

          },
          {
            "id": 972,
            "name": "武义县",
            "region_code": "330723",

          },
          {
            "id": 973,
            "name": "浦江县",
            "region_code": "330726",

          },
          {
            "id": 974,
            "name": "磐安县",
            "region_code": "330727",

          },
          {
            "id": 975,
            "name": "兰溪市",
            "region_code": "330781",

          },
          {
            "id": 976,
            "name": "义乌市",
            "region_code": "330782",

          },
          {
            "id": 977,
            "name": "东阳市",
            "region_code": "330783",

          },
          {
            "id": 978,
            "name": "永康市",
            "region_code": "330784",

          }
        ]
      },
      {
        "id": 979,
        "name": "衢州市",
        "region_code": "330800",
        "child": [
          {
            "id": 980,
            "name": "柯城区",
            "region_code": "330802",

          },
          {
            "id": 981,
            "name": "衢江区",
            "region_code": "330803",

          },
          {
            "id": 982,
            "name": "常山县",
            "region_code": "330822",

          },
          {
            "id": 983,
            "name": "开化县",
            "region_code": "330824",

          },
          {
            "id": 984,
            "name": "龙游县",
            "region_code": "330825",

          },
          {
            "id": 985,
            "name": "江山市",
            "region_code": "330881",

          }
        ]
      },
      {
        "id": 986,
        "name": "舟山市",
        "region_code": "330900",
        "child": [
          {
            "id": 987,
            "name": "定海区",
            "region_code": "330902",

          },
          {
            "id": 988,
            "name": "普陀区",
            "region_code": "330903",

          },
          {
            "id": 989,
            "name": "岱山县",
            "region_code": "330921",

          },
          {
            "id": 990,
            "name": "嵊泗县",
            "region_code": "330922",

          }
        ]
      },
      {
        "id": 991,
        "name": "台州市",
        "region_code": "331000",
        "child": [
          {
            "id": 992,
            "name": "椒江区",
            "region_code": "331002",

          },
          {
            "id": 993,
            "name": "黄岩区",
            "region_code": "331003",

          },
          {
            "id": 994,
            "name": "路桥区",
            "region_code": "331004",

          },
          {
            "id": 995,
            "name": "三门县",
            "region_code": "331022",

          },
          {
            "id": 996,
            "name": "天台县",
            "region_code": "331023",

          },
          {
            "id": 997,
            "name": "仙居县",
            "region_code": "331024",

          },
          {
            "id": 998,
            "name": "温岭市",
            "region_code": "331081",

          },
          {
            "id": 999,
            "name": "临海市",
            "region_code": "331082",

          },
          {
            "id": 1000,
            "name": "玉环市",
            "region_code": "331083",

          }
        ]
      },
      {
        "id": 1001,
        "name": "丽水市",
        "region_code": "331100",
        "child": [
          {
            "id": 1002,
            "name": "莲都区",
            "region_code": "331102",

          },
          {
            "id": 1003,
            "name": "青田县",
            "region_code": "331121",

          },
          {
            "id": 1004,
            "name": "缙云县",
            "region_code": "331122",

          },
          {
            "id": 1005,
            "name": "遂昌县",
            "region_code": "331123",

          },
          {
            "id": 1006,
            "name": "松阳县",
            "region_code": "331124",

          },
          {
            "id": 1007,
            "name": "云和县",
            "region_code": "331125",

          },
          {
            "id": 1008,
            "name": "庆元县",
            "region_code": "331126",

          },
          {
            "id": 1009,
            "name": "景宁畲族自治县",
            "region_code": "331127",

          },
          {
            "id": 1010,
            "name": "龙泉市",
            "region_code": "331181",

          }
        ]
      }
    ]
  },
  {
    "id": 1011,
    "name": "安徽省",
    "region_code": "340000",
    "child": [
      {
        "id": 1012,
        "name": "合肥市",
        "region_code": "340100",
        "child": [
          {
            "id": 1013,
            "name": "瑶海区",
            "region_code": "340102",

          },
          {
            "id": 1014,
            "name": "庐阳区",
            "region_code": "340103",

          },
          {
            "id": 1015,
            "name": "蜀山区",
            "region_code": "340104",

          },
          {
            "id": 1016,
            "name": "包河区",
            "region_code": "340111",

          },
          {
            "id": 1017,
            "name": "长丰县",
            "region_code": "340121",

          },
          {
            "id": 1018,
            "name": "肥东县",
            "region_code": "340122",

          },
          {
            "id": 1019,
            "name": "肥西县",
            "region_code": "340123",

          },
          {
            "id": 1020,
            "name": "庐江县",
            "region_code": "340124",

          },
          {
            "id": 1021,
            "name": "巢湖市",
            "region_code": "340181",

          }
        ]
      },
      {
        "id": 1022,
        "name": "芜湖市",
        "region_code": "340200",
        "child": [
          {
            "id": 1023,
            "name": "镜湖区",
            "region_code": "340202",

          },
          {
            "id": 1024,
            "name": "弋江区",
            "region_code": "340203",

          },
          {
            "id": 1025,
            "name": "鸠江区",
            "region_code": "340207",

          },
          {
            "id": 1026,
            "name": "三山区",
            "region_code": "340208",

          },
          {
            "id": 1027,
            "name": "芜湖县",
            "region_code": "340221",

          },
          {
            "id": 1028,
            "name": "繁昌县",
            "region_code": "340222",

          },
          {
            "id": 1029,
            "name": "南陵县",
            "region_code": "340223",

          },
          {
            "id": 1030,
            "name": "无为市",
            "region_code": "340281",

          }
        ]
      },
      {
        "id": 1031,
        "name": "蚌埠市",
        "region_code": "340300",
        "child": [
          {
            "id": 1032,
            "name": "龙子湖区",
            "region_code": "340302",

          },
          {
            "id": 1033,
            "name": "蚌山区",
            "region_code": "340303",

          },
          {
            "id": 1034,
            "name": "禹会区",
            "region_code": "340304",

          },
          {
            "id": 1035,
            "name": "淮上区",
            "region_code": "340311",

          },
          {
            "id": 1036,
            "name": "怀远县",
            "region_code": "340321",

          },
          {
            "id": 1037,
            "name": "五河县",
            "region_code": "340322",

          },
          {
            "id": 1038,
            "name": "固镇县",
            "region_code": "340323",

          }
        ]
      },
      {
        "id": 1039,
        "name": "淮南市",
        "region_code": "340400",
        "child": [
          {
            "id": 1040,
            "name": "大通区",
            "region_code": "340402",

          },
          {
            "id": 1041,
            "name": "田家庵区",
            "region_code": "340403",

          },
          {
            "id": 1042,
            "name": "谢家集区",
            "region_code": "340404",

          },
          {
            "id": 1043,
            "name": "八公山区",
            "region_code": "340405",

          },
          {
            "id": 1044,
            "name": "潘集区",
            "region_code": "340406",

          },
          {
            "id": 1045,
            "name": "凤台县",
            "region_code": "340421",

          },
          {
            "id": 1046,
            "name": "寿县",
            "region_code": "340422",

          }
        ]
      },
      {
        "id": 1047,
        "name": "马鞍山市",
        "region_code": "340500",
        "child": [
          {
            "id": 1048,
            "name": "花山区",
            "region_code": "340503",

          },
          {
            "id": 1049,
            "name": "雨山区",
            "region_code": "340504",

          },
          {
            "id": 1050,
            "name": "博望区",
            "region_code": "340506",

          },
          {
            "id": 1051,
            "name": "当涂县",
            "region_code": "340521",

          },
          {
            "id": 1052,
            "name": "含山县",
            "region_code": "340522",

          },
          {
            "id": 1053,
            "name": "和县",
            "region_code": "340523",

          }
        ]
      },
      {
        "id": 1054,
        "name": "淮北市",
        "region_code": "340600",
        "child": [
          {
            "id": 1055,
            "name": "杜集区",
            "region_code": "340602",

          },
          {
            "id": 1056,
            "name": "相山区",
            "region_code": "340603",

          },
          {
            "id": 1057,
            "name": "烈山区",
            "region_code": "340604",

          },
          {
            "id": 1058,
            "name": "濉溪县",
            "region_code": "340621",

          }
        ]
      },
      {
        "id": 1059,
        "name": "铜陵市",
        "region_code": "340700",
        "child": [
          {
            "id": 1060,
            "name": "铜官区",
            "region_code": "340705",

          },
          {
            "id": 1061,
            "name": "义安区",
            "region_code": "340706",

          },
          {
            "id": 1062,
            "name": "郊区",
            "region_code": "340711",

          },
          {
            "id": 1063,
            "name": "枞阳县",
            "region_code": "340722",

          }
        ]
      },
      {
        "id": 1064,
        "name": "安庆市",
        "region_code": "340800",
        "child": [
          {
            "id": 1065,
            "name": "迎江区",
            "region_code": "340802",

          },
          {
            "id": 1066,
            "name": "大观区",
            "region_code": "340803",

          },
          {
            "id": 1067,
            "name": "宜秀区",
            "region_code": "340811",

          },
          {
            "id": 1068,
            "name": "怀宁县",
            "region_code": "340822",

          },
          {
            "id": 1069,
            "name": "太湖县",
            "region_code": "340825",

          },
          {
            "id": 1070,
            "name": "宿松县",
            "region_code": "340826",

          },
          {
            "id": 1071,
            "name": "望江县",
            "region_code": "340827",

          },
          {
            "id": 1072,
            "name": "岳西县",
            "region_code": "340828",

          },
          {
            "id": 1073,
            "name": "桐城市",
            "region_code": "340881",

          },
          {
            "id": 1074,
            "name": "潜山市",
            "region_code": "340882",

          }
        ]
      },
      {
        "id": 1075,
        "name": "黄山市",
        "region_code": "341000",
        "child": [
          {
            "id": 1076,
            "name": "屯溪区",
            "region_code": "341002",

          },
          {
            "id": 1077,
            "name": "黄山区",
            "region_code": "341003",

          },
          {
            "id": 1078,
            "name": "徽州区",
            "region_code": "341004",

          },
          {
            "id": 1079,
            "name": "歙县",
            "region_code": "341021",

          },
          {
            "id": 1080,
            "name": "休宁县",
            "region_code": "341022",

          },
          {
            "id": 1081,
            "name": "黟县",
            "region_code": "341023",

          },
          {
            "id": 1082,
            "name": "祁门县",
            "region_code": "341024",

          }
        ]
      },
      {
        "id": 1083,
        "name": "滁州市",
        "region_code": "341100",
        "child": [
          {
            "id": 1084,
            "name": "琅琊区",
            "region_code": "341102",

          },
          {
            "id": 1085,
            "name": "南谯区",
            "region_code": "341103",

          },
          {
            "id": 1086,
            "name": "来安县",
            "region_code": "341122",

          },
          {
            "id": 1087,
            "name": "全椒县",
            "region_code": "341124",

          },
          {
            "id": 1088,
            "name": "定远县",
            "region_code": "341125",

          },
          {
            "id": 1089,
            "name": "凤阳县",
            "region_code": "341126",

          },
          {
            "id": 1090,
            "name": "天长市",
            "region_code": "341181",

          },
          {
            "id": 1091,
            "name": "明光市",
            "region_code": "341182",

          }
        ]
      },
      {
        "id": 1092,
        "name": "阜阳市",
        "region_code": "341200",
        "child": [
          {
            "id": 1093,
            "name": "颍州区",
            "region_code": "341202",

          },
          {
            "id": 1094,
            "name": "颍东区",
            "region_code": "341203",

          },
          {
            "id": 1095,
            "name": "颍泉区",
            "region_code": "341204",

          },
          {
            "id": 1096,
            "name": "临泉县",
            "region_code": "341221",

          },
          {
            "id": 1097,
            "name": "太和县",
            "region_code": "341222",

          },
          {
            "id": 1098,
            "name": "阜南县",
            "region_code": "341225",

          },
          {
            "id": 1099,
            "name": "颍上县",
            "region_code": "341226",

          },
          {
            "id": 1100,
            "name": "界首市",
            "region_code": "341282",

          }
        ]
      },
      {
        "id": 1101,
        "name": "宿州市",
        "region_code": "341300",
        "child": [
          {
            "id": 1102,
            "name": "埇桥区",
            "region_code": "341302",

          },
          {
            "id": 1103,
            "name": "砀山县",
            "region_code": "341321",

          },
          {
            "id": 1104,
            "name": "萧县",
            "region_code": "341322",

          },
          {
            "id": 1105,
            "name": "灵璧县",
            "region_code": "341323",

          },
          {
            "id": 1106,
            "name": "泗县",
            "region_code": "341324",

          }
        ]
      },
      {
        "id": 1107,
        "name": "六安市",
        "region_code": "341500",
        "child": [
          {
            "id": 1108,
            "name": "金安区",
            "region_code": "341502",

          },
          {
            "id": 1109,
            "name": "裕安区",
            "region_code": "341503",

          },
          {
            "id": 1110,
            "name": "叶集区",
            "region_code": "341504",

          },
          {
            "id": 1111,
            "name": "霍邱县",
            "region_code": "341522",

          },
          {
            "id": 1112,
            "name": "舒城县",
            "region_code": "341523",

          },
          {
            "id": 1113,
            "name": "金寨县",
            "region_code": "341524",

          },
          {
            "id": 1114,
            "name": "霍山县",
            "region_code": "341525",

          }
        ]
      },
      {
        "id": 1115,
        "name": "亳州市",
        "region_code": "341600",
        "child": [
          {
            "id": 1116,
            "name": "谯城区",
            "region_code": "341602",

          },
          {
            "id": 1117,
            "name": "涡阳县",
            "region_code": "341621",

          },
          {
            "id": 1118,
            "name": "蒙城县",
            "region_code": "341622",

          },
          {
            "id": 1119,
            "name": "利辛县",
            "region_code": "341623",

          }
        ]
      },
      {
        "id": 1120,
        "name": "池州市",
        "region_code": "341700",
        "child": [
          {
            "id": 1121,
            "name": "贵池区",
            "region_code": "341702",

          },
          {
            "id": 1122,
            "name": "东至县",
            "region_code": "341721",

          },
          {
            "id": 1123,
            "name": "石台县",
            "region_code": "341722",

          },
          {
            "id": 1124,
            "name": "青阳县",
            "region_code": "341723",

          }
        ]
      },
      {
        "id": 1125,
        "name": "宣城市",
        "region_code": "341800",
        "child": [
          {
            "id": 1126,
            "name": "宣州区",
            "region_code": "341802",

          },
          {
            "id": 1127,
            "name": "郎溪县",
            "region_code": "341821",

          },
          {
            "id": 1128,
            "name": "泾县",
            "region_code": "341823",

          },
          {
            "id": 1129,
            "name": "绩溪县",
            "region_code": "341824",

          },
          {
            "id": 1130,
            "name": "旌德县",
            "region_code": "341825",

          },
          {
            "id": 1131,
            "name": "宁国市",
            "region_code": "341881",

          },
          {
            "id": 1132,
            "name": "广德市",
            "region_code": "341882",

          }
        ]
      }
    ]
  },
  {
    "id": 1133,
    "name": "福建省",
    "region_code": "350000",
    "child": [
      {
        "id": 1134,
        "name": "福州市",
        "region_code": "350100",
        "child": [
          {
            "id": 1135,
            "name": "鼓楼区",
            "region_code": "350102",

          },
          {
            "id": 1136,
            "name": "台江区",
            "region_code": "350103",

          },
          {
            "id": 1137,
            "name": "仓山区",
            "region_code": "350104",

          },
          {
            "id": 1138,
            "name": "马尾区",
            "region_code": "350105",

          },
          {
            "id": 1139,
            "name": "晋安区",
            "region_code": "350111",

          },
          {
            "id": 1140,
            "name": "长乐区",
            "region_code": "350112",

          },
          {
            "id": 1141,
            "name": "闽侯县",
            "region_code": "350121",

          },
          {
            "id": 1142,
            "name": "连江县",
            "region_code": "350122",

          },
          {
            "id": 1143,
            "name": "罗源县",
            "region_code": "350123",

          },
          {
            "id": 1144,
            "name": "闽清县",
            "region_code": "350124",

          },
          {
            "id": 1145,
            "name": "永泰县",
            "region_code": "350125",

          },
          {
            "id": 1146,
            "name": "平潭县",
            "region_code": "350128",

          },
          {
            "id": 1147,
            "name": "福清市",
            "region_code": "350181",

          }
        ]
      },
      {
        "id": 1148,
        "name": "厦门市",
        "region_code": "350200",
        "child": [
          {
            "id": 1149,
            "name": "思明区",
            "region_code": "350203",

          },
          {
            "id": 1150,
            "name": "海沧区",
            "region_code": "350205",

          },
          {
            "id": 1151,
            "name": "湖里区",
            "region_code": "350206",

          },
          {
            "id": 1152,
            "name": "集美区",
            "region_code": "350211",

          },
          {
            "id": 1153,
            "name": "同安区",
            "region_code": "350212",

          },
          {
            "id": 1154,
            "name": "翔安区",
            "region_code": "350213",

          }
        ]
      },
      {
        "id": 1155,
        "name": "莆田市",
        "region_code": "350300",
        "child": [
          {
            "id": 1156,
            "name": "城厢区",
            "region_code": "350302",

          },
          {
            "id": 1157,
            "name": "涵江区",
            "region_code": "350303",

          },
          {
            "id": 1158,
            "name": "荔城区",
            "region_code": "350304",

          },
          {
            "id": 1159,
            "name": "秀屿区",
            "region_code": "350305",

          },
          {
            "id": 1160,
            "name": "仙游县",
            "region_code": "350322",

          }
        ]
      },
      {
        "id": 1161,
        "name": "三明市",
        "region_code": "350400",
        "child": [
          {
            "id": 1162,
            "name": "梅列区",
            "region_code": "350402",

          },
          {
            "id": 1163,
            "name": "三元区",
            "region_code": "350403",

          },
          {
            "id": 1164,
            "name": "明溪县",
            "region_code": "350421",

          },
          {
            "id": 1165,
            "name": "清流县",
            "region_code": "350423",

          },
          {
            "id": 1166,
            "name": "宁化县",
            "region_code": "350424",

          },
          {
            "id": 1167,
            "name": "大田县",
            "region_code": "350425",

          },
          {
            "id": 1168,
            "name": "尤溪县",
            "region_code": "350426",

          },
          {
            "id": 1169,
            "name": "沙县",
            "region_code": "350427",

          },
          {
            "id": 1170,
            "name": "将乐县",
            "region_code": "350428",

          },
          {
            "id": 1171,
            "name": "泰宁县",
            "region_code": "350429",

          },
          {
            "id": 1172,
            "name": "建宁县",
            "region_code": "350430",

          },
          {
            "id": 1173,
            "name": "永安市",
            "region_code": "350481",

          }
        ]
      },
      {
        "id": 1174,
        "name": "泉州市",
        "region_code": "350500",
        "child": [
          {
            "id": 1175,
            "name": "鲤城区",
            "region_code": "350502",

          },
          {
            "id": 1176,
            "name": "丰泽区",
            "region_code": "350503",

          },
          {
            "id": 1177,
            "name": "洛江区",
            "region_code": "350504",

          },
          {
            "id": 1178,
            "name": "泉港区",
            "region_code": "350505",

          },
          {
            "id": 1179,
            "name": "惠安县",
            "region_code": "350521",

          },
          {
            "id": 1180,
            "name": "安溪县",
            "region_code": "350524",

          },
          {
            "id": 1181,
            "name": "永春县",
            "region_code": "350525",

          },
          {
            "id": 1182,
            "name": "德化县",
            "region_code": "350526",

          },
          {
            "id": 1183,
            "name": "金门县",
            "region_code": "350527",

          },
          {
            "id": 1184,
            "name": "石狮市",
            "region_code": "350581",

          },
          {
            "id": 1185,
            "name": "晋江市",
            "region_code": "350582",

          },
          {
            "id": 1186,
            "name": "南安市",
            "region_code": "350583",

          }
        ]
      },
      {
        "id": 1187,
        "name": "漳州市",
        "region_code": "350600",
        "child": [
          {
            "id": 1188,
            "name": "芗城区",
            "region_code": "350602",

          },
          {
            "id": 1189,
            "name": "龙文区",
            "region_code": "350603",

          },
          {
            "id": 1190,
            "name": "云霄县",
            "region_code": "350622",

          },
          {
            "id": 1191,
            "name": "漳浦县",
            "region_code": "350623",

          },
          {
            "id": 1192,
            "name": "诏安县",
            "region_code": "350624",

          },
          {
            "id": 1193,
            "name": "长泰县",
            "region_code": "350625",

          },
          {
            "id": 1194,
            "name": "东山县",
            "region_code": "350626",

          },
          {
            "id": 1195,
            "name": "南靖县",
            "region_code": "350627",

          },
          {
            "id": 1196,
            "name": "平和县",
            "region_code": "350628",

          },
          {
            "id": 1197,
            "name": "华安县",
            "region_code": "350629",

          },
          {
            "id": 1198,
            "name": "龙海市",
            "region_code": "350681",

          }
        ]
      },
      {
        "id": 1199,
        "name": "南平市",
        "region_code": "350700",
        "child": [
          {
            "id": 1200,
            "name": "延平区",
            "region_code": "350702",

          },
          {
            "id": 1201,
            "name": "建阳区",
            "region_code": "350703",

          },
          {
            "id": 1202,
            "name": "顺昌县",
            "region_code": "350721",

          },
          {
            "id": 1203,
            "name": "浦城县",
            "region_code": "350722",

          },
          {
            "id": 1204,
            "name": "光泽县",
            "region_code": "350723",

          },
          {
            "id": 1205,
            "name": "松溪县",
            "region_code": "350724",

          },
          {
            "id": 1206,
            "name": "政和县",
            "region_code": "350725",

          },
          {
            "id": 1207,
            "name": "邵武市",
            "region_code": "350781",

          },
          {
            "id": 1208,
            "name": "武夷山市",
            "region_code": "350782",

          },
          {
            "id": 1209,
            "name": "建瓯市",
            "region_code": "350783",

          }
        ]
      },
      {
        "id": 1210,
        "name": "龙岩市",
        "region_code": "350800",
        "child": [
          {
            "id": 1211,
            "name": "新罗区",
            "region_code": "350802",

          },
          {
            "id": 1212,
            "name": "永定区",
            "region_code": "350803",

          },
          {
            "id": 1213,
            "name": "长汀县",
            "region_code": "350821",

          },
          {
            "id": 1214,
            "name": "上杭县",
            "region_code": "350823",

          },
          {
            "id": 1215,
            "name": "武平县",
            "region_code": "350824",

          },
          {
            "id": 1216,
            "name": "连城县",
            "region_code": "350825",

          },
          {
            "id": 1217,
            "name": "漳平市",
            "region_code": "350881",

          }
        ]
      },
      {
        "id": 1218,
        "name": "宁德市",
        "region_code": "350900",
        "child": [
          {
            "id": 1219,
            "name": "蕉城区",
            "region_code": "350902",

          },
          {
            "id": 1220,
            "name": "霞浦县",
            "region_code": "350921",

          },
          {
            "id": 1221,
            "name": "古田县",
            "region_code": "350922",

          },
          {
            "id": 1222,
            "name": "屏南县",
            "region_code": "350923",

          },
          {
            "id": 1223,
            "name": "寿宁县",
            "region_code": "350924",

          },
          {
            "id": 1224,
            "name": "周宁县",
            "region_code": "350925",

          },
          {
            "id": 1225,
            "name": "柘荣县",
            "region_code": "350926",

          },
          {
            "id": 1226,
            "name": "福安市",
            "region_code": "350981",

          },
          {
            "id": 1227,
            "name": "福鼎市",
            "region_code": "350982",

          }
        ]
      }
    ]
  },
  {
    "id": 1228,
    "name": "江西省",
    "region_code": "360000",
    "child": [
      {
        "id": 1229,
        "name": "南昌市",
        "region_code": "360100",
        "child": [
          {
            "id": 1230,
            "name": "东湖区",
            "region_code": "360102",

          },
          {
            "id": 1231,
            "name": "西湖区",
            "region_code": "360103",

          },
          {
            "id": 1232,
            "name": "青云谱区",
            "region_code": "360104",

          },
          {
            "id": 1233,
            "name": "青山湖区",
            "region_code": "360111",

          },
          {
            "id": 1234,
            "name": "新建区",
            "region_code": "360112",

          },
          {
            "id": 1235,
            "name": "红谷滩区",
            "region_code": "360113",

          },
          {
            "id": 1236,
            "name": "南昌县",
            "region_code": "360121",

          },
          {
            "id": 1237,
            "name": "安义县",
            "region_code": "360123",

          },
          {
            "id": 1238,
            "name": "进贤县",
            "region_code": "360124",

          }
        ]
      },
      {
        "id": 1239,
        "name": "景德镇市",
        "region_code": "360200",
        "child": [
          {
            "id": 1240,
            "name": "昌江区",
            "region_code": "360202",

          },
          {
            "id": 1241,
            "name": "珠山区",
            "region_code": "360203",

          },
          {
            "id": 1242,
            "name": "浮梁县",
            "region_code": "360222",

          },
          {
            "id": 1243,
            "name": "乐平市",
            "region_code": "360281",

          }
        ]
      },
      {
        "id": 1244,
        "name": "萍乡市",
        "region_code": "360300",
        "child": [
          {
            "id": 1245,
            "name": "安源区",
            "region_code": "360302",

          },
          {
            "id": 1246,
            "name": "湘东区",
            "region_code": "360313",

          },
          {
            "id": 1247,
            "name": "莲花县",
            "region_code": "360321",

          },
          {
            "id": 1248,
            "name": "上栗县",
            "region_code": "360322",

          },
          {
            "id": 1249,
            "name": "芦溪县",
            "region_code": "360323",

          }
        ]
      },
      {
        "id": 1250,
        "name": "九江市",
        "region_code": "360400",
        "child": [
          {
            "id": 1251,
            "name": "濂溪区",
            "region_code": "360402",

          },
          {
            "id": 1252,
            "name": "浔阳区",
            "region_code": "360403",

          },
          {
            "id": 1253,
            "name": "柴桑区",
            "region_code": "360404",

          },
          {
            "id": 1254,
            "name": "武宁县",
            "region_code": "360423",

          },
          {
            "id": 1255,
            "name": "修水县",
            "region_code": "360424",

          },
          {
            "id": 1256,
            "name": "永修县",
            "region_code": "360425",

          },
          {
            "id": 1257,
            "name": "德安县",
            "region_code": "360426",

          },
          {
            "id": 1258,
            "name": "都昌县",
            "region_code": "360428",

          },
          {
            "id": 1259,
            "name": "湖口县",
            "region_code": "360429",

          },
          {
            "id": 1260,
            "name": "彭泽县",
            "region_code": "360430",

          },
          {
            "id": 1261,
            "name": "瑞昌市",
            "region_code": "360481",

          },
          {
            "id": 1262,
            "name": "共青城市",
            "region_code": "360482",

          },
          {
            "id": 1263,
            "name": "庐山市",
            "region_code": "360483",

          }
        ]
      },
      {
        "id": 1264,
        "name": "新余市",
        "region_code": "360500",
        "child": [
          {
            "id": 1265,
            "name": "渝水区",
            "region_code": "360502",

          },
          {
            "id": 1266,
            "name": "分宜县",
            "region_code": "360521",

          }
        ]
      },
      {
        "id": 1267,
        "name": "鹰潭市",
        "region_code": "360600",
        "child": [
          {
            "id": 1268,
            "name": "月湖区",
            "region_code": "360602",

          },
          {
            "id": 1269,
            "name": "余江区",
            "region_code": "360603",

          },
          {
            "id": 1270,
            "name": "贵溪市",
            "region_code": "360681",

          }
        ]
      },
      {
        "id": 1271,
        "name": "赣州市",
        "region_code": "360700",
        "child": [
          {
            "id": 1272,
            "name": "章贡区",
            "region_code": "360702",

          },
          {
            "id": 1273,
            "name": "南康区",
            "region_code": "360703",

          },
          {
            "id": 1274,
            "name": "赣县区",
            "region_code": "360704",

          },
          {
            "id": 1275,
            "name": "信丰县",
            "region_code": "360722",

          },
          {
            "id": 1276,
            "name": "大余县",
            "region_code": "360723",

          },
          {
            "id": 1277,
            "name": "上犹县",
            "region_code": "360724",

          },
          {
            "id": 1278,
            "name": "崇义县",
            "region_code": "360725",

          },
          {
            "id": 1279,
            "name": "安远县",
            "region_code": "360726",

          },
          {
            "id": 1280,
            "name": "定南县",
            "region_code": "360728",

          },
          {
            "id": 1281,
            "name": "全南县",
            "region_code": "360729",

          },
          {
            "id": 1282,
            "name": "宁都县",
            "region_code": "360730",

          },
          {
            "id": 1283,
            "name": "于都县",
            "region_code": "360731",

          },
          {
            "id": 1284,
            "name": "兴国县",
            "region_code": "360732",

          },
          {
            "id": 1285,
            "name": "会昌县",
            "region_code": "360733",

          },
          {
            "id": 1286,
            "name": "寻乌县",
            "region_code": "360734",

          },
          {
            "id": 1287,
            "name": "石城县",
            "region_code": "360735",

          },
          {
            "id": 1288,
            "name": "瑞金市",
            "region_code": "360781",

          },
          {
            "id": 1289,
            "name": "龙南市",
            "region_code": "360783",

          }
        ]
      },
      {
        "id": 1290,
        "name": "吉安市",
        "region_code": "360800",
        "child": [
          {
            "id": 1291,
            "name": "吉州区",
            "region_code": "360802",

          },
          {
            "id": 1292,
            "name": "青原区",
            "region_code": "360803",

          },
          {
            "id": 1293,
            "name": "吉安县",
            "region_code": "360821",

          },
          {
            "id": 1294,
            "name": "吉水县",
            "region_code": "360822",

          },
          {
            "id": 1295,
            "name": "峡江县",
            "region_code": "360823",

          },
          {
            "id": 1296,
            "name": "新干县",
            "region_code": "360824",

          },
          {
            "id": 1297,
            "name": "永丰县",
            "region_code": "360825",

          },
          {
            "id": 1298,
            "name": "泰和县",
            "region_code": "360826",

          },
          {
            "id": 1299,
            "name": "遂川县",
            "region_code": "360827",

          },
          {
            "id": 1300,
            "name": "万安县",
            "region_code": "360828",

          },
          {
            "id": 1301,
            "name": "安福县",
            "region_code": "360829",

          },
          {
            "id": 1302,
            "name": "永新县",
            "region_code": "360830",

          },
          {
            "id": 1303,
            "name": "井冈山市",
            "region_code": "360881",

          }
        ]
      },
      {
        "id": 1304,
        "name": "宜春市",
        "region_code": "360900",
        "child": [
          {
            "id": 1305,
            "name": "袁州区",
            "region_code": "360902",

          },
          {
            "id": 1306,
            "name": "奉新县",
            "region_code": "360921",

          },
          {
            "id": 1307,
            "name": "万载县",
            "region_code": "360922",

          },
          {
            "id": 1308,
            "name": "上高县",
            "region_code": "360923",

          },
          {
            "id": 1309,
            "name": "宜丰县",
            "region_code": "360924",

          },
          {
            "id": 1310,
            "name": "靖安县",
            "region_code": "360925",

          },
          {
            "id": 1311,
            "name": "铜鼓县",
            "region_code": "360926",

          },
          {
            "id": 1312,
            "name": "丰城市",
            "region_code": "360981",

          },
          {
            "id": 1313,
            "name": "樟树市",
            "region_code": "360982",

          },
          {
            "id": 1314,
            "name": "高安市",
            "region_code": "360983",

          }
        ]
      },
      {
        "id": 1315,
        "name": "抚州市",
        "region_code": "361000",
        "child": [
          {
            "id": 1316,
            "name": "临川区",
            "region_code": "361002",

          },
          {
            "id": 1317,
            "name": "东乡区",
            "region_code": "361003",

          },
          {
            "id": 1318,
            "name": "南城县",
            "region_code": "361021",

          },
          {
            "id": 1319,
            "name": "黎川县",
            "region_code": "361022",

          },
          {
            "id": 1320,
            "name": "南丰县",
            "region_code": "361023",

          },
          {
            "id": 1321,
            "name": "崇仁县",
            "region_code": "361024",

          },
          {
            "id": 1322,
            "name": "乐安县",
            "region_code": "361025",

          },
          {
            "id": 1323,
            "name": "宜黄县",
            "region_code": "361026",

          },
          {
            "id": 1324,
            "name": "金溪县",
            "region_code": "361027",

          },
          {
            "id": 1325,
            "name": "资溪县",
            "region_code": "361028",

          },
          {
            "id": 1326,
            "name": "广昌县",
            "region_code": "361030",

          }
        ]
      },
      {
        "id": 1327,
        "name": "上饶市",
        "region_code": "361100",
        "child": [
          {
            "id": 1328,
            "name": "信州区",
            "region_code": "361102",

          },
          {
            "id": 1329,
            "name": "广丰区",
            "region_code": "361103",

          },
          {
            "id": 1330,
            "name": "广信区",
            "region_code": "361104",

          },
          {
            "id": 1331,
            "name": "玉山县",
            "region_code": "361123",

          },
          {
            "id": 1332,
            "name": "铅山县",
            "region_code": "361124",

          },
          {
            "id": 1333,
            "name": "横峰县",
            "region_code": "361125",

          },
          {
            "id": 1334,
            "name": "弋阳县",
            "region_code": "361126",

          },
          {
            "id": 1335,
            "name": "余干县",
            "region_code": "361127",

          },
          {
            "id": 1336,
            "name": "鄱阳县",
            "region_code": "361128",

          },
          {
            "id": 1337,
            "name": "万年县",
            "region_code": "361129",

          },
          {
            "id": 1338,
            "name": "婺源县",
            "region_code": "361130",

          },
          {
            "id": 1339,
            "name": "德兴市",
            "region_code": "361181",

          }
        ]
      }
    ]
  },
  {
    "id": 1340,
    "name": "山东省",
    "region_code": "370000",
    "child": [
      {
        "id": 1341,
        "name": "济南市",
        "region_code": "370100",
        "child": [
          {
            "id": 1342,
            "name": "历下区",
            "region_code": "370102",

          },
          {
            "id": 1343,
            "name": "市中区",
            "region_code": "370103",

          },
          {
            "id": 1344,
            "name": "槐荫区",
            "region_code": "370104",

          },
          {
            "id": 1345,
            "name": "天桥区",
            "region_code": "370105",

          },
          {
            "id": 1346,
            "name": "历城区",
            "region_code": "370112",

          },
          {
            "id": 1347,
            "name": "长清区",
            "region_code": "370113",

          },
          {
            "id": 1348,
            "name": "章丘区",
            "region_code": "370114",

          },
          {
            "id": 1349,
            "name": "济阳区",
            "region_code": "370115",

          },
          {
            "id": 1350,
            "name": "莱芜区",
            "region_code": "370116",

          },
          {
            "id": 1351,
            "name": "钢城区",
            "region_code": "370117",

          },
          {
            "id": 1352,
            "name": "平阴县",
            "region_code": "370124",

          },
          {
            "id": 1353,
            "name": "商河县",
            "region_code": "370126",

          }
        ]
      },
      {
        "id": 1354,
        "name": "青岛市",
        "region_code": "370200",
        "child": [
          {
            "id": 1355,
            "name": "市南区",
            "region_code": "370202",

          },
          {
            "id": 1356,
            "name": "市北区",
            "region_code": "370203",

          },
          {
            "id": 1357,
            "name": "黄岛区",
            "region_code": "370211",

          },
          {
            "id": 1358,
            "name": "崂山区",
            "region_code": "370212",

          },
          {
            "id": 1359,
            "name": "李沧区",
            "region_code": "370213",

          },
          {
            "id": 1360,
            "name": "城阳区",
            "region_code": "370214",

          },
          {
            "id": 1361,
            "name": "即墨区",
            "region_code": "370215",

          },
          {
            "id": 1362,
            "name": "胶州市",
            "region_code": "370281",

          },
          {
            "id": 1363,
            "name": "平度市",
            "region_code": "370283",

          },
          {
            "id": 1364,
            "name": "莱西市",
            "region_code": "370285",

          }
        ]
      },
      {
        "id": 1365,
        "name": "淄博市",
        "region_code": "370300",
        "child": [
          {
            "id": 1366,
            "name": "淄川区",
            "region_code": "370302",

          },
          {
            "id": 1367,
            "name": "张店区",
            "region_code": "370303",

          },
          {
            "id": 1368,
            "name": "博山区",
            "region_code": "370304",

          },
          {
            "id": 1369,
            "name": "临淄区",
            "region_code": "370305",

          },
          {
            "id": 1370,
            "name": "周村区",
            "region_code": "370306",

          },
          {
            "id": 1371,
            "name": "桓台县",
            "region_code": "370321",

          },
          {
            "id": 1372,
            "name": "高青县",
            "region_code": "370322",

          },
          {
            "id": 1373,
            "name": "沂源县",
            "region_code": "370323",

          }
        ]
      },
      {
        "id": 1374,
        "name": "枣庄市",
        "region_code": "370400",
        "child": [
          {
            "id": 1375,
            "name": "市中区",
            "region_code": "370402",

          },
          {
            "id": 1376,
            "name": "薛城区",
            "region_code": "370403",

          },
          {
            "id": 1377,
            "name": "峄城区",
            "region_code": "370404",

          },
          {
            "id": 1378,
            "name": "台儿庄区",
            "region_code": "370405",

          },
          {
            "id": 1379,
            "name": "山亭区",
            "region_code": "370406",

          },
          {
            "id": 1380,
            "name": "滕州市",
            "region_code": "370481",

          }
        ]
      },
      {
        "id": 1381,
        "name": "东营市",
        "region_code": "370500",
        "child": [
          {
            "id": 1382,
            "name": "东营区",
            "region_code": "370502",

          },
          {
            "id": 1383,
            "name": "河口区",
            "region_code": "370503",

          },
          {
            "id": 1384,
            "name": "垦利区",
            "region_code": "370505",

          },
          {
            "id": 1385,
            "name": "利津县",
            "region_code": "370522",

          },
          {
            "id": 1386,
            "name": "广饶县",
            "region_code": "370523",

          }
        ]
      },
      {
        "id": 1387,
        "name": "烟台市",
        "region_code": "370600",
        "child": [
          {
            "id": 1388,
            "name": "芝罘区",
            "region_code": "370602",

          },
          {
            "id": 1389,
            "name": "福山区",
            "region_code": "370611",

          },
          {
            "id": 1390,
            "name": "牟平区",
            "region_code": "370612",

          },
          {
            "id": 1391,
            "name": "莱山区",
            "region_code": "370613",

          },
          {
            "id": 1392,
            "name": "蓬莱区",
            "region_code": "370614",

          },
          {
            "id": 1393,
            "name": "龙口市",
            "region_code": "370681",

          },
          {
            "id": 1394,
            "name": "莱阳市",
            "region_code": "370682",

          },
          {
            "id": 1395,
            "name": "莱州市",
            "region_code": "370683",

          },
          {
            "id": 1396,
            "name": "招远市",
            "region_code": "370685",

          },
          {
            "id": 1397,
            "name": "栖霞市",
            "region_code": "370686",

          },
          {
            "id": 1398,
            "name": "海阳市",
            "region_code": "370687",

          }
        ]
      },
      {
        "id": 1399,
        "name": "潍坊市",
        "region_code": "370700",
        "child": [
          {
            "id": 1400,
            "name": "潍城区",
            "region_code": "370702",

          },
          {
            "id": 1401,
            "name": "寒亭区",
            "region_code": "370703",

          },
          {
            "id": 1402,
            "name": "坊子区",
            "region_code": "370704",

          },
          {
            "id": 1403,
            "name": "奎文区",
            "region_code": "370705",

          },
          {
            "id": 1404,
            "name": "临朐县",
            "region_code": "370724",

          },
          {
            "id": 1405,
            "name": "昌乐县",
            "region_code": "370725",

          },
          {
            "id": 1406,
            "name": "青州市",
            "region_code": "370781",

          },
          {
            "id": 1407,
            "name": "诸城市",
            "region_code": "370782",

          },
          {
            "id": 1408,
            "name": "寿光市",
            "region_code": "370783",

          },
          {
            "id": 1409,
            "name": "安丘市",
            "region_code": "370784",

          },
          {
            "id": 1410,
            "name": "高密市",
            "region_code": "370785",

          },
          {
            "id": 1411,
            "name": "昌邑市",
            "region_code": "370786",

          }
        ]
      },
      {
        "id": 1412,
        "name": "济宁市",
        "region_code": "370800",
        "child": [
          {
            "id": 1413,
            "name": "任城区",
            "region_code": "370811",

          },
          {
            "id": 1414,
            "name": "兖州区",
            "region_code": "370812",

          },
          {
            "id": 1415,
            "name": "微山县",
            "region_code": "370826",

          },
          {
            "id": 1416,
            "name": "鱼台县",
            "region_code": "370827",

          },
          {
            "id": 1417,
            "name": "金乡县",
            "region_code": "370828",

          },
          {
            "id": 1418,
            "name": "嘉祥县",
            "region_code": "370829",

          },
          {
            "id": 1419,
            "name": "汶上县",
            "region_code": "370830",

          },
          {
            "id": 1420,
            "name": "泗水县",
            "region_code": "370831",

          },
          {
            "id": 1421,
            "name": "梁山县",
            "region_code": "370832",

          },
          {
            "id": 1422,
            "name": "曲阜市",
            "region_code": "370881",

          },
          {
            "id": 1423,
            "name": "邹城市",
            "region_code": "370883",

          }
        ]
      },
      {
        "id": 1424,
        "name": "泰安市",
        "region_code": "370900",
        "child": [
          {
            "id": 1425,
            "name": "泰山区",
            "region_code": "370902",

          },
          {
            "id": 1426,
            "name": "岱岳区",
            "region_code": "370911",

          },
          {
            "id": 1427,
            "name": "宁阳县",
            "region_code": "370921",

          },
          {
            "id": 1428,
            "name": "东平县",
            "region_code": "370923",

          },
          {
            "id": 1429,
            "name": "新泰市",
            "region_code": "370982",

          },
          {
            "id": 1430,
            "name": "肥城市",
            "region_code": "370983",

          }
        ]
      },
      {
        "id": 1431,
        "name": "威海市",
        "region_code": "371000",
        "child": [
          {
            "id": 1432,
            "name": "环翠区",
            "region_code": "371002",

          },
          {
            "id": 1433,
            "name": "文登区",
            "region_code": "371003",

          },
          {
            "id": 1434,
            "name": "荣成市",
            "region_code": "371082",

          },
          {
            "id": 1435,
            "name": "乳山市",
            "region_code": "371083",

          }
        ]
      },
      {
        "id": 1436,
        "name": "日照市",
        "region_code": "371100",
        "child": [
          {
            "id": 1437,
            "name": "东港区",
            "region_code": "371102",

          },
          {
            "id": 1438,
            "name": "岚山区",
            "region_code": "371103",

          },
          {
            "id": 1439,
            "name": "五莲县",
            "region_code": "371121",

          },
          {
            "id": 1440,
            "name": "莒县",
            "region_code": "371122",

          }
        ]
      },
      {
        "id": 1441,
        "name": "临沂市",
        "region_code": "371300",
        "child": [
          {
            "id": 1442,
            "name": "兰山区",
            "region_code": "371302",

          },
          {
            "id": 1443,
            "name": "罗庄区",
            "region_code": "371311",

          },
          {
            "id": 1444,
            "name": "河东区",
            "region_code": "371312",

          },
          {
            "id": 1445,
            "name": "沂南县",
            "region_code": "371321",

          },
          {
            "id": 1446,
            "name": "郯城县",
            "region_code": "371322",

          },
          {
            "id": 1447,
            "name": "沂水县",
            "region_code": "371323",

          },
          {
            "id": 1448,
            "name": "兰陵县",
            "region_code": "371324",

          },
          {
            "id": 1449,
            "name": "费县",
            "region_code": "371325",

          },
          {
            "id": 1450,
            "name": "平邑县",
            "region_code": "371326",

          },
          {
            "id": 1451,
            "name": "莒南县",
            "region_code": "371327",

          },
          {
            "id": 1452,
            "name": "蒙阴县",
            "region_code": "371328",

          },
          {
            "id": 1453,
            "name": "临沭县",
            "region_code": "371329",

          }
        ]
      },
      {
        "id": 1454,
        "name": "德州市",
        "region_code": "371400",
        "child": [
          {
            "id": 1455,
            "name": "德城区",
            "region_code": "371402",

          },
          {
            "id": 1456,
            "name": "陵城区",
            "region_code": "371403",

          },
          {
            "id": 1457,
            "name": "宁津县",
            "region_code": "371422",

          },
          {
            "id": 1458,
            "name": "庆云县",
            "region_code": "371423",

          },
          {
            "id": 1459,
            "name": "临邑县",
            "region_code": "371424",

          },
          {
            "id": 1460,
            "name": "齐河县",
            "region_code": "371425",

          },
          {
            "id": 1461,
            "name": "平原县",
            "region_code": "371426",

          },
          {
            "id": 1462,
            "name": "夏津县",
            "region_code": "371427",

          },
          {
            "id": 1463,
            "name": "武城县",
            "region_code": "371428",

          },
          {
            "id": 1464,
            "name": "乐陵市",
            "region_code": "371481",

          },
          {
            "id": 1465,
            "name": "禹城市",
            "region_code": "371482",

          }
        ]
      },
      {
        "id": 1466,
        "name": "聊城市",
        "region_code": "371500",
        "child": [
          {
            "id": 1467,
            "name": "东昌府区",
            "region_code": "371502",

          },
          {
            "id": 1468,
            "name": "茌平区",
            "region_code": "371503",

          },
          {
            "id": 1469,
            "name": "阳谷县",
            "region_code": "371521",

          },
          {
            "id": 1470,
            "name": "莘县",
            "region_code": "371522",

          },
          {
            "id": 1471,
            "name": "东阿县",
            "region_code": "371524",

          },
          {
            "id": 1472,
            "name": "冠县",
            "region_code": "371525",

          },
          {
            "id": 1473,
            "name": "高唐县",
            "region_code": "371526",

          },
          {
            "id": 1474,
            "name": "临清市",
            "region_code": "371581",

          }
        ]
      },
      {
        "id": 1475,
        "name": "滨州市",
        "region_code": "371600",
        "child": [
          {
            "id": 1476,
            "name": "滨城区",
            "region_code": "371602",

          },
          {
            "id": 1477,
            "name": "沾化区",
            "region_code": "371603",

          },
          {
            "id": 1478,
            "name": "惠民县",
            "region_code": "371621",

          },
          {
            "id": 1479,
            "name": "阳信县",
            "region_code": "371622",

          },
          {
            "id": 1480,
            "name": "无棣县",
            "region_code": "371623",

          },
          {
            "id": 1481,
            "name": "博兴县",
            "region_code": "371625",

          },
          {
            "id": 1482,
            "name": "邹平市",
            "region_code": "371681",

          }
        ]
      },
      {
        "id": 1483,
        "name": "菏泽市",
        "region_code": "371700",
        "child": [
          {
            "id": 1484,
            "name": "牡丹区",
            "region_code": "371702",

          },
          {
            "id": 1485,
            "name": "定陶区",
            "region_code": "371703",

          },
          {
            "id": 1486,
            "name": "曹县",
            "region_code": "371721",

          },
          {
            "id": 1487,
            "name": "单县",
            "region_code": "371722",

          },
          {
            "id": 1488,
            "name": "成武县",
            "region_code": "371723",

          },
          {
            "id": 1489,
            "name": "巨野县",
            "region_code": "371724",

          },
          {
            "id": 1490,
            "name": "郓城县",
            "region_code": "371725",

          },
          {
            "id": 1491,
            "name": "鄄城县",
            "region_code": "371726",

          },
          {
            "id": 1492,
            "name": "东明县",
            "region_code": "371728",

          }
        ]
      }
    ]
  },
  {
    "id": 1493,
    "name": "河南省",
    "region_code": "410000",
    "child": [
      {
        "id": 1494,
        "name": "郑州市",
        "region_code": "410100",
        "child": [
          {
            "id": 1495,
            "name": "中原区",
            "region_code": "410102",

          },
          {
            "id": 1496,
            "name": "二七区",
            "region_code": "410103",

          },
          {
            "id": 1497,
            "name": "管城回族区",
            "region_code": "410104",

          },
          {
            "id": 1498,
            "name": "金水区",
            "region_code": "410105",

          },
          {
            "id": 1499,
            "name": "上街区",
            "region_code": "410106",

          },
          {
            "id": 1500,
            "name": "惠济区",
            "region_code": "410108",

          },
          {
            "id": 1501,
            "name": "中牟县",
            "region_code": "410122",

          },
          {
            "id": 1502,
            "name": "巩义市",
            "region_code": "410181",

          },
          {
            "id": 1503,
            "name": "荥阳市",
            "region_code": "410182",

          },
          {
            "id": 1504,
            "name": "新密市",
            "region_code": "410183",

          },
          {
            "id": 1505,
            "name": "新郑市",
            "region_code": "410184",

          },
          {
            "id": 1506,
            "name": "登封市",
            "region_code": "410185",

          }
        ]
      },
      {
        "id": 1507,
        "name": "开封市",
        "region_code": "410200",
        "child": [
          {
            "id": 1508,
            "name": "龙亭区",
            "region_code": "410202",

          },
          {
            "id": 1509,
            "name": "顺河回族区",
            "region_code": "410203",

          },
          {
            "id": 1510,
            "name": "鼓楼区",
            "region_code": "410204",

          },
          {
            "id": 1511,
            "name": "禹王台区",
            "region_code": "410205",

          },
          {
            "id": 1512,
            "name": "祥符区",
            "region_code": "410212",

          },
          {
            "id": 1513,
            "name": "杞县",
            "region_code": "410221",

          },
          {
            "id": 1514,
            "name": "通许县",
            "region_code": "410222",

          },
          {
            "id": 1515,
            "name": "尉氏县",
            "region_code": "410223",

          },
          {
            "id": 1516,
            "name": "兰考县",
            "region_code": "410225",

          }
        ]
      },
      {
        "id": 1517,
        "name": "洛阳市",
        "region_code": "410300",
        "child": [
          {
            "id": 1518,
            "name": "老城区",
            "region_code": "410302",

          },
          {
            "id": 1519,
            "name": "西工区",
            "region_code": "410303",

          },
          {
            "id": 1520,
            "name": "瀍河回族区",
            "region_code": "410304",

          },
          {
            "id": 1521,
            "name": "涧西区",
            "region_code": "410305",

          },
          {
            "id": 1522,
            "name": "吉利区",
            "region_code": "410306",

          },
          {
            "id": 1523,
            "name": "洛龙区",
            "region_code": "410311",

          },
          {
            "id": 1524,
            "name": "孟津县",
            "region_code": "410322",

          },
          {
            "id": 1525,
            "name": "新安县",
            "region_code": "410323",

          },
          {
            "id": 1526,
            "name": "栾川县",
            "region_code": "410324",

          },
          {
            "id": 1527,
            "name": "嵩县",
            "region_code": "410325",

          },
          {
            "id": 1528,
            "name": "汝阳县",
            "region_code": "410326",

          },
          {
            "id": 1529,
            "name": "宜阳县",
            "region_code": "410327",

          },
          {
            "id": 1530,
            "name": "洛宁县",
            "region_code": "410328",

          },
          {
            "id": 1531,
            "name": "伊川县",
            "region_code": "410329",

          },
          {
            "id": 1532,
            "name": "偃师市",
            "region_code": "410381",

          }
        ]
      },
      {
        "id": 1533,
        "name": "平顶山市",
        "region_code": "410400",
        "child": [
          {
            "id": 1534,
            "name": "新华区",
            "region_code": "410402",

          },
          {
            "id": 1535,
            "name": "卫东区",
            "region_code": "410403",

          },
          {
            "id": 1536,
            "name": "石龙区",
            "region_code": "410404",

          },
          {
            "id": 1537,
            "name": "湛河区",
            "region_code": "410411",

          },
          {
            "id": 1538,
            "name": "宝丰县",
            "region_code": "410421",

          },
          {
            "id": 1539,
            "name": "叶县",
            "region_code": "410422",

          },
          {
            "id": 1540,
            "name": "鲁山县",
            "region_code": "410423",

          },
          {
            "id": 1541,
            "name": "郏县",
            "region_code": "410425",

          },
          {
            "id": 1542,
            "name": "舞钢市",
            "region_code": "410481",

          },
          {
            "id": 1543,
            "name": "汝州市",
            "region_code": "410482",

          }
        ]
      },
      {
        "id": 1544,
        "name": "安阳市",
        "region_code": "410500",
        "child": [
          {
            "id": 1545,
            "name": "文峰区",
            "region_code": "410502",

          },
          {
            "id": 1546,
            "name": "北关区",
            "region_code": "410503",

          },
          {
            "id": 1547,
            "name": "殷都区",
            "region_code": "410505",

          },
          {
            "id": 1548,
            "name": "龙安区",
            "region_code": "410506",

          },
          {
            "id": 1549,
            "name": "安阳县",
            "region_code": "410522",

          },
          {
            "id": 1550,
            "name": "汤阴县",
            "region_code": "410523",

          },
          {
            "id": 1551,
            "name": "滑县",
            "region_code": "410526",

          },
          {
            "id": 1552,
            "name": "内黄县",
            "region_code": "410527",

          },
          {
            "id": 1553,
            "name": "林州市",
            "region_code": "410581",

          }
        ]
      },
      {
        "id": 1554,
        "name": "鹤壁市",
        "region_code": "410600",
        "child": [
          {
            "id": 1555,
            "name": "鹤山区",
            "region_code": "410602",

          },
          {
            "id": 1556,
            "name": "山城区",
            "region_code": "410603",

          },
          {
            "id": 1557,
            "name": "淇滨区",
            "region_code": "410611",

          },
          {
            "id": 1558,
            "name": "浚县",
            "region_code": "410621",

          },
          {
            "id": 1559,
            "name": "淇县",
            "region_code": "410622",

          }
        ]
      },
      {
        "id": 1560,
        "name": "新乡市",
        "region_code": "410700",
        "child": [
          {
            "id": 1561,
            "name": "红旗区",
            "region_code": "410702",

          },
          {
            "id": 1562,
            "name": "卫滨区",
            "region_code": "410703",

          },
          {
            "id": 1563,
            "name": "凤泉区",
            "region_code": "410704",

          },
          {
            "id": 1564,
            "name": "牧野区",
            "region_code": "410711",

          },
          {
            "id": 1565,
            "name": "新乡县",
            "region_code": "410721",

          },
          {
            "id": 1566,
            "name": "获嘉县",
            "region_code": "410724",

          },
          {
            "id": 1567,
            "name": "原阳县",
            "region_code": "410725",

          },
          {
            "id": 1568,
            "name": "延津县",
            "region_code": "410726",

          },
          {
            "id": 1569,
            "name": "封丘县",
            "region_code": "410727",

          },
          {
            "id": 1570,
            "name": "卫辉市",
            "region_code": "410781",

          },
          {
            "id": 1571,
            "name": "辉县市",
            "region_code": "410782",

          },
          {
            "id": 1572,
            "name": "长垣市",
            "region_code": "410783",

          }
        ]
      },
      {
        "id": 1573,
        "name": "焦作市",
        "region_code": "410800",
        "child": [
          {
            "id": 1574,
            "name": "解放区",
            "region_code": "410802",

          },
          {
            "id": 1575,
            "name": "中站区",
            "region_code": "410803",

          },
          {
            "id": 1576,
            "name": "马村区",
            "region_code": "410804",

          },
          {
            "id": 1577,
            "name": "山阳区",
            "region_code": "410811",

          },
          {
            "id": 1578,
            "name": "修武县",
            "region_code": "410821",

          },
          {
            "id": 1579,
            "name": "博爱县",
            "region_code": "410822",

          },
          {
            "id": 1580,
            "name": "武陟县",
            "region_code": "410823",

          },
          {
            "id": 1581,
            "name": "温县",
            "region_code": "410825",

          },
          {
            "id": 1582,
            "name": "沁阳市",
            "region_code": "410882",

          },
          {
            "id": 1583,
            "name": "孟州市",
            "region_code": "410883",

          }
        ]
      },
      {
        "id": 1584,
        "name": "濮阳市",
        "region_code": "410900",
        "child": [
          {
            "id": 1585,
            "name": "华龙区",
            "region_code": "410902",

          },
          {
            "id": 1586,
            "name": "清丰县",
            "region_code": "410922",

          },
          {
            "id": 1587,
            "name": "南乐县",
            "region_code": "410923",

          },
          {
            "id": 1588,
            "name": "范县",
            "region_code": "410926",

          },
          {
            "id": 1589,
            "name": "台前县",
            "region_code": "410927",

          },
          {
            "id": 1590,
            "name": "濮阳县",
            "region_code": "410928",

          }
        ]
      },
      {
        "id": 1591,
        "name": "许昌市",
        "region_code": "411000",
        "child": [
          {
            "id": 1592,
            "name": "魏都区",
            "region_code": "411002",

          },
          {
            "id": 1593,
            "name": "建安区",
            "region_code": "411003",

          },
          {
            "id": 1594,
            "name": "鄢陵县",
            "region_code": "411024",

          },
          {
            "id": 1595,
            "name": "襄城县",
            "region_code": "411025",

          },
          {
            "id": 1596,
            "name": "禹州市",
            "region_code": "411081",

          },
          {
            "id": 1597,
            "name": "长葛市",
            "region_code": "411082",

          }
        ]
      },
      {
        "id": 1598,
        "name": "漯河市",
        "region_code": "411100",
        "child": [
          {
            "id": 1599,
            "name": "源汇区",
            "region_code": "411102",

          },
          {
            "id": 1600,
            "name": "郾城区",
            "region_code": "411103",

          },
          {
            "id": 1601,
            "name": "召陵区",
            "region_code": "411104",

          },
          {
            "id": 1602,
            "name": "舞阳县",
            "region_code": "411121",

          },
          {
            "id": 1603,
            "name": "临颍县",
            "region_code": "411122",

          }
        ]
      },
      {
        "id": 1604,
        "name": "三门峡市",
        "region_code": "411200",
        "child": [
          {
            "id": 1605,
            "name": "湖滨区",
            "region_code": "411202",

          },
          {
            "id": 1606,
            "name": "陕州区",
            "region_code": "411203",

          },
          {
            "id": 1607,
            "name": "渑池县",
            "region_code": "411221",

          },
          {
            "id": 1608,
            "name": "卢氏县",
            "region_code": "411224",

          },
          {
            "id": 1609,
            "name": "义马市",
            "region_code": "411281",

          },
          {
            "id": 1610,
            "name": "灵宝市",
            "region_code": "411282",

          }
        ]
      },
      {
        "id": 1611,
        "name": "南阳市",
        "region_code": "411300",
        "child": [
          {
            "id": 1612,
            "name": "宛城区",
            "region_code": "411302",

          },
          {
            "id": 1613,
            "name": "卧龙区",
            "region_code": "411303",

          },
          {
            "id": 1614,
            "name": "南召县",
            "region_code": "411321",

          },
          {
            "id": 1615,
            "name": "方城县",
            "region_code": "411322",

          },
          {
            "id": 1616,
            "name": "西峡县",
            "region_code": "411323",

          },
          {
            "id": 1617,
            "name": "镇平县",
            "region_code": "411324",

          },
          {
            "id": 1618,
            "name": "内乡县",
            "region_code": "411325",

          },
          {
            "id": 1619,
            "name": "淅川县",
            "region_code": "411326",

          },
          {
            "id": 1620,
            "name": "社旗县",
            "region_code": "411327",

          },
          {
            "id": 1621,
            "name": "唐河县",
            "region_code": "411328",

          },
          {
            "id": 1622,
            "name": "新野县",
            "region_code": "411329",

          },
          {
            "id": 1623,
            "name": "桐柏县",
            "region_code": "411330",

          },
          {
            "id": 1624,
            "name": "邓州市",
            "region_code": "411381",

          }
        ]
      },
      {
        "id": 1625,
        "name": "商丘市",
        "region_code": "411400",
        "child": [
          {
            "id": 1626,
            "name": "梁园区",
            "region_code": "411402",

          },
          {
            "id": 1627,
            "name": "睢阳区",
            "region_code": "411403",

          },
          {
            "id": 1628,
            "name": "民权县",
            "region_code": "411421",

          },
          {
            "id": 1629,
            "name": "睢县",
            "region_code": "411422",

          },
          {
            "id": 1630,
            "name": "宁陵县",
            "region_code": "411423",

          },
          {
            "id": 1631,
            "name": "柘城县",
            "region_code": "411424",

          },
          {
            "id": 1632,
            "name": "虞城县",
            "region_code": "411425",

          },
          {
            "id": 1633,
            "name": "夏邑县",
            "region_code": "411426",

          },
          {
            "id": 1634,
            "name": "永城市",
            "region_code": "411481",

          }
        ]
      },
      {
        "id": 1635,
        "name": "信阳市",
        "region_code": "411500",
        "child": [
          {
            "id": 1636,
            "name": "浉河区",
            "region_code": "411502",

          },
          {
            "id": 1637,
            "name": "平桥区",
            "region_code": "411503",

          },
          {
            "id": 1638,
            "name": "罗山县",
            "region_code": "411521",

          },
          {
            "id": 1639,
            "name": "光山县",
            "region_code": "411522",

          },
          {
            "id": 1640,
            "name": "新县",
            "region_code": "411523",

          },
          {
            "id": 1641,
            "name": "商城县",
            "region_code": "411524",

          },
          {
            "id": 1642,
            "name": "固始县",
            "region_code": "411525",

          },
          {
            "id": 1643,
            "name": "潢川县",
            "region_code": "411526",

          },
          {
            "id": 1644,
            "name": "淮滨县",
            "region_code": "411527",

          },
          {
            "id": 1645,
            "name": "息县",
            "region_code": "411528",

          }
        ]
      },
      {
        "id": 1646,
        "name": "周口市",
        "region_code": "411600",
        "child": [
          {
            "id": 1647,
            "name": "川汇区",
            "region_code": "411602",

          },
          {
            "id": 1648,
            "name": "淮阳区",
            "region_code": "411603",

          },
          {
            "id": 1649,
            "name": "扶沟县",
            "region_code": "411621",

          },
          {
            "id": 1650,
            "name": "西华县",
            "region_code": "411622",

          },
          {
            "id": 1651,
            "name": "商水县",
            "region_code": "411623",

          },
          {
            "id": 1652,
            "name": "沈丘县",
            "region_code": "411624",

          },
          {
            "id": 1653,
            "name": "郸城县",
            "region_code": "411625",

          },
          {
            "id": 1654,
            "name": "太康县",
            "region_code": "411627",

          },
          {
            "id": 1655,
            "name": "鹿邑县",
            "region_code": "411628",

          },
          {
            "id": 1656,
            "name": "项城市",
            "region_code": "411681",

          }
        ]
      },
      {
        "id": 1657,
        "name": "驻马店市",
        "region_code": "411700",
        "child": [
          {
            "id": 1658,
            "name": "驿城区",
            "region_code": "411702",

          },
          {
            "id": 1659,
            "name": "西平县",
            "region_code": "411721",

          },
          {
            "id": 1660,
            "name": "上蔡县",
            "region_code": "411722",

          },
          {
            "id": 1661,
            "name": "平舆县",
            "region_code": "411723",

          },
          {
            "id": 1662,
            "name": "正阳县",
            "region_code": "411724",

          },
          {
            "id": 1663,
            "name": "确山县",
            "region_code": "411725",

          },
          {
            "id": 1664,
            "name": "泌阳县",
            "region_code": "411726",

          },
          {
            "id": 1665,
            "name": "汝南县",
            "region_code": "411727",

          },
          {
            "id": 1666,
            "name": "遂平县",
            "region_code": "411728",

          },
          {
            "id": 1667,
            "name": "新蔡县",
            "region_code": "411729",

          }
        ]
      },
      {
        "id": 1668,
        "name": "直辖县",
        "region_code": "419000",
        "child": [
          {
            "id": 1669,
            "name": "济源市",
            "region_code": "419001",

          }
        ]
      }
    ]
  },
  {
    "id": 1670,
    "name": "湖北省",
    "region_code": "420000",
    "child": [
      {
        "id": 1671,
        "name": "武汉市",
        "region_code": "420100",
        "child": [
          {
            "id": 1672,
            "name": "江岸区",
            "region_code": "420102",

          },
          {
            "id": 1673,
            "name": "江汉区",
            "region_code": "420103",

          },
          {
            "id": 1674,
            "name": "硚口区",
            "region_code": "420104",

          },
          {
            "id": 1675,
            "name": "汉阳区",
            "region_code": "420105",

          },
          {
            "id": 1676,
            "name": "武昌区",
            "region_code": "420106",

          },
          {
            "id": 1677,
            "name": "青山区",
            "region_code": "420107",

          },
          {
            "id": 1678,
            "name": "洪山区",
            "region_code": "420111",

          },
          {
            "id": 1679,
            "name": "东西湖区",
            "region_code": "420112",

          },
          {
            "id": 1680,
            "name": "汉南区",
            "region_code": "420113",

          },
          {
            "id": 1681,
            "name": "蔡甸区",
            "region_code": "420114",

          },
          {
            "id": 1682,
            "name": "江夏区",
            "region_code": "420115",

          },
          {
            "id": 1683,
            "name": "黄陂区",
            "region_code": "420116",

          },
          {
            "id": 1684,
            "name": "新洲区",
            "region_code": "420117",

          }
        ]
      },
      {
        "id": 1685,
        "name": "黄石市",
        "region_code": "420200",
        "child": [
          {
            "id": 1686,
            "name": "黄石港区",
            "region_code": "420202",

          },
          {
            "id": 1687,
            "name": "西塞山区",
            "region_code": "420203",

          },
          {
            "id": 1688,
            "name": "下陆区",
            "region_code": "420204",

          },
          {
            "id": 1689,
            "name": "铁山区",
            "region_code": "420205",

          },
          {
            "id": 1690,
            "name": "阳新县",
            "region_code": "420222",

          },
          {
            "id": 1691,
            "name": "大冶市",
            "region_code": "420281",

          }
        ]
      },
      {
        "id": 1692,
        "name": "十堰市",
        "region_code": "420300",
        "child": [
          {
            "id": 1693,
            "name": "茅箭区",
            "region_code": "420302",

          },
          {
            "id": 1694,
            "name": "张湾区",
            "region_code": "420303",

          },
          {
            "id": 1695,
            "name": "郧阳区",
            "region_code": "420304",

          },
          {
            "id": 1696,
            "name": "郧西县",
            "region_code": "420322",

          },
          {
            "id": 1697,
            "name": "竹山县",
            "region_code": "420323",

          },
          {
            "id": 1698,
            "name": "竹溪县",
            "region_code": "420324",

          },
          {
            "id": 1699,
            "name": "房县",
            "region_code": "420325",

          },
          {
            "id": 1700,
            "name": "丹江口市",
            "region_code": "420381",

          }
        ]
      },
      {
        "id": 1701,
        "name": "宜昌市",
        "region_code": "420500",
        "child": [
          {
            "id": 1702,
            "name": "西陵区",
            "region_code": "420502",

          },
          {
            "id": 1703,
            "name": "伍家岗区",
            "region_code": "420503",

          },
          {
            "id": 1704,
            "name": "点军区",
            "region_code": "420504",

          },
          {
            "id": 1705,
            "name": "猇亭区",
            "region_code": "420505",

          },
          {
            "id": 1706,
            "name": "夷陵区",
            "region_code": "420506",

          },
          {
            "id": 1707,
            "name": "远安县",
            "region_code": "420525",

          },
          {
            "id": 1708,
            "name": "兴山县",
            "region_code": "420526",

          },
          {
            "id": 1709,
            "name": "秭归县",
            "region_code": "420527",

          },
          {
            "id": 1710,
            "name": "长阳土家族自治县",
            "region_code": "420528",

          },
          {
            "id": 1711,
            "name": "五峰土家族自治县",
            "region_code": "420529",

          },
          {
            "id": 1712,
            "name": "宜都市",
            "region_code": "420581",

          },
          {
            "id": 1713,
            "name": "当阳市",
            "region_code": "420582",

          },
          {
            "id": 1714,
            "name": "枝江市",
            "region_code": "420583",

          }
        ]
      },
      {
        "id": 1715,
        "name": "襄阳市",
        "region_code": "420600",
        "child": [
          {
            "id": 1716,
            "name": "襄城区",
            "region_code": "420602",

          },
          {
            "id": 1717,
            "name": "樊城区",
            "region_code": "420606",

          },
          {
            "id": 1718,
            "name": "襄州区",
            "region_code": "420607",

          },
          {
            "id": 1719,
            "name": "南漳县",
            "region_code": "420624",

          },
          {
            "id": 1720,
            "name": "谷城县",
            "region_code": "420625",

          },
          {
            "id": 1721,
            "name": "保康县",
            "region_code": "420626",

          },
          {
            "id": 1722,
            "name": "老河口市",
            "region_code": "420682",

          },
          {
            "id": 1723,
            "name": "枣阳市",
            "region_code": "420683",

          },
          {
            "id": 1724,
            "name": "宜城市",
            "region_code": "420684",

          }
        ]
      },
      {
        "id": 1725,
        "name": "鄂州市",
        "region_code": "420700",
        "child": [
          {
            "id": 1726,
            "name": "梁子湖区",
            "region_code": "420702",

          },
          {
            "id": 1727,
            "name": "华容区",
            "region_code": "420703",

          },
          {
            "id": 1728,
            "name": "鄂城区",
            "region_code": "420704",

          }
        ]
      },
      {
        "id": 1729,
        "name": "荆门市",
        "region_code": "420800",
        "child": [
          {
            "id": 1730,
            "name": "东宝区",
            "region_code": "420802",

          },
          {
            "id": 1731,
            "name": "掇刀区",
            "region_code": "420804",

          },
          {
            "id": 1732,
            "name": "沙洋县",
            "region_code": "420822",

          },
          {
            "id": 1733,
            "name": "钟祥市",
            "region_code": "420881",

          },
          {
            "id": 1734,
            "name": "京山市",
            "region_code": "420882",

          }
        ]
      },
      {
        "id": 1735,
        "name": "孝感市",
        "region_code": "420900",
        "child": [
          {
            "id": 1736,
            "name": "孝南区",
            "region_code": "420902",

          },
          {
            "id": 1737,
            "name": "孝昌县",
            "region_code": "420921",

          },
          {
            "id": 1738,
            "name": "大悟县",
            "region_code": "420922",

          },
          {
            "id": 1739,
            "name": "云梦县",
            "region_code": "420923",

          },
          {
            "id": 1740,
            "name": "应城市",
            "region_code": "420981",

          },
          {
            "id": 1741,
            "name": "安陆市",
            "region_code": "420982",

          },
          {
            "id": 1742,
            "name": "汉川市",
            "region_code": "420984",

          }
        ]
      },
      {
        "id": 1743,
        "name": "荆州市",
        "region_code": "421000",
        "child": [
          {
            "id": 1744,
            "name": "沙市区",
            "region_code": "421002",

          },
          {
            "id": 1745,
            "name": "荆州区",
            "region_code": "421003",

          },
          {
            "id": 1746,
            "name": "公安县",
            "region_code": "421022",

          },
          {
            "id": 1747,
            "name": "监利县",
            "region_code": "421023",

          },
          {
            "id": 1748,
            "name": "江陵县",
            "region_code": "421024",

          },
          {
            "id": 1749,
            "name": "石首市",
            "region_code": "421081",

          },
          {
            "id": 1750,
            "name": "洪湖市",
            "region_code": "421083",

          },
          {
            "id": 1751,
            "name": "松滋市",
            "region_code": "421087",

          }
        ]
      },
      {
        "id": 1752,
        "name": "黄冈市",
        "region_code": "421100",
        "child": [
          {
            "id": 1753,
            "name": "黄州区",
            "region_code": "421102",

          },
          {
            "id": 1754,
            "name": "团风县",
            "region_code": "421121",

          },
          {
            "id": 1755,
            "name": "红安县",
            "region_code": "421122",

          },
          {
            "id": 1756,
            "name": "罗田县",
            "region_code": "421123",

          },
          {
            "id": 1757,
            "name": "英山县",
            "region_code": "421124",

          },
          {
            "id": 1758,
            "name": "浠水县",
            "region_code": "421125",

          },
          {
            "id": 1759,
            "name": "蕲春县",
            "region_code": "421126",

          },
          {
            "id": 1760,
            "name": "黄梅县",
            "region_code": "421127",

          },
          {
            "id": 1761,
            "name": "麻城市",
            "region_code": "421181",

          },
          {
            "id": 1762,
            "name": "武穴市",
            "region_code": "421182",

          }
        ]
      },
      {
        "id": 1763,
        "name": "咸宁市",
        "region_code": "421200",
        "child": [
          {
            "id": 1764,
            "name": "咸安区",
            "region_code": "421202",

          },
          {
            "id": 1765,
            "name": "嘉鱼县",
            "region_code": "421221",

          },
          {
            "id": 1766,
            "name": "通城县",
            "region_code": "421222",

          },
          {
            "id": 1767,
            "name": "崇阳县",
            "region_code": "421223",

          },
          {
            "id": 1768,
            "name": "通山县",
            "region_code": "421224",

          },
          {
            "id": 1769,
            "name": "赤壁市",
            "region_code": "421281",

          }
        ]
      },
      {
        "id": 1770,
        "name": "随州市",
        "region_code": "421300",
        "child": [
          {
            "id": 1771,
            "name": "曾都区",
            "region_code": "421303",

          },
          {
            "id": 1772,
            "name": "随县",
            "region_code": "421321",

          },
          {
            "id": 1773,
            "name": "广水市",
            "region_code": "421381",

          }
        ]
      },
      {
        "id": 1774,
        "name": "恩施土家族苗族自治州",
        "region_code": "422800",
        "child": [
          {
            "id": 1775,
            "name": "恩施市",
            "region_code": "422801",

          },
          {
            "id": 1776,
            "name": "利川市",
            "region_code": "422802",

          },
          {
            "id": 1777,
            "name": "建始县",
            "region_code": "422822",

          },
          {
            "id": 1778,
            "name": "巴东县",
            "region_code": "422823",

          },
          {
            "id": 1779,
            "name": "宣恩县",
            "region_code": "422825",

          },
          {
            "id": 1780,
            "name": "咸丰县",
            "region_code": "422826",

          },
          {
            "id": 1781,
            "name": "来凤县",
            "region_code": "422827",

          },
          {
            "id": 1782,
            "name": "鹤峰县",
            "region_code": "422828",

          }
        ]
      },
      {
        "id": 1783,
        "name": "直辖县",
        "region_code": "429000",
        "child": [
          {
            "id": 1784,
            "name": "仙桃市",
            "region_code": "429004",

          },
          {
            "id": 1785,
            "name": "潜江市",
            "region_code": "429005",

          },
          {
            "id": 1786,
            "name": "天门市",
            "region_code": "429006",

          },
          {
            "id": 1787,
            "name": "神农架林区",
            "region_code": "429021",

          }
        ]
      }
    ]
  },
  {
    "id": 1788,
    "name": "湖南省",
    "region_code": "430000",
    "child": [
      {
        "id": 1789,
        "name": "长沙市",
        "region_code": "430100",
        "child": [
          {
            "id": 1790,
            "name": "芙蓉区",
            "region_code": "430102",

          },
          {
            "id": 1791,
            "name": "天心区",
            "region_code": "430103",

          },
          {
            "id": 1792,
            "name": "岳麓区",
            "region_code": "430104",

          },
          {
            "id": 1793,
            "name": "开福区",
            "region_code": "430105",

          },
          {
            "id": 1794,
            "name": "雨花区",
            "region_code": "430111",

          },
          {
            "id": 1795,
            "name": "望城区",
            "region_code": "430112",

          },
          {
            "id": 1796,
            "name": "长沙县",
            "region_code": "430121",

          },
          {
            "id": 1797,
            "name": "浏阳市",
            "region_code": "430181",

          },
          {
            "id": 1798,
            "name": "宁乡市",
            "region_code": "430182",

          }
        ]
      },
      {
        "id": 1799,
        "name": "株洲市",
        "region_code": "430200",
        "child": [
          {
            "id": 1800,
            "name": "荷塘区",
            "region_code": "430202",

          },
          {
            "id": 1801,
            "name": "芦淞区",
            "region_code": "430203",

          },
          {
            "id": 1802,
            "name": "石峰区",
            "region_code": "430204",

          },
          {
            "id": 1803,
            "name": "天元区",
            "region_code": "430211",

          },
          {
            "id": 1804,
            "name": "渌口区",
            "region_code": "430212",

          },
          {
            "id": 1805,
            "name": "攸县",
            "region_code": "430223",

          },
          {
            "id": 1806,
            "name": "茶陵县",
            "region_code": "430224",

          },
          {
            "id": 1807,
            "name": "炎陵县",
            "region_code": "430225",

          },
          {
            "id": 1808,
            "name": "醴陵市",
            "region_code": "430281",

          }
        ]
      },
      {
        "id": 1809,
        "name": "湘潭市",
        "region_code": "430300",
        "child": [
          {
            "id": 1810,
            "name": "雨湖区",
            "region_code": "430302",

          },
          {
            "id": 1811,
            "name": "岳塘区",
            "region_code": "430304",

          },
          {
            "id": 1812,
            "name": "湘潭县",
            "region_code": "430321",

          },
          {
            "id": 1813,
            "name": "湘乡市",
            "region_code": "430381",

          },
          {
            "id": 1814,
            "name": "韶山市",
            "region_code": "430382",

          }
        ]
      },
      {
        "id": 1815,
        "name": "衡阳市",
        "region_code": "430400",
        "child": [
          {
            "id": 1816,
            "name": "珠晖区",
            "region_code": "430405",

          },
          {
            "id": 1817,
            "name": "雁峰区",
            "region_code": "430406",

          },
          {
            "id": 1818,
            "name": "石鼓区",
            "region_code": "430407",

          },
          {
            "id": 1819,
            "name": "蒸湘区",
            "region_code": "430408",

          },
          {
            "id": 1820,
            "name": "南岳区",
            "region_code": "430412",

          },
          {
            "id": 1821,
            "name": "衡阳县",
            "region_code": "430421",

          },
          {
            "id": 1822,
            "name": "衡南县",
            "region_code": "430422",

          },
          {
            "id": 1823,
            "name": "衡山县",
            "region_code": "430423",

          },
          {
            "id": 1824,
            "name": "衡东县",
            "region_code": "430424",

          },
          {
            "id": 1825,
            "name": "祁东县",
            "region_code": "430426",

          },
          {
            "id": 1826,
            "name": "耒阳市",
            "region_code": "430481",

          },
          {
            "id": 1827,
            "name": "常宁市",
            "region_code": "430482",

          }
        ]
      },
      {
        "id": 1828,
        "name": "邵阳市",
        "region_code": "430500",
        "child": [
          {
            "id": 1829,
            "name": "双清区",
            "region_code": "430502",

          },
          {
            "id": 1830,
            "name": "大祥区",
            "region_code": "430503",

          },
          {
            "id": 1831,
            "name": "北塔区",
            "region_code": "430511",

          },
          {
            "id": 1832,
            "name": "新邵县",
            "region_code": "430522",

          },
          {
            "id": 1833,
            "name": "邵阳县",
            "region_code": "430523",

          },
          {
            "id": 1834,
            "name": "隆回县",
            "region_code": "430524",

          },
          {
            "id": 1835,
            "name": "洞口县",
            "region_code": "430525",

          },
          {
            "id": 1836,
            "name": "绥宁县",
            "region_code": "430527",

          },
          {
            "id": 1837,
            "name": "新宁县",
            "region_code": "430528",

          },
          {
            "id": 1838,
            "name": "城步苗族自治县",
            "region_code": "430529",

          },
          {
            "id": 1839,
            "name": "武冈市",
            "region_code": "430581",

          },
          {
            "id": 1840,
            "name": "邵东市",
            "region_code": "430582",

          }
        ]
      },
      {
        "id": 1841,
        "name": "岳阳市",
        "region_code": "430600",
        "child": [
          {
            "id": 1842,
            "name": "岳阳楼区",
            "region_code": "430602",

          },
          {
            "id": 1843,
            "name": "云溪区",
            "region_code": "430603",

          },
          {
            "id": 1844,
            "name": "君山区",
            "region_code": "430611",

          },
          {
            "id": 1845,
            "name": "岳阳县",
            "region_code": "430621",

          },
          {
            "id": 1846,
            "name": "华容县",
            "region_code": "430623",

          },
          {
            "id": 1847,
            "name": "湘阴县",
            "region_code": "430624",

          },
          {
            "id": 1848,
            "name": "平江县",
            "region_code": "430626",

          },
          {
            "id": 1849,
            "name": "汨罗市",
            "region_code": "430681",

          },
          {
            "id": 1850,
            "name": "临湘市",
            "region_code": "430682",

          }
        ]
      },
      {
        "id": 1851,
        "name": "常德市",
        "region_code": "430700",
        "child": [
          {
            "id": 1852,
            "name": "武陵区",
            "region_code": "430702",

          },
          {
            "id": 1853,
            "name": "鼎城区",
            "region_code": "430703",

          },
          {
            "id": 1854,
            "name": "安乡县",
            "region_code": "430721",

          },
          {
            "id": 1855,
            "name": "汉寿县",
            "region_code": "430722",

          },
          {
            "id": 1856,
            "name": "澧县",
            "region_code": "430723",

          },
          {
            "id": 1857,
            "name": "临澧县",
            "region_code": "430724",

          },
          {
            "id": 1858,
            "name": "桃源县",
            "region_code": "430725",

          },
          {
            "id": 1859,
            "name": "石门县",
            "region_code": "430726",

          },
          {
            "id": 1860,
            "name": "津市市",
            "region_code": "430781",

          }
        ]
      },
      {
        "id": 1861,
        "name": "张家界市",
        "region_code": "430800",
        "child": [
          {
            "id": 1862,
            "name": "永定区",
            "region_code": "430802",

          },
          {
            "id": 1863,
            "name": "武陵源区",
            "region_code": "430811",

          },
          {
            "id": 1864,
            "name": "慈利县",
            "region_code": "430821",

          },
          {
            "id": 1865,
            "name": "桑植县",
            "region_code": "430822",

          }
        ]
      },
      {
        "id": 1866,
        "name": "益阳市",
        "region_code": "430900",
        "child": [
          {
            "id": 1867,
            "name": "资阳区",
            "region_code": "430902",

          },
          {
            "id": 1868,
            "name": "赫山区",
            "region_code": "430903",

          },
          {
            "id": 1869,
            "name": "南县",
            "region_code": "430921",

          },
          {
            "id": 1870,
            "name": "桃江县",
            "region_code": "430922",

          },
          {
            "id": 1871,
            "name": "安化县",
            "region_code": "430923",

          },
          {
            "id": 1872,
            "name": "沅江市",
            "region_code": "430981",

          }
        ]
      },
      {
        "id": 1873,
        "name": "郴州市",
        "region_code": "431000",
        "child": [
          {
            "id": 1874,
            "name": "北湖区",
            "region_code": "431002",

          },
          {
            "id": 1875,
            "name": "苏仙区",
            "region_code": "431003",

          },
          {
            "id": 1876,
            "name": "桂阳县",
            "region_code": "431021",

          },
          {
            "id": 1877,
            "name": "宜章县",
            "region_code": "431022",

          },
          {
            "id": 1878,
            "name": "永兴县",
            "region_code": "431023",

          },
          {
            "id": 1879,
            "name": "嘉禾县",
            "region_code": "431024",

          },
          {
            "id": 1880,
            "name": "临武县",
            "region_code": "431025",

          },
          {
            "id": 1881,
            "name": "汝城县",
            "region_code": "431026",

          },
          {
            "id": 1882,
            "name": "桂东县",
            "region_code": "431027",

          },
          {
            "id": 1883,
            "name": "安仁县",
            "region_code": "431028",

          },
          {
            "id": 1884,
            "name": "资兴市",
            "region_code": "431081",

          }
        ]
      },
      {
        "id": 1885,
        "name": "永州市",
        "region_code": "431100",
        "child": [
          {
            "id": 1886,
            "name": "零陵区",
            "region_code": "431102",

          },
          {
            "id": 1887,
            "name": "冷水滩区",
            "region_code": "431103",

          },
          {
            "id": 1888,
            "name": "祁阳县",
            "region_code": "431121",

          },
          {
            "id": 1889,
            "name": "东安县",
            "region_code": "431122",

          },
          {
            "id": 1890,
            "name": "双牌县",
            "region_code": "431123",

          },
          {
            "id": 1891,
            "name": "道县",
            "region_code": "431124",

          },
          {
            "id": 1892,
            "name": "江永县",
            "region_code": "431125",

          },
          {
            "id": 1893,
            "name": "宁远县",
            "region_code": "431126",

          },
          {
            "id": 1894,
            "name": "蓝山县",
            "region_code": "431127",

          },
          {
            "id": 1895,
            "name": "新田县",
            "region_code": "431128",

          },
          {
            "id": 1896,
            "name": "江华瑶族自治县",
            "region_code": "431129",

          }
        ]
      },
      {
        "id": 1897,
        "name": "怀化市",
        "region_code": "431200",
        "child": [
          {
            "id": 1898,
            "name": "鹤城区",
            "region_code": "431202",

          },
          {
            "id": 1899,
            "name": "中方县",
            "region_code": "431221",

          },
          {
            "id": 1900,
            "name": "沅陵县",
            "region_code": "431222",

          },
          {
            "id": 1901,
            "name": "辰溪县",
            "region_code": "431223",

          },
          {
            "id": 1902,
            "name": "溆浦县",
            "region_code": "431224",

          },
          {
            "id": 1903,
            "name": "会同县",
            "region_code": "431225",

          },
          {
            "id": 1904,
            "name": "麻阳苗族自治县",
            "region_code": "431226",

          },
          {
            "id": 1905,
            "name": "新晃侗族自治县",
            "region_code": "431227",

          },
          {
            "id": 1906,
            "name": "芷江侗族自治县",
            "region_code": "431228",

          },
          {
            "id": 1907,
            "name": "靖州苗族侗族自治县",
            "region_code": "431229",

          },
          {
            "id": 1908,
            "name": "通道侗族自治县",
            "region_code": "431230",

          },
          {
            "id": 1909,
            "name": "洪江市",
            "region_code": "431281",

          }
        ]
      },
      {
        "id": 1910,
        "name": "娄底市",
        "region_code": "431300",
        "child": [
          {
            "id": 1911,
            "name": "娄星区",
            "region_code": "431302",

          },
          {
            "id": 1912,
            "name": "双峰县",
            "region_code": "431321",

          },
          {
            "id": 1913,
            "name": "新化县",
            "region_code": "431322",

          },
          {
            "id": 1914,
            "name": "冷水江市",
            "region_code": "431381",

          },
          {
            "id": 1915,
            "name": "涟源市",
            "region_code": "431382",

          }
        ]
      },
      {
        "id": 1916,
        "name": "湘西土家族苗族自治州",
        "region_code": "433100",
        "child": [
          {
            "id": 1917,
            "name": "吉首市",
            "region_code": "433101",

          },
          {
            "id": 1918,
            "name": "泸溪县",
            "region_code": "433122",

          },
          {
            "id": 1919,
            "name": "凤凰县",
            "region_code": "433123",

          },
          {
            "id": 1920,
            "name": "花垣县",
            "region_code": "433124",

          },
          {
            "id": 1921,
            "name": "保靖县",
            "region_code": "433125",

          },
          {
            "id": 1922,
            "name": "古丈县",
            "region_code": "433126",

          },
          {
            "id": 1923,
            "name": "永顺县",
            "region_code": "433127",

          },
          {
            "id": 1924,
            "name": "龙山县",
            "region_code": "433130",

          }
        ]
      }
    ]
  },
  {
    "id": 1925,
    "name": "广东省",
    "region_code": "440000",
    "child": [
      {
        "id": 1926,
        "name": "广州市",
        "region_code": "440100",
        "child": [
          {
            "id": 1927,
            "name": "荔湾区",
            "region_code": "440103",

          },
          {
            "id": 1928,
            "name": "越秀区",
            "region_code": "440104",

          },
          {
            "id": 1929,
            "name": "海珠区",
            "region_code": "440105",

          },
          {
            "id": 1930,
            "name": "天河区",
            "region_code": "440106",

          },
          {
            "id": 1931,
            "name": "白云区",
            "region_code": "440111",

          },
          {
            "id": 1932,
            "name": "黄埔区",
            "region_code": "440112",

          },
          {
            "id": 1933,
            "name": "番禺区",
            "region_code": "440113",

          },
          {
            "id": 1934,
            "name": "花都区",
            "region_code": "440114",

          },
          {
            "id": 1935,
            "name": "南沙区",
            "region_code": "440115",

          },
          {
            "id": 1936,
            "name": "从化区",
            "region_code": "440117",

          },
          {
            "id": 1937,
            "name": "增城区",
            "region_code": "440118",

          }
        ]
      },
      {
        "id": 1938,
        "name": "韶关市",
        "region_code": "440200",
        "child": [
          {
            "id": 1939,
            "name": "武江区",
            "region_code": "440203",

          },
          {
            "id": 1940,
            "name": "浈江区",
            "region_code": "440204",

          },
          {
            "id": 1941,
            "name": "曲江区",
            "region_code": "440205",

          },
          {
            "id": 1942,
            "name": "始兴县",
            "region_code": "440222",

          },
          {
            "id": 1943,
            "name": "仁化县",
            "region_code": "440224",

          },
          {
            "id": 1944,
            "name": "翁源县",
            "region_code": "440229",

          },
          {
            "id": 1945,
            "name": "乳源瑶族自治县",
            "region_code": "440232",

          },
          {
            "id": 1946,
            "name": "新丰县",
            "region_code": "440233",

          },
          {
            "id": 1947,
            "name": "乐昌市",
            "region_code": "440281",

          },
          {
            "id": 1948,
            "name": "南雄市",
            "region_code": "440282",

          }
        ]
      },
      {
        "id": 1949,
        "name": "深圳市",
        "region_code": "440300",
        "child": [
          {
            "id": 1950,
            "name": "罗湖区",
            "region_code": "440303",

          },
          {
            "id": 1951,
            "name": "福田区",
            "region_code": "440304",

          },
          {
            "id": 1952,
            "name": "南山区",
            "region_code": "440305",

          },
          {
            "id": 1953,
            "name": "宝安区",
            "region_code": "440306",

          },
          {
            "id": 1954,
            "name": "龙岗区",
            "region_code": "440307",

          },
          {
            "id": 1955,
            "name": "盐田区",
            "region_code": "440308",

          },
          {
            "id": 1956,
            "name": "龙华区",
            "region_code": "440309",

          },
          {
            "id": 1957,
            "name": "坪山区",
            "region_code": "440310",

          },
          {
            "id": 1958,
            "name": "光明区",
            "region_code": "440311",

          }
        ]
      },
      {
        "id": 1959,
        "name": "珠海市",
        "region_code": "440400",
        "child": [
          {
            "id": 1960,
            "name": "香洲区",
            "region_code": "440402",

          },
          {
            "id": 1961,
            "name": "斗门区",
            "region_code": "440403",

          },
          {
            "id": 1962,
            "name": "金湾区",
            "region_code": "440404",

          }
        ]
      },
      {
        "id": 1963,
        "name": "汕头市",
        "region_code": "440500",
        "child": [
          {
            "id": 1964,
            "name": "龙湖区",
            "region_code": "440507",

          },
          {
            "id": 1965,
            "name": "金平区",
            "region_code": "440511",

          },
          {
            "id": 1966,
            "name": "濠江区",
            "region_code": "440512",

          },
          {
            "id": 1967,
            "name": "潮阳区",
            "region_code": "440513",

          },
          {
            "id": 1968,
            "name": "潮南区",
            "region_code": "440514",

          },
          {
            "id": 1969,
            "name": "澄海区",
            "region_code": "440515",

          },
          {
            "id": 1970,
            "name": "南澳县",
            "region_code": "440523",

          }
        ]
      },
      {
        "id": 1971,
        "name": "佛山市",
        "region_code": "440600",
        "child": [
          {
            "id": 1972,
            "name": "禅城区",
            "region_code": "440604",

          },
          {
            "id": 1973,
            "name": "南海区",
            "region_code": "440605",

          },
          {
            "id": 1974,
            "name": "顺德区",
            "region_code": "440606",

          },
          {
            "id": 1975,
            "name": "三水区",
            "region_code": "440607",

          },
          {
            "id": 1976,
            "name": "高明区",
            "region_code": "440608",

          }
        ]
      },
      {
        "id": 1977,
        "name": "江门市",
        "region_code": "440700",
        "child": [
          {
            "id": 1978,
            "name": "蓬江区",
            "region_code": "440703",

          },
          {
            "id": 1979,
            "name": "江海区",
            "region_code": "440704",

          },
          {
            "id": 1980,
            "name": "新会区",
            "region_code": "440705",

          },
          {
            "id": 1981,
            "name": "台山市",
            "region_code": "440781",

          },
          {
            "id": 1982,
            "name": "开平市",
            "region_code": "440783",

          },
          {
            "id": 1983,
            "name": "鹤山市",
            "region_code": "440784",

          },
          {
            "id": 1984,
            "name": "恩平市",
            "region_code": "440785",

          }
        ]
      },
      {
        "id": 1985,
        "name": "湛江市",
        "region_code": "440800",
        "child": [
          {
            "id": 1986,
            "name": "赤坎区",
            "region_code": "440802",

          },
          {
            "id": 1987,
            "name": "霞山区",
            "region_code": "440803",

          },
          {
            "id": 1988,
            "name": "坡头区",
            "region_code": "440804",

          },
          {
            "id": 1989,
            "name": "麻章区",
            "region_code": "440811",

          },
          {
            "id": 1990,
            "name": "遂溪县",
            "region_code": "440823",

          },
          {
            "id": 1991,
            "name": "徐闻县",
            "region_code": "440825",

          },
          {
            "id": 1992,
            "name": "廉江市",
            "region_code": "440881",

          },
          {
            "id": 1993,
            "name": "雷州市",
            "region_code": "440882",

          },
          {
            "id": 1994,
            "name": "吴川市",
            "region_code": "440883",

          }
        ]
      },
      {
        "id": 1995,
        "name": "茂名市",
        "region_code": "440900",
        "child": [
          {
            "id": 1996,
            "name": "茂南区",
            "region_code": "440902",

          },
          {
            "id": 1997,
            "name": "电白区",
            "region_code": "440904",

          },
          {
            "id": 1998,
            "name": "高州市",
            "region_code": "440981",

          },
          {
            "id": 1999,
            "name": "化州市",
            "region_code": "440982",

          },
          {
            "id": 2000,
            "name": "信宜市",
            "region_code": "440983",

          }
        ]
      },
      {
        "id": 2001,
        "name": "肇庆市",
        "region_code": "441200",
        "child": [
          {
            "id": 2002,
            "name": "端州区",
            "region_code": "441202",

          },
          {
            "id": 2003,
            "name": "鼎湖区",
            "region_code": "441203",

          },
          {
            "id": 2004,
            "name": "高要区",
            "region_code": "441204",

          },
          {
            "id": 2005,
            "name": "广宁县",
            "region_code": "441223",

          },
          {
            "id": 2006,
            "name": "怀集县",
            "region_code": "441224",

          },
          {
            "id": 2007,
            "name": "封开县",
            "region_code": "441225",

          },
          {
            "id": 2008,
            "name": "德庆县",
            "region_code": "441226",

          },
          {
            "id": 2009,
            "name": "四会市",
            "region_code": "441284",

          }
        ]
      },
      {
        "id": 2010,
        "name": "惠州市",
        "region_code": "441300",
        "child": [
          {
            "id": 2011,
            "name": "惠城区",
            "region_code": "441302",

          },
          {
            "id": 2012,
            "name": "惠阳区",
            "region_code": "441303",

          },
          {
            "id": 2013,
            "name": "博罗县",
            "region_code": "441322",

          },
          {
            "id": 2014,
            "name": "惠东县",
            "region_code": "441323",

          },
          {
            "id": 2015,
            "name": "龙门县",
            "region_code": "441324",

          }
        ]
      },
      {
        "id": 2016,
        "name": "梅州市",
        "region_code": "441400",
        "child": [
          {
            "id": 2017,
            "name": "梅江区",
            "region_code": "441402",

          },
          {
            "id": 2018,
            "name": "梅县区",
            "region_code": "441403",

          },
          {
            "id": 2019,
            "name": "大埔县",
            "region_code": "441422",

          },
          {
            "id": 2020,
            "name": "丰顺县",
            "region_code": "441423",

          },
          {
            "id": 2021,
            "name": "五华县",
            "region_code": "441424",

          },
          {
            "id": 2022,
            "name": "平远县",
            "region_code": "441426",

          },
          {
            "id": 2023,
            "name": "蕉岭县",
            "region_code": "441427",

          },
          {
            "id": 2024,
            "name": "兴宁市",
            "region_code": "441481",

          }
        ]
      },
      {
        "id": 2025,
        "name": "汕尾市",
        "region_code": "441500",
        "child": [
          {
            "id": 2026,
            "name": "城区",
            "region_code": "441502",

          },
          {
            "id": 2027,
            "name": "海丰县",
            "region_code": "441521",

          },
          {
            "id": 2028,
            "name": "陆河县",
            "region_code": "441523",

          },
          {
            "id": 2029,
            "name": "陆丰市",
            "region_code": "441581",

          }
        ]
      },
      {
        "id": 2030,
        "name": "河源市",
        "region_code": "441600",
        "child": [
          {
            "id": 2031,
            "name": "源城区",
            "region_code": "441602",

          },
          {
            "id": 2032,
            "name": "紫金县",
            "region_code": "441621",

          },
          {
            "id": 2033,
            "name": "龙川县",
            "region_code": "441622",

          },
          {
            "id": 2034,
            "name": "连平县",
            "region_code": "441623",

          },
          {
            "id": 2035,
            "name": "和平县",
            "region_code": "441624",

          },
          {
            "id": 2036,
            "name": "东源县",
            "region_code": "441625",

          }
        ]
      },
      {
        "id": 2037,
        "name": "阳江市",
        "region_code": "441700",
        "child": [
          {
            "id": 2038,
            "name": "江城区",
            "region_code": "441702",

          },
          {
            "id": 2039,
            "name": "阳东区",
            "region_code": "441704",

          },
          {
            "id": 2040,
            "name": "阳西县",
            "region_code": "441721",

          },
          {
            "id": 2041,
            "name": "阳春市",
            "region_code": "441781",

          }
        ]
      },
      {
        "id": 2042,
        "name": "清远市",
        "region_code": "441800",
        "child": [
          {
            "id": 2043,
            "name": "清城区",
            "region_code": "441802",

          },
          {
            "id": 2044,
            "name": "清新区",
            "region_code": "441803",

          },
          {
            "id": 2045,
            "name": "佛冈县",
            "region_code": "441821",

          },
          {
            "id": 2046,
            "name": "阳山县",
            "region_code": "441823",

          },
          {
            "id": 2047,
            "name": "连山壮族瑶族自治县",
            "region_code": "441825",

          },
          {
            "id": 2048,
            "name": "连南瑶族自治县",
            "region_code": "441826",

          },
          {
            "id": 2049,
            "name": "英德市",
            "region_code": "441881",

          },
          {
            "id": 2050,
            "name": "连州市",
            "region_code": "441882",

          }
        ]
      },
      {
        "id": 2051,
        "name": "东莞市",
        "region_code": "441900",
        "child": []
      },
      {
        "id": 2052,
        "name": "中山市",
        "region_code": "442000",
        "child": []
      },
      {
        "id": 2053,
        "name": "潮州市",
        "region_code": "445100",
        "child": [
          {
            "id": 2054,
            "name": "湘桥区",
            "region_code": "445102",

          },
          {
            "id": 2055,
            "name": "潮安区",
            "region_code": "445103",

          },
          {
            "id": 2056,
            "name": "饶平县",
            "region_code": "445122",

          }
        ]
      },
      {
        "id": 2057,
        "name": "揭阳市",
        "region_code": "445200",
        "child": [
          {
            "id": 2058,
            "name": "榕城区",
            "region_code": "445202",

          },
          {
            "id": 2059,
            "name": "揭东区",
            "region_code": "445203",

          },
          {
            "id": 2060,
            "name": "揭西县",
            "region_code": "445222",

          },
          {
            "id": 2061,
            "name": "惠来县",
            "region_code": "445224",

          },
          {
            "id": 2062,
            "name": "普宁市",
            "region_code": "445281",

          }
        ]
      },
      {
        "id": 2063,
        "name": "云浮市",
        "region_code": "445300",
        "child": [
          {
            "id": 2064,
            "name": "云城区",
            "region_code": "445302",

          },
          {
            "id": 2065,
            "name": "云安区",
            "region_code": "445303",

          },
          {
            "id": 2066,
            "name": "新兴县",
            "region_code": "445321",

          },
          {
            "id": 2067,
            "name": "郁南县",
            "region_code": "445322",

          },
          {
            "id": 2068,
            "name": "罗定市",
            "region_code": "445381",

          }
        ]
      }
    ]
  },
  {
    "id": 2069,
    "name": "广西壮族自治区",
    "region_code": "450000",
    "child": [
      {
        "id": 2070,
        "name": "南宁市",
        "region_code": "450100",
        "child": [
          {
            "id": 2071,
            "name": "兴宁区",
            "region_code": "450102",

          },
          {
            "id": 2072,
            "name": "青秀区",
            "region_code": "450103",

          },
          {
            "id": 2073,
            "name": "江南区",
            "region_code": "450105",

          },
          {
            "id": 2074,
            "name": "西乡塘区",
            "region_code": "450107",

          },
          {
            "id": 2075,
            "name": "良庆区",
            "region_code": "450108",

          },
          {
            "id": 2076,
            "name": "邕宁区",
            "region_code": "450109",

          },
          {
            "id": 2077,
            "name": "武鸣区",
            "region_code": "450110",

          },
          {
            "id": 2078,
            "name": "隆安县",
            "region_code": "450123",

          },
          {
            "id": 2079,
            "name": "马山县",
            "region_code": "450124",

          },
          {
            "id": 2080,
            "name": "上林县",
            "region_code": "450125",

          },
          {
            "id": 2081,
            "name": "宾阳县",
            "region_code": "450126",

          },
          {
            "id": 2082,
            "name": "横县",
            "region_code": "450127",

          }
        ]
      },
      {
        "id": 2083,
        "name": "柳州市",
        "region_code": "450200",
        "child": [
          {
            "id": 2084,
            "name": "城中区",
            "region_code": "450202",

          },
          {
            "id": 2085,
            "name": "鱼峰区",
            "region_code": "450203",

          },
          {
            "id": 2086,
            "name": "柳南区",
            "region_code": "450204",

          },
          {
            "id": 2087,
            "name": "柳北区",
            "region_code": "450205",

          },
          {
            "id": 2088,
            "name": "柳江区",
            "region_code": "450206",

          },
          {
            "id": 2089,
            "name": "柳城县",
            "region_code": "450222",

          },
          {
            "id": 2090,
            "name": "鹿寨县",
            "region_code": "450223",

          },
          {
            "id": 2091,
            "name": "融安县",
            "region_code": "450224",

          },
          {
            "id": 2092,
            "name": "融水苗族自治县",
            "region_code": "450225",

          },
          {
            "id": 2093,
            "name": "三江侗族自治县",
            "region_code": "450226",

          }
        ]
      },
      {
        "id": 2094,
        "name": "桂林市",
        "region_code": "450300",
        "child": [
          {
            "id": 2095,
            "name": "秀峰区",
            "region_code": "450302",

          },
          {
            "id": 2096,
            "name": "叠彩区",
            "region_code": "450303",

          },
          {
            "id": 2097,
            "name": "象山区",
            "region_code": "450304",

          },
          {
            "id": 2098,
            "name": "七星区",
            "region_code": "450305",

          },
          {
            "id": 2099,
            "name": "雁山区",
            "region_code": "450311",

          },
          {
            "id": 2100,
            "name": "临桂区",
            "region_code": "450312",

          },
          {
            "id": 2101,
            "name": "阳朔县",
            "region_code": "450321",

          },
          {
            "id": 2102,
            "name": "灵川县",
            "region_code": "450323",

          },
          {
            "id": 2103,
            "name": "全州县",
            "region_code": "450324",

          },
          {
            "id": 2104,
            "name": "兴安县",
            "region_code": "450325",

          },
          {
            "id": 2105,
            "name": "永福县",
            "region_code": "450326",

          },
          {
            "id": 2106,
            "name": "灌阳县",
            "region_code": "450327",

          },
          {
            "id": 2107,
            "name": "龙胜各族自治县",
            "region_code": "450328",

          },
          {
            "id": 2108,
            "name": "资源县",
            "region_code": "450329",

          },
          {
            "id": 2109,
            "name": "平乐县",
            "region_code": "450330",

          },
          {
            "id": 2110,
            "name": "恭城瑶族自治县",
            "region_code": "450332",

          },
          {
            "id": 2111,
            "name": "荔浦市",
            "region_code": "450381",

          }
        ]
      },
      {
        "id": 2112,
        "name": "梧州市",
        "region_code": "450400",
        "child": [
          {
            "id": 2113,
            "name": "万秀区",
            "region_code": "450403",

          },
          {
            "id": 2114,
            "name": "长洲区",
            "region_code": "450405",

          },
          {
            "id": 2115,
            "name": "龙圩区",
            "region_code": "450406",

          },
          {
            "id": 2116,
            "name": "苍梧县",
            "region_code": "450421",

          },
          {
            "id": 2117,
            "name": "藤县",
            "region_code": "450422",

          },
          {
            "id": 2118,
            "name": "蒙山县",
            "region_code": "450423",

          },
          {
            "id": 2119,
            "name": "岑溪市",
            "region_code": "450481",

          }
        ]
      },
      {
        "id": 2120,
        "name": "北海市",
        "region_code": "450500",
        "child": [
          {
            "id": 2121,
            "name": "海城区",
            "region_code": "450502",

          },
          {
            "id": 2122,
            "name": "银海区",
            "region_code": "450503",

          },
          {
            "id": 2123,
            "name": "铁山港区",
            "region_code": "450512",

          },
          {
            "id": 2124,
            "name": "合浦县",
            "region_code": "450521",

          }
        ]
      },
      {
        "id": 2125,
        "name": "防城港市",
        "region_code": "450600",
        "child": [
          {
            "id": 2126,
            "name": "港口区",
            "region_code": "450602",

          },
          {
            "id": 2127,
            "name": "防城区",
            "region_code": "450603",

          },
          {
            "id": 2128,
            "name": "上思县",
            "region_code": "450621",

          },
          {
            "id": 2129,
            "name": "东兴市",
            "region_code": "450681",

          }
        ]
      },
      {
        "id": 2130,
        "name": "钦州市",
        "region_code": "450700",
        "child": [
          {
            "id": 2131,
            "name": "钦南区",
            "region_code": "450702",

          },
          {
            "id": 2132,
            "name": "钦北区",
            "region_code": "450703",

          },
          {
            "id": 2133,
            "name": "灵山县",
            "region_code": "450721",

          },
          {
            "id": 2134,
            "name": "浦北县",
            "region_code": "450722",

          }
        ]
      },
      {
        "id": 2135,
        "name": "贵港市",
        "region_code": "450800",
        "child": [
          {
            "id": 2136,
            "name": "港北区",
            "region_code": "450802",

          },
          {
            "id": 2137,
            "name": "港南区",
            "region_code": "450803",

          },
          {
            "id": 2138,
            "name": "覃塘区",
            "region_code": "450804",

          },
          {
            "id": 2139,
            "name": "平南县",
            "region_code": "450821",

          },
          {
            "id": 2140,
            "name": "桂平市",
            "region_code": "450881",

          }
        ]
      },
      {
        "id": 2141,
        "name": "玉林市",
        "region_code": "450900",
        "child": [
          {
            "id": 2142,
            "name": "玉州区",
            "region_code": "450902",

          },
          {
            "id": 2143,
            "name": "福绵区",
            "region_code": "450903",

          },
          {
            "id": 2144,
            "name": "容县",
            "region_code": "450921",

          },
          {
            "id": 2145,
            "name": "陆川县",
            "region_code": "450922",

          },
          {
            "id": 2146,
            "name": "博白县",
            "region_code": "450923",

          },
          {
            "id": 2147,
            "name": "兴业县",
            "region_code": "450924",

          },
          {
            "id": 2148,
            "name": "北流市",
            "region_code": "450981",

          }
        ]
      },
      {
        "id": 2149,
        "name": "百色市",
        "region_code": "451000",
        "child": [
          {
            "id": 2150,
            "name": "右江区",
            "region_code": "451002",

          },
          {
            "id": 2151,
            "name": "田阳区",
            "region_code": "451003",

          },
          {
            "id": 2152,
            "name": "田东县",
            "region_code": "451022",

          },
          {
            "id": 2153,
            "name": "德保县",
            "region_code": "451024",

          },
          {
            "id": 2154,
            "name": "那坡县",
            "region_code": "451026",

          },
          {
            "id": 2155,
            "name": "凌云县",
            "region_code": "451027",

          },
          {
            "id": 2156,
            "name": "乐业县",
            "region_code": "451028",

          },
          {
            "id": 2157,
            "name": "田林县",
            "region_code": "451029",

          },
          {
            "id": 2158,
            "name": "西林县",
            "region_code": "451030",

          },
          {
            "id": 2159,
            "name": "隆林各族自治县",
            "region_code": "451031",

          },
          {
            "id": 2160,
            "name": "靖西市",
            "region_code": "451081",

          },
          {
            "id": 2161,
            "name": "平果市",
            "region_code": "451082",

          }
        ]
      },
      {
        "id": 2162,
        "name": "贺州市",
        "region_code": "451100",
        "child": [
          {
            "id": 2163,
            "name": "八步区",
            "region_code": "451102",

          },
          {
            "id": 2164,
            "name": "平桂区",
            "region_code": "451103",

          },
          {
            "id": 2165,
            "name": "昭平县",
            "region_code": "451121",

          },
          {
            "id": 2166,
            "name": "钟山县",
            "region_code": "451122",

          },
          {
            "id": 2167,
            "name": "富川瑶族自治县",
            "region_code": "451123",

          }
        ]
      },
      {
        "id": 2168,
        "name": "河池市",
        "region_code": "451200",
        "child": [
          {
            "id": 2169,
            "name": "金城江区",
            "region_code": "451202",

          },
          {
            "id": 2170,
            "name": "宜州区",
            "region_code": "451203",

          },
          {
            "id": 2171,
            "name": "南丹县",
            "region_code": "451221",

          },
          {
            "id": 2172,
            "name": "天峨县",
            "region_code": "451222",

          },
          {
            "id": 2173,
            "name": "凤山县",
            "region_code": "451223",

          },
          {
            "id": 2174,
            "name": "东兰县",
            "region_code": "451224",

          },
          {
            "id": 2175,
            "name": "罗城仫佬族自治县",
            "region_code": "451225",

          },
          {
            "id": 2176,
            "name": "环江毛南族自治县",
            "region_code": "451226",

          },
          {
            "id": 2177,
            "name": "巴马瑶族自治县",
            "region_code": "451227",

          },
          {
            "id": 2178,
            "name": "都安瑶族自治县",
            "region_code": "451228",

          },
          {
            "id": 2179,
            "name": "大化瑶族自治县",
            "region_code": "451229",

          }
        ]
      },
      {
        "id": 2180,
        "name": "来宾市",
        "region_code": "451300",
        "child": [
          {
            "id": 2181,
            "name": "兴宾区",
            "region_code": "451302",

          },
          {
            "id": 2182,
            "name": "忻城县",
            "region_code": "451321",

          },
          {
            "id": 2183,
            "name": "象州县",
            "region_code": "451322",

          },
          {
            "id": 2184,
            "name": "武宣县",
            "region_code": "451323",

          },
          {
            "id": 2185,
            "name": "金秀瑶族自治县",
            "region_code": "451324",

          },
          {
            "id": 2186,
            "name": "合山市",
            "region_code": "451381",

          }
        ]
      },
      {
        "id": 2187,
        "name": "崇左市",
        "region_code": "451400",
        "child": [
          {
            "id": 2188,
            "name": "江州区",
            "region_code": "451402",

          },
          {
            "id": 2189,
            "name": "扶绥县",
            "region_code": "451421",

          },
          {
            "id": 2190,
            "name": "宁明县",
            "region_code": "451422",

          },
          {
            "id": 2191,
            "name": "龙州县",
            "region_code": "451423",

          },
          {
            "id": 2192,
            "name": "大新县",
            "region_code": "451424",

          },
          {
            "id": 2193,
            "name": "天等县",
            "region_code": "451425",

          },
          {
            "id": 2194,
            "name": "凭祥市",
            "region_code": "451481",

          }
        ]
      }
    ]
  },
  {
    "id": 2195,
    "name": "海南省",
    "region_code": "460000",
    "child": [
      {
        "id": 2196,
        "name": "海口市",
        "region_code": "460100",
        "child": [
          {
            "id": 2197,
            "name": "秀英区",
            "region_code": "460105",

          },
          {
            "id": 2198,
            "name": "龙华区",
            "region_code": "460106",

          },
          {
            "id": 2199,
            "name": "琼山区",
            "region_code": "460107",

          },
          {
            "id": 2200,
            "name": "美兰区",
            "region_code": "460108",

          }
        ]
      },
      {
        "id": 2201,
        "name": "三亚市",
        "region_code": "460200",
        "child": [
          {
            "id": 2202,
            "name": "海棠区",
            "region_code": "460202",

          },
          {
            "id": 2203,
            "name": "吉阳区",
            "region_code": "460203",

          },
          {
            "id": 2204,
            "name": "天涯区",
            "region_code": "460204",

          },
          {
            "id": 2205,
            "name": "崖州区",
            "region_code": "460205",

          }
        ]
      },
      {
        "id": 2206,
        "name": "三沙市",
        "region_code": "460300",
        "child": [
          {
            "id": 2207,
            "name": "西沙群岛",
            "region_code": "460321",

          },
          {
            "id": 2208,
            "name": "南沙群岛",
            "region_code": "460322",

          },
          {
            "id": 2209,
            "name": "中沙群岛",
            "region_code": "460323",

          }
        ]
      },
      {
        "id": 2210,
        "name": "儋州市",
        "region_code": "460400",
        "child": []
      },
      {
        "id": 2211,
        "name": "直辖县",
        "region_code": "469000",
        "child": [
          {
            "id": 2212,
            "name": "五指山市",
            "region_code": "469001",

          },
          {
            "id": 2213,
            "name": "琼海市",
            "region_code": "469002",

          },
          {
            "id": 2214,
            "name": "文昌市",
            "region_code": "469005",

          },
          {
            "id": 2215,
            "name": "万宁市",
            "region_code": "469006",

          },
          {
            "id": 2216,
            "name": "东方市",
            "region_code": "469007",

          },
          {
            "id": 2217,
            "name": "定安县",
            "region_code": "469021",

          },
          {
            "id": 2218,
            "name": "屯昌县",
            "region_code": "469022",

          },
          {
            "id": 2219,
            "name": "澄迈县",
            "region_code": "469023",

          },
          {
            "id": 2220,
            "name": "临高县",
            "region_code": "469024",

          },
          {
            "id": 2221,
            "name": "白沙黎族自治县",
            "region_code": "469025",

          },
          {
            "id": 2222,
            "name": "昌江黎族自治县",
            "region_code": "469026",

          },
          {
            "id": 2223,
            "name": "乐东黎族自治县",
            "region_code": "469027",

          },
          {
            "id": 2224,
            "name": "陵水黎族自治县",
            "region_code": "469028",

          },
          {
            "id": 2225,
            "name": "保亭黎族苗族自治县",
            "region_code": "469029",

          },
          {
            "id": 2226,
            "name": "琼中黎族苗族自治县",
            "region_code": "469030",

          }
        ]
      }
    ]
  },
  {
    "id": 2227,
    "name": "重庆市",
    "region_code": "500000",
    "child": [
      {
        "id": 2228,
        "name": "重庆市",
        "region_code": "500100",
        "child": [
          {
            "id": 2229,
            "name": "万州区",
            "region_code": "500101",

          },
          {
            "id": 2230,
            "name": "涪陵区",
            "region_code": "500102",

          },
          {
            "id": 2231,
            "name": "渝中区",
            "region_code": "500103",

          },
          {
            "id": 2232,
            "name": "大渡口区",
            "region_code": "500104",

          },
          {
            "id": 2233,
            "name": "江北区",
            "region_code": "500105",

          },
          {
            "id": 2234,
            "name": "沙坪坝区",
            "region_code": "500106",

          },
          {
            "id": 2235,
            "name": "九龙坡区",
            "region_code": "500107",

          },
          {
            "id": 2236,
            "name": "南岸区",
            "region_code": "500108",

          },
          {
            "id": 2237,
            "name": "北碚区",
            "region_code": "500109",

          },
          {
            "id": 2238,
            "name": "綦江区",
            "region_code": "500110",

          },
          {
            "id": 2239,
            "name": "大足区",
            "region_code": "500111",

          },
          {
            "id": 2240,
            "name": "渝北区",
            "region_code": "500112",

          },
          {
            "id": 2241,
            "name": "巴南区",
            "region_code": "500113",

          },
          {
            "id": 2242,
            "name": "黔江区",
            "region_code": "500114",

          },
          {
            "id": 2243,
            "name": "长寿区",
            "region_code": "500115",

          },
          {
            "id": 2244,
            "name": "江津区",
            "region_code": "500116",

          },
          {
            "id": 2245,
            "name": "合川区",
            "region_code": "500117",

          },
          {
            "id": 2246,
            "name": "永川区",
            "region_code": "500118",

          },
          {
            "id": 2247,
            "name": "南川区",
            "region_code": "500119",

          },
          {
            "id": 2248,
            "name": "璧山区",
            "region_code": "500120",

          },
          {
            "id": 2249,
            "name": "铜梁区",
            "region_code": "500151",

          },
          {
            "id": 2250,
            "name": "潼南区",
            "region_code": "500152",

          },
          {
            "id": 2251,
            "name": "荣昌区",
            "region_code": "500153",

          },
          {
            "id": 2252,
            "name": "开州区",
            "region_code": "500154",

          },
          {
            "id": 2253,
            "name": "梁平区",
            "region_code": "500155",

          },
          {
            "id": 2254,
            "name": "武隆区",
            "region_code": "500156",

          }
        ]
      },
      {
        "id": 2255,
        "name": "郊县",
        "region_code": "500200",
        "child": [
          {
            "id": 2256,
            "name": "城口县",
            "region_code": "500229",

          },
          {
            "id": 2257,
            "name": "丰都县",
            "region_code": "500230",

          },
          {
            "id": 2258,
            "name": "垫江县",
            "region_code": "500231",

          },
          {
            "id": 2259,
            "name": "忠县",
            "region_code": "500233",

          },
          {
            "id": 2260,
            "name": "云阳县",
            "region_code": "500235",

          },
          {
            "id": 2261,
            "name": "奉节县",
            "region_code": "500236",

          },
          {
            "id": 2262,
            "name": "巫山县",
            "region_code": "500237",

          },
          {
            "id": 2263,
            "name": "巫溪县",
            "region_code": "500238",

          },
          {
            "id": 2264,
            "name": "石柱土家族自治县",
            "region_code": "500240",

          },
          {
            "id": 2265,
            "name": "秀山土家族苗族自治县",
            "region_code": "500241",

          },
          {
            "id": 2266,
            "name": "酉阳土家族苗族自治县",
            "region_code": "500242",

          },
          {
            "id": 2267,
            "name": "彭水苗族土家族自治县",
            "region_code": "500243",

          }
        ]
      }
    ]
  },
  {
    "id": 2268,
    "name": "四川省",
    "region_code": "510000",
    "child": [
      {
        "id": 2269,
        "name": "成都市",
        "region_code": "510100",
        "child": [
          {
            "id": 2270,
            "name": "锦江区",
            "region_code": "510104",

          },
          {
            "id": 2271,
            "name": "青羊区",
            "region_code": "510105",

          },
          {
            "id": 2272,
            "name": "金牛区",
            "region_code": "510106",

          },
          {
            "id": 2273,
            "name": "武侯区",
            "region_code": "510107",

          },
          {
            "id": 2274,
            "name": "成华区",
            "region_code": "510108",

          },
          {
            "id": 2275,
            "name": "龙泉驿区",
            "region_code": "510112",

          },
          {
            "id": 2276,
            "name": "青白江区",
            "region_code": "510113",

          },
          {
            "id": 2277,
            "name": "新都区",
            "region_code": "510114",

          },
          {
            "id": 2278,
            "name": "温江区",
            "region_code": "510115",

          },
          {
            "id": 2279,
            "name": "双流区",
            "region_code": "510116",

          },
          {
            "id": 2280,
            "name": "郫都区",
            "region_code": "510117",

          },
          {
            "id": 2281,
            "name": "新津区",
            "region_code": "510118",

          },
          {
            "id": 2282,
            "name": "金堂县",
            "region_code": "510121",

          },
          {
            "id": 2283,
            "name": "大邑县",
            "region_code": "510129",

          },
          {
            "id": 2284,
            "name": "蒲江县",
            "region_code": "510131",

          },
          {
            "id": 2285,
            "name": "都江堰市",
            "region_code": "510181",

          },
          {
            "id": 2286,
            "name": "彭州市",
            "region_code": "510182",

          },
          {
            "id": 2287,
            "name": "邛崃市",
            "region_code": "510183",

          },
          {
            "id": 2288,
            "name": "崇州市",
            "region_code": "510184",

          },
          {
            "id": 2289,
            "name": "简阳市",
            "region_code": "510185",

          }
        ]
      },
      {
        "id": 2290,
        "name": "自贡市",
        "region_code": "510300",
        "child": [
          {
            "id": 2291,
            "name": "自流井区",
            "region_code": "510302",

          },
          {
            "id": 2292,
            "name": "贡井区",
            "region_code": "510303",

          },
          {
            "id": 2293,
            "name": "大安区",
            "region_code": "510304",

          },
          {
            "id": 2294,
            "name": "沿滩区",
            "region_code": "510311",

          },
          {
            "id": 2295,
            "name": "荣县",
            "region_code": "510321",

          },
          {
            "id": 2296,
            "name": "富顺县",
            "region_code": "510322",

          }
        ]
      },
      {
        "id": 2297,
        "name": "攀枝花市",
        "region_code": "510400",
        "child": [
          {
            "id": 2298,
            "name": "东区",
            "region_code": "510402",

          },
          {
            "id": 2299,
            "name": "西区",
            "region_code": "510403",

          },
          {
            "id": 2300,
            "name": "仁和区",
            "region_code": "510411",

          },
          {
            "id": 2301,
            "name": "米易县",
            "region_code": "510421",

          },
          {
            "id": 2302,
            "name": "盐边县",
            "region_code": "510422",

          }
        ]
      },
      {
        "id": 2303,
        "name": "泸州市",
        "region_code": "510500",
        "child": [
          {
            "id": 2304,
            "name": "江阳区",
            "region_code": "510502",

          },
          {
            "id": 2305,
            "name": "纳溪区",
            "region_code": "510503",

          },
          {
            "id": 2306,
            "name": "龙马潭区",
            "region_code": "510504",

          },
          {
            "id": 2307,
            "name": "泸县",
            "region_code": "510521",

          },
          {
            "id": 2308,
            "name": "合江县",
            "region_code": "510522",

          },
          {
            "id": 2309,
            "name": "叙永县",
            "region_code": "510524",

          },
          {
            "id": 2310,
            "name": "古蔺县",
            "region_code": "510525",

          }
        ]
      },
      {
        "id": 2311,
        "name": "德阳市",
        "region_code": "510600",
        "child": [
          {
            "id": 2312,
            "name": "旌阳区",
            "region_code": "510603",

          },
          {
            "id": 2313,
            "name": "罗江区",
            "region_code": "510604",

          },
          {
            "id": 2314,
            "name": "中江县",
            "region_code": "510623",

          },
          {
            "id": 2315,
            "name": "广汉市",
            "region_code": "510681",

          },
          {
            "id": 2316,
            "name": "什邡市",
            "region_code": "510682",

          },
          {
            "id": 2317,
            "name": "绵竹市",
            "region_code": "510683",

          }
        ]
      },
      {
        "id": 2318,
        "name": "绵阳市",
        "region_code": "510700",
        "child": [
          {
            "id": 2319,
            "name": "涪城区",
            "region_code": "510703",

          },
          {
            "id": 2320,
            "name": "游仙区",
            "region_code": "510704",

          },
          {
            "id": 2321,
            "name": "安州区",
            "region_code": "510705",

          },
          {
            "id": 2322,
            "name": "三台县",
            "region_code": "510722",

          },
          {
            "id": 2323,
            "name": "盐亭县",
            "region_code": "510723",

          },
          {
            "id": 2324,
            "name": "梓潼县",
            "region_code": "510725",

          },
          {
            "id": 2325,
            "name": "北川羌族自治县",
            "region_code": "510726",

          },
          {
            "id": 2326,
            "name": "平武县",
            "region_code": "510727",

          },
          {
            "id": 2327,
            "name": "江油市",
            "region_code": "510781",

          }
        ]
      },
      {
        "id": 2328,
        "name": "广元市",
        "region_code": "510800",
        "child": [
          {
            "id": 2329,
            "name": "利州区",
            "region_code": "510802",

          },
          {
            "id": 2330,
            "name": "昭化区",
            "region_code": "510811",

          },
          {
            "id": 2331,
            "name": "朝天区",
            "region_code": "510812",

          },
          {
            "id": 2332,
            "name": "旺苍县",
            "region_code": "510821",

          },
          {
            "id": 2333,
            "name": "青川县",
            "region_code": "510822",

          },
          {
            "id": 2334,
            "name": "剑阁县",
            "region_code": "510823",

          },
          {
            "id": 2335,
            "name": "苍溪县",
            "region_code": "510824",

          }
        ]
      },
      {
        "id": 2336,
        "name": "遂宁市",
        "region_code": "510900",
        "child": [
          {
            "id": 2337,
            "name": "船山区",
            "region_code": "510903",

          },
          {
            "id": 2338,
            "name": "安居区",
            "region_code": "510904",

          },
          {
            "id": 2339,
            "name": "蓬溪县",
            "region_code": "510921",

          },
          {
            "id": 2340,
            "name": "大英县",
            "region_code": "510923",

          },
          {
            "id": 2341,
            "name": "射洪市",
            "region_code": "510981",

          }
        ]
      },
      {
        "id": 2342,
        "name": "内江市",
        "region_code": "511000",
        "child": [
          {
            "id": 2343,
            "name": "市中区",
            "region_code": "511002",

          },
          {
            "id": 2344,
            "name": "东兴区",
            "region_code": "511011",

          },
          {
            "id": 2345,
            "name": "威远县",
            "region_code": "511024",

          },
          {
            "id": 2346,
            "name": "资中县",
            "region_code": "511025",

          },
          {
            "id": 2347,
            "name": "隆昌市",
            "region_code": "511083",

          }
        ]
      },
      {
        "id": 2348,
        "name": "乐山市",
        "region_code": "511100",
        "child": [
          {
            "id": 2349,
            "name": "市中区",
            "region_code": "511102",

          },
          {
            "id": 2350,
            "name": "沙湾区",
            "region_code": "511111",

          },
          {
            "id": 2351,
            "name": "五通桥区",
            "region_code": "511112",

          },
          {
            "id": 2352,
            "name": "金口河区",
            "region_code": "511113",

          },
          {
            "id": 2353,
            "name": "犍为县",
            "region_code": "511123",

          },
          {
            "id": 2354,
            "name": "井研县",
            "region_code": "511124",

          },
          {
            "id": 2355,
            "name": "夹江县",
            "region_code": "511126",

          },
          {
            "id": 2356,
            "name": "沐川县",
            "region_code": "511129",

          },
          {
            "id": 2357,
            "name": "峨边彝族自治县",
            "region_code": "511132",

          },
          {
            "id": 2358,
            "name": "马边彝族自治县",
            "region_code": "511133",

          },
          {
            "id": 2359,
            "name": "峨眉山市",
            "region_code": "511181",

          }
        ]
      },
      {
        "id": 2360,
        "name": "南充市",
        "region_code": "511300",
        "child": [
          {
            "id": 2361,
            "name": "顺庆区",
            "region_code": "511302",

          },
          {
            "id": 2362,
            "name": "高坪区",
            "region_code": "511303",

          },
          {
            "id": 2363,
            "name": "嘉陵区",
            "region_code": "511304",

          },
          {
            "id": 2364,
            "name": "南部县",
            "region_code": "511321",

          },
          {
            "id": 2365,
            "name": "营山县",
            "region_code": "511322",

          },
          {
            "id": 2366,
            "name": "蓬安县",
            "region_code": "511323",

          },
          {
            "id": 2367,
            "name": "仪陇县",
            "region_code": "511324",

          },
          {
            "id": 2368,
            "name": "西充县",
            "region_code": "511325",

          },
          {
            "id": 2369,
            "name": "阆中市",
            "region_code": "511381",

          }
        ]
      },
      {
        "id": 2370,
        "name": "眉山市",
        "region_code": "511400",
        "child": [
          {
            "id": 2371,
            "name": "东坡区",
            "region_code": "511402",

          },
          {
            "id": 2372,
            "name": "彭山区",
            "region_code": "511403",

          },
          {
            "id": 2373,
            "name": "仁寿县",
            "region_code": "511421",

          },
          {
            "id": 2374,
            "name": "洪雅县",
            "region_code": "511423",

          },
          {
            "id": 2375,
            "name": "丹棱县",
            "region_code": "511424",

          },
          {
            "id": 2376,
            "name": "青神县",
            "region_code": "511425",

          }
        ]
      },
      {
        "id": 2377,
        "name": "宜宾市",
        "region_code": "511500",
        "child": [
          {
            "id": 2378,
            "name": "翠屏区",
            "region_code": "511502",

          },
          {
            "id": 2379,
            "name": "南溪区",
            "region_code": "511503",

          },
          {
            "id": 2380,
            "name": "叙州区",
            "region_code": "511504",

          },
          {
            "id": 2381,
            "name": "江安县",
            "region_code": "511523",

          },
          {
            "id": 2382,
            "name": "长宁县",
            "region_code": "511524",

          },
          {
            "id": 2383,
            "name": "高县",
            "region_code": "511525",

          },
          {
            "id": 2384,
            "name": "珙县",
            "region_code": "511526",

          },
          {
            "id": 2385,
            "name": "筠连县",
            "region_code": "511527",

          },
          {
            "id": 2386,
            "name": "兴文县",
            "region_code": "511528",

          },
          {
            "id": 2387,
            "name": "屏山县",
            "region_code": "511529",

          }
        ]
      },
      {
        "id": 2388,
        "name": "广安市",
        "region_code": "511600",
        "child": [
          {
            "id": 2389,
            "name": "广安区",
            "region_code": "511602",

          },
          {
            "id": 2390,
            "name": "前锋区",
            "region_code": "511603",

          },
          {
            "id": 2391,
            "name": "岳池县",
            "region_code": "511621",

          },
          {
            "id": 2392,
            "name": "武胜县",
            "region_code": "511622",

          },
          {
            "id": 2393,
            "name": "邻水县",
            "region_code": "511623",

          },
          {
            "id": 2394,
            "name": "华蓥市",
            "region_code": "511681",

          }
        ]
      },
      {
        "id": 2395,
        "name": "达州市",
        "region_code": "511700",
        "child": [
          {
            "id": 2396,
            "name": "通川区",
            "region_code": "511702",

          },
          {
            "id": 2397,
            "name": "达川区",
            "region_code": "511703",

          },
          {
            "id": 2398,
            "name": "宣汉县",
            "region_code": "511722",

          },
          {
            "id": 2399,
            "name": "开江县",
            "region_code": "511723",

          },
          {
            "id": 2400,
            "name": "大竹县",
            "region_code": "511724",

          },
          {
            "id": 2401,
            "name": "渠县",
            "region_code": "511725",

          },
          {
            "id": 2402,
            "name": "万源市",
            "region_code": "511781",

          }
        ]
      },
      {
        "id": 2403,
        "name": "雅安市",
        "region_code": "511800",
        "child": [
          {
            "id": 2404,
            "name": "雨城区",
            "region_code": "511802",

          },
          {
            "id": 2405,
            "name": "名山区",
            "region_code": "511803",

          },
          {
            "id": 2406,
            "name": "荥经县",
            "region_code": "511822",

          },
          {
            "id": 2407,
            "name": "汉源县",
            "region_code": "511823",

          },
          {
            "id": 2408,
            "name": "石棉县",
            "region_code": "511824",

          },
          {
            "id": 2409,
            "name": "天全县",
            "region_code": "511825",

          },
          {
            "id": 2410,
            "name": "芦山县",
            "region_code": "511826",

          },
          {
            "id": 2411,
            "name": "宝兴县",
            "region_code": "511827",

          }
        ]
      },
      {
        "id": 2412,
        "name": "巴中市",
        "region_code": "511900",
        "child": [
          {
            "id": 2413,
            "name": "巴州区",
            "region_code": "511902",

          },
          {
            "id": 2414,
            "name": "恩阳区",
            "region_code": "511903",

          },
          {
            "id": 2415,
            "name": "通江县",
            "region_code": "511921",

          },
          {
            "id": 2416,
            "name": "南江县",
            "region_code": "511922",

          },
          {
            "id": 2417,
            "name": "平昌县",
            "region_code": "511923",

          }
        ]
      },
      {
        "id": 2418,
        "name": "资阳市",
        "region_code": "512000",
        "child": [
          {
            "id": 2419,
            "name": "雁江区",
            "region_code": "512002",

          },
          {
            "id": 2420,
            "name": "安岳县",
            "region_code": "512021",

          },
          {
            "id": 2421,
            "name": "乐至县",
            "region_code": "512022",

          }
        ]
      },
      {
        "id": 2422,
        "name": "阿坝藏族羌族自治州",
        "region_code": "513200",
        "child": [
          {
            "id": 2423,
            "name": "马尔康市",
            "region_code": "513201",

          },
          {
            "id": 2424,
            "name": "汶川县",
            "region_code": "513221",

          },
          {
            "id": 2425,
            "name": "理县",
            "region_code": "513222",

          },
          {
            "id": 2426,
            "name": "茂县",
            "region_code": "513223",

          },
          {
            "id": 2427,
            "name": "松潘县",
            "region_code": "513224",

          },
          {
            "id": 2428,
            "name": "九寨沟县",
            "region_code": "513225",

          },
          {
            "id": 2429,
            "name": "金川县",
            "region_code": "513226",

          },
          {
            "id": 2430,
            "name": "小金县",
            "region_code": "513227",

          },
          {
            "id": 2431,
            "name": "黑水县",
            "region_code": "513228",

          },
          {
            "id": 2432,
            "name": "壤塘县",
            "region_code": "513230",

          },
          {
            "id": 2433,
            "name": "阿坝县",
            "region_code": "513231",

          },
          {
            "id": 2434,
            "name": "若尔盖县",
            "region_code": "513232",

          },
          {
            "id": 2435,
            "name": "红原县",
            "region_code": "513233",

          }
        ]
      },
      {
        "id": 2436,
        "name": "甘孜藏族自治州",
        "region_code": "513300",
        "child": [
          {
            "id": 2437,
            "name": "康定市",
            "region_code": "513301",

          },
          {
            "id": 2438,
            "name": "泸定县",
            "region_code": "513322",

          },
          {
            "id": 2439,
            "name": "丹巴县",
            "region_code": "513323",

          },
          {
            "id": 2440,
            "name": "九龙县",
            "region_code": "513324",

          },
          {
            "id": 2441,
            "name": "雅江县",
            "region_code": "513325",

          },
          {
            "id": 2442,
            "name": "道孚县",
            "region_code": "513326",

          },
          {
            "id": 2443,
            "name": "炉霍县",
            "region_code": "513327",

          },
          {
            "id": 2444,
            "name": "甘孜县",
            "region_code": "513328",

          },
          {
            "id": 2445,
            "name": "新龙县",
            "region_code": "513329",

          },
          {
            "id": 2446,
            "name": "德格县",
            "region_code": "513330",

          },
          {
            "id": 2447,
            "name": "白玉县",
            "region_code": "513331",

          },
          {
            "id": 2448,
            "name": "石渠县",
            "region_code": "513332",

          },
          {
            "id": 2449,
            "name": "色达县",
            "region_code": "513333",

          },
          {
            "id": 2450,
            "name": "理塘县",
            "region_code": "513334",

          },
          {
            "id": 2451,
            "name": "巴塘县",
            "region_code": "513335",

          },
          {
            "id": 2452,
            "name": "乡城县",
            "region_code": "513336",

          },
          {
            "id": 2453,
            "name": "稻城县",
            "region_code": "513337",

          },
          {
            "id": 2454,
            "name": "得荣县",
            "region_code": "513338",

          }
        ]
      },
      {
        "id": 2455,
        "name": "凉山彝族自治州",
        "region_code": "513400",
        "child": [
          {
            "id": 2456,
            "name": "西昌市",
            "region_code": "513401",

          },
          {
            "id": 2457,
            "name": "木里藏族自治县",
            "region_code": "513422",

          },
          {
            "id": 2458,
            "name": "盐源县",
            "region_code": "513423",

          },
          {
            "id": 2459,
            "name": "德昌县",
            "region_code": "513424",

          },
          {
            "id": 2460,
            "name": "会理县",
            "region_code": "513425",

          },
          {
            "id": 2461,
            "name": "会东县",
            "region_code": "513426",

          },
          {
            "id": 2462,
            "name": "宁南县",
            "region_code": "513427",

          },
          {
            "id": 2463,
            "name": "普格县",
            "region_code": "513428",

          },
          {
            "id": 2464,
            "name": "布拖县",
            "region_code": "513429",

          },
          {
            "id": 2465,
            "name": "金阳县",
            "region_code": "513430",

          },
          {
            "id": 2466,
            "name": "昭觉县",
            "region_code": "513431",

          },
          {
            "id": 2467,
            "name": "喜德县",
            "region_code": "513432",

          },
          {
            "id": 2468,
            "name": "冕宁县",
            "region_code": "513433",

          },
          {
            "id": 2469,
            "name": "越西县",
            "region_code": "513434",

          },
          {
            "id": 2470,
            "name": "甘洛县",
            "region_code": "513435",

          },
          {
            "id": 2471,
            "name": "美姑县",
            "region_code": "513436",

          },
          {
            "id": 2472,
            "name": "雷波县",
            "region_code": "513437",

          }
        ]
      }
    ]
  },
  {
    "id": 2473,
    "name": "贵州省",
    "region_code": "520000",
    "child": [
      {
        "id": 2474,
        "name": "贵阳市",
        "region_code": "520100",
        "child": [
          {
            "id": 2475,
            "name": "南明区",
            "region_code": "520102",

          },
          {
            "id": 2476,
            "name": "云岩区",
            "region_code": "520103",

          },
          {
            "id": 2477,
            "name": "花溪区",
            "region_code": "520111",

          },
          {
            "id": 2478,
            "name": "乌当区",
            "region_code": "520112",

          },
          {
            "id": 2479,
            "name": "白云区",
            "region_code": "520113",

          },
          {
            "id": 2480,
            "name": "观山湖区",
            "region_code": "520115",

          },
          {
            "id": 2481,
            "name": "开阳县",
            "region_code": "520121",

          },
          {
            "id": 2482,
            "name": "息烽县",
            "region_code": "520122",

          },
          {
            "id": 2483,
            "name": "修文县",
            "region_code": "520123",

          },
          {
            "id": 2484,
            "name": "清镇市",
            "region_code": "520181",

          }
        ]
      },
      {
        "id": 2485,
        "name": "六盘水市",
        "region_code": "520200",
        "child": [
          {
            "id": 2486,
            "name": "钟山区",
            "region_code": "520201",

          },
          {
            "id": 2487,
            "name": "六枝特区",
            "region_code": "520203",

          },
          {
            "id": 2488,
            "name": "水城县",
            "region_code": "520221",

          },
          {
            "id": 2489,
            "name": "盘州市",
            "region_code": "520281",

          }
        ]
      },
      {
        "id": 2490,
        "name": "遵义市",
        "region_code": "520300",
        "child": [
          {
            "id": 2491,
            "name": "红花岗区",
            "region_code": "520302",

          },
          {
            "id": 2492,
            "name": "汇川区",
            "region_code": "520303",

          },
          {
            "id": 2493,
            "name": "播州区",
            "region_code": "520304",

          },
          {
            "id": 2494,
            "name": "桐梓县",
            "region_code": "520322",

          },
          {
            "id": 2495,
            "name": "绥阳县",
            "region_code": "520323",

          },
          {
            "id": 2496,
            "name": "正安县",
            "region_code": "520324",

          },
          {
            "id": 2497,
            "name": "道真仡佬族苗族自治县",
            "region_code": "520325",

          },
          {
            "id": 2498,
            "name": "务川仡佬族苗族自治县",
            "region_code": "520326",

          },
          {
            "id": 2499,
            "name": "凤冈县",
            "region_code": "520327",

          },
          {
            "id": 2500,
            "name": "湄潭县",
            "region_code": "520328",

          },
          {
            "id": 2501,
            "name": "余庆县",
            "region_code": "520329",

          },
          {
            "id": 2502,
            "name": "习水县",
            "region_code": "520330",

          },
          {
            "id": 2503,
            "name": "赤水市",
            "region_code": "520381",

          },
          {
            "id": 2504,
            "name": "仁怀市",
            "region_code": "520382",

          }
        ]
      },
      {
        "id": 2505,
        "name": "安顺市",
        "region_code": "520400",
        "child": [
          {
            "id": 2506,
            "name": "西秀区",
            "region_code": "520402",

          },
          {
            "id": 2507,
            "name": "平坝区",
            "region_code": "520403",

          },
          {
            "id": 2508,
            "name": "普定县",
            "region_code": "520422",

          },
          {
            "id": 2509,
            "name": "镇宁布依族苗族自治县",
            "region_code": "520423",

          },
          {
            "id": 2510,
            "name": "关岭布依族苗族自治县",
            "region_code": "520424",

          },
          {
            "id": 2511,
            "name": "紫云苗族布依族自治县",
            "region_code": "520425",

          }
        ]
      },
      {
        "id": 2512,
        "name": "毕节市",
        "region_code": "520500",
        "child": [
          {
            "id": 2513,
            "name": "七星关区",
            "region_code": "520502",

          },
          {
            "id": 2514,
            "name": "大方县",
            "region_code": "520521",

          },
          {
            "id": 2515,
            "name": "黔西县",
            "region_code": "520522",

          },
          {
            "id": 2516,
            "name": "金沙县",
            "region_code": "520523",

          },
          {
            "id": 2517,
            "name": "织金县",
            "region_code": "520524",

          },
          {
            "id": 2518,
            "name": "纳雍县",
            "region_code": "520525",

          },
          {
            "id": 2519,
            "name": "威宁彝族回族苗族自治县",
            "region_code": "520526",

          },
          {
            "id": 2520,
            "name": "赫章县",
            "region_code": "520527",

          }
        ]
      },
      {
        "id": 2521,
        "name": "铜仁市",
        "region_code": "520600",
        "child": [
          {
            "id": 2522,
            "name": "碧江区",
            "region_code": "520602",

          },
          {
            "id": 2523,
            "name": "万山区",
            "region_code": "520603",

          },
          {
            "id": 2524,
            "name": "江口县",
            "region_code": "520621",

          },
          {
            "id": 2525,
            "name": "玉屏侗族自治县",
            "region_code": "520622",

          },
          {
            "id": 2526,
            "name": "石阡县",
            "region_code": "520623",

          },
          {
            "id": 2527,
            "name": "思南县",
            "region_code": "520624",

          },
          {
            "id": 2528,
            "name": "印江土家族苗族自治县",
            "region_code": "520625",

          },
          {
            "id": 2529,
            "name": "德江县",
            "region_code": "520626",

          },
          {
            "id": 2530,
            "name": "沿河土家族自治县",
            "region_code": "520627",

          },
          {
            "id": 2531,
            "name": "松桃苗族自治县",
            "region_code": "520628",

          }
        ]
      },
      {
        "id": 2532,
        "name": "黔西南布依族苗族自治州",
        "region_code": "522300",
        "child": [
          {
            "id": 2533,
            "name": "兴义市",
            "region_code": "522301",

          },
          {
            "id": 2534,
            "name": "兴仁市",
            "region_code": "522302",

          },
          {
            "id": 2535,
            "name": "普安县",
            "region_code": "522323",

          },
          {
            "id": 2536,
            "name": "晴隆县",
            "region_code": "522324",

          },
          {
            "id": 2537,
            "name": "贞丰县",
            "region_code": "522325",

          },
          {
            "id": 2538,
            "name": "望谟县",
            "region_code": "522326",

          },
          {
            "id": 2539,
            "name": "册亨县",
            "region_code": "522327",

          },
          {
            "id": 2540,
            "name": "安龙县",
            "region_code": "522328",

          }
        ]
      },
      {
        "id": 2541,
        "name": "黔东南苗族侗族自治州",
        "region_code": "522600",
        "child": [
          {
            "id": 2542,
            "name": "凯里市",
            "region_code": "522601",

          },
          {
            "id": 2543,
            "name": "黄平县",
            "region_code": "522622",

          },
          {
            "id": 2544,
            "name": "施秉县",
            "region_code": "522623",

          },
          {
            "id": 2545,
            "name": "三穗县",
            "region_code": "522624",

          },
          {
            "id": 2546,
            "name": "镇远县",
            "region_code": "522625",

          },
          {
            "id": 2547,
            "name": "岑巩县",
            "region_code": "522626",

          },
          {
            "id": 2548,
            "name": "天柱县",
            "region_code": "522627",

          },
          {
            "id": 2549,
            "name": "锦屏县",
            "region_code": "522628",

          },
          {
            "id": 2550,
            "name": "剑河县",
            "region_code": "522629",

          },
          {
            "id": 2551,
            "name": "台江县",
            "region_code": "522630",

          },
          {
            "id": 2552,
            "name": "黎平县",
            "region_code": "522631",

          },
          {
            "id": 2553,
            "name": "榕江县",
            "region_code": "522632",

          },
          {
            "id": 2554,
            "name": "从江县",
            "region_code": "522633",

          },
          {
            "id": 2555,
            "name": "雷山县",
            "region_code": "522634",

          },
          {
            "id": 2556,
            "name": "麻江县",
            "region_code": "522635",

          },
          {
            "id": 2557,
            "name": "丹寨县",
            "region_code": "522636",

          }
        ]
      },
      {
        "id": 2558,
        "name": "黔南布依族苗族自治州",
        "region_code": "522700",
        "child": [
          {
            "id": 2559,
            "name": "都匀市",
            "region_code": "522701",

          },
          {
            "id": 2560,
            "name": "福泉市",
            "region_code": "522702",

          },
          {
            "id": 2561,
            "name": "荔波县",
            "region_code": "522722",

          },
          {
            "id": 2562,
            "name": "贵定县",
            "region_code": "522723",

          },
          {
            "id": 2563,
            "name": "瓮安县",
            "region_code": "522725",

          },
          {
            "id": 2564,
            "name": "独山县",
            "region_code": "522726",

          },
          {
            "id": 2565,
            "name": "平塘县",
            "region_code": "522727",

          },
          {
            "id": 2566,
            "name": "罗甸县",
            "region_code": "522728",

          },
          {
            "id": 2567,
            "name": "长顺县",
            "region_code": "522729",

          },
          {
            "id": 2568,
            "name": "龙里县",
            "region_code": "522730",

          },
          {
            "id": 2569,
            "name": "惠水县",
            "region_code": "522731",

          },
          {
            "id": 2570,
            "name": "三都水族自治县",
            "region_code": "522732",

          }
        ]
      }
    ]
  },
  {
    "id": 2571,
    "name": "云南省",
    "region_code": "530000",
    "child": [
      {
        "id": 2572,
        "name": "昆明市",
        "region_code": "530100",
        "child": [
          {
            "id": 2573,
            "name": "五华区",
            "region_code": "530102",

          },
          {
            "id": 2574,
            "name": "盘龙区",
            "region_code": "530103",

          },
          {
            "id": 2575,
            "name": "官渡区",
            "region_code": "530111",

          },
          {
            "id": 2576,
            "name": "西山区",
            "region_code": "530112",

          },
          {
            "id": 2577,
            "name": "东川区",
            "region_code": "530113",

          },
          {
            "id": 2578,
            "name": "呈贡区",
            "region_code": "530114",

          },
          {
            "id": 2579,
            "name": "晋宁区",
            "region_code": "530115",

          },
          {
            "id": 2580,
            "name": "富民县",
            "region_code": "530124",

          },
          {
            "id": 2581,
            "name": "宜良县",
            "region_code": "530125",

          },
          {
            "id": 2582,
            "name": "石林彝族自治县",
            "region_code": "530126",

          },
          {
            "id": 2583,
            "name": "嵩明县",
            "region_code": "530127",

          },
          {
            "id": 2584,
            "name": "禄劝彝族苗族自治县",
            "region_code": "530128",

          },
          {
            "id": 2585,
            "name": "寻甸回族彝族自治县",
            "region_code": "530129",

          },
          {
            "id": 2586,
            "name": "安宁市",
            "region_code": "530181",

          }
        ]
      },
      {
        "id": 2587,
        "name": "曲靖市",
        "region_code": "530300",
        "child": [
          {
            "id": 2588,
            "name": "麒麟区",
            "region_code": "530302",

          },
          {
            "id": 2589,
            "name": "沾益区",
            "region_code": "530303",

          },
          {
            "id": 2590,
            "name": "马龙区",
            "region_code": "530304",

          },
          {
            "id": 2591,
            "name": "陆良县",
            "region_code": "530322",

          },
          {
            "id": 2592,
            "name": "师宗县",
            "region_code": "530323",

          },
          {
            "id": 2593,
            "name": "罗平县",
            "region_code": "530324",

          },
          {
            "id": 2594,
            "name": "富源县",
            "region_code": "530325",

          },
          {
            "id": 2595,
            "name": "会泽县",
            "region_code": "530326",

          },
          {
            "id": 2596,
            "name": "宣威市",
            "region_code": "530381",

          }
        ]
      },
      {
        "id": 2597,
        "name": "玉溪市",
        "region_code": "530400",
        "child": [
          {
            "id": 2598,
            "name": "红塔区",
            "region_code": "530402",

          },
          {
            "id": 2599,
            "name": "江川区",
            "region_code": "530403",

          },
          {
            "id": 2600,
            "name": "通海县",
            "region_code": "530423",

          },
          {
            "id": 2601,
            "name": "华宁县",
            "region_code": "530424",

          },
          {
            "id": 2602,
            "name": "易门县",
            "region_code": "530425",

          },
          {
            "id": 2603,
            "name": "峨山彝族自治县",
            "region_code": "530426",

          },
          {
            "id": 2604,
            "name": "新平彝族傣族自治县",
            "region_code": "530427",

          },
          {
            "id": 2605,
            "name": "元江哈尼族彝族傣族自治县",
            "region_code": "530428",

          },
          {
            "id": 2606,
            "name": "澄江市",
            "region_code": "530481",

          }
        ]
      },
      {
        "id": 2607,
        "name": "保山市",
        "region_code": "530500",
        "child": [
          {
            "id": 2608,
            "name": "隆阳区",
            "region_code": "530502",

          },
          {
            "id": 2609,
            "name": "施甸县",
            "region_code": "530521",

          },
          {
            "id": 2610,
            "name": "龙陵县",
            "region_code": "530523",

          },
          {
            "id": 2611,
            "name": "昌宁县",
            "region_code": "530524",

          },
          {
            "id": 2612,
            "name": "腾冲市",
            "region_code": "530581",

          }
        ]
      },
      {
        "id": 2613,
        "name": "昭通市",
        "region_code": "530600",
        "child": [
          {
            "id": 2614,
            "name": "昭阳区",
            "region_code": "530602",

          },
          {
            "id": 2615,
            "name": "鲁甸县",
            "region_code": "530621",

          },
          {
            "id": 2616,
            "name": "巧家县",
            "region_code": "530622",

          },
          {
            "id": 2617,
            "name": "盐津县",
            "region_code": "530623",

          },
          {
            "id": 2618,
            "name": "大关县",
            "region_code": "530624",

          },
          {
            "id": 2619,
            "name": "永善县",
            "region_code": "530625",

          },
          {
            "id": 2620,
            "name": "绥江县",
            "region_code": "530626",

          },
          {
            "id": 2621,
            "name": "镇雄县",
            "region_code": "530627",

          },
          {
            "id": 2622,
            "name": "彝良县",
            "region_code": "530628",

          },
          {
            "id": 2623,
            "name": "威信县",
            "region_code": "530629",

          },
          {
            "id": 2624,
            "name": "水富市",
            "region_code": "530681",

          }
        ]
      },
      {
        "id": 2625,
        "name": "丽江市",
        "region_code": "530700",
        "child": [
          {
            "id": 2626,
            "name": "古城区",
            "region_code": "530702",

          },
          {
            "id": 2627,
            "name": "玉龙纳西族自治县",
            "region_code": "530721",

          },
          {
            "id": 2628,
            "name": "永胜县",
            "region_code": "530722",

          },
          {
            "id": 2629,
            "name": "华坪县",
            "region_code": "530723",

          },
          {
            "id": 2630,
            "name": "宁蒗彝族自治县",
            "region_code": "530724",

          }
        ]
      },
      {
        "id": 2631,
        "name": "普洱市",
        "region_code": "530800",
        "child": [
          {
            "id": 2632,
            "name": "思茅区",
            "region_code": "530802",

          },
          {
            "id": 2633,
            "name": "宁洱哈尼族彝族自治县",
            "region_code": "530821",

          },
          {
            "id": 2634,
            "name": "墨江哈尼族自治县",
            "region_code": "530822",

          },
          {
            "id": 2635,
            "name": "景东彝族自治县",
            "region_code": "530823",

          },
          {
            "id": 2636,
            "name": "景谷傣族彝族自治县",
            "region_code": "530824",

          },
          {
            "id": 2637,
            "name": "镇沅彝族哈尼族拉祜族自治县",
            "region_code": "530825",

          },
          {
            "id": 2638,
            "name": "江城哈尼族彝族自治县",
            "region_code": "530826",

          },
          {
            "id": 2639,
            "name": "孟连傣族拉祜族佤族自治县",
            "region_code": "530827",

          },
          {
            "id": 2640,
            "name": "澜沧拉祜族自治县",
            "region_code": "530828",

          },
          {
            "id": 2641,
            "name": "西盟佤族自治县",
            "region_code": "530829",

          }
        ]
      },
      {
        "id": 2642,
        "name": "临沧市",
        "region_code": "530900",
        "child": [
          {
            "id": 2643,
            "name": "临翔区",
            "region_code": "530902",

          },
          {
            "id": 2644,
            "name": "凤庆县",
            "region_code": "530921",

          },
          {
            "id": 2645,
            "name": "云县",
            "region_code": "530922",

          },
          {
            "id": 2646,
            "name": "永德县",
            "region_code": "530923",

          },
          {
            "id": 2647,
            "name": "镇康县",
            "region_code": "530924",

          },
          {
            "id": 2648,
            "name": "双江拉祜族佤族布朗族傣族自治县",
            "region_code": "530925",

          },
          {
            "id": 2649,
            "name": "耿马傣族佤族自治县",
            "region_code": "530926",

          },
          {
            "id": 2650,
            "name": "沧源佤族自治县",
            "region_code": "530927",

          }
        ]
      },
      {
        "id": 2651,
        "name": "楚雄彝族自治州",
        "region_code": "532300",
        "child": [
          {
            "id": 2652,
            "name": "楚雄市",
            "region_code": "532301",

          },
          {
            "id": 2653,
            "name": "双柏县",
            "region_code": "532322",

          },
          {
            "id": 2654,
            "name": "牟定县",
            "region_code": "532323",

          },
          {
            "id": 2655,
            "name": "南华县",
            "region_code": "532324",

          },
          {
            "id": 2656,
            "name": "姚安县",
            "region_code": "532325",

          },
          {
            "id": 2657,
            "name": "大姚县",
            "region_code": "532326",

          },
          {
            "id": 2658,
            "name": "永仁县",
            "region_code": "532327",

          },
          {
            "id": 2659,
            "name": "元谋县",
            "region_code": "532328",

          },
          {
            "id": 2660,
            "name": "武定县",
            "region_code": "532329",

          },
          {
            "id": 2661,
            "name": "禄丰县",
            "region_code": "532331",

          }
        ]
      },
      {
        "id": 2662,
        "name": "红河哈尼族彝族自治州",
        "region_code": "532500",
        "child": [
          {
            "id": 2663,
            "name": "个旧市",
            "region_code": "532501",

          },
          {
            "id": 2664,
            "name": "开远市",
            "region_code": "532502",

          },
          {
            "id": 2665,
            "name": "蒙自市",
            "region_code": "532503",

          },
          {
            "id": 2666,
            "name": "弥勒市",
            "region_code": "532504",

          },
          {
            "id": 2667,
            "name": "屏边苗族自治县",
            "region_code": "532523",

          },
          {
            "id": 2668,
            "name": "建水县",
            "region_code": "532524",

          },
          {
            "id": 2669,
            "name": "石屏县",
            "region_code": "532525",

          },
          {
            "id": 2670,
            "name": "泸西县",
            "region_code": "532527",

          },
          {
            "id": 2671,
            "name": "元阳县",
            "region_code": "532528",

          },
          {
            "id": 2672,
            "name": "红河县",
            "region_code": "532529",

          },
          {
            "id": 2673,
            "name": "金平苗族瑶族傣族自治县",
            "region_code": "532530",

          },
          {
            "id": 2674,
            "name": "绿春县",
            "region_code": "532531",

          },
          {
            "id": 2675,
            "name": "河口瑶族自治县",
            "region_code": "532532",

          }
        ]
      },
      {
        "id": 2676,
        "name": "文山壮族苗族自治州",
        "region_code": "532600",
        "child": [
          {
            "id": 2677,
            "name": "文山市",
            "region_code": "532601",

          },
          {
            "id": 2678,
            "name": "砚山县",
            "region_code": "532622",

          },
          {
            "id": 2679,
            "name": "西畴县",
            "region_code": "532623",

          },
          {
            "id": 2680,
            "name": "麻栗坡县",
            "region_code": "532624",

          },
          {
            "id": 2681,
            "name": "马关县",
            "region_code": "532625",

          },
          {
            "id": 2682,
            "name": "丘北县",
            "region_code": "532626",

          },
          {
            "id": 2683,
            "name": "广南县",
            "region_code": "532627",

          },
          {
            "id": 2684,
            "name": "富宁县",
            "region_code": "532628",

          }
        ]
      },
      {
        "id": 2685,
        "name": "西双版纳傣族自治州",
        "region_code": "532800",
        "child": [
          {
            "id": 2686,
            "name": "景洪市",
            "region_code": "532801",

          },
          {
            "id": 2687,
            "name": "勐海县",
            "region_code": "532822",

          },
          {
            "id": 2688,
            "name": "勐腊县",
            "region_code": "532823",

          }
        ]
      },
      {
        "id": 2689,
        "name": "大理白族自治州",
        "region_code": "532900",
        "child": [
          {
            "id": 2690,
            "name": "大理市",
            "region_code": "532901",

          },
          {
            "id": 2691,
            "name": "漾濞彝族自治县",
            "region_code": "532922",

          },
          {
            "id": 2692,
            "name": "祥云县",
            "region_code": "532923",

          },
          {
            "id": 2693,
            "name": "宾川县",
            "region_code": "532924",

          },
          {
            "id": 2694,
            "name": "弥渡县",
            "region_code": "532925",

          },
          {
            "id": 2695,
            "name": "南涧彝族自治县",
            "region_code": "532926",

          },
          {
            "id": 2696,
            "name": "巍山彝族回族自治县",
            "region_code": "532927",

          },
          {
            "id": 2697,
            "name": "永平县",
            "region_code": "532928",

          },
          {
            "id": 2698,
            "name": "云龙县",
            "region_code": "532929",

          },
          {
            "id": 2699,
            "name": "洱源县",
            "region_code": "532930",

          },
          {
            "id": 2700,
            "name": "剑川县",
            "region_code": "532931",

          },
          {
            "id": 2701,
            "name": "鹤庆县",
            "region_code": "532932",

          }
        ]
      },
      {
        "id": 2702,
        "name": "德宏傣族景颇族自治州",
        "region_code": "533100",
        "child": [
          {
            "id": 2703,
            "name": "瑞丽市",
            "region_code": "533102",

          },
          {
            "id": 2704,
            "name": "芒市",
            "region_code": "533103",

          },
          {
            "id": 2705,
            "name": "梁河县",
            "region_code": "533122",

          },
          {
            "id": 2706,
            "name": "盈江县",
            "region_code": "533123",

          },
          {
            "id": 2707,
            "name": "陇川县",
            "region_code": "533124",

          }
        ]
      },
      {
        "id": 2708,
        "name": "怒江傈僳族自治州",
        "region_code": "533300",
        "child": [
          {
            "id": 2709,
            "name": "泸水市",
            "region_code": "533301",

          },
          {
            "id": 2710,
            "name": "福贡县",
            "region_code": "533323",

          },
          {
            "id": 2711,
            "name": "贡山独龙族怒族自治县",
            "region_code": "533324",

          },
          {
            "id": 2712,
            "name": "兰坪白族普米族自治县",
            "region_code": "533325",

          }
        ]
      },
      {
        "id": 2713,
        "name": "迪庆藏族自治州",
        "region_code": "533400",
        "child": [
          {
            "id": 2714,
            "name": "香格里拉市",
            "region_code": "533401",

          },
          {
            "id": 2715,
            "name": "德钦县",
            "region_code": "533422",

          },
          {
            "id": 2716,
            "name": "维西傈僳族自治县",
            "region_code": "533423",

          }
        ]
      }
    ]
  },
  {
    "id": 2717,
    "name": "西藏自治区",
    "region_code": "540000",
    "child": [
      {
        "id": 2718,
        "name": "拉萨市",
        "region_code": "540100",
        "child": [
          {
            "id": 2719,
            "name": "城关区",
            "region_code": "540102",

          },
          {
            "id": 2720,
            "name": "堆龙德庆区",
            "region_code": "540103",

          },
          {
            "id": 2721,
            "name": "达孜区",
            "region_code": "540104",

          },
          {
            "id": 2722,
            "name": "林周县",
            "region_code": "540121",

          },
          {
            "id": 2723,
            "name": "当雄县",
            "region_code": "540122",

          },
          {
            "id": 2724,
            "name": "尼木县",
            "region_code": "540123",

          },
          {
            "id": 2725,
            "name": "曲水县",
            "region_code": "540124",

          },
          {
            "id": 2726,
            "name": "墨竹工卡县",
            "region_code": "540127",

          }
        ]
      },
      {
        "id": 2727,
        "name": "日喀则市",
        "region_code": "540200",
        "child": [
          {
            "id": 2728,
            "name": "桑珠孜区",
            "region_code": "540202",

          },
          {
            "id": 2729,
            "name": "南木林县",
            "region_code": "540221",

          },
          {
            "id": 2730,
            "name": "江孜县",
            "region_code": "540222",

          },
          {
            "id": 2731,
            "name": "定日县",
            "region_code": "540223",

          },
          {
            "id": 2732,
            "name": "萨迦县",
            "region_code": "540224",

          },
          {
            "id": 2733,
            "name": "拉孜县",
            "region_code": "540225",

          },
          {
            "id": 2734,
            "name": "昂仁县",
            "region_code": "540226",

          },
          {
            "id": 2735,
            "name": "谢通门县",
            "region_code": "540227",

          },
          {
            "id": 2736,
            "name": "白朗县",
            "region_code": "540228",

          },
          {
            "id": 2737,
            "name": "仁布县",
            "region_code": "540229",

          },
          {
            "id": 2738,
            "name": "康马县",
            "region_code": "540230",

          },
          {
            "id": 2739,
            "name": "定结县",
            "region_code": "540231",

          },
          {
            "id": 2740,
            "name": "仲巴县",
            "region_code": "540232",

          },
          {
            "id": 2741,
            "name": "亚东县",
            "region_code": "540233",

          },
          {
            "id": 2742,
            "name": "吉隆县",
            "region_code": "540234",

          },
          {
            "id": 2743,
            "name": "聂拉木县",
            "region_code": "540235",

          },
          {
            "id": 2744,
            "name": "萨嘎县",
            "region_code": "540236",

          },
          {
            "id": 2745,
            "name": "岗巴县",
            "region_code": "540237",

          }
        ]
      },
      {
        "id": 2746,
        "name": "昌都市",
        "region_code": "540300",
        "child": [
          {
            "id": 2747,
            "name": "卡若区",
            "region_code": "540302",

          },
          {
            "id": 2748,
            "name": "江达县",
            "region_code": "540321",

          },
          {
            "id": 2749,
            "name": "贡觉县",
            "region_code": "540322",

          },
          {
            "id": 2750,
            "name": "类乌齐县",
            "region_code": "540323",

          },
          {
            "id": 2751,
            "name": "丁青县",
            "region_code": "540324",

          },
          {
            "id": 2752,
            "name": "察雅县",
            "region_code": "540325",

          },
          {
            "id": 2753,
            "name": "八宿县",
            "region_code": "540326",

          },
          {
            "id": 2754,
            "name": "左贡县",
            "region_code": "540327",

          },
          {
            "id": 2755,
            "name": "芒康县",
            "region_code": "540328",

          },
          {
            "id": 2756,
            "name": "洛隆县",
            "region_code": "540329",

          },
          {
            "id": 2757,
            "name": "边坝县",
            "region_code": "540330",

          }
        ]
      },
      {
        "id": 2758,
        "name": "林芝市",
        "region_code": "540400",
        "child": [
          {
            "id": 2759,
            "name": "巴宜区",
            "region_code": "540402",

          },
          {
            "id": 2760,
            "name": "工布江达县",
            "region_code": "540421",

          },
          {
            "id": 2761,
            "name": "米林县",
            "region_code": "540422",

          },
          {
            "id": 2762,
            "name": "墨脱县",
            "region_code": "540423",

          },
          {
            "id": 2763,
            "name": "波密县",
            "region_code": "540424",

          },
          {
            "id": 2764,
            "name": "察隅县",
            "region_code": "540425",

          },
          {
            "id": 2765,
            "name": "朗县",
            "region_code": "540426",

          }
        ]
      },
      {
        "id": 2766,
        "name": "山南市",
        "region_code": "540500",
        "child": [
          {
            "id": 2767,
            "name": "乃东区",
            "region_code": "540502",

          },
          {
            "id": 2768,
            "name": "扎囊县",
            "region_code": "540521",

          },
          {
            "id": 2769,
            "name": "贡嘎县",
            "region_code": "540522",

          },
          {
            "id": 2770,
            "name": "桑日县",
            "region_code": "540523",

          },
          {
            "id": 2771,
            "name": "琼结县",
            "region_code": "540524",

          },
          {
            "id": 2772,
            "name": "曲松县",
            "region_code": "540525",

          },
          {
            "id": 2773,
            "name": "措美县",
            "region_code": "540526",

          },
          {
            "id": 2774,
            "name": "洛扎县",
            "region_code": "540527",

          },
          {
            "id": 2775,
            "name": "加查县",
            "region_code": "540528",

          },
          {
            "id": 2776,
            "name": "隆子县",
            "region_code": "540529",

          },
          {
            "id": 2777,
            "name": "错那县",
            "region_code": "540530",

          },
          {
            "id": 2778,
            "name": "浪卡子县",
            "region_code": "540531",

          }
        ]
      },
      {
        "id": 2779,
        "name": "那曲市",
        "region_code": "540600",
        "child": [
          {
            "id": 2780,
            "name": "色尼区",
            "region_code": "540602",

          },
          {
            "id": 2781,
            "name": "嘉黎县",
            "region_code": "540621",

          },
          {
            "id": 2782,
            "name": "比如县",
            "region_code": "540622",

          },
          {
            "id": 2783,
            "name": "聂荣县",
            "region_code": "540623",

          },
          {
            "id": 2784,
            "name": "安多县",
            "region_code": "540624",

          },
          {
            "id": 2785,
            "name": "申扎县",
            "region_code": "540625",

          },
          {
            "id": 2786,
            "name": "索县",
            "region_code": "540626",

          },
          {
            "id": 2787,
            "name": "班戈县",
            "region_code": "540627",

          },
          {
            "id": 2788,
            "name": "巴青县",
            "region_code": "540628",

          },
          {
            "id": 2789,
            "name": "尼玛县",
            "region_code": "540629",

          },
          {
            "id": 2790,
            "name": "双湖县",
            "region_code": "540630",

          }
        ]
      },
      {
        "id": 2791,
        "name": "阿里地区",
        "region_code": "542500",
        "child": [
          {
            "id": 2792,
            "name": "普兰县",
            "region_code": "542521",

          },
          {
            "id": 2793,
            "name": "札达县",
            "region_code": "542522",

          },
          {
            "id": 2794,
            "name": "噶尔县",
            "region_code": "542523",

          },
          {
            "id": 2795,
            "name": "日土县",
            "region_code": "542524",

          },
          {
            "id": 2796,
            "name": "革吉县",
            "region_code": "542525",

          },
          {
            "id": 2797,
            "name": "改则县",
            "region_code": "542526",

          },
          {
            "id": 2798,
            "name": "措勤县",
            "region_code": "542527",

          }
        ]
      }
    ]
  },
  {
    "id": 2799,
    "name": "陕西省",
    "region_code": "610000",
    "child": [
      {
        "id": 2800,
        "name": "西安市",
        "region_code": "610100",
        "child": [
          {
            "id": 2801,
            "name": "新城区",
            "region_code": "610102",

          },
          {
            "id": 2802,
            "name": "碑林区",
            "region_code": "610103",

          },
          {
            "id": 2803,
            "name": "莲湖区",
            "region_code": "610104",

          },
          {
            "id": 2804,
            "name": "灞桥区",
            "region_code": "610111",

          },
          {
            "id": 2805,
            "name": "未央区",
            "region_code": "610112",

          },
          {
            "id": 2806,
            "name": "雁塔区",
            "region_code": "610113",

          },
          {
            "id": 2807,
            "name": "阎良区",
            "region_code": "610114",

          },
          {
            "id": 2808,
            "name": "临潼区",
            "region_code": "610115",

          },
          {
            "id": 2809,
            "name": "长安区",
            "region_code": "610116",

          },
          {
            "id": 2810,
            "name": "高陵区",
            "region_code": "610117",

          },
          {
            "id": 2811,
            "name": "鄠邑区",
            "region_code": "610118",

          },
          {
            "id": 2812,
            "name": "蓝田县",
            "region_code": "610122",

          },
          {
            "id": 2813,
            "name": "周至县",
            "region_code": "610124",

          }
        ]
      },
      {
        "id": 2814,
        "name": "铜川市",
        "region_code": "610200",
        "child": [
          {
            "id": 2815,
            "name": "王益区",
            "region_code": "610202",

          },
          {
            "id": 2816,
            "name": "印台区",
            "region_code": "610203",

          },
          {
            "id": 2817,
            "name": "耀州区",
            "region_code": "610204",

          },
          {
            "id": 2818,
            "name": "宜君县",
            "region_code": "610222",

          }
        ]
      },
      {
        "id": 2819,
        "name": "宝鸡市",
        "region_code": "610300",
        "child": [
          {
            "id": 2820,
            "name": "渭滨区",
            "region_code": "610302",

          },
          {
            "id": 2821,
            "name": "金台区",
            "region_code": "610303",

          },
          {
            "id": 2822,
            "name": "陈仓区",
            "region_code": "610304",

          },
          {
            "id": 2823,
            "name": "凤翔县",
            "region_code": "610322",

          },
          {
            "id": 2824,
            "name": "岐山县",
            "region_code": "610323",

          },
          {
            "id": 2825,
            "name": "扶风县",
            "region_code": "610324",

          },
          {
            "id": 2826,
            "name": "眉县",
            "region_code": "610326",

          },
          {
            "id": 2827,
            "name": "陇县",
            "region_code": "610327",

          },
          {
            "id": 2828,
            "name": "千阳县",
            "region_code": "610328",

          },
          {
            "id": 2829,
            "name": "麟游县",
            "region_code": "610329",

          },
          {
            "id": 2830,
            "name": "凤县",
            "region_code": "610330",

          },
          {
            "id": 2831,
            "name": "太白县",
            "region_code": "610331",

          }
        ]
      },
      {
        "id": 2832,
        "name": "咸阳市",
        "region_code": "610400",
        "child": [
          {
            "id": 2833,
            "name": "秦都区",
            "region_code": "610402",

          },
          {
            "id": 2834,
            "name": "杨陵区",
            "region_code": "610403",

          },
          {
            "id": 2835,
            "name": "渭城区",
            "region_code": "610404",

          },
          {
            "id": 2836,
            "name": "三原县",
            "region_code": "610422",

          },
          {
            "id": 2837,
            "name": "泾阳县",
            "region_code": "610423",

          },
          {
            "id": 2838,
            "name": "乾县",
            "region_code": "610424",

          },
          {
            "id": 2839,
            "name": "礼泉县",
            "region_code": "610425",

          },
          {
            "id": 2840,
            "name": "永寿县",
            "region_code": "610426",

          },
          {
            "id": 2841,
            "name": "长武县",
            "region_code": "610428",

          },
          {
            "id": 2842,
            "name": "旬邑县",
            "region_code": "610429",

          },
          {
            "id": 2843,
            "name": "淳化县",
            "region_code": "610430",

          },
          {
            "id": 2844,
            "name": "武功县",
            "region_code": "610431",

          },
          {
            "id": 2845,
            "name": "兴平市",
            "region_code": "610481",

          },
          {
            "id": 2846,
            "name": "彬州市",
            "region_code": "610482",

          }
        ]
      },
      {
        "id": 2847,
        "name": "渭南市",
        "region_code": "610500",
        "child": [
          {
            "id": 2848,
            "name": "临渭区",
            "region_code": "610502",

          },
          {
            "id": 2849,
            "name": "华州区",
            "region_code": "610503",

          },
          {
            "id": 2850,
            "name": "潼关县",
            "region_code": "610522",

          },
          {
            "id": 2851,
            "name": "大荔县",
            "region_code": "610523",

          },
          {
            "id": 2852,
            "name": "合阳县",
            "region_code": "610524",

          },
          {
            "id": 2853,
            "name": "澄城县",
            "region_code": "610525",

          },
          {
            "id": 2854,
            "name": "蒲城县",
            "region_code": "610526",

          },
          {
            "id": 2855,
            "name": "白水县",
            "region_code": "610527",

          },
          {
            "id": 2856,
            "name": "富平县",
            "region_code": "610528",

          },
          {
            "id": 2857,
            "name": "韩城市",
            "region_code": "610581",

          },
          {
            "id": 2858,
            "name": "华阴市",
            "region_code": "610582",

          }
        ]
      },
      {
        "id": 2859,
        "name": "延安市",
        "region_code": "610600",
        "child": [
          {
            "id": 2860,
            "name": "宝塔区",
            "region_code": "610602",

          },
          {
            "id": 2861,
            "name": "安塞区",
            "region_code": "610603",

          },
          {
            "id": 2862,
            "name": "延长县",
            "region_code": "610621",

          },
          {
            "id": 2863,
            "name": "延川县",
            "region_code": "610622",

          },
          {
            "id": 2864,
            "name": "志丹县",
            "region_code": "610625",

          },
          {
            "id": 2865,
            "name": "吴起县",
            "region_code": "610626",

          },
          {
            "id": 2866,
            "name": "甘泉县",
            "region_code": "610627",

          },
          {
            "id": 2867,
            "name": "富县",
            "region_code": "610628",

          },
          {
            "id": 2868,
            "name": "洛川县",
            "region_code": "610629",

          },
          {
            "id": 2869,
            "name": "宜川县",
            "region_code": "610630",

          },
          {
            "id": 2870,
            "name": "黄龙县",
            "region_code": "610631",

          },
          {
            "id": 2871,
            "name": "黄陵县",
            "region_code": "610632",

          },
          {
            "id": 2872,
            "name": "子长市",
            "region_code": "610681",

          }
        ]
      },
      {
        "id": 2873,
        "name": "汉中市",
        "region_code": "610700",
        "child": [
          {
            "id": 2874,
            "name": "汉台区",
            "region_code": "610702",

          },
          {
            "id": 2875,
            "name": "南郑区",
            "region_code": "610703",

          },
          {
            "id": 2876,
            "name": "城固县",
            "region_code": "610722",

          },
          {
            "id": 2877,
            "name": "洋县",
            "region_code": "610723",

          },
          {
            "id": 2878,
            "name": "西乡县",
            "region_code": "610724",

          },
          {
            "id": 2879,
            "name": "勉县",
            "region_code": "610725",

          },
          {
            "id": 2880,
            "name": "宁强县",
            "region_code": "610726",

          },
          {
            "id": 2881,
            "name": "略阳县",
            "region_code": "610727",

          },
          {
            "id": 2882,
            "name": "镇巴县",
            "region_code": "610728",

          },
          {
            "id": 2883,
            "name": "留坝县",
            "region_code": "610729",

          },
          {
            "id": 2884,
            "name": "佛坪县",
            "region_code": "610730",

          }
        ]
      },
      {
        "id": 2885,
        "name": "榆林市",
        "region_code": "610800",
        "child": [
          {
            "id": 2886,
            "name": "榆阳区",
            "region_code": "610802",

          },
          {
            "id": 2887,
            "name": "横山区",
            "region_code": "610803",

          },
          {
            "id": 2888,
            "name": "府谷县",
            "region_code": "610822",

          },
          {
            "id": 2889,
            "name": "靖边县",
            "region_code": "610824",

          },
          {
            "id": 2890,
            "name": "定边县",
            "region_code": "610825",

          },
          {
            "id": 2891,
            "name": "绥德县",
            "region_code": "610826",

          },
          {
            "id": 2892,
            "name": "米脂县",
            "region_code": "610827",

          },
          {
            "id": 2893,
            "name": "佳县",
            "region_code": "610828",

          },
          {
            "id": 2894,
            "name": "吴堡县",
            "region_code": "610829",

          },
          {
            "id": 2895,
            "name": "清涧县",
            "region_code": "610830",

          },
          {
            "id": 2896,
            "name": "子洲县",
            "region_code": "610831",

          },
          {
            "id": 2897,
            "name": "神木市",
            "region_code": "610881",

          }
        ]
      },
      {
        "id": 2898,
        "name": "安康市",
        "region_code": "610900",
        "child": [
          {
            "id": 2899,
            "name": "汉滨区",
            "region_code": "610902",

          },
          {
            "id": 2900,
            "name": "汉阴县",
            "region_code": "610921",

          },
          {
            "id": 2901,
            "name": "石泉县",
            "region_code": "610922",

          },
          {
            "id": 2902,
            "name": "宁陕县",
            "region_code": "610923",

          },
          {
            "id": 2903,
            "name": "紫阳县",
            "region_code": "610924",

          },
          {
            "id": 2904,
            "name": "岚皋县",
            "region_code": "610925",

          },
          {
            "id": 2905,
            "name": "平利县",
            "region_code": "610926",

          },
          {
            "id": 2906,
            "name": "镇坪县",
            "region_code": "610927",

          },
          {
            "id": 2907,
            "name": "旬阳县",
            "region_code": "610928",

          },
          {
            "id": 2908,
            "name": "白河县",
            "region_code": "610929",

          }
        ]
      },
      {
        "id": 2909,
        "name": "商洛市",
        "region_code": "611000",
        "child": [
          {
            "id": 2910,
            "name": "商州区",
            "region_code": "611002",

          },
          {
            "id": 2911,
            "name": "洛南县",
            "region_code": "611021",

          },
          {
            "id": 2912,
            "name": "丹凤县",
            "region_code": "611022",

          },
          {
            "id": 2913,
            "name": "商南县",
            "region_code": "611023",

          },
          {
            "id": 2914,
            "name": "山阳县",
            "region_code": "611024",

          },
          {
            "id": 2915,
            "name": "镇安县",
            "region_code": "611025",

          },
          {
            "id": 2916,
            "name": "柞水县",
            "region_code": "611026",

          }
        ]
      }
    ]
  },
  {
    "id": 2917,
    "name": "甘肃省",
    "region_code": "620000",
    "child": [
      {
        "id": 2918,
        "name": "兰州市",
        "region_code": "620100",
        "child": [
          {
            "id": 2919,
            "name": "城关区",
            "region_code": "620102",

          },
          {
            "id": 2920,
            "name": "七里河区",
            "region_code": "620103",

          },
          {
            "id": 2921,
            "name": "西固区",
            "region_code": "620104",

          },
          {
            "id": 2922,
            "name": "安宁区",
            "region_code": "620105",

          },
          {
            "id": 2923,
            "name": "红古区",
            "region_code": "620111",

          },
          {
            "id": 2924,
            "name": "永登县",
            "region_code": "620121",

          },
          {
            "id": 2925,
            "name": "皋兰县",
            "region_code": "620122",

          },
          {
            "id": 2926,
            "name": "榆中县",
            "region_code": "620123",

          }
        ]
      },
      {
        "id": 2927,
        "name": "嘉峪关市",
        "region_code": "620200",
        "child": [
          {
            "id": 2928,
            "name": "市辖区",
            "region_code": "620201",

          }
        ]
      },
      {
        "id": 2929,
        "name": "金昌市",
        "region_code": "620300",
        "child": [
          {
            "id": 2930,
            "name": "金川区",
            "region_code": "620302",

          },
          {
            "id": 2931,
            "name": "永昌县",
            "region_code": "620321",

          }
        ]
      },
      {
        "id": 2932,
        "name": "白银市",
        "region_code": "620400",
        "child": [
          {
            "id": 2933,
            "name": "白银区",
            "region_code": "620402",

          },
          {
            "id": 2934,
            "name": "平川区",
            "region_code": "620403",

          },
          {
            "id": 2935,
            "name": "靖远县",
            "region_code": "620421",

          },
          {
            "id": 2936,
            "name": "会宁县",
            "region_code": "620422",

          },
          {
            "id": 2937,
            "name": "景泰县",
            "region_code": "620423",

          }
        ]
      },
      {
        "id": 2938,
        "name": "天水市",
        "region_code": "620500",
        "child": [
          {
            "id": 2939,
            "name": "秦州区",
            "region_code": "620502",

          },
          {
            "id": 2940,
            "name": "麦积区",
            "region_code": "620503",

          },
          {
            "id": 2941,
            "name": "清水县",
            "region_code": "620521",

          },
          {
            "id": 2942,
            "name": "秦安县",
            "region_code": "620522",

          },
          {
            "id": 2943,
            "name": "甘谷县",
            "region_code": "620523",

          },
          {
            "id": 2944,
            "name": "武山县",
            "region_code": "620524",

          },
          {
            "id": 2945,
            "name": "张家川回族自治县",
            "region_code": "620525",

          }
        ]
      },
      {
        "id": 2946,
        "name": "武威市",
        "region_code": "620600",
        "child": [
          {
            "id": 2947,
            "name": "凉州区",
            "region_code": "620602",

          },
          {
            "id": 2948,
            "name": "民勤县",
            "region_code": "620621",

          },
          {
            "id": 2949,
            "name": "古浪县",
            "region_code": "620622",

          },
          {
            "id": 2950,
            "name": "天祝藏族自治县",
            "region_code": "620623",

          }
        ]
      },
      {
        "id": 2951,
        "name": "张掖市",
        "region_code": "620700",
        "child": [
          {
            "id": 2952,
            "name": "甘州区",
            "region_code": "620702",

          },
          {
            "id": 2953,
            "name": "肃南裕固族自治县",
            "region_code": "620721",

          },
          {
            "id": 2954,
            "name": "民乐县",
            "region_code": "620722",

          },
          {
            "id": 2955,
            "name": "临泽县",
            "region_code": "620723",

          },
          {
            "id": 2956,
            "name": "高台县",
            "region_code": "620724",

          },
          {
            "id": 2957,
            "name": "山丹县",
            "region_code": "620725",

          }
        ]
      },
      {
        "id": 2958,
        "name": "平凉市",
        "region_code": "620800",
        "child": [
          {
            "id": 2959,
            "name": "崆峒区",
            "region_code": "620802",

          },
          {
            "id": 2960,
            "name": "泾川县",
            "region_code": "620821",

          },
          {
            "id": 2961,
            "name": "灵台县",
            "region_code": "620822",

          },
          {
            "id": 2962,
            "name": "崇信县",
            "region_code": "620823",

          },
          {
            "id": 2963,
            "name": "庄浪县",
            "region_code": "620825",

          },
          {
            "id": 2964,
            "name": "静宁县",
            "region_code": "620826",

          },
          {
            "id": 2965,
            "name": "华亭市",
            "region_code": "620881",

          }
        ]
      },
      {
        "id": 2966,
        "name": "酒泉市",
        "region_code": "620900",
        "child": [
          {
            "id": 2967,
            "name": "肃州区",
            "region_code": "620902",

          },
          {
            "id": 2968,
            "name": "金塔县",
            "region_code": "620921",

          },
          {
            "id": 2969,
            "name": "瓜州县",
            "region_code": "620922",

          },
          {
            "id": 2970,
            "name": "肃北蒙古族自治县",
            "region_code": "620923",

          },
          {
            "id": 2971,
            "name": "阿克塞哈萨克族自治县",
            "region_code": "620924",

          },
          {
            "id": 2972,
            "name": "玉门市",
            "region_code": "620981",

          },
          {
            "id": 2973,
            "name": "敦煌市",
            "region_code": "620982",

          }
        ]
      },
      {
        "id": 2974,
        "name": "庆阳市",
        "region_code": "621000",
        "child": [
          {
            "id": 2975,
            "name": "西峰区",
            "region_code": "621002",

          },
          {
            "id": 2976,
            "name": "庆城县",
            "region_code": "621021",

          },
          {
            "id": 2977,
            "name": "环县",
            "region_code": "621022",

          },
          {
            "id": 2978,
            "name": "华池县",
            "region_code": "621023",

          },
          {
            "id": 2979,
            "name": "合水县",
            "region_code": "621024",

          },
          {
            "id": 2980,
            "name": "正宁县",
            "region_code": "621025",

          },
          {
            "id": 2981,
            "name": "宁县",
            "region_code": "621026",

          },
          {
            "id": 2982,
            "name": "镇原县",
            "region_code": "621027",

          }
        ]
      },
      {
        "id": 2983,
        "name": "定西市",
        "region_code": "621100",
        "child": [
          {
            "id": 2984,
            "name": "安定区",
            "region_code": "621102",

          },
          {
            "id": 2985,
            "name": "通渭县",
            "region_code": "621121",

          },
          {
            "id": 2986,
            "name": "陇西县",
            "region_code": "621122",

          },
          {
            "id": 2987,
            "name": "渭源县",
            "region_code": "621123",

          },
          {
            "id": 2988,
            "name": "临洮县",
            "region_code": "621124",

          },
          {
            "id": 2989,
            "name": "漳县",
            "region_code": "621125",

          },
          {
            "id": 2990,
            "name": "岷县",
            "region_code": "621126",

          }
        ]
      },
      {
        "id": 2991,
        "name": "陇南市",
        "region_code": "621200",
        "child": [
          {
            "id": 2992,
            "name": "武都区",
            "region_code": "621202",

          },
          {
            "id": 2993,
            "name": "成县",
            "region_code": "621221",

          },
          {
            "id": 2994,
            "name": "文县",
            "region_code": "621222",

          },
          {
            "id": 2995,
            "name": "宕昌县",
            "region_code": "621223",

          },
          {
            "id": 2996,
            "name": "康县",
            "region_code": "621224",

          },
          {
            "id": 2997,
            "name": "西和县",
            "region_code": "621225",

          },
          {
            "id": 2998,
            "name": "礼县",
            "region_code": "621226",

          },
          {
            "id": 2999,
            "name": "徽县",
            "region_code": "621227",

          },
          {
            "id": 3000,
            "name": "两当县",
            "region_code": "621228",

          }
        ]
      },
      {
        "id": 3001,
        "name": "临夏回族自治州",
        "region_code": "622900",
        "child": [
          {
            "id": 3002,
            "name": "临夏市",
            "region_code": "622901",

          },
          {
            "id": 3003,
            "name": "临夏县",
            "region_code": "622921",

          },
          {
            "id": 3004,
            "name": "康乐县",
            "region_code": "622922",

          },
          {
            "id": 3005,
            "name": "永靖县",
            "region_code": "622923",

          },
          {
            "id": 3006,
            "name": "广河县",
            "region_code": "622924",

          },
          {
            "id": 3007,
            "name": "和政县",
            "region_code": "622925",

          },
          {
            "id": 3008,
            "name": "东乡族自治县",
            "region_code": "622926",

          },
          {
            "id": 3009,
            "name": "积石山保安族东乡族撒拉族自治县",
            "region_code": "622927",

          }
        ]
      },
      {
        "id": 3010,
        "name": "甘南藏族自治州",
        "region_code": "623000",
        "child": [
          {
            "id": 3011,
            "name": "合作市",
            "region_code": "623001",

          },
          {
            "id": 3012,
            "name": "临潭县",
            "region_code": "623021",

          },
          {
            "id": 3013,
            "name": "卓尼县",
            "region_code": "623022",

          },
          {
            "id": 3014,
            "name": "舟曲县",
            "region_code": "623023",

          },
          {
            "id": 3015,
            "name": "迭部县",
            "region_code": "623024",

          },
          {
            "id": 3016,
            "name": "玛曲县",
            "region_code": "623025",

          },
          {
            "id": 3017,
            "name": "碌曲县",
            "region_code": "623026",

          },
          {
            "id": 3018,
            "name": "夏河县",
            "region_code": "623027",

          }
        ]
      }
    ]
  },
  {
    "id": 3019,
    "name": "青海省",
    "region_code": "630000",
    "child": [
      {
        "id": 3020,
        "name": "西宁市",
        "region_code": "630100",
        "child": [
          {
            "id": 3021,
            "name": "城东区",
            "region_code": "630102",

          },
          {
            "id": 3022,
            "name": "城中区",
            "region_code": "630103",

          },
          {
            "id": 3023,
            "name": "城西区",
            "region_code": "630104",

          },
          {
            "id": 3024,
            "name": "城北区",
            "region_code": "630105",

          },
          {
            "id": 3025,
            "name": "湟中区",
            "region_code": "630106",

          },
          {
            "id": 3026,
            "name": "大通回族土族自治县",
            "region_code": "630121",

          },
          {
            "id": 3027,
            "name": "湟源县",
            "region_code": "630123",

          }
        ]
      },
      {
        "id": 3028,
        "name": "海东市",
        "region_code": "630200",
        "child": [
          {
            "id": 3029,
            "name": "乐都区",
            "region_code": "630202",

          },
          {
            "id": 3030,
            "name": "平安区",
            "region_code": "630203",

          },
          {
            "id": 3031,
            "name": "民和回族土族自治县",
            "region_code": "630222",

          },
          {
            "id": 3032,
            "name": "互助土族自治县",
            "region_code": "630223",

          },
          {
            "id": 3033,
            "name": "化隆回族自治县",
            "region_code": "630224",

          },
          {
            "id": 3034,
            "name": "循化撒拉族自治县",
            "region_code": "630225",

          }
        ]
      },
      {
        "id": 3035,
        "name": "海北藏族自治州",
        "region_code": "632200",
        "child": [
          {
            "id": 3036,
            "name": "门源回族自治县",
            "region_code": "632221",

          },
          {
            "id": 3037,
            "name": "祁连县",
            "region_code": "632222",

          },
          {
            "id": 3038,
            "name": "海晏县",
            "region_code": "632223",

          },
          {
            "id": 3039,
            "name": "刚察县",
            "region_code": "632224",

          }
        ]
      },
      {
        "id": 3040,
        "name": "黄南藏族自治州",
        "region_code": "632300",
        "child": [
          {
            "id": 3041,
            "name": "同仁市",
            "region_code": "632301",

          },
          {
            "id": 3042,
            "name": "尖扎县",
            "region_code": "632322",

          },
          {
            "id": 3043,
            "name": "泽库县",
            "region_code": "632323",

          },
          {
            "id": 3044,
            "name": "河南蒙古族自治县",
            "region_code": "632324",

          }
        ]
      },
      {
        "id": 3045,
        "name": "海南藏族自治州",
        "region_code": "632500",
        "child": [
          {
            "id": 3046,
            "name": "共和县",
            "region_code": "632521",

          },
          {
            "id": 3047,
            "name": "同德县",
            "region_code": "632522",

          },
          {
            "id": 3048,
            "name": "贵德县",
            "region_code": "632523",

          },
          {
            "id": 3049,
            "name": "兴海县",
            "region_code": "632524",

          },
          {
            "id": 3050,
            "name": "贵南县",
            "region_code": "632525",

          }
        ]
      },
      {
        "id": 3051,
        "name": "果洛藏族自治州",
        "region_code": "632600",
        "child": [
          {
            "id": 3052,
            "name": "玛沁县",
            "region_code": "632621",

          },
          {
            "id": 3053,
            "name": "班玛县",
            "region_code": "632622",

          },
          {
            "id": 3054,
            "name": "甘德县",
            "region_code": "632623",

          },
          {
            "id": 3055,
            "name": "达日县",
            "region_code": "632624",

          },
          {
            "id": 3056,
            "name": "久治县",
            "region_code": "632625",

          },
          {
            "id": 3057,
            "name": "玛多县",
            "region_code": "632626",

          }
        ]
      },
      {
        "id": 3058,
        "name": "玉树藏族自治州",
        "region_code": "632700",
        "child": [
          {
            "id": 3059,
            "name": "玉树市",
            "region_code": "632701",

          },
          {
            "id": 3060,
            "name": "杂多县",
            "region_code": "632722",

          },
          {
            "id": 3061,
            "name": "称多县",
            "region_code": "632723",

          },
          {
            "id": 3062,
            "name": "治多县",
            "region_code": "632724",

          },
          {
            "id": 3063,
            "name": "囊谦县",
            "region_code": "632725",

          },
          {
            "id": 3064,
            "name": "曲麻莱县",
            "region_code": "632726",

          }
        ]
      },
      {
        "id": 3065,
        "name": "海西蒙古族藏族自治州",
        "region_code": "632800",
        "child": [
          {
            "id": 3066,
            "name": "格尔木市",
            "region_code": "632801",

          },
          {
            "id": 3067,
            "name": "德令哈市",
            "region_code": "632802",

          },
          {
            "id": 3068,
            "name": "茫崖市",
            "region_code": "632803",

          },
          {
            "id": 3069,
            "name": "乌兰县",
            "region_code": "632821",

          },
          {
            "id": 3070,
            "name": "都兰县",
            "region_code": "632822",

          },
          {
            "id": 3071,
            "name": "天峻县",
            "region_code": "632823",

          }
        ]
      }
    ]
  },
  {
    "id": 3072,
    "name": "宁夏回族自治区",
    "region_code": "640000",
    "child": [
      {
        "id": 3073,
        "name": "银川市",
        "region_code": "640100",
        "child": [
          {
            "id": 3074,
            "name": "兴庆区",
            "region_code": "640104",

          },
          {
            "id": 3075,
            "name": "西夏区",
            "region_code": "640105",

          },
          {
            "id": 3076,
            "name": "金凤区",
            "region_code": "640106",

          },
          {
            "id": 3077,
            "name": "永宁县",
            "region_code": "640121",

          },
          {
            "id": 3078,
            "name": "贺兰县",
            "region_code": "640122",

          },
          {
            "id": 3079,
            "name": "灵武市",
            "region_code": "640181",

          }
        ]
      },
      {
        "id": 3080,
        "name": "石嘴山市",
        "region_code": "640200",
        "child": [
          {
            "id": 3081,
            "name": "大武口区",
            "region_code": "640202",

          },
          {
            "id": 3082,
            "name": "惠农区",
            "region_code": "640205",

          },
          {
            "id": 3083,
            "name": "平罗县",
            "region_code": "640221",

          }
        ]
      },
      {
        "id": 3084,
        "name": "吴忠市",
        "region_code": "640300",
        "child": [
          {
            "id": 3085,
            "name": "利通区",
            "region_code": "640302",

          },
          {
            "id": 3086,
            "name": "红寺堡区",
            "region_code": "640303",

          },
          {
            "id": 3087,
            "name": "盐池县",
            "region_code": "640323",

          },
          {
            "id": 3088,
            "name": "同心县",
            "region_code": "640324",

          },
          {
            "id": 3089,
            "name": "青铜峡市",
            "region_code": "640381",

          }
        ]
      },
      {
        "id": 3090,
        "name": "固原市",
        "region_code": "640400",
        "child": [
          {
            "id": 3091,
            "name": "原州区",
            "region_code": "640402",

          },
          {
            "id": 3092,
            "name": "西吉县",
            "region_code": "640422",

          },
          {
            "id": 3093,
            "name": "隆德县",
            "region_code": "640423",

          },
          {
            "id": 3094,
            "name": "泾源县",
            "region_code": "640424",

          },
          {
            "id": 3095,
            "name": "彭阳县",
            "region_code": "640425",

          }
        ]
      },
      {
        "id": 3096,
        "name": "中卫市",
        "region_code": "640500",
        "child": [
          {
            "id": 3097,
            "name": "沙坡头区",
            "region_code": "640502",

          },
          {
            "id": 3098,
            "name": "中宁县",
            "region_code": "640521",

          },
          {
            "id": 3099,
            "name": "海原县",
            "region_code": "640522",

          }
        ]
      }
    ]
  },
  {
    "id": 3100,
    "name": "新疆维吾尔自治区",
    "region_code": "650000",
    "child": [
      {
        "id": 3101,
        "name": "乌鲁木齐市",
        "region_code": "650100",
        "child": [
          {
            "id": 3102,
            "name": "天山区",
            "region_code": "650102",

          },
          {
            "id": 3103,
            "name": "沙依巴克区",
            "region_code": "650103",

          },
          {
            "id": 3104,
            "name": "新市区",
            "region_code": "650104",

          },
          {
            "id": 3105,
            "name": "水磨沟区",
            "region_code": "650105",

          },
          {
            "id": 3106,
            "name": "头屯河区",
            "region_code": "650106",

          },
          {
            "id": 3107,
            "name": "达坂城区",
            "region_code": "650107",

          },
          {
            "id": 3108,
            "name": "米东区",
            "region_code": "650109",

          },
          {
            "id": 3109,
            "name": "乌鲁木齐县",
            "region_code": "650121",

          }
        ]
      },
      {
        "id": 3110,
        "name": "克拉玛依市",
        "region_code": "650200",
        "child": [
          {
            "id": 3111,
            "name": "独山子区",
            "region_code": "650202",

          },
          {
            "id": 3112,
            "name": "克拉玛依区",
            "region_code": "650203",

          },
          {
            "id": 3113,
            "name": "白碱滩区",
            "region_code": "650204",

          },
          {
            "id": 3114,
            "name": "乌尔禾区",
            "region_code": "650205",

          }
        ]
      },
      {
        "id": 3115,
        "name": "吐鲁番市",
        "region_code": "650400",
        "child": [
          {
            "id": 3116,
            "name": "高昌区",
            "region_code": "650402",

          },
          {
            "id": 3117,
            "name": "鄯善县",
            "region_code": "650421",

          },
          {
            "id": 3118,
            "name": "托克逊县",
            "region_code": "650422",

          }
        ]
      },
      {
        "id": 3119,
        "name": "哈密市",
        "region_code": "650500",
        "child": [
          {
            "id": 3120,
            "name": "伊州区",
            "region_code": "650502",

          },
          {
            "id": 3121,
            "name": "巴里坤哈萨克自治县",
            "region_code": "650521",

          },
          {
            "id": 3122,
            "name": "伊吾县",
            "region_code": "650522",

          }
        ]
      },
      {
        "id": 3123,
        "name": "昌吉回族自治州",
        "region_code": "652300",
        "child": [
          {
            "id": 3124,
            "name": "昌吉市",
            "region_code": "652301",

          },
          {
            "id": 3125,
            "name": "阜康市",
            "region_code": "652302",

          },
          {
            "id": 3126,
            "name": "呼图壁县",
            "region_code": "652323",

          },
          {
            "id": 3127,
            "name": "玛纳斯县",
            "region_code": "652324",

          },
          {
            "id": 3128,
            "name": "奇台县",
            "region_code": "652325",

          },
          {
            "id": 3129,
            "name": "吉木萨尔县",
            "region_code": "652327",

          },
          {
            "id": 3130,
            "name": "木垒哈萨克自治县",
            "region_code": "652328",

          }
        ]
      },
      {
        "id": 3131,
        "name": "博尔塔拉蒙古自治州",
        "region_code": "652700",
        "child": [
          {
            "id": 3132,
            "name": "博乐市",
            "region_code": "652701",

          },
          {
            "id": 3133,
            "name": "阿拉山口市",
            "region_code": "652702",

          },
          {
            "id": 3134,
            "name": "精河县",
            "region_code": "652722",

          },
          {
            "id": 3135,
            "name": "温泉县",
            "region_code": "652723",

          }
        ]
      },
      {
        "id": 3136,
        "name": "巴音郭楞蒙古自治州",
        "region_code": "652800",
        "child": [
          {
            "id": 3137,
            "name": "库尔勒市",
            "region_code": "652801",

          },
          {
            "id": 3138,
            "name": "轮台县",
            "region_code": "652822",

          },
          {
            "id": 3139,
            "name": "尉犁县",
            "region_code": "652823",

          },
          {
            "id": 3140,
            "name": "若羌县",
            "region_code": "652824",

          },
          {
            "id": 3141,
            "name": "且末县",
            "region_code": "652825",

          },
          {
            "id": 3142,
            "name": "焉耆回族自治县",
            "region_code": "652826",

          },
          {
            "id": 3143,
            "name": "和静县",
            "region_code": "652827",

          },
          {
            "id": 3144,
            "name": "和硕县",
            "region_code": "652828",

          },
          {
            "id": 3145,
            "name": "博湖县",
            "region_code": "652829",

          }
        ]
      },
      {
        "id": 3146,
        "name": "阿克苏地区",
        "region_code": "652900",
        "child": [
          {
            "id": 3147,
            "name": "阿克苏市",
            "region_code": "652901",

          },
          {
            "id": 3148,
            "name": "库车市",
            "region_code": "652902",

          },
          {
            "id": 3149,
            "name": "温宿县",
            "region_code": "652922",

          },
          {
            "id": 3150,
            "name": "沙雅县",
            "region_code": "652924",

          },
          {
            "id": 3151,
            "name": "新和县",
            "region_code": "652925",

          },
          {
            "id": 3152,
            "name": "拜城县",
            "region_code": "652926",

          },
          {
            "id": 3153,
            "name": "乌什县",
            "region_code": "652927",

          },
          {
            "id": 3154,
            "name": "阿瓦提县",
            "region_code": "652928",

          },
          {
            "id": 3155,
            "name": "柯坪县",
            "region_code": "652929",

          }
        ]
      },
      {
        "id": 3156,
        "name": "克孜勒苏柯尔克孜自治州",
        "region_code": "653000",
        "child": [
          {
            "id": 3157,
            "name": "阿图什市",
            "region_code": "653001",

          },
          {
            "id": 3158,
            "name": "阿克陶县",
            "region_code": "653022",

          },
          {
            "id": 3159,
            "name": "阿合奇县",
            "region_code": "653023",

          },
          {
            "id": 3160,
            "name": "乌恰县",
            "region_code": "653024",

          }
        ]
      },
      {
        "id": 3161,
        "name": "喀什地区",
        "region_code": "653100",
        "child": [
          {
            "id": 3162,
            "name": "喀什市",
            "region_code": "653101",

          },
          {
            "id": 3163,
            "name": "疏附县",
            "region_code": "653121",

          },
          {
            "id": 3164,
            "name": "疏勒县",
            "region_code": "653122",

          },
          {
            "id": 3165,
            "name": "英吉沙县",
            "region_code": "653123",

          },
          {
            "id": 3166,
            "name": "泽普县",
            "region_code": "653124",

          },
          {
            "id": 3167,
            "name": "莎车县",
            "region_code": "653125",

          },
          {
            "id": 3168,
            "name": "叶城县",
            "region_code": "653126",

          },
          {
            "id": 3169,
            "name": "麦盖提县",
            "region_code": "653127",

          },
          {
            "id": 3170,
            "name": "岳普湖县",
            "region_code": "653128",

          },
          {
            "id": 3171,
            "name": "伽师县",
            "region_code": "653129",

          },
          {
            "id": 3172,
            "name": "巴楚县",
            "region_code": "653130",

          },
          {
            "id": 3173,
            "name": "塔什库尔干塔吉克自治县",
            "region_code": "653131",

          }
        ]
      },
      {
        "id": 3174,
        "name": "和田地区",
        "region_code": "653200",
        "child": [
          {
            "id": 3175,
            "name": "和田市",
            "region_code": "653201",

          },
          {
            "id": 3176,
            "name": "和田县",
            "region_code": "653221",

          },
          {
            "id": 3177,
            "name": "墨玉县",
            "region_code": "653222",

          },
          {
            "id": 3178,
            "name": "皮山县",
            "region_code": "653223",

          },
          {
            "id": 3179,
            "name": "洛浦县",
            "region_code": "653224",

          },
          {
            "id": 3180,
            "name": "策勒县",
            "region_code": "653225",

          },
          {
            "id": 3181,
            "name": "于田县",
            "region_code": "653226",

          },
          {
            "id": 3182,
            "name": "民丰县",
            "region_code": "653227",

          }
        ]
      },
      {
        "id": 3183,
        "name": "伊犁哈萨克自治州",
        "region_code": "654000",
        "child": [
          {
            "id": 3184,
            "name": "伊宁市",
            "region_code": "654002",

          },
          {
            "id": 3185,
            "name": "奎屯市",
            "region_code": "654003",

          },
          {
            "id": 3186,
            "name": "霍尔果斯市",
            "region_code": "654004",

          },
          {
            "id": 3187,
            "name": "伊宁县",
            "region_code": "654021",

          },
          {
            "id": 3188,
            "name": "察布查尔锡伯自治县",
            "region_code": "654022",

          },
          {
            "id": 3189,
            "name": "霍城县",
            "region_code": "654023",

          },
          {
            "id": 3190,
            "name": "巩留县",
            "region_code": "654024",

          },
          {
            "id": 3191,
            "name": "新源县",
            "region_code": "654025",

          },
          {
            "id": 3192,
            "name": "昭苏县",
            "region_code": "654026",

          },
          {
            "id": 3193,
            "name": "特克斯县",
            "region_code": "654027",

          },
          {
            "id": 3194,
            "name": "尼勒克县",
            "region_code": "654028",

          }
        ]
      },
      {
        "id": 3195,
        "name": "塔城地区",
        "region_code": "654200",
        "child": [
          {
            "id": 3196,
            "name": "塔城市",
            "region_code": "654201",

          },
          {
            "id": 3197,
            "name": "乌苏市",
            "region_code": "654202",

          },
          {
            "id": 3198,
            "name": "额敏县",
            "region_code": "654221",

          },
          {
            "id": 3199,
            "name": "沙湾县",
            "region_code": "654223",

          },
          {
            "id": 3200,
            "name": "托里县",
            "region_code": "654224",

          },
          {
            "id": 3201,
            "name": "裕民县",
            "region_code": "654225",

          },
          {
            "id": 3202,
            "name": "和布克赛尔蒙古自治县",
            "region_code": "654226",

          }
        ]
      },
      {
        "id": 3203,
        "name": "阿勒泰地区",
        "region_code": "654300",
        "child": [
          {
            "id": 3204,
            "name": "阿勒泰市",
            "region_code": "654301",

          },
          {
            "id": 3205,
            "name": "布尔津县",
            "region_code": "654321",

          },
          {
            "id": 3206,
            "name": "富蕴县",
            "region_code": "654322",

          },
          {
            "id": 3207,
            "name": "福海县",
            "region_code": "654323",

          },
          {
            "id": 3208,
            "name": "哈巴河县",
            "region_code": "654324",

          },
          {
            "id": 3209,
            "name": "青河县",
            "region_code": "654325",

          },
          {
            "id": 3210,
            "name": "吉木乃县",
            "region_code": "654326",

          }
        ]
      },
      {
        "id": 3211,
        "name": "直辖县",
        "region_code": "659000",
        "child": [
          {
            "id": 3212,
            "name": "石河子市",
            "region_code": "659001",

          },
          {
            "id": 3213,
            "name": "阿拉尔市",
            "region_code": "659002",

          },
          {
            "id": 3214,
            "name": "图木舒克市",
            "region_code": "659003",

          },
          {
            "id": 3215,
            "name": "五家渠市",
            "region_code": "659004",

          },
          {
            "id": 3216,
            "name": "北屯市",
            "region_code": "659005",

          },
          {
            "id": 3217,
            "name": "铁门关市",
            "region_code": "659006",

          },
          {
            "id": 3218,
            "name": "双河市",
            "region_code": "659007",

          },
          {
            "id": 3219,
            "name": "可克达拉市",
            "region_code": "659008",

          },
          {
            "id": 3220,
            "name": "昆玉市",
            "region_code": "659009",

          },
          {
            "id": 3221,
            "name": "胡杨河市",
            "region_code": "659010",

          }
        ]
      }
    ]
  },
  {
    "id": 3222,
    "name": "台湾省",
    "region_code": "710000",
    "child": [
      {
        "id": 3223,
        "name": "台北市",
        "region_code": "710100",
        "child": [
          {
            "id": 3224,
            "name": "中正区",
            "region_code": "710101",

          },
          {
            "id": 3225,
            "name": "大同区",
            "region_code": "710102",

          },
          {
            "id": 3226,
            "name": "中山区",
            "region_code": "710103",

          },
          {
            "id": 3227,
            "name": "万华区",
            "region_code": "710104",

          },
          {
            "id": 3228,
            "name": "信义区",
            "region_code": "710105",

          },
          {
            "id": 3229,
            "name": "松山区",
            "region_code": "710106",

          },
          {
            "id": 3230,
            "name": "大安区",
            "region_code": "710107",

          },
          {
            "id": 3231,
            "name": "南港区",
            "region_code": "710108",

          },
          {
            "id": 3232,
            "name": "北投区",
            "region_code": "710109",

          },
          {
            "id": 3233,
            "name": "内湖区",
            "region_code": "710110",

          },
          {
            "id": 3234,
            "name": "士林区",
            "region_code": "710111",

          },
          {
            "id": 3235,
            "name": "文山区",
            "region_code": "710112",

          }
        ]
      },
      {
        "id": 3236,
        "name": "新北市",
        "region_code": "710200",
        "child": [
          {
            "id": 3237,
            "name": "板桥区",
            "region_code": "710201",

          },
          {
            "id": 3238,
            "name": "土城区",
            "region_code": "710202",

          },
          {
            "id": 3239,
            "name": "新庄区",
            "region_code": "710203",

          },
          {
            "id": 3240,
            "name": "新店区",
            "region_code": "710204",

          },
          {
            "id": 3241,
            "name": "深坑区",
            "region_code": "710205",

          },
          {
            "id": 3242,
            "name": "石碇区",
            "region_code": "710206",

          },
          {
            "id": 3243,
            "name": "坪林区",
            "region_code": "710207",

          },
          {
            "id": 3244,
            "name": "乌来区",
            "region_code": "710208",

          },
          {
            "id": 3245,
            "name": "五股区",
            "region_code": "710209",

          },
          {
            "id": 3246,
            "name": "八里区",
            "region_code": "710210",

          },
          {
            "id": 3247,
            "name": "林口区",
            "region_code": "710211",

          },
          {
            "id": 3248,
            "name": "淡水区",
            "region_code": "710212",

          },
          {
            "id": 3249,
            "name": "中和区",
            "region_code": "710213",

          },
          {
            "id": 3250,
            "name": "永和区",
            "region_code": "710214",

          },
          {
            "id": 3251,
            "name": "三重区",
            "region_code": "710215",

          },
          {
            "id": 3252,
            "name": "芦洲区",
            "region_code": "710216",

          },
          {
            "id": 3253,
            "name": "泰山区",
            "region_code": "710217",

          },
          {
            "id": 3254,
            "name": "树林区",
            "region_code": "710218",

          },
          {
            "id": 3255,
            "name": "莺歌区",
            "region_code": "710219",

          },
          {
            "id": 3256,
            "name": "三峡区",
            "region_code": "710220",

          },
          {
            "id": 3257,
            "name": "汐止区",
            "region_code": "710221",

          },
          {
            "id": 3258,
            "name": "金山区",
            "region_code": "710222",

          },
          {
            "id": 3259,
            "name": "万里区",
            "region_code": "710223",

          },
          {
            "id": 3260,
            "name": "三芝区",
            "region_code": "710224",

          },
          {
            "id": 3261,
            "name": "石门区",
            "region_code": "710225",

          },
          {
            "id": 3262,
            "name": "瑞芳区",
            "region_code": "710226",

          },
          {
            "id": 3263,
            "name": "贡寮区",
            "region_code": "710227",

          },
          {
            "id": 3264,
            "name": "双溪区",
            "region_code": "710228",

          },
          {
            "id": 3265,
            "name": "平溪区",
            "region_code": "710229",

          }
        ]
      },
      {
        "id": 3266,
        "name": "桃园市",
        "region_code": "710300",
        "child": [
          {
            "id": 3267,
            "name": "桃园区",
            "region_code": "710301",

          },
          {
            "id": 3268,
            "name": "中坜区",
            "region_code": "710302",

          },
          {
            "id": 3269,
            "name": "平镇区",
            "region_code": "710303",

          },
          {
            "id": 3270,
            "name": "八德区",
            "region_code": "710304",

          },
          {
            "id": 3271,
            "name": "杨梅区",
            "region_code": "710305",

          },
          {
            "id": 3272,
            "name": "芦竹区",
            "region_code": "710306",

          },
          {
            "id": 3273,
            "name": "大溪区",
            "region_code": "710307",

          },
          {
            "id": 3274,
            "name": "龙潭区",
            "region_code": "710308",

          },
          {
            "id": 3275,
            "name": "龟山区",
            "region_code": "710309",

          },
          {
            "id": 3276,
            "name": "大园区",
            "region_code": "710310",

          },
          {
            "id": 3277,
            "name": "观音区",
            "region_code": "710311",

          },
          {
            "id": 3278,
            "name": "新屋区",
            "region_code": "710312",

          },
          {
            "id": 3279,
            "name": "复兴区",
            "region_code": "710313",

          }
        ]
      },
      {
        "id": 3280,
        "name": "台中市",
        "region_code": "710400",
        "child": [
          {
            "id": 3281,
            "name": "中区",
            "region_code": "710401",

          },
          {
            "id": 3282,
            "name": "东区",
            "region_code": "710402",

          },
          {
            "id": 3283,
            "name": "西区",
            "region_code": "710403",

          },
          {
            "id": 3284,
            "name": "南区",
            "region_code": "710404",

          },
          {
            "id": 3285,
            "name": "北区",
            "region_code": "710405",

          },
          {
            "id": 3286,
            "name": "西屯区",
            "region_code": "710406",

          },
          {
            "id": 3287,
            "name": "南屯区",
            "region_code": "710407",

          },
          {
            "id": 3288,
            "name": "北屯区",
            "region_code": "710408",

          },
          {
            "id": 3289,
            "name": "丰原区",
            "region_code": "710409",

          },
          {
            "id": 3290,
            "name": "大里区",
            "region_code": "710410",

          },
          {
            "id": 3291,
            "name": "太平区",
            "region_code": "710411",

          },
          {
            "id": 3292,
            "name": "东势区",
            "region_code": "710412",

          },
          {
            "id": 3293,
            "name": "大甲区",
            "region_code": "710413",

          },
          {
            "id": 3294,
            "name": "清水区",
            "region_code": "710414",

          },
          {
            "id": 3295,
            "name": "沙鹿区",
            "region_code": "710415",

          },
          {
            "id": 3296,
            "name": "梧栖区",
            "region_code": "710416",

          },
          {
            "id": 3297,
            "name": "后里区",
            "region_code": "710417",

          },
          {
            "id": 3298,
            "name": "神冈区",
            "region_code": "710418",

          },
          {
            "id": 3299,
            "name": "潭子区",
            "region_code": "710419",

          },
          {
            "id": 3300,
            "name": "大雅区",
            "region_code": "710420",

          },
          {
            "id": 3301,
            "name": "新小区",
            "region_code": "710421",

          },
          {
            "id": 3302,
            "name": "石冈区",
            "region_code": "710422",

          },
          {
            "id": 3303,
            "name": "外埔区",
            "region_code": "710423",

          },
          {
            "id": 3304,
            "name": "大安区",
            "region_code": "710424",

          },
          {
            "id": 3305,
            "name": "乌日区",
            "region_code": "710425",

          },
          {
            "id": 3306,
            "name": "大肚区",
            "region_code": "710426",

          },
          {
            "id": 3307,
            "name": "龙井区",
            "region_code": "710427",

          },
          {
            "id": 3308,
            "name": "雾峰区",
            "region_code": "710428",

          },
          {
            "id": 3309,
            "name": "和平区",
            "region_code": "710429",

          }
        ]
      },
      {
        "id": 3310,
        "name": "台南市",
        "region_code": "710500",
        "child": [
          {
            "id": 3311,
            "name": "中西区",
            "region_code": "710501",

          },
          {
            "id": 3312,
            "name": "东区",
            "region_code": "710502",

          },
          {
            "id": 3313,
            "name": "南区",
            "region_code": "710503",

          },
          {
            "id": 3314,
            "name": "北区",
            "region_code": "710504",

          },
          {
            "id": 3315,
            "name": "安平区",
            "region_code": "710505",

          },
          {
            "id": 3316,
            "name": "安南区",
            "region_code": "710506",

          },
          {
            "id": 3317,
            "name": "永康区",
            "region_code": "710507",

          },
          {
            "id": 3318,
            "name": "归仁区",
            "region_code": "710508",

          },
          {
            "id": 3319,
            "name": "新化区",
            "region_code": "710509",

          },
          {
            "id": 3320,
            "name": "左镇区",
            "region_code": "710510",

          },
          {
            "id": 3321,
            "name": "玉井区",
            "region_code": "710511",

          },
          {
            "id": 3322,
            "name": "楠西区",
            "region_code": "710512",

          },
          {
            "id": 3323,
            "name": "南化区",
            "region_code": "710513",

          },
          {
            "id": 3324,
            "name": "仁德区",
            "region_code": "710514",

          },
          {
            "id": 3325,
            "name": "关庙区",
            "region_code": "710515",

          },
          {
            "id": 3326,
            "name": "龙崎区",
            "region_code": "710516",

          },
          {
            "id": 3327,
            "name": "官田区",
            "region_code": "710517",

          },
          {
            "id": 3328,
            "name": "麻豆区",
            "region_code": "710518",

          },
          {
            "id": 3329,
            "name": "佳里区",
            "region_code": "710519",

          },
          {
            "id": 3330,
            "name": "西港区",
            "region_code": "710520",

          },
          {
            "id": 3331,
            "name": "七股区",
            "region_code": "710521",

          },
          {
            "id": 3332,
            "name": "将军区",
            "region_code": "710522",

          },
          {
            "id": 3333,
            "name": "学甲区",
            "region_code": "710523",

          },
          {
            "id": 3334,
            "name": "北门区",
            "region_code": "710524",

          },
          {
            "id": 3335,
            "name": "新营区",
            "region_code": "710525",

          },
          {
            "id": 3336,
            "name": "后壁区",
            "region_code": "710526",

          },
          {
            "id": 3337,
            "name": "白河区",
            "region_code": "710527",

          },
          {
            "id": 3338,
            "name": "东山区",
            "region_code": "710528",

          },
          {
            "id": 3339,
            "name": "六甲区",
            "region_code": "710529",

          },
          {
            "id": 3340,
            "name": "下营区",
            "region_code": "710530",

          },
          {
            "id": 3341,
            "name": "柳营区",
            "region_code": "710531",

          },
          {
            "id": 3342,
            "name": "盐水区",
            "region_code": "710532",

          },
          {
            "id": 3343,
            "name": "善化区",
            "region_code": "710533",

          },
          {
            "id": 3344,
            "name": "大内区",
            "region_code": "710534",

          },
          {
            "id": 3345,
            "name": "山上区",
            "region_code": "710535",

          },
          {
            "id": 3346,
            "name": "新市区",
            "region_code": "710536",

          },
          {
            "id": 3347,
            "name": "安定区",
            "region_code": "710537",

          }
        ]
      },
      {
        "id": 3348,
        "name": "高雄市",
        "region_code": "710600",
        "child": [
          {
            "id": 3349,
            "name": "楠梓区",
            "region_code": "710601",

          },
          {
            "id": 3350,
            "name": "左营区",
            "region_code": "710602",

          },
          {
            "id": 3351,
            "name": "鼓山区",
            "region_code": "710603",

          },
          {
            "id": 3352,
            "name": "三民区",
            "region_code": "710604",

          },
          {
            "id": 3353,
            "name": "盐埕区",
            "region_code": "710605",

          },
          {
            "id": 3354,
            "name": "前金区",
            "region_code": "710606",

          },
          {
            "id": 3355,
            "name": "新兴区",
            "region_code": "710607",

          },
          {
            "id": 3356,
            "name": "苓雅区",
            "region_code": "710608",

          },
          {
            "id": 3357,
            "name": "前镇区",
            "region_code": "710609",

          },
          {
            "id": 3358,
            "name": "旗津区",
            "region_code": "710610",

          },
          {
            "id": 3359,
            "name": "小港区",
            "region_code": "710611",

          },
          {
            "id": 3360,
            "name": "凤山区",
            "region_code": "710612",

          },
          {
            "id": 3361,
            "name": "大寮区",
            "region_code": "710613",

          },
          {
            "id": 3362,
            "name": "鸟松区",
            "region_code": "710614",

          },
          {
            "id": 3363,
            "name": "林园区",
            "region_code": "710615",

          },
          {
            "id": 3364,
            "name": "仁武区",
            "region_code": "710616",

          },
          {
            "id": 3365,
            "name": "大树区",
            "region_code": "710617",

          },
          {
            "id": 3366,
            "name": "大社区",
            "region_code": "710618",

          },
          {
            "id": 3367,
            "name": "冈山区",
            "region_code": "710619",

          },
          {
            "id": 3368,
            "name": "路竹区",
            "region_code": "710620",

          },
          {
            "id": 3369,
            "name": "桥头区",
            "region_code": "710621",

          },
          {
            "id": 3370,
            "name": "梓官区",
            "region_code": "710622",

          },
          {
            "id": 3371,
            "name": "弥陀区",
            "region_code": "710623",

          },
          {
            "id": 3372,
            "name": "永安区",
            "region_code": "710624",

          },
          {
            "id": 3373,
            "name": "燕巢区",
            "region_code": "710625",

          },
          {
            "id": 3374,
            "name": "阿莲区",
            "region_code": "710626",

          },
          {
            "id": 3375,
            "name": "茄萣区",
            "region_code": "710627",

          },
          {
            "id": 3376,
            "name": "湖内区",
            "region_code": "710628",

          },
          {
            "id": 3377,
            "name": "旗山区",
            "region_code": "710629",

          },
          {
            "id": 3378,
            "name": "美浓区",
            "region_code": "710630",

          },
          {
            "id": 3379,
            "name": "内门区",
            "region_code": "710631",

          },
          {
            "id": 3380,
            "name": "杉林区",
            "region_code": "710632",

          },
          {
            "id": 3381,
            "name": "甲仙区",
            "region_code": "710633",

          },
          {
            "id": 3382,
            "name": "六龟区",
            "region_code": "710634",

          },
          {
            "id": 3383,
            "name": "茂林区",
            "region_code": "710635",

          },
          {
            "id": 3384,
            "name": "桃源区",
            "region_code": "710636",

          },
          {
            "id": 3385,
            "name": "那玛夏区",
            "region_code": "710637",

          }
        ]
      },
      {
        "id": 3386,
        "name": "基隆市",
        "region_code": "710700",
        "child": [
          {
            "id": 3387,
            "name": "中正区",
            "region_code": "710701",

          },
          {
            "id": 3388,
            "name": "七堵区",
            "region_code": "710702",

          },
          {
            "id": 3389,
            "name": "暖暖区",
            "region_code": "710703",

          },
          {
            "id": 3390,
            "name": "仁爱区",
            "region_code": "710704",

          },
          {
            "id": 3391,
            "name": "中山区",
            "region_code": "710705",

          },
          {
            "id": 3392,
            "name": "安乐区",
            "region_code": "710706",

          },
          {
            "id": 3393,
            "name": "信义区",
            "region_code": "710707",

          }
        ]
      },
      {
        "id": 3394,
        "name": "新竹市",
        "region_code": "710800",
        "child": [
          {
            "id": 3395,
            "name": "东区",
            "region_code": "710801",

          },
          {
            "id": 3396,
            "name": "北区",
            "region_code": "710802",

          },
          {
            "id": 3397,
            "name": "香山区",
            "region_code": "710803",

          }
        ]
      },
      {
        "id": 3398,
        "name": "嘉义市",
        "region_code": "710900",
        "child": [
          {
            "id": 3399,
            "name": "东区",
            "region_code": "710901",

          },
          {
            "id": 3400,
            "name": "西区",
            "region_code": "710902",

          }
        ]
      },
      {
        "id": 3401,
        "name": "直辖县",
        "region_code": "719000",
        "child": [
          {
            "id": 3402,
            "name": "宜兰县",
            "region_code": "719001",

          },
          {
            "id": 3403,
            "name": "新竹县",
            "region_code": "719002",

          },
          {
            "id": 3404,
            "name": "苗栗县",
            "region_code": "719003",

          },
          {
            "id": 3405,
            "name": "彰化县",
            "region_code": "719004",

          },
          {
            "id": 3406,
            "name": "南投县",
            "region_code": "719005",

          },
          {
            "id": 3407,
            "name": "嘉义县",
            "region_code": "719006",

          },
          {
            "id": 3408,
            "name": "云林县",
            "region_code": "719007",

          },
          {
            "id": 3409,
            "name": "屏东县",
            "region_code": "719008",

          },
          {
            "id": 3410,
            "name": "台东县",
            "region_code": "719009",

          },
          {
            "id": 3411,
            "name": "花莲县",
            "region_code": "719010",

          },
          {
            "id": 3412,
            "name": "澎湖县",
            "region_code": "719011",

          },
          {
            "id": 3413,
            "name": "金门县",
            "region_code": "719012",

          },
          {
            "id": 3414,
            "name": "连江县",
            "region_code": "719013",

          }
        ]
      }
    ]
  },
  {
    "id": 3415,
    "name": "香港特别行政区",
    "region_code": "810000",
    "child": [
      {
        "id": 3416,
        "name": "辖区",
        "region_code": "810100",
        "child": [
          {
            "id": 3417,
            "name": "中西区",
            "region_code": "810101",

          },
          {
            "id": 3418,
            "name": "湾仔区",
            "region_code": "810102",

          },
          {
            "id": 3419,
            "name": "东区",
            "region_code": "810103",

          },
          {
            "id": 3420,
            "name": "南区",
            "region_code": "810104",

          },
          {
            "id": 3421,
            "name": "油尖旺区",
            "region_code": "810105",

          },
          {
            "id": 3422,
            "name": "深水埗区",
            "region_code": "810106",

          },
          {
            "id": 3423,
            "name": "九龙城区",
            "region_code": "810107",

          },
          {
            "id": 3424,
            "name": "黄大仙区",
            "region_code": "810108",

          },
          {
            "id": 3425,
            "name": "观塘区",
            "region_code": "810109",

          },
          {
            "id": 3426,
            "name": "北区",
            "region_code": "810110",

          },
          {
            "id": 3427,
            "name": "大埔区",
            "region_code": "810111",

          },
          {
            "id": 3428,
            "name": "沙田区",
            "region_code": "810112",

          },
          {
            "id": 3429,
            "name": "西贡区",
            "region_code": "810113",

          },
          {
            "id": 3430,
            "name": "荃湾区",
            "region_code": "810114",

          },
          {
            "id": 3431,
            "name": "屯门区",
            "region_code": "810115",

          },
          {
            "id": 3432,
            "name": "元朗区",
            "region_code": "810116",

          },
          {
            "id": 3433,
            "name": "葵青区",
            "region_code": "810117",

          },
          {
            "id": 3434,
            "name": "离岛区",
            "region_code": "810118",

          }
        ]
      }
    ]
  },
  {
    "id": 3435,
    "name": "澳门特别行政区",
    "region_code": "820000",
    "child": [
      {
        "id": 3436,
        "name": "辖区",
        "region_code": "820100",
        "child": [
          {
            "id": 3437,
            "name": "花地玛堂区",
            "region_code": "820101",

          },
          {
            "id": 3438,
            "name": "圣安多尼堂区",
            "region_code": "820102",

          },
          {
            "id": 3439,
            "name": "大堂区",
            "region_code": "820103",

          },
          {
            "id": 3440,
            "name": "望德堂区",
            "region_code": "820104",

          },
          {
            "id": 3441,
            "name": "风顺堂区",
            "region_code": "820105",

          },
          {
            "id": 3442,
            "name": "嘉模堂区",
            "region_code": "820106",

          },
          {
            "id": 3443,
            "name": "圣方济各堂区",
            "region_code": "820107",

          },
          {
            "id": 3444,
            "name": "路氹城",
            "region_code": "820108",

          },
          {
            "id": 3445,
            "name": "澳门新城",
            "region_code": "820109",

          }
        ]
      }
    ]
  }
];

onMounted(()=>{
  //初始默认显示文字
  if((defaultData.value == undefined) || (defaultData.value == '') || (defaultData.value == 0)){
    // areaText.value = '福建省/厦门市/湖里区'
    // areaValue.value = '350206'
    areaText.value = ''
    areaValue.value = '0'
    props.setData(name.value, areaText.value);
  }else{
    areaText.value = defaultData.value;

    let areaTempArr = areaText.value.split('/');
    let provinceText,cityText,countyText,provinceChild,cityChild;
    //默认的字段值
    for(let i in areaoptions){
      provinceText = areaoptions[i]['name'];//省
      if(provinceText == areaTempArr[0]){
        provinceChild = areaoptions[i]['child'];//省下级，市列表
        for(let b in provinceChild){
          cityText = provinceChild[b].name;//市名
          if(cityText == areaTempArr[1]){
            cityChild = provinceChild[b]['child'];//市下级，区县列表
            for(let c in cityChild){
              countyText = cityChild[c].name;//区县名
              if(countyText == areaTempArr[2]){
                areaValue.value = cityChild[c].region_code;//区县编码
                break;
              }
            }
            break;
          }
        }
        break;
      }
    }
  }
});

// 全部选项选择完毕后，会触发 finish 事件
const onFinish = ({ selectedOptions }) => {
  areaShow.value = false;
  //areaText需要手动赋值
  areaText.value = selectedOptions.map((option) => option.name).join("/");
  props.setData(name.value, areaText.value);
  //areaValue的值无需手动赋值，组件会自动赋值，值是最后一级地区编码，比如350206,
};
const onClickOverlay = () => {
  areaShow.value = false;
  props.setData(name.value, 'overlay');
};
///监听控制点击时展示地区弹窗
watch(boxShow, (newData) => {
  if (newData) {
    areaShow.value = true;
  } else {
    areaShow.value = false;
  }
});
</script>

<style lang="less" scoped>
.formitem {
  margin-bottom: 30px;
}
</style>
